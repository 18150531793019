import { Action } from 'aos-helpers/src/helpers/ActionCreator'
import { findGateFeature, findStandFeature } from 'aos-services/src/core/bimLayersData/sagas'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { GatesProperties } from 'aos-services/src/services/layerData/properties/GatesProperties'
import { StandProperties } from 'aos-services/src/services/layerData/properties/StandProperties'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import {
    SelectedMapElement,
    SelectedMapElementType,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import {
    NavigationMapElement,
    NavigationMapElementType,
} from 'aos-services/src/services/mapui/types/NavigationMapElement'
import { Feature, Point } from 'geojson'
import { toLonLat } from 'ol/proj'
import { put } from 'redux-saga/effects'

import {
    MapAction,
    mapPositionChangedAction,
    selectFeatureAction,
    switchMapAction,
} from './actions'

export function* navigateToMap(
    parentAction: (v: MapAction) => Action<any>,
    target: NavigationMapElement,
) {
    function* navigateToPoint(mapVariant: MapVariant, feature: Feature<Point, any>) {
        const lonLat = toLonLat(feature.geometry.coordinates)
        const position: MapPosition = {
            zoom: 18,
            lon: lonLat[0],
            lat: lonLat[1],
        }
        yield put(parentAction(switchMapAction(mapVariant)))
        yield put(parentAction(mapPositionChangedAction([mapVariant, position])))
    }
    switch (target.type) {
        case NavigationMapElementType.Gate:
            const gateId = target.gateId
            const gate: [Feature<Point, GatesProperties>, SelectedMapElementType] | undefined =
                yield findGateFeature(gateId)
            if (gate) {
                yield navigateToPoint(MapVariant.MainAirport, gate[0])
                yield put(
                    parentAction(
                        selectFeatureAction({
                            type: gate[1],
                            payload: gate[0].properties,
                        } as SelectedMapElement),
                    ),
                )
            }
            break
        case NavigationMapElementType.Stand:
            const standId = target.standId
            const stand: Feature<Point, StandProperties> | undefined = yield findStandFeature(
                standId,
            )
            if (stand) {
                yield navigateToPoint(MapVariant.MainAirport, stand)
                yield put(
                    parentAction(
                        selectFeatureAction({
                            type: SelectedMapElementType.Stand,
                            payload: stand.properties,
                        }),
                    ),
                )
            }
            break
    }
}

export const commonMapSagas = []
