import { flatten } from 'lodash'
import React from 'react'

import { DropdownItemProps } from './base/BaseDropdown'
import { dropdownHeight, dropdownPadding } from './base/DropdownContent'
import { DropdownItem } from './base/DropdownItem'
import { DropdownVariant } from './base/DropdownVariant'
import { Dropdown, DropdownProps } from './Dropdown'

export interface DropdownGroupsProps<T> extends Omit<DropdownProps<T>, 'items'> {
    items: T[][]
}

export const DropdownGroups = <T extends any>({
    items,
    value,
    variant = DropdownVariant.White,
    small,
    valueRenderer,
    ...dropdownProps
}: DropdownGroupsProps<T>) => {
    const height = dropdownHeight(variant, small)
    const padding = dropdownPadding(variant)
    const borderIndexes = calcIndexes(items.map(arr => arr.length))

    const itemRenderer = ({ onClick, variant, index, v }: DropdownItemProps<T>) => (
        <DropdownItem
            onClick={onClick}
            variant={variant}
            isSelected={value === v}
            height={height}
            padding={padding}
            separator={borderIndexes.some(i => index === i)}
        >
            {valueRenderer(v)}
        </DropdownItem>
    )

    return (
        <Dropdown
            items={flatten(items)}
            variant={variant}
            {...dropdownProps}
            small={small}
            value={value}
            valueRenderer={valueRenderer}
            itemRenderer={itemRenderer}
        />
    )
}

// Sum with prev values eg. [1, 2, 5] -> [1, 3, 8]
const calcIndexes = (arr: number[]) =>
    arr.map((_, index) => arr.slice(0, index + 1).reduce((a, b) => a + b))
