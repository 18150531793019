import Kdl from './kardla.svg'
import Eku from './kihnu.svg'
import Ure from './kuressaare.svg'
import Epu from './parnu.svg'
import Eru from './ruhnu.svg'
import Tll from './tallinn.svg'
import Tay from './tartu.svg'

export default {
    Kdl,
    Ure,
    Eku,
    Epu,
    Eru,
    Tay,
    Tll,
}
