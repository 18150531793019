import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { DateTime, isToday } from 'aos-helpers/src/helpers/Time'
import { formatDate, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

export interface TimeCellProps extends ClassNameProps {
    value?: DateTime
}

export class TimeCell extends PureComponent<TimeCellProps> {
    public render() {
        const { value, className } = this.props

        if (!value || !value.isValid()) {
            return null
        }

        return (
            <Box className={className}>
                <Text color={Color.WidgetPrimary}>{formatTime(value)}</Text>
                {!isToday(value) && this.renderDate(value)}
            </Box>
        )
    }

    private renderDate(t: DateTime) {
        return <span className='text__xxs-light-yellow text--lh-large'>{formatDate(t)}</span>
    }
}
