import { logger } from 'aos-helpers/src/helpers/logging/Logger'

export function createPlaySoundSaga() {
    function* playSoundSaga(src: string) {
        const audio = new Audio(src)
        try {
            yield audio.play()
        } catch (e) {
            logger.log('Failed to play sound')
        }
    }

    return playSoundSaga
}
