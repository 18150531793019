import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { mapEngine } from 'aos-map-engine/src'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { chain } from 'lodash'

export const allBimLayerCategories = (airport: AosAirport) =>
    chain(mapEngine.getLayersForCurrentAirport(airport))
        .groupBy(q => q.category)
        .keys()
        .value()

export const translateBimLayerCategory = (category: string) =>
    translate(`bim.layer.category.${category}`)

export enum KnownLayerCategories {
    Atc = 'atc',
    TasksAndEvents = 'tasks-and-events',
}
