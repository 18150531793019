import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

interface InfoProps {
    icon?: Svg
}

export const Info: FCWithChildren<InfoProps> = props => {
    const { children, icon = SvgIcon.Info } = props
    return (
        <Text row alignItems='flex-start' color={Color.Grey2} size={13}>
            <Icon svg={icon} iconSize={BlockSize.Std} />
            <Text paddingLeft={8} lineHeight='standard'>
                {children}
            </Text>
        </Text>
    )
}
