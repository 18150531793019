import { appConfig } from 'appConfig'

import AirlineGroundHandling from './airline-ground-handling.svg'
import AOIMS from './aoims/index'
import AOS from './aos/index'
import Info from './info.svg'
import World from './world.svg'

const iconConfig: Record<string, any> = {
    AOS,
    AOIMS,
}

export const ScenariosIcons = {
    ...iconConfig[appConfig.theme],
    AirlineGroundHandling,
    Info,
    World,
}
