import {
    IExportForm,
    mapFormToExportTaskPayload,
    TaskExport,
} from 'aos-helpers/src/helpers/TaskExport'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import {
    taskCategories,
    TaskCategory,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconBoxButton } from 'aos-ui/src/components/buttons/IconBoxButton'
import { CurrentTime } from 'aos-ui/src/components/CurrentTime'
import { JumpToDate } from 'aos-ui/src/components/form/datetime/JumpToDate'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useState } from 'react'

import { ExportTasksModal } from '../common/export/ExportTasksModal'
import { FilterType, TasksHeaderFilter } from '../common/TasksHeaderFilter'

interface TasksTimelineHeaderProps {
    date: DateTime
    categories: TaskCategory[]
    changeCategory(t: TaskCategory[]): void
    onSelectDate(d: DateTime): void
    jumpToNow(): void
    zoomIn(): void
    zoomOut(): void
    exportTasks(r: TaskExport): void
}

export const TasksTimelineHeader: FC<TasksTimelineHeaderProps> = ({
    date,
    onSelectDate,
    jumpToNow,
    zoomIn,
    zoomOut,
    categories,
    changeCategory,
    exportTasks,
}) => {
    const [exportTaskOpen, setExportTaskOpen] = useState(false)
    const submit = (r: IExportForm) => {
        setExportTaskOpen(false)
        exportTasks(mapFormToExportTaskPayload(r))
    }
    const clearAction = () => {
        changeCategory([])
    }
    return (
        <HeaderContainer title={<TasksHeaderFilter value={FilterType.Timeline} />}>
            <Box flex={1} row justify='flex-end' shrink={0}>
                <Box marginRight={30}>
                    <CheckboxDropdown
                        variant={DropdownVariant.Black}
                        items={taskCategories}
                        value={categories}
                        allContent='All categories'
                        partialContent={i => `Categories: ${i.length}`}
                        valueRenderer={translateTaskCategory}
                        onChange={changeCategory}
                        clearAction={clearAction}
                        width={DropdownWidth.Std}
                        preventEmpty
                    />
                </Box>
                <JumpToDate marginRight={30} onChange={onSelectDate} />
                <CurrentTime marginRight={30} onClick={jumpToNow} date={date} />
                <IconBoxButton id='zoom-in' svg={SvgIcon.ZoomIn} marginRight={8} onClick={zoomIn} />
                <IconBoxButton id='zoom-out' svg={SvgIcon.ZoomOut} onClick={zoomOut} />
                <More iconVariant={IconVariant.Smart} marginLeft={16}>
                    <MoreItem onClick={() => setExportTaskOpen(true)}>
                        {translate('export-tasks.title')}
                    </MoreItem>
                </More>
            </Box>
            <ExportTasksModal
                initialTaskVariant={TaskVariant.TASKS}
                isOpen={exportTaskOpen}
                onClose={() => setExportTaskOpen(false)}
                onSubmit={submit}
            />
        </HeaderContainer>
    )
}
