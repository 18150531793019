import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { InvitationRole } from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { PrivateChannelParticipant } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import moment from 'moment'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

interface InviteEtaProps {
    status: InvitationRole | undefined
    onBack: () => void
    me: PrivateChannelParticipant
    onSubmit: (eta: DateTime | undefined) => void
}
export const InviteEta: FC<InviteEtaProps> = ({ status, onBack, me, onSubmit }) => {
    const [eta, setEta] = useState(me.arrivalTime)
    const getRoleIcon = () => {
        switch (status) {
            case InvitationRole.Command:
                return (
                    <Box column alignItems='center'>
                        <SvgImage svg={SvgIcon.ParticipantCommand} />
                        <Text marginTop={12}>
                            {translate(`private-channel.list-description.role.command`)}
                        </Text>
                    </Box>
                )
            case InvitationRole.Participate:
                return (
                    <Box column alignItems='center'>
                        <SvgImage svg={SvgIcon.ParticipantComing} />
                        <Text marginTop={12}>
                            {translate('private-channel.list-description.role.participate')}
                        </Text>
                    </Box>
                )
            default:
                return undefined
        }
    }

    return (
        <Box centered column rowGap={16}>
            <Box row justify='space-between' width='100%'>
                <Box row columnGap={4}>
                    <IconButton
                        onClick={onBack}
                        svg={SvgIcon.Back}
                        iconVariant={IconVariant.White}
                    />
                    <Text>{translate('back')}</Text>
                </Box>
            </Box>
            <Box column>{getRoleIcon()}</Box>
            <Text size={12}>
                {`${translate('private-channel.provideEta')} `}
                <Text as='span' size={12} weight='bold'>
                    {translate('private-channel.eta')}
                </Text>
            </Text>
            <DarkTimeInput
                onChange={setEta}
                value={eta || dateTime(moment())}
                viewMode='time'
                placeholder={translate('i-will-arrive.placeholder')}
            />
            <Text size={12} justify='center' width={200}>
                {`${translate('private-channel.eta.message-pt1')} `}
                <Text as='span' size={12} weight='bold'>
                    {`"${translate('private-channel.status.button.coming')}"`}
                </Text>
                {` ${translate('private-channel.eta.message-pt2')} `}
                <Text as='span' size={12} weight='bold'>
                    {`"${translate('private-channel.status.button.at-airport')}"`}
                </Text>
            </Text>
            <Box
                row
                justify='space-between'
                fullWidth
                marginTop={12}
                paddingLeft={12}
                paddingRight={12}
            >
                <FormButton
                    fullWidth
                    disabled={!eta}
                    onClick={() => onSubmit(eta)}
                    label={translate('private-channel.set')}
                    variant={FormButtonVariant.Primary}
                />
            </Box>
        </Box>
    )
}

const DarkTimeInput = styled(DatetimeInput)`
    input {
        border-radius: 0;
    }
`
