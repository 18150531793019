import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent, ReactNode } from 'react'

import { BigItemStatsContainer, ChartItemContainer, ItemContainer } from './ItemContainer'

interface FlightChartStatsWrapperProps {
    flightTable: () => ReactNode
    flightTableTitle: string
    chart: ReactNode
    statsContent: ReactNode
    showChart: boolean
}

export class FlightChartStatsWrapper extends PureComponent<FlightChartStatsWrapperProps> {
    public render() {
        return (
            <>
                <BigItemStatsContainer>{this.props.statsContent}</BigItemStatsContainer>
                {this.renderContent()}
            </>
        )
    }

    private renderContent() {
        return this.props.showChart ? this.renderChart() : this.renderFlights()
    }

    private renderChart() {
        return <ChartItemContainer>{this.props.chart}</ChartItemContainer>
    }

    private renderFlights() {
        return (
            <ItemContainer chart vertical autoHeight>
                <ItemContainer
                    horizontalSpacing
                    background={Color.FlightChartStatsWrapperBackground}
                >
                    <Text
                        color={Color.FlightsChartTitle}
                        size={14}
                        paddingTop={30}
                        paddingBottom={16}
                    >
                        {this.props.flightTableTitle}
                    </Text>
                </ItemContainer>
                <Box
                    flex='auto'
                    paddingHorizontal={20}
                    paddingBottom={20}
                    bg={Color.FlightChartStatsWrapperBackground}
                >
                    {this.props.flightTable()}
                </Box>
            </ItemContainer>
        )
    }
}
