import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { ExportDataNotificationDto } from '../../../dataaccess/notifications/types/ExportDataNotificationDto'
import { ExportDataNotification } from './ExportDataNotification'

export class ExportDataNotificationImpl
    extends WrapperObject<ExportDataNotificationDto>
    implements ExportDataNotification
{
    constructor(
        public id: string,
        value: ExportDataNotificationDto,
        private markAlwaysAsRead: boolean,
    ) {
        super(value)
    }

    public get title() {
        return this.value.title
    }

    public get url() {
        return this.value.url
    }

    public get type() {
        return this.value.type
    }

    public get read() {
        return this.markAlwaysAsRead || this.value.read
    }

    public get timestamp() {
        return dateTime(this.value.timestamp)
    }
}
