import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Rotated } from 'aos-ui/src/components/base/Rotated'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'

import { IconVariant } from '../svg/Icon'

export interface FoldIconProps {
    isFold: boolean
    onToggleFold(): void
}

export const FoldIcon: FCWithChildren<FoldIconProps> = props => {
    const { isFold, onToggleFold } = props
    const svgIcon = isFold ? SvgIcon.Fold : SvgIcon.Unfold

    return (
        <Rotated angle={-45}>
            <IconButton
                svg={svgIcon}
                onClick={() => onToggleFold()}
                iconVariant={IconVariant.Smart}
                iconSize={BlockSize.Small}
            />
        </Rotated>
    )
}
