import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import {
    TaskProcessType,
    translateTaskProcessType,
} from 'aos-services/src/services/tasks/types/TaskProcessType'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { BlockRadioGroup, RendererProps } from '../radio/BlockRadioGroup'
import { DefaultRadioRendererWithDisable } from '../radio/DefaultRadioRendererWithDisable'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledProcessTypeProps extends SeleniumProps, MarginBoxProps {
    keyPrefix: string
    value?: TaskProcessType
    processTypes: TaskProcessType[]
    isRequired?: boolean
    isError?: boolean
    variant?: ThemeVariant
    onChange(v: TaskProcessType): void
    translateProcessType?(e: TaskProcessType): string
    severity?: string
}

export const LabeledProcessType: FCWithChildren<LabeledProcessTypeProps> = ({
    keyPrefix,
    value,
    processTypes,
    isRequired,
    onChange,
    isError,
    variant,
    seleniumLocation,
    severity,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)

    const ProcessTypeRadioItem: FCWithChildren<RendererProps<TaskProcessType>> = ({
        item,
        isSelected,
        onSelect,
    }) => {
        return (
            <DefaultRadioRendererWithDisable
                label={translateTaskProcessType(item)}
                color={Color.RadioPrimary}
                isSelected={isSelected}
                onSelect={severity !== AosSeverity.Emergency ? onSelect : () => {}}
                disabled={severity === AosSeverity.Emergency && item === TaskProcessType.Seasonal}
            />
        )
    }

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <BlockRadioGroup
                items={processTypes}
                value={value}
                Renderer={ProcessTypeRadioItem}
                onChange={onChange}
                marginBottom={8}
                gridSize={3}
            />
        </LabeledFormElement>
    )
}
