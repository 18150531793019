import { createEnumFromKeys } from 'aos-helpers/src/helpers/Enum'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { capitalize, entries, flatMap, keys, pickBy, reduce } from 'lodash'

import { MapSiteLocationCustomization } from '../../common/types/MapSiteLocationCustomization'

const airportsJson = require('siteLocation.json') as AirportConfig

type AirportConfig = {
    airports: {
        [key: string]: AirportDetails
    }
    processes: {
        [key: string]: ProcessConfig
    }
    notificationPreferenceTypes: {
        [key: string]: { categories: string }
    }
    handlingAgents: string[]
}
export type ProcessCategories = {
    categories: ProcessCategory[]
}
export type ProcessConfig = ProcessCategories & {
    isAtc: boolean
    isNetwork: boolean
    locationRequired: boolean
}

export type ProcessCategory = {
    icon: string
    name: string
    hideOnEmergency?: boolean
}

export const processCategories = airportsJson.processes

export const atcProcessCategories = pickBy(processCategories, q => q.isAtc)

export const handlingAgents = airportsJson.handlingAgents

const iconCategoryMap = flatMap(processCategories, q => q.categories).reduce((acc, q) => {
    acc[q.name] = q.icon
    return acc
}, {} as Record<string, string>)

export const processCategoryIcon = (category: string) => iconCategoryMap[category]

export const typedAirports = airportsJson
export const AosAirport = reduce(
    typedAirports.airports,
    (acc, _, key) => {
        acc[key] = key
        return acc
    },
    {} as Record<string, string>,
)
export type AosAirport = keyof typeof AosAirport

export const MainAirport = entries(typedAirports.airports).find(
    q => q[1].mainAirport,
)?.[0] as AosAirport
export interface AirportDetails {
    iata: string
    icao: string
    name: string
    city: string
    country: string
    latitude: number
    longitude: number
    mainAirport?: boolean // Optional because not all airports might have this field
    atcConfig?: {
        latitude: number
        longitude: number
        zoom: number
        extent: {
            bbox: number[]
            source: string
            destination: string
        }
    }
}

export const allAirports = EnumValues.getValues<AosAirport>(AosAirport)

export const locationName = translateEnum<AosAirport>(AosAirport, 'airports')

export const airportIcon = (e: AosAirport) => capitalize((e as string).toLowerCase())

export const toSiteLocationCustomization = (aosAirport: AosAirport): MapSiteLocationCustomization =>
    aosAirport === MainAirport
        ? MapSiteLocationCustomization.MainAirport
        : MapSiteLocationCustomization.Other

export const NotificationsPreferencesEnum = createEnumFromKeys(
    airportsJson.notificationPreferenceTypes,
)
export type NotificationsPreferencesType = keyof typeof NotificationsPreferencesEnum

export const allNotificationsPreferencesTypes = keys(airportsJson.notificationPreferenceTypes)

export type AosHandlingAgentType = (typeof handlingAgents)[number]

export const AosHandlingAgent = Object.freeze(
    handlingAgents?.reduce((acc, value, index) => {
        if (index === handlingAgents.length - 1) {
            acc['Unknown'] = value // Assign a custom key for the last element
        } else {
            acc[value] = value // Default behavior for other elements
        }
        return acc
    }, {} as Record<AosHandlingAgentType, AosHandlingAgentType>),
)
export const mapHandlingAgentToCompany = (agent: AosHandlingAgentType) =>
    translateEnum<AosHandlingAgentType>(
        AosHandlingAgent,
        'flight-information.handling-agent',
        AosHandlingAgent.Unknown,
    )(agent)
