import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import {
    AnonymousType,
    BaseFodFormPayload,
    BaseFodPayloadValidation,
    FodSource,
    fodSourceOptions,
    FodType,
    fodTypeOptions,
    PlaceOfDiscovery,
    placeOfDiscoveryOptions,
    translatedFodSource,
    translatedFodType,
    translatedPlaceOfDiscovery,
} from 'aos-services/src/services/fod/types/FodNotesFormData'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledDatetimeInput } from 'aos-ui/src/components/form/labeled/LabeledDatetimeInput'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledLocation } from 'aos-ui/src/components/form/labeled/LabeledLocation'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { LabeledToggle } from 'aos-ui/src/components/form/labeled/LabeledToggle'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { FormSidebar } from 'aos-ui/src/components/form/ui/FormSidebar'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'

interface FodNotesEditModalProps {
    task: BaseFodFormPayload
    form: FormValidation<BaseFodPayloadValidation>
    attachmentsState: AttachmentsState
    metadata: TaskMetadata

    changeForm(t: Partial<BaseFodFormPayload>): void
    setViewMode(n: DialogView): void
    attachmentsAction(a: AttachmentsAction): void
}

export const FodNotesForm: FC<FodNotesEditModalProps> = ({
    form,
    task,
    attachmentsState,
    attachmentsAction,
    setViewMode,
    changeForm,
}) => {
    return (
        <Box row height='100%' alignItems='stretch'>
            <LightScrollbar>
                <FormBox fullHeight flex={1} paddingLeft={30} paddingVertical={20}>
                    <Box
                        marginBottom={12}
                        paddingVertical={8}
                        paddingHorizontal={16}
                        rounded
                        bg={Color.Grey1}
                    >
                        <Text size={14} color={Color.Black}>
                            {translate('fod-notes.form.about')}
                        </Text>
                    </Box>
                    <LabeledSelect
                        keyPrefix='create-fod-modal.place-of-discovery'
                        marginBottom={12}
                        isRequired
                        isError={form.error.placeOfDiscovery}
                        seleniumLocation='place-of-discovery'
                        items={placeOfDiscoveryOptions}
                        labelRenderer={translatedPlaceOfDiscovery}
                        value={task.placeOfDiscovery}
                        onChange={placeOfDiscovery => {
                            changeForm({
                                placeOfDiscovery: placeOfDiscovery as PlaceOfDiscovery,
                            })
                        }}
                    />
                    <LabeledSelect
                        keyPrefix='create-fod-modal.type'
                        marginBottom={12}
                        isRequired
                        seleniumLocation='type'
                        items={fodTypeOptions}
                        labelRenderer={translatedFodType}
                        value={task.fodType}
                        isError={form.error.fodType}
                        onChange={fodType => {
                            changeForm({ fodType: fodType as FodType })
                        }}
                    />
                    <LabeledSelect
                        keyPrefix='create-fod-modal.source'
                        marginBottom={12}
                        isRequired
                        seleniumLocation='source'
                        items={fodSourceOptions}
                        labelRenderer={translatedFodSource}
                        value={task.fodSource}
                        isError={form.error.fodSource}
                        onChange={fodSource => {
                            changeForm({ fodSource: fodSource as FodSource })
                        }}
                    />
                    <LabeledTextArea
                        keyPrefix='fod-notes.form.description'
                        rows={14}
                        marginBottom={16}
                        value={task.description}
                        onChangeText={description => {
                            changeForm({ description })
                        }}
                    />
                </FormBox>
            </LightScrollbar>
            <Box flex={1}>
                <FormSidebar>
                    <LabeledDatetimeInput
                        keyPrefix='fod-notes.form.discoveryDate'
                        marginBottom={16}
                        isValidDate={date => date.isBefore(moment())}
                        timeConstraints={{
                            hours: { max: moment().hours(), min: 0, step: 1 },
                            minutes: { max: moment().minutes(), min: 0, step: 1 },
                        }}
                        isRequired
                        value={task.discoveryDate ? moment(task.discoveryDate) : undefined}
                        isError={form.error.date}
                        onChange={discoveryDate => {
                            changeForm({ discoveryDate: discoveryDate })
                        }}
                    />

                    <LabeledLocation
                        marginBottom={16}
                        value={task.location}
                        isRequired
                        isError={form.error.location}
                        keyPrefix='fod-notes.form.location'
                        onChange={location => changeForm({ location })}
                        onOpenOnMap={() => setViewMode(DialogView.Map)}
                    />

                    <Box rowGap={8} column flex={1} marginBottom={12}>
                        <LabeledToggle
                            rowReverse
                            justify='flex-end'
                            columnGap={8}
                            horizontal
                            keyPrefix='fod-notes.form.anonymous'
                            value={task.anonymousReport!}
                            onChange={v => changeForm({ anonymousReport: v })}
                        />
                        <Text size={12} color={Color.Grey5}>
                            {translate('fod-notes.form.anonymous-explanation')}
                        </Text>
                        {task.anonymousReport && (
                            <RadioGroup
                                name='anonymousType'
                                items={EnumValues.getValues<AnonymousType>(AnonymousType)}
                                formatter={translateEnum(AnonymousType, 'anonymousType')}
                                value={task.anonymousType}
                                onChange={(anonymousType: AnonymousType) => {
                                    changeForm({ anonymousType })
                                }}
                                fontSize={13}
                                variant={ThemeVariant.White}
                            />
                        )}
                    </Box>

                    <LabeledFormElement
                        label={translate('fod-notes.form.attachments.label')}
                        marginBottom={12}
                    >
                        <AttachmentContainer
                            attachmentsState={attachmentsState}
                            attachmentsAction={attachmentsAction}
                            editable
                            gridSize={2}
                        />
                    </LabeledFormElement>
                </FormSidebar>
            </Box>
        </Box>
    )
}

const FormBox = styled(Box)`
    padding-right: 66px;
`
