import { Sortable, sortableHandle } from 'aos-components/src/components/dnd/Sortable'
import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { showCustomizeModalAction, updateFiltersAction } from '../../core/flightInformation/actions'
import {
    filterAndSortFlightColumns,
    FlightInfoColumnListMeta,
} from '../../core/flightInformation/flight/flightColumnsState'
import { FlightFilters } from '../../core/flightInformation/flight/flightFiltersState'
import { FlightInfoField } from '../../core/flightInformation/flight/FlightInfoField'
import {
    arrivalsFlightInfoColumnListSelector,
    departuresFlightInfoColumnListSelector,
    showCustomizeModalSelector,
} from '../../core/flightInformation/flight/flightSelectors'
import { FiltersObject } from '../../core/flightInformation/helpers/urlFilters'
import { flightsUrlFiltersSelector } from '../../core/flightInformation/selectors'

const disabledFields = [FlightInfoField.sdt]

class FlightInfoCustomizeModalClass extends PureComponent<
    FlightInfoCustomizeModalProps,
    FlightInfoShareModalState
> {
    public state: FlightInfoShareModalState = {
        items: null,
        type: null,
        hasChanges: false,
    }

    public static getDerivedStateFromProps(
        props: FlightInfoCustomizeModalProps,
        state: FlightInfoShareModalState,
    ): FlightInfoShareModalState | null {
        if (state.items && state.type === props.type) {
            return null
        }

        const filters =
            props.type === FlightListType.Arrivals
                ? props.filters.arrivalsColumns
                : props.filters.departuresColumns

        const columns =
            props.type === FlightListType.Arrivals
                ? props.arrivalsColumnList
                : props.departuresColumnList

        return {
            items: filterAndSortFlightColumns(columns, filters),
            type: props.type,
            hasChanges: false,
        }
    }

    public render() {
        const { show } = this.props
        const { items } = this.state

        if (!show || !items) {
            return null
        }

        return (
            <WhiteModal
                title={translate('flight-information.customize-columns')}
                closeAction={this.handleClose}
                shouldCloseOnOverlayClick
                size={ModalKind.Medium}
                isOpen
                footer={this.renderFooter()}
            >
                <Box paddingTop={30} flex={1}>
                    <LightScrollbar autoHeight autoHeightMax='70vh'>
                        <Box paddingHorizontal={30}>
                            <Sortable
                                items={items}
                                onChange={this.handleChangeOrder}
                                renderItem={this.renderItem}
                                isDisabled={this.isDisabledItem}
                                getKey={this.getKey}
                                lockAxis='y'
                                helperClass='dnd-white-box--dragging'
                                useDragHandle
                            />
                        </Box>
                    </LightScrollbar>
                </Box>
            </WhiteModal>
        )
    }

    private renderFooter() {
        return (
            <Box row justify='space-between' fullWidth>
                <GreyTextButton
                    label={translate('flight-information.reset-to-default')}
                    onClick={this.handleResetToDefault}
                />
                <FormButton
                    label={translate('flight-information.close')}
                    onClick={this.handleClose}
                />
            </Box>
        )
    }

    private handleChangeOrder = (items: FlightInfoColumnListMeta[]) => {
        this.updateItems(items)
    }

    private handleClose = () => {
        const { items, hasChanges } = this.state

        if (hasChanges) {
            this.updateFilters({ [this.getSectionName()]: items })
        }

        this.props.showCustomizeModal(false)
    }

    private handleResetToDefault = () => {
        this.updateFilters({
            arrivalsColumns: undefined,
            departuresColumns: undefined,
        })
        this.updateItems(null)
    }

    private updateFilters(filters: Partial<FlightFilters>) {
        this.props.updateFilters([this.props.type, filters])
    }

    private renderItem = (item: FlightInfoColumnListMeta) => {
        const isDisabled = this.isDisabledItem(item)
        return (
            <Box className='dnd-white-box' paddingLeft={8}>
                <Checkbox
                    onChange={() => this.toggleVisibility(item)}
                    checked={item.visible}
                    disabled={isDisabled}
                >
                    <ItemHandle
                        name={item.label || (item.field as string)}
                        isDisabled={isDisabled}
                    />
                </Checkbox>
            </Box>
        )
    }

    private toggleVisibility({ field, visible }: FlightInfoColumnListMeta) {
        const { items } = this.state

        const newItems = items!.map(item => {
            if (item.field === field) {
                return { ...item, visible: !visible }
            }
            return item
        })

        this.updateItems(newItems)
    }

    private updateItems(items: FlightInfoColumnListMeta[] | null) {
        this.setState({ items, hasChanges: items !== null })
    }

    private getSectionName(): keyof FlightFilters {
        return this.state.type === FlightListType.Arrivals ? 'arrivalsColumns' : 'departuresColumns'
    }

    private getKey = (item: FlightInfoColumnListMeta) => item.field

    private isDisabledItem = (item: FlightInfoColumnListMeta) =>
        disabledFields.includes(item.field as FlightInfoField)
}

interface ItemHandleProps {
    name: string
    isDisabled: boolean
}

const ItemHandle = sortableHandle<ItemHandleProps>((props: ItemHandleProps) => {
    const color = props.isDisabled ? Color.DisabledText : Color.Grey
    return (
        <Box
            fullWidth
            row
            paddingVertical={4}
            paddingLeft={12}
            paddingRight={4}
            cursor={props.isDisabled ? 'default' : 'grab'}
        >
            <Text color={color} size={12} weight='bold' width='15%'>
                {translate(`flight-information.table.${props.name}.title`)}
            </Text>
            <Text flex={1} color={Color.Grey2} size={12}>
                {translate(`flight-information.table.${props.name}.description`)}
            </Text>
            {!props.isDisabled && (
                <Icon
                    block
                    svg={SvgIcon.DraggingHandle}
                    iconVariant={IconVariant.GreyTxt}
                    iconSize={BlockSize.Std}
                />
            )}
        </Box>
    )
})

interface FlightInfoShareModalState {
    items: FlightInfoColumnListMeta[] | null
    type: FlightListType | null
    hasChanges: boolean
}

interface FlightInfoShareModalStateProps {
    arrivalsColumnList: FlightInfoColumnListMeta[]
    departuresColumnList: FlightInfoColumnListMeta[]
    filters: FiltersObject
    show: boolean
}

interface FlightInfoShareModalDispatchProps {
    showCustomizeModal: typeof showCustomizeModalAction
    updateFilters: typeof updateFiltersAction
}

interface FlightInfoCustomizeModalProps
    extends FlightInfoShareModalStateProps,
        FlightInfoShareModalDispatchProps {
    type: FlightListType
}

export const FlightInfoCustomizeModal = connect<
    FlightInfoShareModalStateProps,
    FlightInfoShareModalDispatchProps
>(
    createStructuredSelector({
        show: showCustomizeModalSelector,
        filters: flightsUrlFiltersSelector,
        arrivalsColumnList: arrivalsFlightInfoColumnListSelector,
        departuresColumnList: departuresFlightInfoColumnListSelector,
    }),
    {
        showCustomizeModal: showCustomizeModalAction,
        updateFilters: updateFiltersAction,
    },
)(FlightInfoCustomizeModalClass)
