import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { reduce } from 'lodash'
import translations from 'translations.json'

interface TranslationsConfig {
    translationsExcelUrl: string
    languages: string[]
}

export const AosUserLocalisation = reduce(
    (translations as any as TranslationsConfig).languages,
    (acc, lang) => {
        acc[lang] = lang
        return acc
    },
    {} as Record<string, string>,
)

export type AosUserLocalisation = (typeof AosUserLocalisation)[number]

export const languageOptions = EnumValues.getValues<AosUserLocalisation>(AosUserLocalisation)

export const languageName = (u: AosUserLocalisation) => translate(`languages.${u}`)
