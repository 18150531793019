import { IdAware } from 'aos-ui/src/components/base/IdAware'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { ModalWrapperProps } from 'aos-ui/src/components/modal/ModalWrapper'
import React, { PureComponent } from 'react'

import { IconModal } from '../IconModal/IconModal'
import { ActionModalTextContent, ActionModalTextContentProps } from './ActionModalTextContent'

export class ActionModal extends PureComponent<ActionModalProps> {
    public render() {
        const onClick = this.props.onClickAction
            ? () => {
                  this.props.onClickAction!()
              }
            : () => this.props.closeAction()

        return (
            <IconModal {...this.props} modalKind={ModalKind.Icon}>
                <ActionModalTextContent {...this.props} onClickAction={onClick} />
            </IconModal>
        )
    }
}

export interface ActionModalProps
    extends IdAware,
        ModalWrapperProps,
        ActionModalTextContentProps,
        SvgProps {
    skipCloseAction?: boolean
}
