import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { uniqueId } from 'lodash'
import React, { FCWithChildren, useState } from 'react'
import styled, { css } from 'styled-components'

interface RadioProps extends MarginBoxProps {
    checked: boolean
    value: number
    name: string
    position: 'left' | 'right'
    onSelect(): void
}

export const Radio: FCWithChildren<RadioProps> = ({
    name,
    value,
    checked,
    children,
    onSelect,
    position,
    ...margins
}) => {
    const [radioId] = useState(() => uniqueId('radio'))

    return (
        <RadioContainer row position={position} data-test-id={`${name}-radio`} {...margins}>
            <input
                type='radio'
                id={radioId}
                checked={checked}
                value={value}
                name={name}
                onChange={() => onSelect()}
            />
            <label className={checked ? 'radio-checked' : 'radio-unchecked'} htmlFor={radioId}>
                {children}
            </label>
        </RadioContainer>
    )
}

const RadioContainer = styled(Box)<{ position: 'left' | 'right' }>`
    > input {
        display: none;
    }

    > label {
        position: relative;
        line-height: 24px;

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            transform-origin: 50% 50%;
            content: '';
        }

        &::before {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid ${Color.Grey2};
            transform: translate(0, -50%);
        }
        &::after {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${Color.Primary};
            transform: translate(0, -50%);
            display: none;
        }
    }

    > .radio-checked::before {
        border-color: ${Color.Primary};
    }

    > .radio-checked::after {
        display: block;
    }

    ${p =>
        p.position === 'left' &&
        css`
            > label {
                padding-left: 16px;

                &::before,
                &::after {
                    left: 0;
                }
                &::after {
                    left: 4px;
                }
            }
        `}

    ${p =>
        p.position === 'right' &&
        css`
            > label {
                padding-right: 16px;

                &::before,
                &::after {
                    right: 0;
                }
                &::after {
                    right: 4px;
                }
            }
        `}
`
