import { SvgMultilineText } from 'aos-ui/src/components/svg/SvgMultilineText'
import {
    ChartContextProps,
    withChartContext,
} from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisProps } from './AxisProps'
import { HorizontalAxis } from './HorizontalAxis'

const axisTickPadding = 20
const axisTickSize = 0

class AxisBottomComponent<T extends AxisDomain> extends HorizontalAxis<T> {
    constructor(props: AxisProps<T> & ChartContextProps) {
        super(props, 'bottom')
    }

    protected renderTick = (tick: T, index: number) => {
        const { scale, size, axisConfig, rotate } = this.props

        const { tickClass, tickSize, tickOffset, tickFormat, tickLabelStyle } = this.getTickConfig(
            tick,
            {
                defaultPadding: axisTickPadding,
                defaultTickSize: axisTickSize,
            },
        )

        const tickOrTickGridSize =
            axisConfig.showGrids === false ? -tickSize : -(size.height + tickSize * 2)

        const xTickOffset = scale.bandwidth ? scale.bandwidth() / 2 : 0
        const xPos = (scale(tick) || 0) + xTickOffset

        const transformRotate = rotate ? `rotate(${rotate})` : ''

        return (
            <g
                className={tickClass}
                key={index}
                transform={`translate(${xPos} ${0}) ${transformRotate}`}
            >
                <line y2={tickOrTickGridSize} transform={`translate(${0} ${tickSize})`} />
                <SvgMultilineText
                    y={tickOffset}
                    dy='0.71em'
                    textAnchor='middle'
                    style={tickLabelStyle}
                    fill='red'
                >
                    {tickFormat(tick)}
                </SvgMultilineText>
            </g>
        )
    }
}

export const AxisBottom = withChartContext(AxisBottomComponent)
