import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Rotated } from 'aos-ui/src/components/base/Rotated'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface RestrictionsHeaderProps {
    airport: string
}

export const RestrictionsHeader: FC<RestrictionsHeaderProps> = ({ airport }) => (
    <Box row>
        <Text
            size={18}
            weight='light'
            color={Color.WidgetPrimary}
            lineHeight='standard'
            marginRight={6}
        >
            {translate('dashboard.restrictions.restrictions')}
        </Text>
        <Rotated angle={90}>
            <Icon
                iconSize={BlockSize.Std}
                svg={SvgIcon.AirlineGroundHandling}
                color={Color.WidgetSecondary}
            />
        </Rotated>
        <Text
            size={12}
            weight='light'
            color={Color.WidgetSecondary}
            lineHeight='standard'
            marginLeft={6}
        >
            {airport}
        </Text>
    </Box>
)
