import {
    clearSelectedDiscountStoreFilter,
    loadDiscountCoupons,
    loadDiscountStores,
    setSelectedDiscountStoreFilter,
} from 'aos-services/src/core/discountModule/actions'
import { DiscountArea } from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import {
    discountCouponsSelector,
    discountStoresSelector,
    selectedFilterStoresSelector,
} from '../../core/discountModule/selectors'
import DiscountModuleListColumn from './partial/DiscountCouponListColumn'
import DiscountModuleHeader from './partial/DiscountModuleHeader'
import DiscountModuleStoreFilterBar from './partial/DiscountModuleStoreFilterBar'

export const DiscountModule = () => {
    const dispatch = useDispatch()
    const selectedFilterStores = useSelector(selectedFilterStoresSelector)
    const discountCoupons = useSelector(discountCouponsSelector)
    const discountStores = useSelector(discountStoresSelector)

    const handleSelectStore = useCallback(
        (selectedStoreId: number) => dispatch(setSelectedDiscountStoreFilter(selectedStoreId)),
        [],
    )

    const handleClearSelectedStores = useCallback(
        () => dispatch(clearSelectedDiscountStoreFilter()),
        [],
    )

    return (
        <SyncWrapper onEnter={[loadDiscountCoupons(), loadDiscountStores()]}>
            <DarkScrollbar>
                <Box height='100%' column>
                    <DiscountModuleHeader />
                    <ContentWrapper
                        paddingVertical={16}
                        paddingHorizontal={24}
                        paddingBottom={2}
                        column
                        rowGap={16}
                        grow={1}
                    >
                        <DiscountModuleStoreFilterBar
                            discountStores={discountStores}
                            handleClearSelectedStores={handleClearSelectedStores}
                            handleSelectStore={handleSelectStore}
                            selectedStores={selectedFilterStores}
                        />
                        <Box grow={1} row alignItems='flex-start' overflow='hidden' minWidth={1200}>
                            <DiscountModuleListColumn
                                discountCoupons={discountCoupons?.LANDSIDE?.filter(
                                    ({ discountStore }) =>
                                        selectedFilterStores.length
                                            ? selectedFilterStores.includes(discountStore.id)
                                            : true,
                                )}
                                discountArea={DiscountArea.LANDSIDE}
                            />
                            <DiscountModuleListColumn
                                discountCoupons={discountCoupons?.SCHENGEN?.filter(
                                    ({ discountStore }) =>
                                        selectedFilterStores.length
                                            ? selectedFilterStores.includes(discountStore.id)
                                            : true,
                                )}
                                discountArea={DiscountArea.SCHENGEN}
                            />
                            <DiscountModuleListColumn
                                discountCoupons={discountCoupons?.NON_SCHENGEN?.filter(
                                    ({ discountStore }) =>
                                        selectedFilterStores.length
                                            ? selectedFilterStores.includes(discountStore.id)
                                            : true,
                                )}
                                discountArea={DiscountArea.NON_SCHENGEN}
                            />
                        </Box>
                    </ContentWrapper>
                </Box>
            </DarkScrollbar>
        </SyncWrapper>
    )
}

const ContentWrapper = styled(Box)`
    max-height: calc(100% - 76px);
`
