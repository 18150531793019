import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { attachmentsSagaFactory } from 'aos-services/src/core/attachments/sagas'
import {
    loadDiscountCoupons,
    loadDiscountStores,
} from 'aos-services/src/core/discountModule/actions'
import { baseDiscountModuleSagas } from 'aos-services/src/core/discountModule/sagas'
import { discountModuleService } from 'aos-services/src/services/discountModule/discountModuleService'
import {
    DiscountCoupon,
    DiscountStore,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { Task as SagaTask } from 'redux-saga'
import { call, cancel, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    HIDE_ACTION_MODAL,
    HideActionModalAction,
    showActionModalAction,
} from '../actionModal/actions'
import {
    clearDiscountModuleAttachmentsAction,
    CLOSE_CREATE_DISCOUNT_STORE_MODAL,
    CLOSE_MANAGE_DISCOUNT_STORES_MODAL,
    CLOSE_SAVE_DISCOUNT_COUPON_MODAL,
    CloseCreateDiscountStoreModal,
    CloseManageDiscountStoresModal,
    CloseSaveDiscountCouponModal,
    CREATE_DISCOUNT_STORE,
    CREATE_DISCOUNT_STORE_SUCCESS,
    CreateDiscountStoreAction,
    createDiscountStoreSuccess,
    DELETE_DISCOUNT_COUPON,
    DELETE_DISCOUNT_COUPON_SUCCESS,
    DELETE_DISCOUNT_STORE,
    DELETE_DISCOUNT_STORE_SUCCESS,
    DeleteDiscountCouponAction,
    deleteDiscountCouponSuccess,
    DeleteDiscountStoreAction,
    deleteDiscountStoreSuccess,
    DISCOUNT_MODULE_ATTACHMENTS,
    discountModuleAttachmentsAction,
    MANAGE_DISCOUNT_STORES,
    MANAGE_DISCOUNT_STORES_SUCCESS,
    ManageDiscountStoresAction,
    manageDiscountStoresSuccess,
    OPEN_CREATE_DISCOUNT_STORE_MODAL,
    OPEN_MANAGE_DISCOUNT_STORES_MODAL,
    OPEN_SAVE_DISCOUNT_COUPON_MODAL,
    openCreateDiscountStoreModal,
    openManageDiscountStoresModal,
    openSaveDiscountCouponModal,
    SAVE_DISCOUNT_COUPON,
    SAVE_DISCOUNT_COUPON_SUCCESS,
    SaveDiscountCouponAction,
    saveDiscountCouponSuccess,
} from './actions'

function* saveDiscountCouponSaga() {
    let takeLatestEffect: SagaTask | undefined
    yield takeEvery<SaveDiscountCouponAction>(SAVE_DISCOUNT_COUPON, function* ({ payload }) {
        yield put(openSaveDiscountCouponModal(payload))
        takeLatestEffect = yield takeLatest<CloseSaveDiscountCouponModal>(
            CLOSE_SAVE_DISCOUNT_COUPON_MODAL,
            function* (response) {
                if (response.payload) {
                    if (response.payload.id) {
                        const result: DiscountCoupon = yield call(
                            discountModuleService.editDiscountCouponById,
                            {
                                id: response.payload.id,
                                payload: response.payload.payload,
                            },
                        )
                        yield put(saveDiscountCouponSuccess(result))
                        successToast(translate('discountModule.toast.couponSaved'))
                    } else {
                        const result: DiscountCoupon = yield call(
                            discountModuleService.createDiscountCoupon,
                            response.payload.payload,
                        )
                        yield put(saveDiscountCouponSuccess(result))
                        successToast(translate('discountModule.toast.couponCreated'))
                    }
                }
                if (takeLatestEffect) {
                    yield cancel(takeLatestEffect)
                }
            },
        )
    })
}

function* deleteDiscountCouponSaga() {
    let takeLatestEffect: SagaTask | undefined

    yield takeEvery<DeleteDiscountCouponAction>(
        DELETE_DISCOUNT_COUPON,
        function* ({ payload: { id } }) {
            yield put(
                showActionModalAction({
                    title: translate('discountModule.deleteCouponModal.title'),
                    description: translate('discountModule.deleteCouponModal.subtitle'),
                    svg: SvgIcon.DeleteRound,
                    titleClassName: 'text__xl-black text--center',
                    descriptionClassName: 'text__m_grey text--center',
                    okLabel: translate('discountModule.deleteCouponModal.okLabel'),
                }),
            )

            takeLatestEffect = yield takeLatest<HideActionModalAction>(
                HIDE_ACTION_MODAL,
                function* (result) {
                    if (result.payload) {
                        yield call(discountModuleService.deleteDiscountCouponById, id)
                    }
                    yield put(deleteDiscountCouponSuccess())
                    successToast(translate('discountModule.toast.couponDeleted'))
                    if (takeLatestEffect) {
                        yield cancel(takeLatestEffect)
                    }
                },
            )
        },
    )
}

function* createDiscountStoreSaga() {
    let takeLatestEffect: SagaTask | undefined
    yield takeEvery<CreateDiscountStoreAction>(CREATE_DISCOUNT_STORE, function* () {
        yield put(openCreateDiscountStoreModal())
        takeLatestEffect = yield takeLatest<CloseCreateDiscountStoreModal>(
            CLOSE_CREATE_DISCOUNT_STORE_MODAL,
            function* (response) {
                yield put(clearDiscountModuleAttachmentsAction())

                if (response.payload) {
                    const result: DiscountStore = yield call(
                        discountModuleService.createDiscountStore,
                        response.payload.payload,
                    )
                    yield put(createDiscountStoreSuccess(result))
                    successToast(translate('discountModule.toast.storeCreated'))
                }
                if (takeLatestEffect) {
                    yield cancel(takeLatestEffect)
                }
            },
        )
    })
}

function* manageDiscountStoresSaga() {
    let takeLatestEffect: SagaTask | undefined
    yield takeEvery<ManageDiscountStoresAction>(MANAGE_DISCOUNT_STORES, function* () {
        yield put(openManageDiscountStoresModal())
        takeLatestEffect = yield takeLatest<CloseManageDiscountStoresModal>(
            CLOSE_MANAGE_DISCOUNT_STORES_MODAL,
            function* (response) {
                yield put(clearDiscountModuleAttachmentsAction())
                if (response.payload) {
                    const result: DiscountStore = yield call(
                        discountModuleService.editDiscountStoreById,
                        response.payload.payload,
                    )
                    yield put(manageDiscountStoresSuccess(result))
                    successToast(translate('discountModule.toast.storeSaved'))
                }
                if (takeLatestEffect) {
                    yield cancel(takeLatestEffect)
                }
            },
        )
    })
}

function* deleteDiscountStoreSaga() {
    let takeLatestEffect: SagaTask | undefined

    yield takeEvery<DeleteDiscountStoreAction>(
        DELETE_DISCOUNT_STORE,
        function* ({ payload: { id } }) {
            yield put(
                showActionModalAction({
                    title: translate('discountModule.deleteStoreModal.title'),
                    description: translate('discountModule.deleteStoreModal.subtitle'),
                    svg: SvgIcon.DeleteRound,
                    titleClassName: 'text__xl-black text--center',
                    descriptionClassName: 'text__m_grey text--center',
                    okLabel: translate('discountModule.deleteStoreModal.okLabel'),
                }),
            )

            takeLatestEffect = yield takeLatest<HideActionModalAction>(
                HIDE_ACTION_MODAL,
                function* (result) {
                    if (result.payload) {
                        yield call(discountModuleService.deleteDiscountStoreById, id)
                        yield put(deleteDiscountStoreSuccess())
                        successToast(translate('discountModule.toast.storeDeleted'))
                    }
                    if (takeLatestEffect) {
                        yield cancel(takeLatestEffect)
                    }
                },
            )
        },
    )
}

function* openAnyDiscountModuleModalSaga() {
    yield takeEvery(
        [
            OPEN_CREATE_DISCOUNT_STORE_MODAL,
            OPEN_MANAGE_DISCOUNT_STORES_MODAL,
            OPEN_SAVE_DISCOUNT_COUPON_MODAL,
        ],
        function* () {
            yield put(clearDiscountModuleAttachmentsAction())
        },
    )
}

function* updateDiscountCouponListSaga() {
    yield takeEvery(
        [
            SAVE_DISCOUNT_COUPON_SUCCESS,
            DELETE_DISCOUNT_COUPON_SUCCESS,
            MANAGE_DISCOUNT_STORES_SUCCESS,
            DELETE_DISCOUNT_STORE_SUCCESS,
        ],
        function* () {
            yield put(loadDiscountCoupons())
        },
    )
}
function* updateDiscountStoresListSaga() {
    yield takeEvery(
        [
            CREATE_DISCOUNT_STORE_SUCCESS,
            DELETE_DISCOUNT_STORE_SUCCESS,
            MANAGE_DISCOUNT_STORES_SUCCESS,
        ],
        function* () {
            yield put(loadDiscountStores())
        },
    )
}

export const discountModuleSagas = [
    ...baseDiscountModuleSagas,
    saveDiscountCouponSaga,
    deleteDiscountCouponSaga,
    updateDiscountCouponListSaga,
    updateDiscountStoresListSaga,
    createDiscountStoreSaga,
    manageDiscountStoresSaga,
    deleteDiscountStoreSaga,
    openAnyDiscountModuleModalSaga,
    ...attachmentsSagaFactory(DISCOUNT_MODULE_ATTACHMENTS, discountModuleAttachmentsAction),
]
