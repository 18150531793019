import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren, PropsWithChildren } from 'react'

export const BackButtonTitleSection: FCWithChildren<PropsWithChildren<ButtonProps>> = props => (
    <Text overflow='hidden' flex={1} row lineHeight='standard'>
        <IconButton
            iconSize={BlockSize.Small}
            iconVariant={IconVariant.Smart}
            svg={SvgIcon.Back}
            onClick={props.onClick}
        />
        <Box paddingHorizontal={8} flex={1}>
            {props.children}
        </Box>
    </Text>
)
