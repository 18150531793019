import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    DiscountCoupon,
    DiscountCouponDTO,
    DiscountStore,
    DiscountType,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import z from 'zod'

import { closeSaveDiscountCouponModal } from '../../../core/discountModule/actions'
import {
    discountStoresSelector,
    editedDiscountCouponsSelector,
    saveDiscountModuleOpenSelector,
} from '../../../core/discountModule/selectors'

const discountCouponToDTO = ({
    title,
    type,
    discountValue,
    discountStore: { id },
    specialOfferValue,
}: DiscountCoupon): DiscountCouponDTO => ({
    title,
    type,
    discountStoreRef: { id },
    discountValue: discountValue ?? undefined,
    specialOfferValue: specialOfferValue ?? undefined,
})

type DiscountCouponForm = {
    discountStore: DiscountStore | null
    type: DiscountType
    title: string
    specialOfferValue: string | null
}

const SaveDiscountCouponModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(saveDiscountModuleOpenSelector)
    const discountStores = useSelector(discountStoresSelector)
    const editedCoupon = useSelector(editedDiscountCouponsSelector)
    const defaultValues = useMemo(
        () => ({
            type: DiscountType.SPECIAL_OFFER,
            discountStore: null,
            title: '',
            specialOfferValue: '',
        }),
        [],
    )
    const { control, handleSubmit, reset } = useForm<DiscountCouponForm>({
        mode: 'onSubmit',
        defaultValues,
        resolver: zodResolver(
            z.object({
                id: z.number().optional(),
                discountStore: z.object({
                    id: z.number(),
                    name: z.string(),
                    area: z.string(),
                    location: z.string(),
                    logo: z.object({
                        id: z.number().optional(),
                        link: z.string(),
                        name: z.string().optional(),
                        type: z.string().optional(),
                    }),
                }),
                title: z.string().min(1),
                type: z.string(),
                specialOfferValue: z.string().optional().nullable(),
            }),
        ),
    })

    useEffect(() => {
        if (editedCoupon) {
            reset(editedCoupon)
        }
    }, [editedCoupon])

    const onSubmit = useCallback((form: DiscountCoupon) => {
        reset(defaultValues)
        dispatch(
            closeSaveDiscountCouponModal({
                id: form.id,
                payload: discountCouponToDTO(form),
            }),
        )
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            title={
                editedCoupon
                    ? translate('discountModule.saveCouponModule.editTitle')
                    : translate('discountModule.saveCouponModule.createTitle')
            }
            modalKind={ModalKind.Medium}
            closeAction={() => {
                reset(defaultValues)
                dispatch(closeSaveDiscountCouponModal(undefined))
            }}
            footer={
                <Box row flex={1} justify='space-between'>
                    <Text color={Color.Grey6} size={13}>
                        {translate('discountModule.form.requiredFields')}
                    </Text>
                    <FormButton
                        onClick={handleSubmit(onSubmit)}
                        label={
                            editedCoupon
                                ? translate('discountModule.form.saveButtonLabel')
                                : translate('discountModule.form.createButtonLabel')
                        }
                    />
                </Box>
            }
        >
            <Box padding={30} column rowGap={16} flex={1}>
                {/* post MVP */}
                {/* <Controller
                    control={control}
                    name='type'
                    render={({ field: { value, onChange } }) => (
                        <BlockRadioGroup
                            gridGap={0}
                            items={values(DiscountType)}
                            value={value}
                            Renderer={({ item, isSelected, onSelect }) => (
                                <RadioElement
                                    onClick={onSelect}
                                    borderWidth={1}
                                    border={isSelected ? Color.PrimaryPale : Color.Grey6}
                                    justify='center'
                                    alignItems='center'
                                    padding={12}
                                    bg={isSelected ? Color.PaleBlue : Color.White}
                                >
                                    <Text
                                        size={12}
                                        color={isSelected ? Color.PrimaryDark : Color.Black}
                                    >
                                        {translate(`discountModule.discountType.${item}`)}
                                    </Text>
                                </RadioElement>
                            )}
                            onChange={onChange}
                        />
                    )}
                /> */}
                <Controller
                    name='discountStore'
                    control={control}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <Box column rowGap={10}>
                            <Text
                                size={14}
                                color={fieldState.invalid ? Color.Red : Color.TimelineBackground}
                            >
                                {translate('discountModule.form.storeLabel')}
                            </Text>
                            <Box column rowGap={2}>
                                <DropdownAutocomplete
                                    value={value}
                                    items={discountStores}
                                    variant={DropdownVariant.White}
                                    labelRenderer={value => value.name}
                                    placeholder={translate(
                                        'discountStore.form.storeInputPlaceholder',
                                    )}
                                    onChange={onChange}
                                    outlined
                                />
                                {fieldState.invalid && (
                                    <Text color={Color.Red} size={12}>
                                        {translate('discountModule.form.fieldRequired')}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                    )}
                />
                <Controller
                    name='title'
                    control={control}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <>
                            <LabeledInput
                                type='text'
                                value={value}
                                onChangeText={onChange}
                                keyPrefix='discountModule.form.titleInput'
                                isRequired
                                isError={fieldState.invalid}
                                errorMessage={translate('discountModule.form.fieldRequired')}
                                maxLength={50}
                            />
                        </>
                    )}
                />

                <Controller
                    name='specialOfferValue'
                    control={control}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <LabeledInput
                            type='text'
                            value={value}
                            onChangeText={onChange}
                            keyPrefix='discountModule.form.discountValueInput'
                            isError={fieldState.invalid}
                            maxLength={50}
                        />
                    )}
                />
                {/*  post MVP */}
                {/* <Controller
                    name='specialOfferValue'
                    control={control}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <LabeledInput
                            type='text'
                            value={value}
                            onChangeText={onChange}
                            keyPrefix='discountModule.form.specialOfferValueInput'
                            isError={fieldState.invalid}
                        />
                    )}
                /> */}
            </Box>
        </Modal>
    )
}

export default SaveDiscountCouponModal

// Post mvp
// const RadioElement = styled(Box)`
//     &:hover {
//         cursor: pointer;
//     }
// `
