import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { FormLabel } from '../form/labeled/FormLabel'

export const FixedHeightList: FCWithChildren<PropsWithChildren<FixedHeightListProps>> = props => {
    const { label, children, height } = props
    return (
        <Box>
            {label && <FormLabel>{label}</FormLabel>}
            <FixedHeightListWrapper style={{ height: `${height}px` }}>
                {children}
            </FixedHeightListWrapper>
        </Box>
    )
}

const FixedHeightListWrapper = styled.ul`
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid ${Color.DisabledText};
`

interface FixedHeightListProps {
    label?: string
    height: number
}
