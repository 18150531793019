import { State as WebState } from 'aos-frontend/src/app/core/state'
import { EventsListState } from 'aos-mobile/src/app/core/events/state'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import { userLayerVisibilityService } from 'aos-ui-map/src/components/map/layersVisibility/UserLayerVisibilityService'
import { shouldSyncRingRail } from 'aos-ui-map/src/core/state'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { currentUserIdSelector } from '../auth/state'
import { assureRingRailSync } from '../layersData/sagas'
import {
    LOAD_USER_LAYER_VISIBILITY,
    LoadUserLayerVisibilityAction,
    loadUserLayerVisibilitySuccessAction,
    SET_USER_LAYERS_VISIBILITY,
} from './actions'

function* loadUserLayerVisibilitySaga() {
    yield takeEvery<LoadUserLayerVisibilityAction>(LOAD_USER_LAYER_VISIBILITY, function* () {
        const id: number = yield select(currentUserIdSelector)
        const userLayerData: string[] = yield call(userLayerVisibilityService.loadUserLayers, id)
        if (userLayerData.length > 0) {
            const getFloorNumber = () => {
                const floorMapLayer = userLayerData.find((mapLayer: string) =>
                    mapLayer.includes('floor'),
                )
                const floorNumber = floorMapLayer?.slice(floorMapLayer.length - 1) as string
                return +floorNumber
            }
            const userLayerPayload = {
                list: userLayerData,
                floor: getFloorNumber(),
            }
            yield put(loadUserLayerVisibilitySuccessAction(userLayerPayload))
        } else {
            const initialLayers = {
                list: [BimLayerName.Tasks, BimLayerName.Events, BimLayerName.Floor0],
                floor: 0,
            }
            yield put(loadUserLayerVisibilitySuccessAction(initialLayers))
        }
    })
}

function* syncRingRailSagaOnChangingLayers() {
    yield takeEvery(SET_USER_LAYERS_VISIBILITY, function* () {
        yield assureRingRailSync(
            (state: WebState & EventsListState) =>
                shouldSyncRingRail(state.airportMap || state.eventMap),
            'airportMap',
        )
    })
}

export const userLayerVisibilitySagas = [
    loadUserLayerVisibilitySaga,
    syncRingRailSagaOnChangingLayers,
]
