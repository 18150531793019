/* eslint-disable react/jsx-no-literals */
import { Page } from 'aos-helpers/src/helpers/Pageable'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { range } from 'lodash'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

export interface PaginationProps {
    page: Page
    maxButtons?: number
    onChange(page: Page): void
}

export const Pagination: FCWithChildren<PaginationProps> = ({ page, maxButtons = 9, onChange }) => {
    const totalPages = Math.ceil(page.totalElements / page.size)
    const totalButtons = Math.min(maxButtons, totalPages)
    const leftRightForIndex = (
        minValue: number,
        maxValue: number,
        current: number,
        bufforSize: number,
    ): [number, number] => {
        if (maxValue - minValue <= bufforSize) {
            return [minValue, maxValue]
        }
        if (current + bufforSize / 2 > maxValue) {
            return [maxValue - bufforSize, maxValue]
        }
        if (current - bufforSize / 2 < minValue) {
            return [minValue, minValue + bufforSize]
        }
        const start = Math.floor(current - bufforSize / 2)
        return [start, start + bufforSize]
    }
    const [leftPage, rightPage] = leftRightForIndex(0, totalPages, page.number, totalButtons)
    const pageItems = range(leftPage, rightPage)
    const jump = (pageIndex: number) => {
        onChange({ ...page, number: pageIndex })
    }

    const pageButton = (p: number, index: number) => {
        const isActive = p === page.number

        if (index === 0) {
            return (
                <PageButton onClick={() => jump(0)} isActive={isActive}>
                    1
                </PageButton>
            )
        }

        if (index === pageItems.length - 1) {
            return (
                <PageButton onClick={() => jump(totalPages - 1)} isActive={isActive}>
                    {`${totalPages}`}
                </PageButton>
            )
        }

        if (index === 1 && p > 1) {
            return <MoreButton onClick={() => jump(p)} />
        }

        if (index === pageItems.length - 2 && p < totalPages - 2) {
            return <MoreButton onClick={() => jump(p)} />
        }

        return (
            <PageButton onClick={() => jump(p)} isActive={p === page.number}>{`${
                p + 1
            }`}</PageButton>
        )
    }

    return (
        <Box centered row>
            <Box marginRight={8}>
                <NavButton onClick={() => jump(page.number - 1)} disabled={page.number === 0}>
                    {translate('pagination.prev')}
                </NavButton>
            </Box>
            {pageItems.map((p, index) => (
                <Box key={index} marginRight={8}>
                    {pageButton(p, index)}
                </Box>
            ))}
            <NavButton
                onClick={() => jump(page.number + 1)}
                disabled={page.number === totalPages - 1}
            >
                {translate('pagination.next')}
            </NavButton>
        </Box>
    )
}

interface MoreButtonProps {
    onClick(): void
}

const NavButton = styled.button`
    height: 36px;
    border-radius: 5px;
    padding: 0 24px;
    border: 1px solid ${Color.ChartBase};
    background-color: ${Color.TimelineBackground};
    line-height: 34px;
    float: left;
    font-size: 13px;
    font-weight: 500;
    color: ${p => (p.disabled ? Color.Grey : Color.White)};
    cursor: ${p => (p.disabled ? 'initial' : 'pointer')};
    user-select: none;
`

const PageButton = styled.button<{ isActive: boolean }>`
    min-width: 28px;
    height: 32px;
    border-radius: 5px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: ${p => (p.isActive ? Color.PrimaryComponentLabel : Color.White)};
    background-color: ${p => (p.isActive ? Color.Primary : Color.Transparent)};
`

const MoreButton: FCWithChildren<MoreButtonProps> = ({ onClick }) => (
    <PageButton onClick={onClick} isActive={false}>
        ...
    </PageButton>
)
