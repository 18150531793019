import { useDebounce } from 'aos-ui-common/src/components/hooks/useDebounce'
import { Color } from 'aos-ui-common/src/styles/Color'
import { take } from 'lodash'
import React, { Children, FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import SplitterLayout from 'react-splitter-layout'
import styled from 'styled-components'

import { SplittedPanel, SplittedPanelProps } from './SplittedPanel'

interface ResizableSplittedPanelProps extends SplittedPanelProps {
    initialRightSizes: number[]
    leftMinSizes: number[]
    rightMinSizes: number[]
    className?: string
    onResize?(v: number[]): void
}

const ResizableSplittedPanelComponent: FCWithChildren<
    PropsWithChildren<ResizableSplittedPanelProps>
> = props => {
    const {
        leftMinSizes,
        rightMinSizes,
        initialRightSizes,
        onResize,
        children,
        className,
        ...rest
    } = props
    const wrapWithSplitter = (cols: ReactNode[], index: number) =>
        (
            <SplitterLayout
                customClassName={className}
                percentage
                primaryMinSize={leftMinSizes[index]}
                secondaryMinSize={rightMinSizes[index]}
                secondaryInitialSize={initialRightSizes[index]}
                onSecondaryPaneSizeChange={v => resizeColumn(v, index)}
                key={index}
            >
                {cols}
            </SplitterLayout>
        ) as ReactNode

    const resizeColumn = useDebounce((value: number, index: number) => {
        const sizes = [...initialRightSizes]
        sizes[index] = value
        if (onResize) {
            onResize(sizes)
        }
    }, 100)

    const columns = () => {
        const childrenArray = Children.toArray(children)
        if (childrenArray.length >= 2) {
            const firstTwo = take(childrenArray, 2)
            return childrenArray.slice(1).reduce((acc, cur, index) => {
                return wrapWithSplitter([acc, cur], index) as any
            }, firstTwo)
        }
        return children
    }

    return <SplittedPanel {...rest}>{columns()}</SplittedPanel>
}

export const ResizableSplittedPanel = styled(ResizableSplittedPanelComponent)`
    display: flex;
    position: static;
    background: transparent;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;

    .layout-pane {
        position: relative;
        flex: 0 0 auto;
        overflow: hidden;

        &.layout-pane-primary {
            flex: 1 1 auto;
        }
        &:last-child {
            margin-left: -1px;
        }
    }
    & > .layout-splitter {
        flex: 0 0 auto;
        height: 100%;
        cursor: col-resize;
        z-index: 2;
        border-left: 1px solid ${Color.ChartBase};
        width: 2px;
        background: transparent;

        &:hover {
            border-left: 1px solid ${Color.Grey1};
            background: transparent;
        }
    }

    &.layout-changing {
        cursor: col-resize;

        & > .layout-splitter {
            background-color: #aaa;
        }

        &.splitter-layout-vertical {
            flex-direction: column;

            &.layout-changing {
                cursor: row-resize;
            }

            & > .layout-splitter {
                width: 100%;
                height: 4px;
                cursor: row-resize;
            }
        }
    }
`
