import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Box } from '../../base/Box'
import { Text } from '../../base/Text'
import { Icon, IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'

interface ValidationInfoProps {
    pristine: boolean
    valid: boolean
}

export const ValidationInfo: FCWithChildren<ValidationInfoProps> = ({ pristine, valid }) => {
    const renderRequiredErrorMessage = () => (
        <Text row size={14} color={Color.Red}>
            <Icon
                iconSize={BlockSize.Std}
                iconVariant={IconVariant.Red}
                svg={SvgIcon.OtherRed}
                marginRight={8}
            />
            {translate('validation.required-error')}
        </Text>
    )

    /* eslint-disable react/jsx-no-literals */
    const renderRequiredInfoMessage = () => (
        <Text size={14} color={Color.Grey2} weight='regular'>
            <sup>*</sup>
            {translate('validation.required-info')}
        </Text>
    )
    return (
        <Box flex={1}>
            {!pristine && !valid ? renderRequiredErrorMessage() : renderRequiredInfoMessage()}
        </Box>
    )
}
