import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren, forwardRef } from 'react'

import { BoxProps } from '../../base/Box'
import { ThemeVariant } from '../../base/ThemeVariant'
import { CleanableInput } from '../input/CleanableInput'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledInputProps extends BoxProps {
    keyPrefix: string
    value?: string | null
    isRequired?: boolean
    isError?: boolean
    readOnly?: boolean
    type?: 'text' | 'password' | 'email'
    errorMessage?: string
    variant?: ThemeVariant
    svg?: Svg
    hideLabel?: boolean
    onChangeText?(v: string): void
    maxLength?: number
}

export const LabeledInput: FCWithChildren<LabeledInputProps> = forwardRef(
    ({
        type,
        value,
        onChangeText,
        isRequired,
        isError,
        readOnly,
        seleniumLocation,
        variant,
        keyPrefix,
        svg,
        hideLabel,
        errorMessage,
        maxLength,
        ...marginProps
    }) => {
        const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
        return (
            <LabeledFormElement
                hideLabel={hideLabel}
                label={translateSuffix('label')}
                isRequired={isRequired}
                isError={isError}
                errorMessage={errorMessage ? errorMessage : translateSuffix('error')}
                seleniumLocation={seleniumLocation}
                variant={variant}
                {...marginProps}
            >
                <CleanableInput
                    hideClean={readOnly}
                    variant={variant}
                    type={type}
                    placeholder={translateSuffix('placeholder')}
                    value={value}
                    onChangeText={onChangeText}
                    readOnly={readOnly}
                    svg={svg}
                    maxLength={maxLength}
                />
            </LabeledFormElement>
        )
    },
)
