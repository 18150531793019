import { Color } from 'aos-ui-common/src/styles/Color'

export enum AosUserGroupType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    MANUAL = 'MANUAL',
    ALL = 'ALL',
    REMOVED = 'REMOVED',
    CUSTOM = 'CUSTOM',
}

interface MapAosUserGroupToOrder {
    [key: string]: number
}

export const mapAosUserGroupToOrder: MapAosUserGroupToOrder = {
    [AosUserGroupType.ALL]: 0,
    [AosUserGroupType.INTERNAL]: 1,
    [AosUserGroupType.EXTERNAL]: 2,
    [AosUserGroupType.MANUAL]: 3,
    [AosUserGroupType.REMOVED]: 4,
}

export const mapAosUserGroupTypeToColor: Record<AosUserGroupType, Color> = {
    [AosUserGroupType.ALL]: Color.Transparent,
    [AosUserGroupType.CUSTOM]: Color.Transparent,
    [AosUserGroupType.EXTERNAL]: Color.Green,
    [AosUserGroupType.INTERNAL]: Color.Primary,
    [AosUserGroupType.MANUAL]: Color.TimelineBackground,
    [AosUserGroupType.REMOVED]: Color.Red,
}
