import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { FC } from 'react'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { openShiftNotesModalAction } from '../../../../core/tasks/shiftNotes/actions'
import { ShiftNotesItemState } from '../../../../services/statusDashboard/types/itemStates/ShiftNotesItemState'
import { ShiftNotesList } from '../../../tasks/shiftNotes/ShiftNotesList'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { ShiftNotesItem } from './ShiftNotesItem'

type ShiftNotesOwnProps = StatusDashboardItemCommonProps<ShiftNotesItemState>

interface ShiftNotesProps extends ShiftNotesOwnProps, ShiftNotesStateProps {}

export const ShiftNotesComponent: FC<ShiftNotesProps> = ({ canEdit, ...props }) => {
    const dispatch = useDispatch()

    return (
        <ShiftNotesItem
            noDataWarning={false}
            itemProps={pickDashboardItemProps(props)}
            syncId='overview'
        >
            <DarkScrollbar>
                <ShiftNotesList />
            </DarkScrollbar>

            <Box justify='flex-end' padding={16} marginRight={12} row>
                {canEdit && (
                    <FormButton
                        label={translate('shift-notes.button.create')}
                        onClick={() => dispatch(openShiftNotesModalAction())}
                        variant={FormButtonVariant.Primary}
                        size={FormComponentSize.Small}
                    />
                )}
            </Box>
        </ShiftNotesItem>
    )
}

interface ShiftNotesStateProps {
    canEdit: boolean
}

export const ShiftNotesWidget = connect<ShiftNotesStateProps>(
    createStructuredSelector({
        canEdit: canEditTasksSelector,
    }),
)(ShiftNotesComponent)
