import {
    DiscountCouponGroup,
    DiscountStore,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'

export interface DiscountModuleBaseState {
    discountCoupons: DiscountCouponGroup
    discountStores: DiscountStore[]
    selectedFilterStores: number[]
}

export const initialDiscountModuleBaseState: DiscountModuleBaseState = {
    discountCoupons: {},
    discountStores: [],
    selectedFilterStores: [],
}
