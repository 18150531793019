import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { css } from 'styled-components'

import { BorderStyle } from '../../../../aos-ui-common/src/styles/Borders'
import { Box } from '../base/Box'
import { boxShadow } from '../base/Theme'

interface InfoBarProps {
    color?: Color
    borderColor?: Color
    borderStyle?: BorderStyle
    borderWidth?: number
    outlined?: boolean
    isClippedLeft?: boolean
    isClippedRight?: boolean
    shadowed?: boolean
}

export const InfoBar = styled(Box)<InfoBarProps>`
    border-radius: 5px;
    border-width: ${({ borderWidth }) => `${borderWidth ?? 2}px`};
    border-color: ${p => (p.borderColor ? p.borderColor : p.color ? p.color : 'rgba(0,0,0,0.3)')};
    border-style: ${p => (p.borderStyle ? p.borderStyle : BorderStyle.solid)};

    ${p =>
        p.outlined &&
        css`
            background-color: ${Color.TimelineBackground};
        `}

    ${p =>
        !p.outlined &&
        css`
            background-color: ${p.color ? p.color : 'rgba(0,0,0,0.3)'};
        `}
    ${p =>
        p.isClippedLeft &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}
    ${p =>
        p.isClippedRight &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `}
    ${p =>
        p.shadowed &&
        css`
            box-shadow: ${boxShadow.std};
        `}
`

export const InfoBarContent = styled(Box)<{ inTooltip?: boolean }>`
    width: 100%;
    height: 40px;
    ${p =>
        p.inTooltip &&
        css`
            height: auto;
            min-height: 40px;
            background-color: ${Color.White};

            ${InfoBarText} {
                padding-top: 8px;
                padding-bottom: 8px;
                text-overflow: clip;
                white-space: normal;
            }
        `}
`

export const InfoBarText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
