import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { LeftRight } from 'aos-ui/src/components/leftRight/LeftRight'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { ProgressBar } from './ProgressBar'

interface ProgressDatum {
    value: number
    color: Color
    label: string
}

interface LabeledMultiProgressBarProps extends BoxProps {
    minValue: number
    barData: ProgressDatum[]
    title: string
}

/* eslint-disable react/jsx-no-literals */
export const LabeledMultiProgressBar: FCWithChildren<LabeledMultiProgressBarProps> = ({
    barData,
    title,
    minValue,
    ...boxProps
}) => {
    const label = (s: string, index: number, isLast: boolean) => (
        <Text
            key={index}
            size={12}
            color={Color.Grey}
            weight='regular'
            lineHeight='large'
            paddingBottom={isLast ? 0 : 8}
        >
            {s}
        </Text>
    )

    const bar = (s: ProgressDatum, index: number, isLast: boolean) => (
        <Box key={index} paddingBottom={isLast ? 0 : 12}>
            <ProgressBar
                key={index}
                minValue={minValue}
                seriesConfig={{ leftColor: s.color, rightColor: Color.TimelineBackground }}
                value={s.value}
            />
        </Box>
    )

    return (
        <Box row alignItems='flex-start' {...boxProps}>
            <Box shrink={0} paddingRight={30}>
                <Text paddingBottom={16} color={Color.White} size={12} weight='regular'>
                    {title}
                </Text>
                {barData.map((v, index) => label(v.label, index, index === barData.length - 1))}
            </Box>
            <Box flex='auto'>
                <LeftRight
                    paddingBottom={16}
                    textNoWrap
                    size={12}
                    color={Color.Grey}
                    weight='regular'
                    left={`${minValue}%`}
                    right='100%'
                />
                {barData.map((v, index) => bar(v, index, index === barData.length - 1))}
            </Box>
            <Box shrink={0} paddingLeft={16}>
                <Text paddingBottom={16} color={Color.White} size={12}>
                    &nbsp;
                </Text>
                {barData.map((v, index) =>
                    label(`${v.value}%`, index, index === barData.length - 1),
                )}
            </Box>
        </Box>
    )
}
