import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

export const GroupHeader: FC<PropsWithChildren> = ({ children }) => (
    <GroupHeaderBox paddingHorizontal={30} color={Color.White} size={18}>
        {children}
    </GroupHeaderBox>
)

const GroupHeaderBox = styled(Text)`
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid ${Color.ChartBase};
`
