import { GeoJSONWithFeature } from 'aos-map-engine/src/Types'
import { FeatureCollection, GeometryObject } from 'geojson'
import GeoJSON from 'ol/format/GeoJSON'

import { RingRailTrainsInfo } from './RingRailTrain'

export type LayerData<T> = FeatureCollection<GeometryObject, T>

export interface LayersDataState {
    gates?: GeoJSON
    busGates?: GeoJSONWithFeature
    arrivalServices?: GeoJSON
    securityCheck?: GeoJSON
    checkInKiosk?: GeoJSON
    checkInDesks?: GeoJSON
    baggageClaim?: GeoJSON
    customs?: GeoJSON
    passportControl?: GeoJSON
    parking?: GeoJSON
    stands?: GeoJSON
    busDoors?: GeoJSON
    ringRailTrainsInfo?: RingRailTrainsInfo
    walkingRoutes?: GeoJSON
    walkingRoutesIcon?: GeoJSON
    basemapServiceName?: string
}

export interface LayersDataStateAware {
    layersData: LayersDataState
}

export type LayerDataKey = keyof LayersDataState

export const findPointInLayerData = <T>(
    data: GeoJSONWithFeature | undefined,
    predicate: (props: T) => boolean,
): GeoJSONWithFeature | undefined =>
    // @ts-ignore
    data ? data.features.find(d => predicate(d.properties)) : undefined
