import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Text, TextProps } from '../base/Text'

export const BorderedChip: FCWithChildren<TextProps> = props => {
    return (
        <Container
            paddingVertical={4}
            paddingHorizontal={8}
            size={11}
            weight='bold'
            textAlign='center'
            {...props}
        />
    )
}

const Container = styled(Text)`
    display: inline-block;
    border: 1px solid ${p => p.border || Color.SideMenuInactive};
    border-radius: 4px;
    text-transform: uppercase;
`
