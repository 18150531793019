import { logger } from '../../../../aos-helpers/src/helpers/logging/Logger'
import { firebaseRestService } from '../../dataaccess/firebase/firebaseRestService'
import { firebaseService } from '../firebase/firebaseService'

class FirebaseAuthService {
    public assureUserLoggedIn = async () => {
        if (firebaseService.auth().currentUser) {
            return Promise.resolve()
        } else {
            const token = await firebaseRestService.getAuthToken()
            return await firebaseService.auth().signInWithCustomToken(token)
        }
    }

    public logout = async () => {
        try {
            if (firebaseService.auth().currentUser) {
                return await firebaseService.auth().signOut()
            }
        } catch (error) {
            logger.handleError(error as Error, 'Firebase logout tried without user signed in')
            return null
        }
    }
}

export const firebaseAuthService = new FirebaseAuthService()
