import {
    DropTargetOverlay,
    DropTargetOverlayVariant,
} from 'aos-components/src/components/dnd/DropTargetOverlay'
import { DnDWrapper } from 'aos-components/src/helpers/DnDWrapper'
import { BaseDragDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { severityColorsMapping } from 'aos-services/src/services/common/types/AosSeverity'
import {
    AosEventOrFeedIn,
    isAosEvent,
} from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { processCategoryIcon } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import { DarkListItemTextBlock } from 'aos-ui/src/components/darkListItem/DarkListItemTextBlock'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import { Icon, IconVariant, svgIconForName } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { ScheduledRerender } from 'aos-ui-common/src/components/time/ScheduledRerender'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { EventItemIconsSection } from './EventItemIconsSection'
import { draggableEventWrapper, droppableEventWrapper } from './EventListDnd'

class EventItemClass extends PureComponent<EventItemProps & BaseDragDropTargetProps> {
    public render() {
        const {
            event,
            isDragging,
            isOver,
            draggable,
            droppable,
            connectDragSource,
            connectDropTarget,
            onClick,
        } = this.props
        const hasContextMenu = this.props.activate || this.props.dismiss
        const hasPrivateChannel = isAosEvent(event) ? event.hasPrivateChannel : false
        const attachmentsLength = event.allAttachments.length
        const forceUpdate = () => this.forceUpdate()
        const borderColor = event.severity
            ? severityColorsMapping[event.severity]
            : Color.Transparent
        return (
            <DnDWrapper
                onClick={onClick}
                draggable={draggable}
                droppable={droppable}
                connectDragSource={connectDragSource}
                connectDropTarget={connectDropTarget}
            >
                <DarkListItemBox
                    isDragging={isDragging}
                    row
                    cursor='pointer'
                    paddingLeft={4}
                    paddingRight={hasContextMenu ? 8 : 16}
                    borderColor={borderColor}
                    paddingVertical={12}
                >
                    <ScheduledRerender updateTime={event.endTime} updateCallback={forceUpdate}>
                        <MoreHover flex='auto' row>
                            <Box overflow='hidden' row flex={1}>
                                <Box row flex={1} fullHeight>
                                    {this.renderCategoryIcon()}
                                    {this.renderDescriptionSection()}
                                </Box>
                                <Section textAlign='center'>
                                    {this.renderTimeAndReporterSection()}
                                </Section>
                                <EventItemIconsSection
                                    hasPrivateChannel={hasPrivateChannel}
                                    hasLocation={event.hasLocation}
                                    attachmentsLength={attachmentsLength}
                                    discussionLength={event.discussionLength || 0}
                                />
                            </Box>
                            {hasContextMenu && (
                                <Box centered shrink={0} width={64}>
                                    <More>
                                        <MoreItem onClick={this.props.activate}>
                                            {translate('event-manager.activate')}
                                        </MoreItem>
                                        <MoreItem onClick={this.props.dismiss}>
                                            {translate('event-manager.dismiss')}
                                        </MoreItem>
                                    </More>
                                </Box>
                            )}
                            {isOver && this.renderOverPlaceholder()}
                        </MoreHover>
                    </ScheduledRerender>
                </DarkListItemBox>
            </DnDWrapper>
        )
    }

    private renderOverPlaceholder = () => (
        <DropTargetOverlay
            label={translate('event-manager.create-group')}
            onDrop={this.props.onDrop}
            isOver
            variant={DropTargetOverlayVariant.Dark}
        />
    )

    private renderDescriptionSection = () => (
        <TextSection>
            <DarkListItemTextBlock
                isOverdue={this.props.event.overdue}
                paddingLeft={20}
                title={this.props.event.title}
                subtitle={this.props.event.description}
                seleniumLocation='event-manager-item-description'
            />
        </TextSection>
    )

    private renderEventTime = () => {
        const { event } = this.props
        const formattedTime = event.presentationTime
            ? formatCalendarTime(event.presentationTime)
            : undefined
        return (
            formattedTime && (
                <>
                    {this.props.event.overdue && (
                        <OverdueIcon
                            svg={SvgIcon.Schedule}
                            marginRight={4}
                            iconVariant={IconVariant.Yellow}
                            iconSize={BlockSize.Tiny}
                        />
                    )}
                    {formattedTime}
                </>
            )
        )
    }

    private renderTimeAndReporterSection = () => (
        <DarkListItemTextBlock
            paddingLeft={20}
            isOverdue={this.props.event.overdue}
            title={this.renderEventTime()}
            subtitle={this.props.event.createdBy}
            seleniumLocation='event-manager-item-reporter-timestamp'
        />
    )

    private renderCategoryIcon = () => {
        const categoryIcon = this.props.event.category
            ? svgIconForName(processCategoryIcon(this.props.event.category))
            : null
        return (
            categoryIcon && (
                <Box marginLeft={20}>
                    <Icon
                        iconSize={BlockSize.XLarge}
                        iconVariant={IconVariant.White}
                        svg={categoryIcon}
                    />
                </Box>
            )
        )
    }
}

const DarkListItemBox = styled(DarkListItem)`
    min-height: 80px;
`

const TextSection = styled(Text)`
    min-width: 100px;
`
const Section = styled(Text)`
    min-width: 180px;
`

const OverdueIcon = styled(Icon)`
    vertical-align: top;
`

interface EventItemProps extends SeleniumProps {
    event: AosEventOrFeedIn
    draggable?: boolean
    droppable?: boolean
    onDrop?(v: AosEventOrFeedIn): void
    activate?(): void
    dismiss?(): void
    onClick?(): void
}

export const EventItem = droppableEventWrapper<EventItemProps>()(
    draggableEventWrapper<EventItemProps>()(EventItemClass),
)
