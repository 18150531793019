import { cx } from 'aos-components/src/components/base/ClassNames'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { EditButton } from 'aos-ui/src/components/buttons/EditButton'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { HeaderFilterItem } from 'aos-ui/src/components/header/HeaderFilterItem'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import {
    MyDashboardTab,
    translateTab,
} from '../../../services/statusDashboard/types/DashboardPreset'

interface MyDashboardButtonProps {
    selected: boolean
    isSidebarOpen: boolean
    onClick(): void
    toggleSidebar(v: boolean): void
}

export const MyDashboardButton: FC<MyDashboardButtonProps> = props => (
    <Box
        className={cx(
            'dashboard-sidebar-button',
            'border-left--black',
            'full-height',
            'padding-left--x-large padding-right--large',
            'container container--horizontal container--justify-space',
        )}
    >
        <Box row justify='space-between' flex={1}>
            <HeaderFilterItem selected={props.selected} onClick={props.onClick}>
                {translateTab(MyDashboardTab.MyDashboard)}
            </HeaderFilterItem>
            {props.isSidebarOpen ? (
                <IconButton
                    margin={6}
                    onClick={() => props.toggleSidebar(false)}
                    svg={SvgIcon.Close}
                    iconSize={BlockSize.Std}
                    iconVariant={IconVariant.Smart}
                />
            ) : (
                <EditButton onClick={() => props.toggleSidebar(true)} />
            )}
        </Box>
    </Box>
)
