import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { boxShadow, ZIndex } from 'aos-ui/src/components/base/Theme'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import styled from 'styled-components'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { toggleGlobalMessagingAction } from '../../core/globalMessaging/actions'
import { syncEventManagerDotAction, toggleWhatsNewAction } from '../../core/layout/actions'
import { Link } from '../../core/Links'
import { State } from '../../core/state'
import { MenuAbout } from './MenuAbout'
import { MenuItem } from './MenuItem'

export const Menu = React.memo(() => {
    const dispatch = useDispatch()
    const { isMenuOpen, currentUser, currentPath, isMessagingOpen, isEventManagerDotVisible } =
        useSelector(menuSelector)
    const closeMessagesContainer = () => dispatch(toggleGlobalMessagingAction(false))

    const navLinkActive = (link: string) => {
        return currentPath && !isMessagingOpen ? currentPath.indexOf(link) === 0 : false
    }
    const location = useSelector(currentUserSiteLocation)

    return (
        <MenuBox as='aside' isExpanded={isMenuOpen} column>
            <DarkScrollbar>
                <MenuItem
                    svg={SvgIcon.Dashboard}
                    isMenuOpen={isMenuOpen}
                    link={Link.StatusDashboard}
                    label={translate('menu.status-dashboard')}
                    isActive={navLinkActive(Link.StatusDashboard)}
                    onClick={closeMessagesContainer}
                />
                <MenuItem
                    svg={SvgIcon.Timeline}
                    isMenuOpen={isMenuOpen}
                    link={Link.EventTimeline}
                    label={translate('menu.dashboard')}
                    isActive={navLinkActive(Link.EventTimeline)}
                    onClick={closeMessagesContainer}
                />

                {currentUser.isApoc() && (
                    <SyncWrapper onEnter={syncEventManagerDotAction()}>
                        <MenuItem
                            svg={SvgIcon.Events}
                            isMenuOpen={isMenuOpen}
                            link={Link.EventManager}
                            label={translate('menu.event-manager')}
                            isActive={navLinkActive(Link.EventManager)}
                            onClick={closeMessagesContainer}
                            isDotVisible={isEventManagerDotVisible}
                        />
                    </SyncWrapper>
                )}
                {currentUser.canViewTasks() && (
                    <MenuItem
                        svg={SvgIcon.Assignment}
                        isMenuOpen={isMenuOpen}
                        link={Link.Tasks}
                        label={translate('menu.tasks')}
                        isActive={navLinkActive(Link.Tasks)}
                        onClick={closeMessagesContainer}
                    />
                )}
                {currentUser.isApoc() && (
                    <MenuItem
                        svg={SvgIcon.Users}
                        isMenuOpen={isMenuOpen}
                        link={Link.GroupManager}
                        label={translate('menu.group-manager')}
                        isActive={navLinkActive(Link.GroupManager)}
                        onClick={closeMessagesContainer}
                    />
                )}
                <MenuItem
                    svg={SvgIcon.Map}
                    isMenuOpen={isMenuOpen}
                    link={Link.Map}
                    label={translate('menu.map')}
                    isActive={navLinkActive(Link.Map)}
                    onClick={closeMessagesContainer}
                />
                {(currentUser.isApoc() || currentUser.canViewTasks()) && (
                    <MenuItem
                        svg={SvgIcon.Checklist}
                        isMenuOpen={isMenuOpen}
                        link={Link.ChecklistManager}
                        label={translate('menu.checklists-manager')}
                        isActive={navLinkActive(Link.ChecklistManager)}
                        onClick={closeMessagesContainer}
                    />
                )}
                <MenuItem
                    svg={SvgIcon.Flights}
                    isMenuOpen={isMenuOpen}
                    link={Link.Flights}
                    label={translate('menu.flight-information')}
                    isActive={navLinkActive(Link.Flights)}
                    onClick={closeMessagesContainer}
                />
                {currentUser.isApoc() && (
                    <MenuItem
                        svg={SvgIcon.Messaging}
                        isMenuOpen={isMenuOpen}
                        label={translate('menu.messaging')}
                        isActive={isMessagingOpen}
                        onClick={() => dispatch(toggleGlobalMessagingAction(!isMessagingOpen))}
                    />
                )}

                <MenuItem
                    isMenuOpen={isMenuOpen}
                    isActive={navLinkActive(Link.ContactList)}
                    label={translate('menu.contact-list')}
                    link={Link.ContactList}
                    svg={SvgIcon.ContactList}
                    onClick={closeMessagesContainer}
                />
                {location === AosAirport.HEL && (
                    <MenuItem
                        isMenuOpen={isMenuOpen}
                        isActive={navLinkActive(Link.DiscountModule)}
                        label={translate('menu.discounts')}
                        link={Link.DiscountModule}
                        svg={SvgIcon.Percent}
                        onClick={closeMessagesContainer}
                    />
                )}
            </DarkScrollbar>
            <Box marginVertical={10} flex={1} />
            <MenuAbout
                horizontal={isMenuOpen}
                toggleWhatsNew={val => dispatch(toggleWhatsNewAction(val))}
            />
        </MenuBox>
    )
})

const MenuBox = styled(Box)<{ isExpanded: boolean }>`
    box-shadow: ${boxShadow.large};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${p => (p.isExpanded ? 300 : 96)}px;
    transition: width 0.2s;
    background-color: ${Color.WidgetBackground};
    overflow: hidden;
    z-index: ${ZIndex.Menu};
    will-change: width;

    ${Text} {
        transition: opacity 0.2s;
        opacity: ${p => (p.isExpanded ? 1 : 0)};
    }
`

const menuSelector = createSelector(
    (state: State) => state.layout,
    (state: State) => state.router.location?.pathname,
    (state: State) => state.auth.currentUser,
    (state: State) => state.globalMessaging.isOpen,
    (layout, currentPath, currentUser, isMessagingOpen) => ({
        ...layout,
        currentPath,
        currentUser,
        isMessagingOpen,
    }),
)
