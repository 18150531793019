import { appConfig } from 'appConfig'

import { EventAutomation } from './Types'

export class AutomationFactory {
    getAutomation(): EventAutomation {
        switch (appConfig.theme) {
            case 'AOS':
                const { AosProvider } = require('./concrete/AosProvider')
                return new AosProvider()
            case 'AOIMS':
                const { AoimsProvider } = require('./concrete/AoimsProvider')
                return new AoimsProvider()
            default:
                throw new Error(`Unknown theme: ${appConfig.theme}`)
        }
    }
}
