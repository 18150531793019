import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    ContactDTO,
    ContactVisibility,
    UnitAirportContact,
    UnitAirportContactDTO,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FilterOption } from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import {
    getAirportFilterOptions,
    getAirportNameWithCode,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { LabeledCheckboxDropdown } from 'aos-ui/src/components/form/labeled/LabeledCheckboxDropdown'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../core/state'

interface ContactFormProps {
    onAddNewUnit: () => void
    onEditUnit: (unit: UnitAirportContactDTO) => void
    form: UseFormReturn<ContactDTO>
}

export const ContactForm: FC<ContactFormProps> = ({ onAddNewUnit, onEditUnit, form }) => {
    const units = useSelector((state: State) => state.contactList.units)

    return (
        <Box flex={1} column>
            <Box column rowGap={8}>
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledFormElement
                            label={translate('contact-list.form.visibility.label')}
                            isError={fieldState.invalid}
                            marginBottom={12}
                            isRequired
                            seleniumLocation='visibility'
                            errorMessage={translate('contact-list.form.visibility.error')}
                        >
                            <RadioGroup
                                items={EnumValues.getValues<ContactVisibility>(ContactVisibility)}
                                formatter={translateEnum(ContactVisibility, 'contact-visibility')}
                                fontSize={13}
                                variant={ThemeVariant.White}
                                {...field}
                            />
                        </LabeledFormElement>
                    )}
                    name='contactVisibility'
                    control={form.control}
                    defaultValue={ContactVisibility.AllUsers}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledCheckboxDropdown
                            isError={fieldState.invalid}
                            selectAllOption={FilterOption.All}
                            keyPrefix='contact-list.form.airport'
                            partialContent={(i: AosAirport[]) =>
                                i.length === 1
                                    ? getAirportNameWithCode(i[0])
                                    : translate('filter.placeholder.selected', { count: i.length })
                            }
                            allContent={translate('contact-list.form.airport.all')}
                            valueRenderer={getAirportNameWithCode}
                            variant={DropdownVariant.White}
                            outlined
                            isRequired
                            items={getAirportFilterOptions()}
                            {...field}
                            onChange={(v: AosAirport[]) => field.onChange(v)}
                        />
                    )}
                    name='siteLocations'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <Box>
                            <LabeledSelect
                                valueRenderer={(item: UnitAirportContact) =>
                                    item.id < 0 ? (
                                        <AddNewButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                onAddNewUnit()
                                            }}
                                        >
                                            <Box row>
                                                <Icon svg={SvgIcon.Plus} /> {item.name}
                                            </Box>
                                        </AddNewButton>
                                    ) : (
                                        item.name
                                    )
                                }
                                tooltip={translate(
                                    'contact-list.form.unit-airport-contact.tooltip',
                                )}
                                isError={fieldState.invalid}
                                isRequired
                                keyPrefix='contact-list.form.unit-airport-contact'
                                items={[
                                    ...units,
                                    { id: -1, name: translate('contact-list.form.new') },
                                ]}
                                labelRenderer={(q: UnitAirportContact) => q.name}
                                {...field}
                                onChange={(v: UnitAirportContact) =>
                                    v.id < 0 ? onAddNewUnit() : field.onChange(v)
                                }
                            />
                            {field.value && (
                                <LabeledFormElement
                                    label={translate(
                                        'contact-list.form.unit-airport-contact.description',
                                    )}
                                >
                                    <Box row>
                                        <Text flex={1} size={14} color={Color.Grey8}>
                                            {field.value?.description}
                                        </Text>
                                        <FormButton
                                            onClick={() =>
                                                onEditUnit(field.value as UnitAirportContactDTO)
                                            }
                                            label={translate(
                                                'contact-list.form.unit-airport-contact.edit',
                                            )}
                                            variant={FormButtonVariant.Text}
                                        />
                                    </Box>
                                </LabeledFormElement>
                            )}
                        </Box>
                    )}
                    name='unitAirportContact'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isError={fieldState.invalid}
                            isRequired
                            errorMessage={fieldState.error?.message}
                            onChangeText={field.onChange}
                            keyPrefix='contact-list.form.contact-name'
                            {...field}
                        />
                    )}
                    name='name'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isError={fieldState.invalid}
                            errorMessage={fieldState.error?.message}
                            onChangeText={field.onChange}
                            keyPrefix='contact-list.form.contact-description'
                            {...field}
                        />
                    )}
                    name='description'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isError={fieldState.invalid}
                            onChangeText={field.onChange}
                            isRequired
                            errorMessage={fieldState.error?.message}
                            keyPrefix='contact-list.form.phone-number'
                            {...field}
                        />
                    )}
                    name='phoneNumber'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            type='email'
                            isError={fieldState.invalid}
                            errorMessage={fieldState.error?.message}
                            onChangeText={field.onChange}
                            keyPrefix='contact-list.form.email'
                            {...field}
                        />
                    )}
                    name='email'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isError={fieldState.invalid}
                            errorMessage={fieldState.error?.message}
                            onChangeText={field.onChange}
                            keyPrefix='contact-list.form.link'
                            {...field}
                        />
                    )}
                    name='link'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isError={fieldState.invalid}
                            onChangeText={field.onChange}
                            errorMessage={fieldState.error?.message}
                            keyPrefix='contact-list.form.link-label'
                            {...field}
                        />
                    )}
                    name='linkLabel'
                    control={form.control}
                />
            </Box>
        </Box>
    )
}

const AddNewButton = styled.button`
    background-color: transparent;
    border: none;
    color: ${Color.ContactFormAddNewButtonColor};
    cursor: pointer;
    font-size: 12px;
    padding: 0;
`
