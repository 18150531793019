import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

const IndeterminateIcon = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='checkbox'>
            <rect
                id='Rectangle 20'
                x='1'
                y='1'
                width='14'
                height='14'
                rx='2'
                fill={Color.CheckboxOnWhiteBackground}
                stroke={Color.CheckboxOnWhiteBackground}
                strokeWidth='2'
            />
            <rect id='Rectangle 28' x='3' y='7' width='10' height='2' fill={Color.White} />
        </g>
    </svg>
)
export default IndeterminateIcon
