import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { cx } from '../../base/ClassNames'

export class WhiteModalHeader extends PureComponent<WhiteModalHeaderProps> {
    public render() {
        return (
            <div
                className={cx(
                    'container container--horizontal padding-horizontal--x-large ' +
                        'padding-top--x-large',
                )}
                data-test-id='modal-container-title'
            >
                <div className='flex--1'>
                    <Text color={Color.TimelineBackground}>{this.props.title}</Text>
                </div>
                <div className='flex-shrink--0'>
                    <IconButton
                        onClick={this.props.closeAction}
                        iconSize={BlockSize.Small}
                        iconVariant={IconVariant.Black}
                        svg={SvgIcon.Close}
                        seleniumLocation='modal-container-button-close'
                    />
                </div>
            </div>
        )
    }
}

export interface WhiteModalHeaderProps {
    title: string
    closeAction(): void
}
