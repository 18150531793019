import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    RescueServiceTaskFormPayload,
    RescueServiceTaskPayloadValidation,
    RescueServiceUnitPayload,
} from 'aos-services/src/services/tasks/types/payload/RescueServiceTaskFormPayload'
import { FireTruck } from 'aos-services/src/services/tasks/types/task/FireTruck'
import { RescueServiceTaskType } from 'aos-services/src/services/tasks/types/task/RescueServiceTask'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { CleanableNumberInput } from 'aos-ui/src/components/form/input/CleanableNumberInput'
import { LabeledEditableList } from 'aos-ui/src/components/form/labeled/LabeledEditableList'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { isNull } from 'lodash'
import React, { FC } from 'react'

interface RescueServiceTaskFormProps {
    form: FormValidation<RescueServiceTaskPayloadValidation>
    payload: RescueServiceTaskFormPayload
    metadata: TaskMetadata
    changeForm(t: Partial<RescueServiceTaskFormPayload>): void
}

export const RescueServiceTaskForm: FC<RescueServiceTaskFormProps> = ({
    form,
    metadata,
    payload,
    changeForm,
}) => (
    <>
        <LabeledSelect
            keyPrefix='tasks.form.rescue-service.task-type'
            marginBottom={12}
            isRequired
            isError={form.error.taskType}
            seleniumLocation='task-type'
            items={metadata.rescueServiceTaskTypes}
            labelRenderer={(l: RescueServiceTaskType) => l.label}
            value={payload.taskType}
            onChange={(taskType: RescueServiceTaskType) =>
                changeForm({ taskType, title: taskType?.label })
            }
        />
        <LabeledInput
            keyPrefix='tasks.form.rescue-service.address'
            marginBottom={12}
            value={payload.address}
            onChangeText={address => changeForm({ address })}
        />
        <LabeledInput
            keyPrefix='tasks.form.rescue-service.aircraft-registration'
            marginBottom={12}
            value={payload.aircraftRegistration}
            onChangeText={aircraftRegistration => changeForm({ aircraftRegistration })}
        />
        <LabeledEditableList
            keyPrefix='tasks.form.rescue-service.unit'
            spacing={16}
            value={payload.units}
            emptyItem={{ uuid: crypto.randomUUID() }}
            onChange={units => changeForm({ units })}
            validation={form.error.units.map(i => ({
                isError: i.name || i.numberOfWorkers,
                errorMessage: translate('tasks.form.rescue-service.unit-entry.error'),
            }))}
            itemRenderer={({ item, index, onChangeItem }) => (
                <RescueServiceUnitRenderer
                    allFireTrucks={metadata.fireTrucks}
                    unit={item}
                    key={index}
                    onChange={onChangeItem}
                />
            )}
        />
        <LabeledInput
            keyPrefix='tasks.form.rescue-service.additional-unit'
            marginBottom={12}
            value={payload.additionalUnits}
            onChangeText={additionalUnits => changeForm({ additionalUnits })}
        />
    </>
)

interface RescueServiceUnitRendererProps {
    unit: RescueServiceUnitPayload
    allFireTrucks: FireTruck[]
    onChange(v: RescueServiceUnitPayload): void
}

export const RescueServiceUnitRenderer: FC<RescueServiceUnitRendererProps> = ({
    unit,
    allFireTrucks,
    onChange,
}) => (
    <Box row alignItems='flex-start' paddingRight={16} marginBottom={12}>
        <Box flex={2}>
            <DropdownAutocomplete
                value={unit.name}
                items={allFireTrucks.map(fireTruck => fireTruck.callSign)}
                variant={DropdownVariant.White}
                labelRenderer={name => name}
                placeholder={translate('tasks.form.rescue-service.unit-name.placeholder')}
                onChange={name => onChange({ ...unit, name: isNull(name) ? undefined : name })}
                outlined
            />
        </Box>
        <Box flex={1} marginLeft={20}>
            <CleanableNumberInput
                placeholder={translate('tasks.form.rescue-service.unit-number.placeholder')}
                value={unit.numberOfWorkers}
                onChange={numberOfWorkers => onChange({ ...unit, numberOfWorkers })}
            />
        </Box>
    </Box>
)
