import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Box, BoxProps } from '../base/Box'
import { DarkScrollbar } from '../scrollbar/DarkScrollbar'

export const DarkScrollableList: FCWithChildren<BoxProps> = ({ children, ...boxProps }) => (
    <Box bg={Color.DarkBackground} overflow='hidden' flex={1}>
        <DarkScrollbar>
            <Box {...boxProps}>{children}</Box>
        </DarkScrollbar>
    </Box>
)
