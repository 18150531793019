export enum Link {
    Root = '/',
    EventTimeline = '/event-timeline',
    StatusDashboard = '/status-dashboard',
    StatusDashboardOverview = '/status-dashboard/overview',
    StatusDashboardPreset = '/status-dashboard/:preset?',
    Map = '/map',
    EventManager = '/event-manager',
    GroupManager = '/group-manager',
    RemoveAccount = '/remove-account',
    EventManagerItem = '/event-manager/:id(\\d+)',
    ChecklistManager = '/checklist-manager',
    ContactList = '/contact-list',

    Flights = '/flights',
    FlightsArrivals = '/flights/arrivals',
    FlightsDepartures = '/flights/departures',
    FlightsTowingList = '/flights/towing-list',
    FlightsStandsGates = '/flights/stands-gates',
    FlightsNotam = '/flights/notam',

    NotificationPreferences = '/notification-preferences',

    Tasks = '/tasks',
    TasksTimeline = '/tasks/timeline',
    TasksList = '/tasks/list',
    TasksReports = '/tasks/reports',
    TasksListMatch = '/tasks/list/:filter',
    TasksShiftNotes = '/tasks/shift-notes',
    TasksFireFighters = '/tasks/fire-fighters',
    FOD = '/tasks/fod',

    DiscountModule = '/discounts',
}

export interface StatusDashboardLinkParams {
    preset: string
}
