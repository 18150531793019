import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import React, { PureComponent } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { DashboardHeaderStates } from '../../../core/statusDashboard/selectors'
import {
    MyDashboardTab,
    StatusDashboardTab,
} from '../../../services/statusDashboard/types/DashboardPreset'
import { MyDashboardButton } from '../partials/MyDashboardButton'
import { StatusDashboardLink } from './StatusDashboardLink'

interface StatusDashboardHeaderProps extends RouteComponentProps {
    tab: StatusDashboardTab
    isSidebarOpen: boolean
    headerTabStates: DashboardHeaderStates
    onChangeTab(v: StatusDashboardTab): void
    toggleSidebar(v: boolean): void
}

class StatusDashboardHeaderClass extends PureComponent<StatusDashboardHeaderProps> {
    public render() {
        const { tab, isSidebarOpen, toggleSidebar, onChangeTab } = this.props
        return (
            <HeaderContainer title={this.renderTitle()}>
                <MyDashboardButton
                    selected={tab === MyDashboardTab.MyDashboard}
                    onClick={() => onChangeTab(MyDashboardTab.MyDashboard)}
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                />
            </HeaderContainer>
        )
    }

    private renderTitle() {
        const {
            headerTabStates,
            location: { pathname },
        } = this.props
        return headerTabStates.map(([tab, status]) => (
            <StatusDashboardLink
                key={tab}
                tab={tab}
                onClick={this.props.onChangeTab}
                status={status}
                selected={pathname.includes(tab)}
            />
        ))
    }
}

export const StatusDashboardHeader = withRouter(StatusDashboardHeaderClass)
