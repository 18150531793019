import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { GeoJSON } from 'ol/format'

import { LayerConfiguration, LayerFactory, MapLayer } from './Types'

export class LayerManager {
    private layerFactory: LayerFactory
    private config: LayerConfiguration

    constructor(config: LayerConfiguration, layerFactory: LayerFactory) {
        this.config = config
        this.layerFactory = layerFactory
    }

    public async loadLayer(airportId: string, layerId: string): Promise<MapLayer | null> {
        const layerConfig = this.config.airports[airportId].find(layer => layer.id === layerId)
        if (!layerConfig) {
            throw new Error(`Layer configuration for ${layerId} not found for airport ${airportId}`)
        }

        try {
            const loader = await this.layerFactory.createLoader(layerConfig)
            const result = await loader()
            return {
                ...layerConfig,
                geojson: result as GeoJSON,
            }
        } catch (e) {
            logger.handleError(e as Error)
            return null
        }
    }
}
