import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate, formatDayLong, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import {
    AnonymousType,
    FodSource,
    FodType,
    PlaceOfDiscovery,
} from 'aos-services/src/services/fod/types/FodNotesFormData'
import { selectedLocationZoom } from 'aos-services/src/services/map/mapPositions'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import {
    Breakpoint,
    breakpoints as defaultBreakpoints,
    useBreakpoint,
} from 'aos-ui/src/components/grid/ResponsiveGrid'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Link } from 'aos-ui/src/components/text/Link'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { setFodEditModalOpenAction } from '../../../core/fod/actions'
import { StatusTile } from '../../tasks/common/StatusTile'

interface FodNotesTileProps {
    item: FodDto
    fodToggleShowOnMap: (isOpen: boolean) => void
    isShowOnMapOpen: boolean
    fodSelectMapLocation: (payload: [MapVariant, MapPosition]) => void
    fodSwitchVariant: (variant: MapVariant) => void
    fodPreviewLocation: (fod: FodDto) => void
}

export const FodNotesTile: FC<FodNotesTileProps> = ({
    item,
    fodToggleShowOnMap,
    isShowOnMapOpen,
    fodSelectMapLocation,
    fodSwitchVariant,
    fodPreviewLocation,
}) => {
    const date = dateTime(item.discoveryDate)
    const isToday = moment().isSame(date, 'day')
    const breakpoints = useBreakpoint()
    const dispatch = useDispatch()

    return (
        <StatusTile
            status={isToday ? 'ok' : 'neutral'}
            title={
                <FodTitleWrapper>
                    <Box paddingRight={16} column>
                        <Text size={18} color={Color.White} paddingRight={8}>
                            {translateEnum(
                                PlaceOfDiscovery,
                                'fod.options.placeOfDiscovery',
                            )(item.placeOfDiscovery)}
                        </Text>
                        <Link
                            onClick={() => {
                                if (item?.location?.locationSource) {
                                    fodSwitchVariant(item.location.locationSource)
                                    fodSelectMapLocation([
                                        item.location.locationSource,
                                        { ...item.location, zoom: selectedLocationZoom },
                                    ])
                                    fodPreviewLocation(item)
                                }

                                fodToggleShowOnMap(!isShowOnMapOpen)
                            }}
                            size={12}
                            as='span'
                            paddingRight={8}
                            paddingTop={2}
                        >
                            {translate(`fod.item-element.show-on-map`)}
                        </Link>
                    </Box>
                    <Box>
                        <FodTypeLabel>
                            <Text size={18} color={Color.White}>
                                {translateEnum(FodType, 'fod.options.type')(item.fodType)}
                            </Text>
                        </FodTypeLabel>
                    </Box>
                    <Box row justify='flex-end'>
                        <IconButton
                            svg={SvgIcon.EditDark}
                            iconSize={BlockSize.Std}
                            iconVariant={IconVariant.White}
                            onClick={() => dispatch(setFodEditModalOpenAction(item))}
                        />
                    </Box>
                </FodTitleWrapper>
            }
            footer={
                <Box row>
                    <Box paddingRight={16} row>
                        <Text size={14} color={Color.White} paddingRight={8}>
                            {`${formatDayLong(date)}  ${formatDate(date)}  ${formatTime(date)}`}
                        </Text>
                    </Box>
                    <Box>
                        <Text size={14} color={Color.TextSecondary}>
                            {translate('fod-notes.list.reported-by', {
                                By: item.anonymousReport
                                    ? translateEnum<AnonymousType>(
                                          AnonymousType,
                                          'anonymousType',
                                      )(item.anonymousType)
                                    : item.reporterName,
                            })}
                        </Text>
                    </Box>
                </Box>
            }
        >
            <FodMessageWrapper>
                <FodDescriptionWrapper>
                    <Text size={18} color={Color.White}>
                        {translateEnum(FodSource, 'fod.options.source')(item.fodSource)}
                    </Text>
                    {item.description && (
                        <Box marginTop={16} column>
                            <Text size={14} color={Color.White}>
                                {item.description}
                            </Text>
                        </Box>
                    )}
                </FodDescriptionWrapper>
                {item?.attachments?.length > 0 && (
                    <FodPhotoWrapper>
                        <AttachmentContainer
                            reversePhotos={breakpoints !== Breakpoint.sm}
                            editable={false}
                            attachmentsState={{
                                attachments: item.attachments,
                                isUploading: false,
                            }}
                            gridSize={4}
                        />
                    </FodPhotoWrapper>
                )}
            </FodMessageWrapper>
        </StatusTile>
    )
}

const FodMessageWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;

    @media (min-width: ${defaultBreakpoints.md}px) {
        grid-template-columns: 3fr 2fr;
    }
`

const FodTitleWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 170px auto 1fr;
`

const FodDescriptionWrapper = styled.div`
    display: grid;
    align-content: start;
`

const FodPhotoWrapper = styled.div`
    display: grid;
    margin-top: 16px;

    @media (min-width: ${defaultBreakpoints.md}px) {
        margin-top: 0;
    }
`

const FodTypeLabel = styled.div`
    display: grid;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.426);
    padding: 8px 16px;
    justify-content: center;
`
