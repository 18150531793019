import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DashboardItemStatus } from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { FC } from 'react'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { openFireFighterFormAction } from '../../../../core/tasks/firefighters/actions'
import { firetrucksStatusSelector } from '../../../../core/tasks/firefighters/selectors'
import { FirefightersItemState } from '../../../../services/statusDashboard/types/itemStates/FirefightersItemState'
import { FirefightersList } from '../../../tasks/firefighters/FirefightersList'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { FireFightersItem } from './FirefightersItem'

type FireFightersWidgetOwnProps = StatusDashboardItemCommonProps<FirefightersItemState>

interface FireFightersWidgetProps extends FireFightersWidgetOwnProps, FireFightersStateProps {}

export const FireFightersWidgetComponent: FC<FireFightersWidgetProps> = props => {
    const dispatch = useDispatch()

    const onEdit = (shift: FirefighterShift) => {
        dispatch(openFireFighterFormAction(shift))
    }

    return (
        <FireFightersItem
            noDataWarning={false}
            itemProps={pickDashboardItemProps(props)}
            syncId='overview'
            overrideItemStatus={props.customStatus}
        >
            <DarkScrollbar>
                <FirefightersList onEdit={onEdit} />
            </DarkScrollbar>

            <Box justify='flex-end' padding={16} marginRight={12} row>
                <FormButton
                    label={translate('firefighters.button.create')}
                    onClick={() => dispatch(openFireFighterFormAction(undefined))}
                    variant={FormButtonVariant.Primary}
                    size={FormComponentSize.Small}
                />
            </Box>
        </FireFightersItem>
    )
}

interface FireFightersStateProps {
    customStatus: DashboardItemStatus
}

export const FireFightersWidget = connect<FireFightersStateProps>(
    createStructuredSelector({
        customStatus: firetrucksStatusSelector,
    }),
)(FireFightersWidgetComponent)
