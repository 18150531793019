import { call, put, takeEvery } from 'redux-saga/effects'

import { discountModuleService } from '../../services/discountModule/discountModuleService'
import {
    DiscountCouponGroup,
    DiscountStore,
} from '../../services/discountModule/types/DiscountCoupon'
import {
    LOAD_DISCOUNT_COUPONS,
    LOAD_DISCOUNT_STORES,
    LoadDiscountCouponsAction,
    loadDiscountCouponsSuccess,
    LoadDiscountStoresAction,
    loadDiscountStoresSuccess,
} from './actions'

function* loadDiscountCouponsSaga() {
    yield takeEvery<LoadDiscountCouponsAction>(LOAD_DISCOUNT_COUPONS, function* () {
        const discountCoupons: DiscountCouponGroup = yield call(
            discountModuleService.getDiscountCoupons,
        )
        yield put(loadDiscountCouponsSuccess(discountCoupons))
    })
}

function* loadDiscountStoresSaga() {
    yield takeEvery<LoadDiscountStoresAction>(LOAD_DISCOUNT_STORES, function* () {
        const discountStores: DiscountStore[] = yield call(discountModuleService.getDiscountStores)
        yield put(loadDiscountStoresSuccess(discountStores))
    })
}

export const baseDiscountModuleSagas = [loadDiscountCouponsSaga, loadDiscountStoresSaga]
