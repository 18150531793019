import { Box } from 'aos-ui/src/components/base/Box'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Color } from 'aos-ui-common/src/styles/Color'
import { lighten, rgba } from 'polished'
import React, { FCWithChildren } from 'react'
import styled, { CSSObject } from 'styled-components'

interface ToggleProps {
    value: boolean
    disabled?: boolean
    onChange(newValue: boolean): void
}

export const Toggle: FCWithChildren<ToggleProps> = ({ value, onChange, disabled }) => (
    <ToggleBox
        as='button'
        onClick={() => !disabled && onChange(!value)}
        isOn={value}
        disabled={disabled}
    >
        <Track>
            <Knob />
        </Track>
    </ToggleBox>
)

const Knob = styled(Box)`
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: absolute;
    top: -3px;
    left: -3px;
    background-color: ${Color.MenuButtonColor};
    transition: all 0.2s ease;
    box-shadow: ${boxShadow.std};
`

const Track = styled(Box)`
    position: relative;
    height: 14px;
    width: 34px;
    background-color: ${rgba(Color.MenuButtonColor, 0.5)};
    border-radius: 14px;
`

const ToggleBox = styled(Box)<{ isOn: boolean; disabled?: boolean }>(({ isOn, disabled }) => {
    const styles: CSSObject = {
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: 3,
        [`${Knob}`]: {},
        [`${Track}`]: {},
    }

    if (!disabled) {
        styles[`:hover ${Knob}`] = {
            transform: 'scale(1.2)',
        }
    }

    const knobTrack = (knobColor: string, trackColor: string) => {
        ;(styles[`${Knob}`] as CSSObject).backgroundColor = knobColor
        ;(styles[`${Track}`] as CSSObject).backgroundColor = trackColor
    }

    if (isOn) {
        ;(styles[`${Knob}`] as CSSObject).left = 'calc(100% - 17px)'
    }

    if (isOn && disabled) {
        knobTrack(lighten(0.4, Color.Black), rgba(Color.TimelineBackground, 0.5))
    } else if (!isOn && disabled) {
        knobTrack(Color.GreyLight, Color.DisabledText)
    } else if (isOn && !disabled) {
        knobTrack(Color.Primary, rgba(Color.Primary, 0.6))
    } else if (!isOn && !disabled) {
        knobTrack(Color.MenuButtonColor, rgba(Color.SideMenuInactive, 0.5))
    }

    return styles
})
