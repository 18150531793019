import { searchStringsContain } from 'aos-helpers/src/helpers/Filters'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { mapUpdatableValue } from 'aos-helpers/src/helpers/UpdatableValue'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { TowingDto, TowingStandDto } from '../../../../dataaccess/towingList/types/TowingDto'
import { AosHandlingAgentType } from '../AosHandlingAgent'
import { TimeType, Towing, TowingStand } from './Towing'
import { TowingStatus } from './TowingStatus'

export class TowingStandImpl extends WrapperObject<TowingStandDto> implements TowingStand {
    public stand = this.value.stand
    public confirmed = this.value.confirmed
}

export class TowingImpl extends WrapperObject<TowingDto> implements Towing {
    public id = this.value.uniqueId
    public acreg = this.value.acreg
    public actype = this.value.actype
    public after = mapUpdatableValue(this.value.after, dateTime)
    public until = this.value.until && mapUpdatableValue(this.value.until, dateTime)

    public arrFltnr = this.value.arrFltnr
    public arrTime = this.value.arrTime && mapUpdatableValue(this.value.arrTime, dateTime)
    public arrTimeType = this.value && (this.value.arrTimeType as TimeType)
    public arrCallsign = this.value && this.value.arrCallsign

    public depFltnr = this.value.depFltnr
    public depTime = this.value.depTime && mapUpdatableValue(this.value.depTime, dateTime)
    public depTimeType = this.value.depTimeType as TimeType
    public depCallsign = this.value.depCallsign

    public handl = this.value.handl as AosHandlingAgentType
    public standFrom = this.value.standFrom && new TowingStandImpl(this.value.standFrom)
    public standTo = this.value.standTo && new TowingStandImpl(this.value.standTo)
    public status = this.value.status as TowingStatus
    public lastUpdated = dateTime(this.value.lastUpdated)

    constructor(protected value: TowingDto) {
        super(value)
    }

    public quickMatch(query?: string) {
        const sourceStrings = [
            this.standFrom && this.standFrom.stand.value,
            this.standTo && this.standTo.stand.value,
            this.acreg,
            this.handl,
            this.status,
            this.arrFltnr,
            this.arrCallsign,
            this.depFltnr,
            this.depCallsign,
        ]
        return searchStringsContain(sourceStrings, query)
    }
}
