import { FirebaseEntity } from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { UpdatableValue } from 'aos-helpers/src/helpers/UpdatableValue'

import { AosHandlingAgentType } from '../AosHandlingAgent'
import { QuickFilterable } from '../QuickFilterable'
import { TowingStatus } from './TowingStatus'

export interface TowingStand {
    stand: UpdatableValue<string>
    confirmed: UpdatableValue<boolean>
}

export enum TimeType {
    Chocks = 'CHOCKS',
    Estimated = 'ESTIMATED',
    Scheduled = 'SCHEDULED',
    UserSupplied = 'USER_SUPPLIED',
}

export const translateTimeType = translateEnum<TimeType>(TimeType, 'flight-information.time-type')

export interface Towing extends FirebaseEntity, QuickFilterable {
    after: UpdatableValue<DateTime>
    until?: UpdatableValue<DateTime>
    standFrom?: TowingStand
    standTo?: TowingStand
    acreg?: string
    actype?: string
    handl?: AosHandlingAgentType

    arrFltnr?: string
    arrTime?: UpdatableValue<DateTime>
    arrTimeType?: TimeType
    arrCallsign?: string

    depFltnr?: string
    depTime?: UpdatableValue<DateTime>
    depTimeType?: TimeType
    depCallsign?: string

    status: TowingStatus
    lastUpdated: DateTime
}

export type TowingKey = keyof Towing

export const towingDateTimeColumns: TowingKey[] = [
    'after',
    'until',
    'arrTime',
    'depTime',
    'lastUpdated',
]
export type TowingDataTimeTypes = UpdatableValue<DateTime> | DateTime

export const afterGetter = (t: Towing) => t.after.value
export const untilGetter = (t: Towing) => t.until && t.until.value
