import { IExportForm } from 'aos-helpers/src/helpers/TaskExport'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const TimeFrame = () => {
    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext<IExportForm & { isChronological: boolean }>()

    return (
        <Box>
            <Text color={Color.TimelineBackground} lineHeight='standard' paddingBottom={20}>
                {translate('export-tasks.description')}
            </Text>
            <Controller
                control={control}
                name='timeStart'
                render={({ field, fieldState: { error } }) => (
                    <Box>
                        <DatetimeInput
                            {...field}
                            placeholder={translate('datetime.from')}
                            onChange={(value, changeType) =>
                                changeType === 'date'
                                    ? field.onChange(dateTime(value).startOf('day'))
                                    : field.onChange(dateTime(value))
                            }
                        />
                        {error && (
                            <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                                {error.message}
                            </Text>
                        )}
                    </Box>
                )}
            />
            <Controller
                control={control}
                name='timeEnd'
                render={({ field, fieldState: { error } }) => (
                    <Box>
                        <DatetimeInput
                            {...field}
                            timeRangeStart={dateTime(watch().timeStart)}
                            marginTop={8}
                            placeholder={translate('datetime.to')}
                            onChange={(value, changeType) =>
                                changeType === 'date'
                                    ? field.onChange(dateTime(value).endOf('day'))
                                    : field.onChange(dateTime(value))
                            }
                        />
                        {error && (
                            <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                                {error.message}
                            </Text>
                        )}
                    </Box>
                )}
            />

            {errors.isChronological && (
                <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                    {errors.isChronological.message as string}
                </Text>
            )}
        </Box>
    )
}
