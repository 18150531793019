import { discountModuleRestService } from '../../dataaccess/discountModule/discountModuleRestService'
import {
    DiscountCoupon,
    DiscountCouponDTO,
    DiscountCouponGroup,
    DiscountStore,
    DiscountStoreDTO,
} from './types/DiscountCoupon'

class DiscountModuleService {
    //coupons
    public async createDiscountCoupon(data: DiscountCouponDTO): Promise<DiscountCoupon> {
        return discountModuleRestService.createDiscountCoupon(data)
    }
    public async getDiscountCoupons(): Promise<DiscountCouponGroup> {
        return discountModuleRestService.getDiscountCoupons()
    }
    public async getDiscountCouponById(id: number): Promise<DiscountCoupon> {
        return discountModuleRestService.getDiscountCouponById(id)
    }
    public async editDiscountCouponById(data: { id: number; payload: DiscountCouponDTO }) {
        return discountModuleRestService.editDiscountCouponById(data)
    }
    public async deleteDiscountCouponById(id: number) {
        return discountModuleRestService.deleteDiscountCouponById(id)
    }

    //stores
    public async getDiscountStores(): Promise<DiscountStore[]> {
        return discountModuleRestService.getDiscountStores()
    }
    public async getDiscountStoreById(id: number): Promise<DiscountStore> {
        return discountModuleRestService.getDiscountStoreById(id)
    }
    public async createDiscountStore(data: DiscountStoreDTO): Promise<DiscountStore> {
        return discountModuleRestService.createDiscountStore(data)
    }
    public async editDiscountStoreById(data: DiscountStore) {
        return discountModuleRestService.editDiscountStoreById(data)
    }
    public async deleteDiscountStoreById(id: number) {
        return discountModuleRestService.deleteDiscountStoreById(id)
    }
}

export const discountModuleService = new DiscountModuleService()
