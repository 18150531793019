import { zodResolver } from '@hookform/resolvers/zod'
import {
    FooterModalProps,
    FormModal,
} from 'aos-components/src/components/modal/InputModal/FormModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    aosTaskChecklistSchema,
    AosTaskChecklistTemplate,
    TaskTag,
} from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import { getAirportName } from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import {
    taskCategories,
    TaskCategory,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { IconRadioRenderer } from 'aos-ui/src/components/form/radio/IconRadioRenderer'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Color } from 'aos-ui-common/src/styles/Color'
import { taskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import React, { FC, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

interface TaskChecklistModalProps {
    item?: AosTaskChecklistTemplate
    isOpen: boolean
    keyPrefix: string
    onHide: () => void
    onSubmit: (item: AosTaskChecklistTemplate) => void
}

export const TaskChecklistModal: FC<TaskChecklistModalProps> = ({
    item,
    isOpen,
    keyPrefix,
    onHide,
    onSubmit,
}) => {
    const currentLocation = useSelector(currentUserSiteLocation)

    const form = useForm<AosTaskChecklistTemplate>({
        defaultValues: item ?? { tag: currentLocation },
        mode: 'onSubmit',
        resolver: zodResolver(aosTaskChecklistSchema),
    })

    const footer = ({ pristine, valid, translateSuffix, submit }: FooterModalProps) => {
        return (
            <Box columnGap={8} className='container container--horizontal full-width'>
                <ValidationInfo pristine={pristine} valid={valid} />
                <Box row columnGap={24}>
                    <FormButton label={translateSuffix('ok')} onClick={submit} />
                </Box>
            </Box>
        )
    }
    const submit = form.handleSubmit(formData => {
        onSubmit(formData)
        onHide()
        form.reset({ tag: currentLocation })
    })

    useEffect(() => {
        if (item) {
            form.reset(item)
        }
    }, [item])

    return (
        <FormProvider {...form}>
            <FormModal
                pristine={!form.formState.isDirty || !form.formState.isSubmitted}
                valid={form.formState.isValid}
                isOpen={isOpen}
                hide={() => {
                    onHide()
                    form.reset()
                }}
                keyPrefix={keyPrefix}
                submit={submit}
                customFooter={footer}
            >
                <Box rowGap={8} column paddingBottom={12}>
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledInput
                                isError={fieldState.invalid}
                                isRequired
                                errorMessage={fieldState.error?.message}
                                keyPrefix='checklist-template.name'
                                onChangeText={field.onChange}
                                {...field}
                            />
                        )}
                        name='name'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledFormElement
                                label={translate('checklist-template.tag.label')}
                                isError={fieldState.invalid}
                                marginBottom={12}
                                isRequired
                                seleniumLocation='visibility'
                                errorMessage={translate('checklist-template.label.error')}
                            >
                                <RadioGroup
                                    items={[currentLocation, 'COMMON']}
                                    formatter={(item: TaskTag) =>
                                        item === 'COMMON'
                                            ? translate('checklist-template.tag.common')
                                            : getAirportName(item)
                                    }
                                    {...field}
                                    fontSize={13}
                                    variant={ThemeVariant.White}
                                />
                            </LabeledFormElement>
                        )}
                        name='tag'
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledFormElement
                                label={translate('tasks.form.category.label')}
                                isRequired
                                isError={fieldState.invalid}
                                seleniumLocation='category'
                            >
                                <BlockRadioGroup<TaskCategory>
                                    items={taskCategories}
                                    value={field.value}
                                    marginBottom={8}
                                    gridSize={3}
                                    Renderer={({ isSelected, item, onSelect, disabled }) => (
                                        <IconRadioRenderer
                                            isSelected={isSelected}
                                            label={translateTaskCategory(item)}
                                            onSelect={onSelect}
                                            disabled={disabled}
                                            icon={taskIcon[item]}
                                            color={Color.ChartBase}
                                        />
                                    )}
                                    onChange={field.onChange}
                                />
                            </LabeledFormElement>
                        )}
                        name='taskCategory'
                    />
                </Box>
            </FormModal>
        </FormProvider>
    )
}
