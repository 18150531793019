import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    RestrictionArea,
    restrictionAreas,
    translateAreaNumberLabel,
} from 'aos-services/src/services/restrictions/types/RestrictionArea'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { CheckboxList } from 'aos-ui/src/components/form/checkbox/CheckboxList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    closeFiltersModalAction,
    submitFiltersAction,
} from '../../../core/standsGatesTimeline/actions'
import {
    filtersSelector,
    isFiltersModalOpenSelector,
} from '../../../core/standsGatesTimeline/selectors'
import { StandsGatesTimelineFilters } from '../../../core/standsGatesTimeline/state'

interface StandsGatesTimelineFiltersModalProps
    extends StandsGatesTimelineFiltersModalDispatchProps,
        StandsGatesTimelineFiltersStateProps {}

export const StandsGatesTimelineFiltersModalClass: FC<
    StandsGatesTimelineFiltersModalProps
> = props => {
    const { isOpen, filters, submitFilters, closeModal } = props
    const [areaItems, setAreaItems] = useState(
        restrictionAreas.map(
            area => [area, !filters.hiddenAreas.includes(area)] as CheckboxItem<RestrictionArea>,
        ),
    )
    const getAreaLabel = (area: RestrictionArea) =>
        translateAreaNumberLabel(area.areaType, area.areaNumber)

    const onAreasChange = (areas: CheckboxItem<RestrictionArea>[]) => {
        setAreaItems(areas)
    }

    const toggleAll = (value: boolean) => setAreaItems(areaItems.map(item => [item[0], value]))

    const submit = () => {
        submitFilters({
            hiddenAreas: areaItems.filter(item => !item[1]).map(item => item[0]),
        })
        closeModal()
    }

    return (
        <WhiteModal
            title={translate('flight-information.timeline.filters.title')}
            footer={<FormButton label={translate('alerts.modal.confirm')} onClick={submit} />}
            closeAction={closeModal}
            shouldCloseOnOverlayClick
            isOpen={isOpen}
        >
            <Box padding={30}>
                <Text color={Color.TimelineBackground} size={14}>
                    {translate('flight-information.timeline.filters.areas')}
                </Text>
                <Box flex={1} paddingTop={24}>
                    <CheckboxList
                        items={areaItems}
                        itemToLabel={getAreaLabel}
                        onChange={onAreasChange}
                    />
                </Box>
                <Box row>
                    <Box>
                        <GreyTextButton
                            label={translate(
                                'flight-information.timeline.filters.areas.select-all',
                            )}
                            onClick={() => toggleAll(true)}
                            bare
                        />
                    </Box>
                    <Box marginLeft={20}>
                        <GreyTextButton
                            label={translate(
                                'flight-information.timeline.filters.areas.deselect-all',
                            )}
                            onClick={() => toggleAll(false)}
                            bare
                        />
                    </Box>
                </Box>
            </Box>
        </WhiteModal>
    )
}

interface StandsGatesTimelineFiltersStateProps {
    isOpen: boolean
    filters: StandsGatesTimelineFilters
}

interface StandsGatesTimelineFiltersModalDispatchProps {
    submitFilters: typeof submitFiltersAction
    closeModal: typeof closeFiltersModalAction
}

export const StandsGatesTimelineFiltersModal = connect<
    StandsGatesTimelineFiltersStateProps,
    StandsGatesTimelineFiltersModalDispatchProps
>(
    createStructuredSelector({
        isOpen: isFiltersModalOpenSelector,
        filters: filtersSelector,
    }),
    {
        submitFilters: submitFiltersAction,
        closeModal: closeFiltersModalAction,
    },
)(StandsGatesTimelineFiltersModalClass)
