import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { weatherForecastSelector } from 'aos-services/src/core/statusDashboardData/weather/selectors'
import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { State } from '../../../../../core/state'
import { WeatherItemState } from '../../../../../services/statusDashboard/types/itemStates/WeatherItemState'
import { pickDashboardItemProps, StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { Footer } from '../../base/Footer'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'

const lengthBreakpointLow = 1200
const lengthBreakpointMedium = 2200
const lengthBreakpointHigh = 3000
export const WeatherForecast: FC<WeatherForecastOwnProps> = ({
    itemState,
    itemStatus,
    ...rest
}) => {
    const generalWeatherStatus = useSelector(
        (state: State) => state.statusDashboard.statuses.WEATHER,
    )
    const weatherInfo = useSelector(weatherForecastSelector)
    const itemProps = pickDashboardItemProps({
        itemState,
        itemStatus: { ...itemStatus, status: generalWeatherStatus?.status as DashboardItemMode },
        ...rest,
    })

    useEffect(() => {
        if (weatherInfo && weatherInfo.additionalInfo) {
            if (weatherInfo.additionalInfo.length > lengthBreakpointHigh) {
                itemProps.onChangeSize([itemProps.id, DashboardItemSizeVariant.Custom3])
            } else if (weatherInfo.additionalInfo.length > lengthBreakpointMedium) {
                itemProps.onChangeSize([itemProps.id, DashboardItemSizeVariant.Custom2])
            } else if (weatherInfo.additionalInfo.length > lengthBreakpointLow) {
                itemProps.onChangeSize([itemProps.id, DashboardItemSizeVariant.Custom1])
            } else {
                itemProps.onChangeSize([itemProps.id, DashboardItemSizeVariant.Small])
            }
        }
    }, [weatherInfo?.additionalInfo])

    const emptyWeatherInfo = () => (
        <Box columnGap={12} row alignItems='center'>
            <Icon
                color={Color.WidgetSecondary}
                svg={SvgIcon.WeatherNormal}
                iconSize={BlockSize.XXLarge}
            />
            <Text size={16} color={Color.WidgetSecondary}>
                {translate('dashboard.weatherStatus.noData')}
            </Text>
        </Box>
    )

    return (
        <StatusDashboardItem
            title={translate('dashboard.weatherStatus.title')}
            description={translate('dashboard.weatherStatus.description')}
            {...itemProps}
        >
            <Box
                bg={Color.DashboardItemBackground}
                flex={1}
                paddingHorizontal={24}
                alignContent='center'
            >
                {weatherInfo ? (
                    <Text lineHeight='xLarge'>{weatherInfo.additionalInfo}</Text>
                ) : (
                    emptyWeatherInfo()
                )}
            </Box>
            <Footer>
                <Text size={12} color={Color.WidgetSecondary}>
                    {translate('dashboard.weatherStatus.updatedOn')}{' '}
                    {formatDateTime(moment(weatherInfo?.dateTime))}
                </Text>
            </Footer>
        </StatusDashboardItem>
    )
}

type WeatherForecastOwnProps = StatusDashboardItemCommonProps<WeatherItemState>
