import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosMapEventDto } from '../../../dataaccess/events/types/AosMapEventDto'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosEventVisibility } from '../../events/types/AosEventVisibility'
import { AosMapEvent } from './AosMapEvent'

export class AosMapEventImpl extends WrapperObject<AosMapEventDto> implements AosMapEvent {
    constructor(value: AosMapEventDto) {
        super(value)
    }

    public get id() {
        return this.value.id!
    }

    public get hasPrivateChannel() {
        return this.value.hasPrivateChannel
    }

    public get process() {
        return this.value.process
    }

    public get title() {
        return this.value.title
    }

    public get visibility() {
        return this.value.visibility as AosEventVisibility
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get category() {
        return this.value.category
    }

    public get location() {
        return this.value.location as AosLocation
    }
}
