import Groups from 'i18n.json'
import { chain, Dictionary } from 'lodash'

import { AnyEnum, enumToLabel, enumToName, EnumValue, isValidEnumEntry } from '../Enum'
import { languageProvider } from './LanguageProvider'
import { Translation } from './types/Translation'
import { TranslationGroup } from './types/TranslationGroup'

const dictionaryForGroups = (groups: TranslationGroup[]): Dictionary<Dictionary<string>> => {
    function translationsToDict(translation: Translation[]) {
        return chain(translation)
            .map(t => [t.key, t.value])
            .fromPairs()
            .value()
    }

    function groupToDictPair(group: TranslationGroup) {
        return [group.language, translationsToDict(group.translations)]
    }

    return chain(groups).map(groupToDictPair).fromPairs().value()
}

const allDictionaries: Dictionary<Dictionary<string>> = dictionaryForGroups(Groups as any)
const extractionRegex = /{(.*?)}/g

interface TranslatePayload {
    [key: string]: any
}

const handleNotTranslatedKey = (key: string) => {
    window.missingTranslations = window.missingTranslations || []
    if (!window.missingTranslations.includes(key)) {
        window.missingTranslations.push(key)
    }
}

export const translate = (key: string, payload?: TranslatePayload): string => {
    const lang = languageProvider.getCurrent()
    const fallback = languageProvider.getFallback()

    if (allDictionaries[lang]?.[key]) {
        return interpolate(allDictionaries[lang]?.[key], payload)
    } else if (allDictionaries[fallback]?.[key]) {
        return interpolate(allDictionaries[fallback]?.[key], payload)
    } else {
        handleNotTranslatedKey(key)
        return key
    }
}

export const translateParts = (...items: (string | number)[]) => translate(items.join('.'))

export const translatePlural = (key: string, count: number) =>
    count === 1 ? translate(`${key}.single`, { count }) : translate(`${key}.plural`, { count })

export const translateFirst = (keys: string[], payload?: TranslatePayload): string => {
    const firstKey = keys.find(key => hasTranslation(key))
    if (firstKey) {
        return translate(firstKey, payload)
    }
    return keys[0]
}

export const translateEnum =
    <E extends EnumValue, T extends AnyEnum = AnyEnum>(
        enumEntity: T,
        prefix: string,
        fallbackEnumValue?: E,
    ) =>
    (enumValue: E) => {
        const enumLabel = enumToLabel(enumEntity, prefix)
        if (isValidEnumEntry(enumEntity)(enumValue)) {
            return translate(enumLabel(enumValue))
        }
        return translate(enumLabel(fallbackEnumValue!))
    }

export const translateFirstEnum =
    <E extends EnumValue, T extends AnyEnum = AnyEnum>(
        enumEntity: T,
        prefixes: string[],
        fallbackEnumValue?: E,
    ) =>
    (enumValue: E) => {
        const enumLabel = enumToName(enumEntity)(enumValue)
        const firstKey = prefixes.find(key => hasTranslation(`${key}.${enumLabel}`))
        const prefix = firstKey ? firstKey : prefixes[0]
        return translateEnum<E>(enumEntity, prefix, fallbackEnumValue)(enumValue)
    }

export const translateEnumUnion =
    <V extends EnumValue>(prefix: string, ...enumEntities: AnyEnum[]) =>
    (value: V) => {
        const enumEntity = enumEntities.find(e => isValidEnumEntry(e)(value)) || enumEntities[0]
        return translateEnum(enumEntity, prefix)(value)
    }

export const hasTranslation = (enumValue: string): boolean => translate(enumValue) !== enumValue

const interpolate = (value: string | undefined, payload?: TranslatePayload) => {
    if (!value || !payload) {
        return value as string
    }
    const extractedPlaceholder = value.match(extractionRegex)

    if (extractedPlaceholder) {
        return extractedPlaceholder.reduce((prev: string, current: string) => {
            const key = current.substr(1, current.length - 2)
            return prev.replace(current, payload[key])
        }, value)
    }
    return value
}
