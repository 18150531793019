import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    airportIcon,
    AosAirport,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    getAirportName,
    getAirportSettingOptions,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { Icon, IconVariant, svgIconForName, SvgIconType } from 'aos-ui/src/components/svg/Icon'
import React, { FC, useCallback } from 'react'

interface AirportDropdownProps {
    value: AosAirport
    onChange: (value: AosAirport) => void
}
export const AirportDropdown: FC<AirportDropdownProps> = ({ value, onChange }) => {
    const renderAirport = useCallback((airport: AosAirport) => {
        const label = getAirportName(airport)
        const icon = airportIcon(airport as AosAirport) as SvgIconType

        return (
            <Box row>
                <Icon
                    iconSize={BlockSize.Std}
                    svg={svgIconForName(icon)}
                    iconVariant={IconVariant.BlackGrey}
                />
                <Box paddingLeft={8} as='span'>
                    {label}
                </Box>
            </Box>
        )
    }, [])

    return (
        <Dropdown
            variant={DropdownVariant.Black}
            width={DropdownWidth.Big}
            value={value}
            onChange={onChange}
            valueRenderer={renderAirport}
            items={getAirportSettingOptions()}
        />
    )
}
