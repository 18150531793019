import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface RadioCheckboxProps extends ClassNameProps {
    checked: boolean
    onChange(checked: boolean): void
}

export const RadioCheckbox: FC<RadioCheckboxProps> = ({ checked, onChange, ...props }) => {
    return <Container checked={checked} onClick={() => onChange(!checked)} {...props} />
}

const Container = styled.button<{ checked: boolean }>`
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
        content: '';
        border-radius: 50%;
        display: block;
        position: absolute;
    }

    &::before {
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border: 2px solid ${p => (p.checked ? Color.Primary : Color.Grey2)};
    }

    &::after {
        width: 8px;
        height: 8px;
        top: 4px;
        left: 4px;
        background-color: ${Color.Primary};
        display: ${p => (p.checked ? 'block' : 'none')};
    }
`
