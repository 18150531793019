import { Color } from 'aos-ui-common/src/styles/Color'
import { lighten } from 'polished'
import styled from 'styled-components'

import { Text } from '../base/Text'

export const Link = styled(Text)`
    color: ${Color.LinkText};
    cursor: pointer;

    &:hover {
        color: ${lighten(0.1, Color.LinkText)};
    }
`
