import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { DiscountModuleBaseActions } from 'aos-services/src/core/discountModule/actions'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import {
    DiscountCoupon,
    DiscountCouponDTO,
    DiscountStore,
    DiscountStoreDTO,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'

//Coupons

export const SAVE_DISCOUNT_COUPON = 'DISCOUNT_MODULE/SAVE_DISCOUNT_COUPON'
export type SaveDiscountCouponAction = PayloadAction<
    typeof SAVE_DISCOUNT_COUPON,
    DiscountCoupon | undefined
>
export const saveDiscountCoupon = payloadActionCreator<
    SaveDiscountCouponAction,
    DiscountCoupon | undefined
>(SAVE_DISCOUNT_COUPON)

export const SAVE_DISCOUNT_COUPON_SUCCESS = 'DISCOUNT_MODULE/SAVE_DISCOUNT_COUPON_SUCCESS'
export type SaveDiscountCouponSuccessAction = PayloadAction<
    typeof SAVE_DISCOUNT_COUPON_SUCCESS,
    DiscountCoupon
>
export const saveDiscountCouponSuccess = payloadActionCreator<
    SaveDiscountCouponSuccessAction,
    DiscountCoupon
>(SAVE_DISCOUNT_COUPON_SUCCESS)

export const DELETE_DISCOUNT_COUPON = 'DISCOUNT_MODULE/DELETE_DISCOUNT_COUPON'
export type DeleteDiscountCouponAction = PayloadAction<
    typeof DELETE_DISCOUNT_COUPON,
    { id: number }
>
export const deleteDiscountCoupon = payloadActionCreator<
    DeleteDiscountCouponAction,
    { id: number }
>(DELETE_DISCOUNT_COUPON)

export const DELETE_DISCOUNT_COUPON_SUCCESS = 'DISCOUNT_MODULE/DELETE_DISCOUNT_COUPON_SUCCESS'
export type DeleteDiscountCouponSuccessAction = Action<typeof DELETE_DISCOUNT_COUPON_SUCCESS>
export const deleteDiscountCouponSuccess = emptyActionCreator<DeleteDiscountCouponSuccessAction>(
    DELETE_DISCOUNT_COUPON_SUCCESS,
)

export const OPEN_SAVE_DISCOUNT_COUPON_MODAL = 'DISCOUNT_MODULE/OPEN_SAVE_DISCOUNT_COUPON_MODAL'
export type OpenSaveDiscountCouponModal = PayloadAction<
    typeof OPEN_SAVE_DISCOUNT_COUPON_MODAL,
    DiscountCoupon | undefined
>
export const openSaveDiscountCouponModal = payloadActionCreator<
    OpenSaveDiscountCouponModal,
    DiscountCoupon | undefined
>(OPEN_SAVE_DISCOUNT_COUPON_MODAL)

export const CLOSE_SAVE_DISCOUNT_COUPON_MODAL = 'DISCOUNT_MODULE/CLOSE_SAVE_DISCOUNT_COUPON_MODAL'
export type CloseSaveDiscountCouponModal = PayloadAction<
    typeof CLOSE_SAVE_DISCOUNT_COUPON_MODAL,
    { payload: DiscountCouponDTO; id: number | undefined } | undefined
>
export const closeSaveDiscountCouponModal = payloadActionCreator<
    CloseSaveDiscountCouponModal,
    { payload: DiscountCouponDTO; id: number | undefined } | undefined
>(CLOSE_SAVE_DISCOUNT_COUPON_MODAL)

//Stores

export const CREATE_DISCOUNT_STORE = 'DISCOUNT_MODULE/CREATE_DISCOUNT_STORE'
export type CreateDiscountStoreAction = Action<typeof CREATE_DISCOUNT_STORE>
export const createDiscountStore =
    emptyActionCreator<CreateDiscountStoreAction>(CREATE_DISCOUNT_STORE)

export const CREATE_DISCOUNT_STORE_SUCCESS = 'DISCOUNT_MODULE/CREATE_DISCOUNT_STORE_SUCCESS'
export type CreateDiscountStoreSuccessAction = PayloadAction<
    typeof CREATE_DISCOUNT_STORE_SUCCESS,
    DiscountStoreDTO
>
export const createDiscountStoreSuccess = payloadActionCreator<
    CreateDiscountStoreSuccessAction,
    DiscountStoreDTO
>(CREATE_DISCOUNT_STORE_SUCCESS)

export const MANAGE_DISCOUNT_STORES = 'DISCOUNT_MODULE/MANAGE_DISCOUNT_STORES'
export type ManageDiscountStoresAction = Action<typeof MANAGE_DISCOUNT_STORES>
export const manageDiscountStores =
    emptyActionCreator<ManageDiscountStoresAction>(MANAGE_DISCOUNT_STORES)

export const MANAGE_DISCOUNT_STORES_SUCCESS = 'DISCOUNT_MODULE/MANAGE_DISCOUNT_STORE_SUCCESS'
export type ManageDiscountStoresSuccessAction = PayloadAction<
    typeof MANAGE_DISCOUNT_STORES_SUCCESS,
    DiscountStore
>
export const manageDiscountStoresSuccess = payloadActionCreator<
    ManageDiscountStoresSuccessAction,
    DiscountStore
>(MANAGE_DISCOUNT_STORES_SUCCESS)

export const DELETE_DISCOUNT_STORE = 'DISCOUNT_MODULE/DELETE_DISCOUNT_STORE'
export type DeleteDiscountStoreAction = PayloadAction<typeof DELETE_DISCOUNT_STORE, { id: number }>
export const deleteDiscountStore = payloadActionCreator<DeleteDiscountStoreAction, { id: number }>(
    DELETE_DISCOUNT_STORE,
)

export const DELETE_DISCOUNT_STORE_SUCCESS = 'DISCOUNT_MODULE/DELETE_DISCOUNT_STORE_SUCCESS'
export type DeleteDiscountStoreSuccessAction = Action<typeof DELETE_DISCOUNT_STORE_SUCCESS>
export const deleteDiscountStoreSuccess = emptyActionCreator<DeleteDiscountStoreSuccessAction>(
    DELETE_DISCOUNT_STORE_SUCCESS,
)

export const OPEN_CREATE_DISCOUNT_STORE_MODAL = 'DISCOUNT_MODULE/OPEN_CREATE_DISCOUNT_STORE_MODAL'
export type OpenCreateDiscountStoreModal = Action<typeof OPEN_CREATE_DISCOUNT_STORE_MODAL>
export const openCreateDiscountStoreModal = emptyActionCreator<OpenCreateDiscountStoreModal>(
    OPEN_CREATE_DISCOUNT_STORE_MODAL,
)

export const CLOSE_CREATE_DISCOUNT_STORE_MODAL = 'DISCOUNT_MODULE/CLOSE_CREATE_DISCOUNT_STORE_MODAL'
export type CloseCreateDiscountStoreModal = PayloadAction<
    typeof CLOSE_CREATE_DISCOUNT_STORE_MODAL,
    { payload: DiscountStoreDTO } | undefined
>
export const closeCreateDiscountStoreModal = payloadActionCreator<
    CloseCreateDiscountStoreModal,
    { payload: DiscountStoreDTO } | undefined
>(CLOSE_CREATE_DISCOUNT_STORE_MODAL)

export const OPEN_MANAGE_DISCOUNT_STORES_MODAL = 'DISCOUNT_MODULE/OPEN_MANAGE_DISCOUNT_STORES_MODAL'
export type OpenManageDiscountStoresModal = Action<typeof OPEN_MANAGE_DISCOUNT_STORES_MODAL>
export const openManageDiscountStoresModal = emptyActionCreator<OpenManageDiscountStoresModal>(
    OPEN_MANAGE_DISCOUNT_STORES_MODAL,
)

export const CLOSE_MANAGE_DISCOUNT_STORES_MODAL =
    'DISCOUNT_MODULE/CLOSE_MANAGE_DISCOUNT_STORES_MODAL'
export type CloseManageDiscountStoresModal = PayloadAction<
    typeof CLOSE_MANAGE_DISCOUNT_STORES_MODAL,
    { payload: DiscountStore } | undefined
>
export const closeManageDiscountStoresModal = payloadActionCreator<
    CloseManageDiscountStoresModal,
    { payload: DiscountStore } | undefined
>(CLOSE_MANAGE_DISCOUNT_STORES_MODAL)

export const DISCOUNT_MODULE_ATTACHMENTS = 'DISCOUNT_MODULE/DISCOUNT_MODULE_ATTACHMENTS'
export type DiscountModuleAttachmentsAction = PayloadAction<
    typeof DISCOUNT_MODULE_ATTACHMENTS,
    AttachmentsAction
>
export const discountModuleAttachmentsAction = payloadActionCreator<
    DiscountModuleAttachmentsAction,
    AttachmentsAction
>(DISCOUNT_MODULE_ATTACHMENTS)

export const CLEAR_DISCOUNT_MODULE_ATTACHMENTS = 'DISCOUNT_MODULE/CLEAR_DISCOUNT_MODULE_ATTACHMENTS'
export type ClearDiscountModuleAttachmentsAction = Action<typeof CLEAR_DISCOUNT_MODULE_ATTACHMENTS>
export const clearDiscountModuleAttachmentsAction =
    emptyActionCreator<ClearDiscountModuleAttachmentsAction>(CLEAR_DISCOUNT_MODULE_ATTACHMENTS)

export const SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL =
    'DISCOUNT_MODULE/SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL'
export type SetDiscountModuleAttachmentManual = PayloadAction<
    typeof SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL,
    UploadedAttachment[]
>
export const setDiscountModuleAttachmentManual = payloadActionCreator<
    SetDiscountModuleAttachmentManual,
    UploadedAttachment[]
>(SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL)

export type DiscountModuleActions =
    | DiscountModuleBaseActions
    | SaveDiscountCouponAction
    | SaveDiscountCouponSuccessAction
    | DeleteDiscountCouponAction
    | DeleteDiscountCouponSuccessAction
    | OpenSaveDiscountCouponModal
    | CloseSaveDiscountCouponModal
    | OpenCreateDiscountStoreModal
    | CloseCreateDiscountStoreModal
    | OpenManageDiscountStoresModal
    | CloseManageDiscountStoresModal
    | CreateDiscountStoreAction
    | CreateDiscountStoreSuccessAction
    | ManageDiscountStoresAction
    | ManageDiscountStoresSuccessAction
    | DeleteDiscountStoreAction
    | DeleteDiscountStoreSuccessAction
    | DiscountModuleAttachmentsAction
    | ClearDiscountModuleAttachmentsAction
    | SetDiscountModuleAttachmentManual
