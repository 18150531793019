import { GeoJsonLayerFactory } from './GeoJsonLayerFactory'
import { LayerManager } from './LayerManager'
import { mapEngine } from './MapEngine'
import { LayerConfiguration } from './Types'

const loadConfiguration = () => {
    const typedConfig = require('layersConfig.json') as LayerConfiguration
    const geoJsonLayerFactory = new GeoJsonLayerFactory(typedConfig.provider)
    const layerManager = new LayerManager(typedConfig, geoJsonLayerFactory)
    const mapProvider = geoJsonLayerFactory.getMapProvider()
    return {
        layerManager,
        mapProvider,
    }
}

const { layerManager, mapProvider } = loadConfiguration()

export { layerManager, mapEngine, mapProvider }
