import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import { useKey } from 'react-use'
import styled from 'styled-components'

import { ClickOutside } from '../base/ClickOutside'
import { boxShadow } from '../base/Theme'
import { IconButton } from '../buttons/IconButton'
import { IconVariant } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'

interface AttachmentEditToolbarProps {
    editable: boolean
    rotateLeft?(): void
    rotateRight?(): void
    reset?(): void
    save?(): void
    next?(): void
    previous?(): void
    onClose(): void
}

// icon, action, alwaysVisible

type ButtonDef = [Svg, (() => void) | undefined, boolean]

export const AttachmentEditToolbar: FCWithChildren<AttachmentEditToolbarProps> = ({
    editable,
    rotateLeft,
    rotateRight,
    reset,
    save,
    next,
    previous,
    onClose,
}) => {
    useKey('ArrowLeft', () => previous && previous(), {}, [previous])
    useKey('ArrowRight', () => next && next(), {}, [next])

    const buttons: ButtonDef[] = [
        [SvgIcon.Back, previous, true],
        [SvgIcon.Forward, next, true],
        [SvgIcon.RotateLeft, rotateLeft, false],
        [SvgIcon.RotateRight, rotateRight, false],
        [SvgIcon.Close, reset, false],
        [SvgIcon.Done, save, false],
    ]

    return (
        <Wrapper onClickOutside={onClose}>
            {buttons
                .filter(b => b[2] || editable)
                .map(([icon, action], index) => (
                    <IconButton
                        margin={8}
                        key={index}
                        iconSize={BlockSize.Std}
                        iconVariant={IconVariant.White}
                        svg={icon}
                        disabled={!action}
                        onClick={action}
                    />
                ))}
        </Wrapper>
    )
}

const Wrapper = styled(ClickOutside)`
    display: inline-flex;
    background-color: ${Color.ChartBase};
    border-radius: 3px;
    position: fixed;
    bottom: 25px;
    pointer-events: all;
    text-align: center;
    box-shadow: ${boxShadow.std};
`
