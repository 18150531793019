import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { GeoJSON } from 'ol/format'
import { Layer } from 'ol/layer'
import VektorEmbeddedAirportLite from 'vektor-embedded-airport-lite'

import { MapProvider } from '../Types'

export class VektorProvider implements MapProvider {
    private _instance: VektorEmbeddedAirportLite | null = null
    private readonly CDN_URL = 'https://cdn.vektor.io/bim-component/airport/pictograms/'
    private readonly WMTS_URL =
        'https://proxy-finland.vektor.io/maps/finavia/locationdata/geoserver/v1/gis/gwc/service/wmts'
    private readonly WMS_URL =
        'https://proxy-finland.vektor.io/maps/finavia/locationdata/geoserver/v1/gis/wms'
    private readonly DefaultTileMatrixSet = 'EPSG:900913'

    public async loadLayer(layerId: string, _: string = '') {
        try {
            return await VektorEmbeddedAirportLite.fetchVectorLayerData(layerId)
        } catch (e) {
            logger.handleError(e as Error)
        }
    }

    public initialise(): MapProvider {
        this._instance = new VektorEmbeddedAirportLite()
        return this
    }

    public getTileWMSUrl(layerId: string, scale: number): string {
        return `${
            this.WMS_URL
        }?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png&STYLES=&TRANSPARENT=true&LAYERS=${layerId}&WIDTH=${
            256 * scale
        }&HEIGHT=${256 * scale}&CRS=EPSG:3857&BBOX={bbox-epsg-3857}`
    }

    public getTileWMTSUrl(layerId: string, scale: number): string {
        return `${
            this.WMTS_URL
        }?Service=WMTS&Request=GetTile&Version=1.0.0&Layer=${layerId}&Format=image/png&TileMatrixSet=${
            this.DefaultTileMatrixSet
        }&TileMatrix=${this.DefaultTileMatrixSet}:{z}&TileCol={x}&TileRow={y}&Width=${
            256 * scale
        }&Height=${256 * scale}`
    }

    public async mount(props: { bcContainer: string; bcOptions: any }) {
        if (this._instance) {
            await this._instance.init()
            this._instance.mount(props.bcContainer, props.bcOptions)
        } else {
            throw new Error('mount: Provider is not initialized')
        }
    }

    public async addMapLayer(layerId: string) {
        if (this._instance) {
            return this._instance.addMapLayer(layerId)
        } else {
            throw new Error('addMapLayer: Provider is not initialized')
        }
    }

    public removeMapLayer(layerId: string) {
        if (this._instance) {
            return this._instance.removeMapLayer(layerId)
        } else {
            throw new Error('removeMapLayer: Provider is not initialized')
        }
    }

    public async addMapLayerAsGeoJson(
        layerId: string,
        _: string,
        data: GeoJSON,
        minZoom: number,
        maxZoom: number,
        scale: number,
        zIndex: number,
    ): Promise<Layer> {
        if (this._instance) {
            return this._instance.addMapLayerAsGeoJson(
                layerId,
                data,
                minZoom,
                maxZoom,
                scale,
                zIndex,
            )
        } else {
            throw new Error('addMapLayerAsGeoJson: Provider is not initialized')
        }
    }

    public async addMapLayerAsVector(layerId: string): Promise<Layer> {
        if (this._instance) {
            return this._instance.addMapLayerAsVector(layerId)
        } else {
            throw new Error('addMapLayerAsVector: Provider is not initialized')
        }
    }

    public getIconUrl(iconId: string): string {
        return `${this.CDN_URL}${iconId}.svg.png`
    }
}
