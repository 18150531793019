import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from './base/Box'
import { SvgIcon } from './svg/SvgIcon'
import { SvgImage } from './svg/SvgImage'

interface UserPhotoProps extends BoxProps {
    base64Img?: string
    large?: boolean
}

export const UserPhoto: FCWithChildren<UserPhotoProps> = props => {
    const { base64Img, large, ...boxProps } = props
    return (
        <UserPhotoWrapper shrink={0} rect={large ? 108 : 50} {...boxProps}>
            <UserPhotoImgContainer>
                {base64Img ? (
                    <UserPhotoImg src={base64Img} />
                ) : (
                    <UserPhotoImgPlaceholder svg={SvgIcon.UserThumbnail} />
                )}
            </UserPhotoImgContainer>
        </UserPhotoWrapper>
    )
}

const UserPhotoWrapper = styled(Box)`
    padding: 3px;
    border-radius: 50%;
    border: 2px solid ${Color.MenuButtonColor};
`

const UserPhotoImgContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
`
const UserPhotoImg = styled.img`
    width: 100%;
    height: 100%;
`

const UserPhotoImgPlaceholder = styled(SvgImage)`
    width: 100%;
    height: 100%;
    fill: ${Color.MenuButtonColor};
`
