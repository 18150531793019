import AircraftStands from './aircraft-stands.svg'
import AirfieldMaintenance from './airfiled-maintanace.svg'
import ApronBuses from './apron-buses.svg'
import ApronConstruction from './apron-construction.svg'
import Arrivals from './arrivals.svg'
import AtcReducedCapacity from './atc-reduced-capacity.svg'
import AutomatedBorderControl from './automated-border-control.svg'
import BaggageArrival from './baggage-arrival.svg'
import BaggageClaim from './baggage-claim.svg'
import BaggageDepartures from './baggage-departures.svg'
import BaggageGeneralInfo from './baggage-general-info.svg'
import BaggageHandling from './baggage-handling.svg'
import BaggageTransfer from './baggage-transfer.svg'
import BorderControl from './border-control.svg'
import Bus from './bus.svg'
import CheckIn from './check-in.svg'
import CheckinAutomat from './checkin-automat.svg'
import Construction from './construction.svg'
import DeIcing from './de-icing.svg'
import DivergedFlights from './diverged-flights.svg'
import Electricity from './electricity.svg'
import Elevators from './elevators.svg'
import Fog from './fog.svg'
import Gate from './gate.svg'
import Gates from './gates.svg'
import HeavyDelays from './heavy-delays.svg'
import InfoManagement from './info-management.svg'
import LandSideConstruction from './land-side-construction.svg'
import Lightning from './lightning.svg'
import Lvp from './lvp.svg'
import Maintenance from './maintenance.svg'
import Media from './media.svg'
import NetworkGeneralInfo from './network-general-info.svg'
import Other from './other.svg'
import ParkingArea from './parking-area.svg'
import PassengerBridges from './passenger-bridges.svg'
import PassengerService from './passenger-service.svg'
import PassengerServices from './passenger-services.svg'
import PaxGeneralInfo from './pax-general-info.svg'
import Prm from './prm.svg'
import Rain from './rain.svg'
import RescueService from './rescue-service.svg'
import Roads from './roads.svg'
import Rwy from './rwy.svg'
import SafetySecurity from './safety-security.svg'
import SecurityGates from './security-gates.svg'
import Services from './services.svg'
import SlipperyApron from './slippery-apron.svg'
import Snow from './snow.svg'
import Taxi from './taxi.svg'
import Taxiways from './taxiways.svg'
import TerminalConstruction from './terminal-construction.svg'
import TerminalFacilities from './terminal-facilities.svg'
import TerminalPeakTimes from './terminal-peak-times.svg'
import TrafficStatus from './traffic-status.svg'
import Train from './train.svg'
import Transfer from './transfer.svg'
import TurnaroundGeneralInfo from './turnaround-general-info.svg'
import Wa from './wa.svg'
import WaterDistribution from './water-distribution.svg'
import Wind from './wind.svg'

export default {
    AircraftStands,
    AirfieldMaintenance,
    ApronBuses,
    ApronConstruction,
    Arrivals,
    AtcReducedCapacity,
    AutomatedBorderControl,
    BaggageArrival,
    BaggageClaim,
    BaggageDepartures,
    BaggageGeneralInfo,
    BaggageHandling,
    BaggageTransfer,
    BorderControl,
    Bus,
    CheckIn,
    CheckinAutomat,
    Construction,
    DeIcing,
    DivergedFlights,
    Electricity,
    Elevators,
    Fog,
    Gate,
    Gates,
    HeavyDelays,
    InfoManagement,
    LandSideConstruction,
    Lightning,
    Lvp,
    Maintenance,
    Media,
    NetworkGeneralInfo,
    Other,
    ParkingArea,
    PassengerBridges,
    PassengerService,
    PassengerServices,
    PaxGeneralInfo,
    Prm,
    Rain,
    RescueService,
    Roads,
    Rwy,
    SafetySecurity,
    SecurityGates,
    Services,
    SlipperyApron,
    Snow,
    Taxi,
    Taxiways,
    TerminalConstruction,
    TerminalFacilities,
    TerminalPeakTimes,
    TrafficStatus,
    Train,
    Transfer,
    TurnaroundGeneralInfo,
    Wa,
    WaterDistribution,
    Wind,
}
