import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import {
    DiscountArea,
    DiscountStore,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import { isEmpty, values } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import z from 'zod'

import {
    clearDiscountModuleAttachmentsAction,
    closeManageDiscountStoresModal,
    deleteDiscountStore,
    discountModuleAttachmentsAction,
    setDiscountModuleAttachmentManual,
} from '../../../core/discountModule/actions'
import {
    discountModuleAttachmentsState,
    discountStoresSelector,
    manageDiscountStoresModalSelector,
} from '../../../core/discountModule/selectors'

const isObjectEmptyOrFieldsUndefined = (obj?: Object | null) => {
    if (!obj || isEmpty(obj)) {
        return true
    }

    return values(obj).every(value => value === undefined)
}

const ManageDiscountStoresModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(manageDiscountStoresModalSelector)
    const attachmentsState = useSelector(discountModuleAttachmentsState)
    const discountStores = useSelector(discountStoresSelector)
    const [discountStoreId, setDiscountStoreId] = useState<number>()

    const { control, handleSubmit, reset, watch, setValue } = useForm<{
        discountStore: DiscountStore | null
    }>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { discountStore: null },
        resolver: zodResolver(
            z.object({
                discountStore: z.object({
                    id: z.number(),
                    name: z
                        .string()
                        .min(1, { message: translate('discountModule.form.fieldRequired') }),
                    area: z
                        .string()
                        .min(1, { message: translate('discountModule.form.fieldRequired') }),
                    location: z
                        .string()
                        .min(1, { message: translate('discountModule.form.fieldRequired') }),
                    logo: z.object(
                        {
                            id: z.number().optional(),
                            link: z.string().min(1),
                            name: z.string().optional(),
                            type: z.string().optional(),
                        },
                        { message: translate('discountModule.form.fieldRequired') },
                    ),
                }),
            }),
        ),
    })

    const onSubmit = useCallback(
        ({ discountStore: payload }: { discountStore: DiscountStore }) => {
            reset({
                discountStore: null,
            })
            dispatch(
                closeManageDiscountStoresModal({
                    payload,
                }),
            )
        },
        [attachmentsState.attachments[0]?.name],
    )

    const handleFileUpload = useCallback((f: AttachmentsAction) => {
        dispatch(clearDiscountModuleAttachmentsAction())
        return dispatch(discountModuleAttachmentsAction(f))
    }, [])

    const onClose = useCallback(() => {
        reset({
            discountStore: null,
        })
        dispatch(closeManageDiscountStoresModal(undefined))
    }, [attachmentsState])

    const handleDeleteStore = useCallback((id: number) => {
        onClose()
        dispatch(deleteDiscountStore({ id }))
    }, [])

    useEffect(() => {
        const { unsubscribe } = watch(({ discountStore }) => {
            setDiscountStoreId(discountStore?.id)
        })
        return () => unsubscribe()
    }, [])

    useEffect(
        () => setValue('discountStore.logo', attachmentsState.attachments[0]),
        [attachmentsState],
    )

    const footer = useMemo(
        () => (
            <Box row flex={1} justify='space-between'>
                <Text color={Color.Grey6} size={13}>
                    {translate('discountModule.form.requiredFields')}
                </Text>
                <Box row columnGap={16}>
                    {discountStoreId && (
                        <FormButton
                            onClick={() => handleDeleteStore(discountStoreId)}
                            label={translate('discountModule.form.deleteStoreButtonLabel')}
                            variant={FormButtonVariant.RedOutlined}
                        />
                    )}
                    <FormButton
                        onClick={handleSubmit(onSubmit)}
                        label={translate('discountModule.form.saveButtonLabel')}
                    />
                </Box>
            </Box>
        ),
        [discountStoreId],
    )

    return (
        <Modal
            relative
            isOpen={isOpen}
            title={translate('discountModule.manageStoresModal.title')}
            modalKind={ModalKind.Medium}
            closeAction={onClose}
            footer={footer}
        >
            <Controller
                name='discountStore'
                control={control}
                render={({ field: { value, onChange }, fieldState, formState }) => (
                    <Box padding={30} column rowGap={16} flex={1}>
                        <Box column rowGap={10}>
                            <Text
                                size={14}
                                color={
                                    fieldState.invalid &&
                                    Boolean(formState.errors.discountStore?.id)
                                        ? Color.Red
                                        : Color.TimelineBackground
                                }
                            >
                                {translate('discountModule.form.storeLabel')}
                            </Text>

                            <Box column rowGap={2}>
                                <DropdownAutocomplete
                                    value={isObjectEmptyOrFieldsUndefined(value) ? null : value}
                                    items={discountStores}
                                    variant={DropdownVariant.White}
                                    labelRenderer={value => value?.name ?? ''}
                                    placeholder={translate(
                                        'discountStore.form.storeInputPlaceholder',
                                    )}
                                    onChange={e => {
                                        onChange(e)
                                        dispatch(
                                            setDiscountModuleAttachmentManual(
                                                e?.logo ? [e.logo as UploadedAttachment] : [],
                                            ),
                                        )
                                    }}
                                    outlined
                                />
                                {fieldState.invalid &&
                                    Boolean(formState.errors.discountStore?.name) && (
                                        <Text color={Color.Red} size={12}>
                                            {formState.errors.discountStore?.name?.message}
                                        </Text>
                                    )}
                            </Box>
                        </Box>
                        {value?.id ? (
                            <>
                                <Box column rowGap={10}>
                                    <Text
                                        color={
                                            fieldState.invalid &&
                                            Boolean(formState.errors.discountStore?.area)
                                                ? Color.Red
                                                : Color.TimelineBackground
                                        }
                                        size={14}
                                    >
                                        {translate('discountModule.form.areaLabel')}
                                    </Text>
                                    <BlockRadioGroup
                                        items={values(DiscountArea)}
                                        value={value.area}
                                        Renderer={({ item, isSelected, onSelect }) => (
                                            <RadioElement
                                                rounded
                                                onClick={onSelect}
                                                borderWidth={1}
                                                border={isSelected ? Color.ChartBase : Color.Grey6}
                                                justify='flex-start'
                                                alignItems='center'
                                                padding={12}
                                                bg={isSelected ? Color.ChartBase : Color.White}
                                            >
                                                <Text
                                                    size={12}
                                                    color={isSelected ? Color.White : Color.Black}
                                                >
                                                    {translate(
                                                        `discountModule.discountArea.${item}`,
                                                    )}
                                                </Text>
                                            </RadioElement>
                                        )}
                                        onChange={area => onChange({ ...value, area })}
                                    />
                                    {fieldState.invalid &&
                                        Boolean(formState.errors.discountStore?.area) && (
                                            <Text color={Color.Red} size={12}>
                                                {formState.errors.discountStore?.area?.message}
                                            </Text>
                                        )}
                                </Box>

                                <LabeledInput
                                    type='text'
                                    value={value.location}
                                    onChangeText={location => onChange({ ...value, location })}
                                    keyPrefix='discountModule.form.locationInput'
                                    errorMessage={formState.errors.discountStore?.location?.message}
                                    isError={
                                        fieldState.invalid &&
                                        Boolean(formState.errors.discountStore?.location)
                                    }
                                    maxLength={50}
                                />

                                <Box column rowGap={10}>
                                    <Text
                                        size={14}
                                        color={
                                            fieldState.invalid &&
                                            Boolean(formState.errors.discountStore?.logo)
                                                ? Color.Red
                                                : Color.TimelineBackground
                                        }
                                    >
                                        {translate('discountModule.form.logoLabel')}
                                    </Text>
                                    <AttachmentContainer
                                        attachmentsState={attachmentsState}
                                        editable
                                        gridSize={5}
                                        attachmentsAction={handleFileUpload}
                                    />

                                    {fieldState.invalid &&
                                        Boolean(formState.errors.discountStore?.logo) && (
                                            <Text size={12} color={Color.Red}>
                                                {formState.errors.discountStore?.logo?.message}
                                            </Text>
                                        )}
                                </Box>
                            </>
                        ) : (
                            <Box
                                alignItems='center'
                                justify='center'
                                height={314}
                                column
                                rowGap={8}
                            >
                                <SvgImage svg={SvgIcon.SelectItem} />
                                <Text size={14} color={Color.Grey8}>
                                    {translate('discountStore.form.storeInputPlaceholder')}
                                </Text>
                            </Box>
                        )}
                    </Box>
                )}
            />
        </Modal>
    )
}

export default ManageDiscountStoresModal

const RadioElement = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`
