import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export interface ItemContainerProps {
    chart?: boolean
    vertical?: boolean
    horizontal?: boolean
    autoHeight?: boolean

    chartSpacing?: boolean
    horizontalSpacing?: boolean
    verticalSpacing?: boolean

    noShrink?: boolean

    background?: Color
}

export const ItemContainer: FC<PropsWithChildren<ItemContainerProps>> = props => {
    const {
        horizontalSpacing = false,
        verticalSpacing = false,
        chartSpacing = false,
        chart = false,
        horizontal = false,
        vertical = false,
        autoHeight = false,
        noShrink = false,
        children,
        background,
    } = props
    return (
        <ItemContainerBox
            horizontalSpacing={horizontalSpacing}
            verticalSpacing={verticalSpacing}
            chartSpacing={chartSpacing}
            row={horizontal}
            column={vertical}
            shrink={noShrink ? 0 : undefined}
            bg={chart ? Color.DarkBackground : background}
            flex={autoHeight ? 'auto' : undefined}
        >
            {children}
        </ItemContainerBox>
    )
}

const ItemContainerBox = styled(Box)<{
    horizontalSpacing?: boolean
    chartSpacing?: boolean
    verticalSpacing?: boolean
}>`
    ${p =>
        p.horizontalSpacing &&
        css`
            padding-left: 20px;
            padding-right: 20px;
        `}
    ${p =>
        p.chartSpacing &&
        css`
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 30px;
        `}
    ${p =>
        p.verticalSpacing &&
        css`
            padding-top: 20px;
            padding-bottom: 30px;
        `}
`

export const TileContainer: FC<PropsWithChildren<ItemContainerProps>> = props => (
    <ItemContainer horizontalSpacing verticalSpacing {...props} />
)

export const BigItemStatsContainer: FC<PropsWithChildren<ItemContainerProps>> = props => (
    <ItemContainer horizontalSpacing verticalSpacing noShrink {...props} />
)

export const ChartItemContainer: FC<PropsWithChildren<ItemContainerProps>> = props => (
    <ItemContainer chart chartSpacing autoHeight {...props} />
)
