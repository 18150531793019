import { DiscountStore } from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

const DiscountModuleStoreImage = ({
    logo: { link },
    id,
    size,
    selected = false,
    name,
    ...boxProps
}: DiscountStore & BoxProps & { size: number; selected?: boolean }) => {
    return (
        <Box {...boxProps}>
            <Image alt={name} src={link} $size={size} $selected={selected} />
        </Box>
    )
}

export default DiscountModuleStoreImage

const Image = styled.img<{ $size: number; $selected: boolean }>`
    border: solid ${Color.PrimaryPale} ${props => (props.$selected ? 2 : 0)}px;
    border-radius: 100%;
    height: ${props => props.$size}px;
    width: ${props => props.$size}px;
    box-shadow: ${props => (props.$selected ? `0px 0px 6px 0px ${Color.White}` : '')};
`
