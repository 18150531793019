import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { getAirportName } from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import { Snowtam } from 'aos-services/src/services/flightInformation/types/Notam'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'

interface SnowtamBottomBarProps {
    item: Snowtam
}
export const SnowtamBottomBar: FC<SnowtamBottomBarProps> = ({ item }) => {
    return (
        <Container bg={Color.TimelineBackground} padding={24}>
            <Box columnGap={8} row>
                <Icon svg={SvgIcon.SNOWTAM} />
                <Text size={18} weight='medium'>
                    {translate('notam.bottombar.snowtam')}
                </Text>
            </Box>
            <Box alignItems='flex-start' justify='space-between' padding={24} row>
                <Box rowGap={12} column>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.airport')}
                        </Text>
                        <Text size={12}>{getAirportName(item.siteLocation)}</Text>
                    </Box>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.number')}
                        </Text>
                        <Text size={12}>{item.number}</Text>
                    </Box>
                </Box>
                <Box rowGap={12} column>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.start')}
                        </Text>
                        <Text size={12}>
                            {moment(item.startTime).utc().format('DD.MM.YYYY, HH:mm UTC')}
                        </Text>
                    </Box>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.end')}
                        </Text>
                        <Text size={12}>
                            {moment(item.endTime).utc().format('DD.MM.YYYY, HH:mm UTC')}
                        </Text>
                    </Box>
                </Box>
                <Box flex={1 / 2} rowGap={12} column>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.aeroplane-performance')}
                        </Text>
                        <Text size={12}>{item.aeroplanePerformance}</Text>
                    </Box>
                </Box>
                <Box rowGap={12} column>
                    <Box rowGap={8} column>
                        <Text color={Color.MenuButtonColor} size={12}>
                            {translate('notam.bottombar.situational-awareness')}
                        </Text>
                        <Text size={12}>{item.situationalAwareness}</Text>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const Container = styled(Box)`
    border-top: 1px solid ${Color.MenuButtonColor};
`
