import { SortDirection, SortEntry } from 'aos-helpers/src/helpers/Pageable'
import { EnumValues } from 'enum-values'

import { TaskFilter } from './TaskFilter'

// backend enum
export enum TaskSortingRequestParams {
    BySeverity = 'severity',
    ByStartTime = 'startTime',
    ByEndTime = 'endTime',
    ByLastUpdated = 'modifiedAt',
}

// ui enum
export enum TaskSorting {
    BySeverity = 'severity',
    ByTime = 'time',
}

export enum TaskSortingMobile {
    StartTimeAsc = 'startTimeAsc',
    StartTimeDesc = 'startTimeDesc',
    EndTimeAsc = 'endTimeAsc',
    EndTimeDesc = 'endTimeDesc',
    LastUpdatedAsc = 'lastUpdatedAsc',
    LastUpdatedDesc = 'lastUpdatedDesc',
}

export const allTaskSortings = EnumValues.getValues<TaskSorting>(TaskSorting)

const startTimeAsc: SortEntry = [TaskSortingRequestParams.ByStartTime, SortDirection.Asc]
const startTimeDesc: SortEntry = [TaskSortingRequestParams.ByStartTime, SortDirection.Desc]
const endTimeDesc: SortEntry = [TaskSortingRequestParams.ByEndTime, SortDirection.Desc]
const endTimeAsc: SortEntry = [TaskSortingRequestParams.ByEndTime, SortDirection.Asc]
const severityDesc: SortEntry = [TaskSortingRequestParams.BySeverity, SortDirection.Desc]
const lastUpdatedDesc: SortEntry = [TaskSortingRequestParams.ByLastUpdated, SortDirection.Desc]
const lastUpdatedAsc: SortEntry = [TaskSortingRequestParams.ByLastUpdated, SortDirection.Asc]

export const defaultSorting = {
    [TaskSorting.BySeverity]: [severityDesc, startTimeAsc],
    [TaskSorting.ByTime]: [startTimeAsc],
}

export const taskSortingMobile: [TaskSortingMobile, SortEntry[]][] = [
    [TaskSortingMobile.StartTimeDesc, [startTimeDesc]],
    [TaskSortingMobile.StartTimeAsc, [startTimeAsc]],
    [TaskSortingMobile.EndTimeDesc, [endTimeDesc]],
    [TaskSortingMobile.EndTimeAsc, [endTimeAsc]],
    [TaskSortingMobile.LastUpdatedDesc, [lastUpdatedDesc]],
    [TaskSortingMobile.LastUpdatedAsc, [lastUpdatedAsc]],
]

export const defaultSortingMobile = taskSortingMobile[4]

export const taskSorting: Record<TaskFilter, Record<TaskSorting, SortEntry[]>> = {
    [TaskFilter.Todo]: defaultSorting,
    [TaskFilter.OnHold]: defaultSorting,
    [TaskFilter.InProgress]: defaultSorting,
    [TaskFilter.Done]: {
        [TaskSorting.BySeverity]: [severityDesc, endTimeDesc],
        [TaskSorting.ByTime]: [endTimeDesc],
    },
    [TaskFilter.Overdue]: defaultSorting,
    [TaskFilter.All]: defaultSorting,
    [TaskFilter.AssignedToMe]: {
        [TaskSorting.BySeverity]: [severityDesc, startTimeAsc],
        [TaskSorting.ByTime]: [startTimeAsc],
    },
}
