import { reduce } from 'lodash'
import { transformExtent } from 'ol/proj'

import { AosAirport, MainAirport, typedAirports } from '../flightInformation/types/AosAirport'
import { MapPosition } from './types/MapPosition'

export const selectedLocationZoom = 14

const mainAirport = typedAirports.airports[MainAirport]
export const mainAirportLocation = {
    lon: mainAirport.longitude,
    lat: mainAirport.latitude,
    zoom: selectedLocationZoom,
}

export const mainAirportAtcCenterPosition = typedAirports.airports[MainAirport].atcConfig

export const atcGridPositionExtent = transformExtent(
    mainAirportAtcCenterPosition!.extent.bbox,
    mainAirportAtcCenterPosition!.extent.source,
    mainAirportAtcCenterPosition!.extent.destination,
)

export const atcMapFitConfig = {
    duration: 3000,
}

// position customized only for Helsinki
export const europeCentralPosition = {
    lon: 19.893307580295385,
    lat: 57.53543713442852,
    zoom: 4.3,
}

const mappingAosAirportToAirportPosition: Record<AosAirport, MapPosition> = reduce(
    typedAirports.airports,
    (acc, airport, key) => {
        acc[key] = {
            lon: airport.longitude,
            lat: airport.latitude,
            zoom: selectedLocationZoom,
        }
        return acc
    },
    {} as Record<AosAirport, MapPosition>,
)

export const getAirportPosition = (aosAirport: AosAirport): MapPosition =>
    mappingAosAirportToAirportPosition[aosAirport] ?? europeCentralPosition

export const isMainAirport = (airport: AosAirport): boolean => airport === MainAirport
