import { EXPORT_REPORT } from 'aos-services/src/services/notifications/types/ExportDataNotification'
import { FEED_OUT } from 'aos-services/src/services/notifications/types/FeedOutNotification'
import { LVP_WA_ALERT_HEL } from 'aos-services/src/services/notifications/types/LvpWaAlertNotification'
import { MESSAGE } from 'aos-services/src/services/notifications/types/MessageNotification'
import { AosNotification } from 'aos-services/src/services/notifications/types/Notification'
import { PRIVATE_CHANNEL_INVITE } from 'aos-services/src/services/notifications/types/PrivateChannelInviteNotification'
import React, { Component } from 'react'

import { ExportDataNotificationItem } from './items/ExportDataNotificationItem'
import { FeedOutNotificationItem } from './items/FeedOutNotificationItem'
import { MessageNotificationItem } from './items/MessageNotificationItem'
import { PrivateChannelInviteNotificationItem } from './items/PrivateChannelInviteNotificationItem'

export class NotificationItem extends Component<NotificationItemProps> {
    public render() {
        const { notification, openEventAction } = this.props
        switch (notification.type) {
            case FEED_OUT:
                return (
                    <FeedOutNotificationItem
                        notification={notification}
                        openEventAction={openEventAction}
                    />
                )

            case PRIVATE_CHANNEL_INVITE:
                return (
                    <PrivateChannelInviteNotificationItem
                        notification={notification}
                        openEventAction={openEventAction}
                    />
                )

            case MESSAGE:
                return <MessageNotificationItem notification={notification} />

            case LVP_WA_ALERT_HEL:
                return (
                    <FeedOutNotificationItem
                        notification={notification}
                        openEventAction={openEventAction}
                    />
                )
            case EXPORT_REPORT:
                return <ExportDataNotificationItem notification={notification} />

            default:
                return null
        }
    }
}

interface NotificationItemProps {
    notification: AosNotification
    openEventAction(v: number): void
}
