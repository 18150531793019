import { AttachmentsState, initialAttachmentsState } from 'aos-services/src/core/attachments/state'
import {
    DiscountModuleBaseState,
    initialDiscountModuleBaseState,
} from 'aos-services/src/core/discountModule/state'
import { DiscountCoupon } from 'aos-services/src/services/discountModule/types/DiscountCoupon'
export type DiscountModuleState = DiscountModuleBaseState & {
    editedDiscountCoupon?: DiscountCoupon
    saveDiscountCouponModalOpen: boolean
    createDiscountStoreModalOpen: boolean
    manageDiscountStoresModalOpen: boolean
    attachmentsState: AttachmentsState
}

export const initialDiscountModuleState: DiscountModuleState = {
    ...initialDiscountModuleBaseState,
    saveDiscountCouponModalOpen: false,
    createDiscountStoreModalOpen: false,
    manageDiscountStoresModalOpen: false,
    attachmentsState: initialAttachmentsState,
}

export type DiscountModuleStateAware = {
    discountModule: DiscountModuleState
}
