import { EnumValues } from 'enum-values'
import { fromPairs } from 'lodash'

import {
    allNotificationsPreferencesTypes,
    NotificationsPreferencesType,
} from '../../flightInformation/types/AosAirport'

export enum NotificationsPreferencesDay {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export const allNotificationsPreferencesDays = EnumValues.getValues(
    NotificationsPreferencesDay,
) as NotificationsPreferencesDay[]

export interface AdditionalPreferenceItem {
    email: boolean
    push: boolean
}

export type AosUserNotificationsAdditionalPreferences = Record<
    NotificationsPreferencesType,
    AdditionalPreferenceItem
>

export interface AosUserNotificationsTimePreference {
    fromTime: string | null
    toTime: string | null
    days: NotificationsPreferencesDay[]
}

export const defaultDisabledTimeNotificationPreferences: AosUserNotificationsTimePreference = {
    fromTime: null,
    toTime: null,
    days: allNotificationsPreferencesDays,
}

export const defaultEnabledTimeNotificationPreferences: AosUserNotificationsTimePreference = {
    fromTime: '07:00',
    toTime: '17:00',
    days: allNotificationsPreferencesDays.slice(0, 5),
}

export const defaultAdditionalNotificationPreferences: AosUserNotificationsAdditionalPreferences =
    fromPairs(
        allNotificationsPreferencesTypes.map(type => [type, { push: false, email: false }]),
    ) as AosUserNotificationsAdditionalPreferences

export interface AosUserNotificationsPreferences {
    timePreference: AosUserNotificationsTimePreference
    additional: AosUserNotificationsAdditionalPreferences
}

export const defaultUserNotificationsPreferences: AosUserNotificationsPreferences = {
    timePreference: defaultDisabledTimeNotificationPreferences,
    additional: defaultAdditionalNotificationPreferences,
}
