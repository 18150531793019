import { layerManager, mapEngine } from 'aos-map-engine/src'
import { LoaderVariant } from 'aos-map-engine/src/Types'

import { AosAirport } from '../flightInformation/types/AosAirport'

export class MapLayerService {
    public loadAllLayers = async (siteLocation: AosAirport) => {
        const layers = mapEngine.getLayersForCurrentAirport(siteLocation)

        const geoJsonLayersConfig = layers.filter(
            layer =>
                layer.customLoader?.variant === LoaderVariant.Basic ||
                layer.customLoader?.variant === LoaderVariant.Advanced,
        )
        const layerPromises = geoJsonLayersConfig.map(layer =>
            layerManager.loadLayer(siteLocation, layer.id),
        )
        const otherLayers = layers.filter(layer => !layer.customLoader)
        const geoJsonLayers = await Promise.all(layerPromises)

        return [...geoJsonLayers, ...otherLayers]
    }

    public loadLayer = async (siteLocation: AosAirport, layerId: string) => {
        const layer = mapEngine.getLayerById(siteLocation, layerId)
        if (!layer) {
            return null
        }
        return layerManager.loadLayer(siteLocation, layer.id)
    }
}

export const mapLayerService = new MapLayerService()
