import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { mapLayerService } from 'aos-services/src/services/layerData/MapLayerService'

export class MapLayerDataService {
    constructor() {}
    public loadBimLayers = (airport: AosAirport) => {
        return mapLayerService.loadAllLayers(airport)
    }
}

export const mapLayerDataService = new MapLayerDataService()
