import {
    RestrictionAreaType,
    translateRestrictionAreaResources,
} from 'aos-services/src/services/restrictions/types/RestrictionArea'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface RestrictionsCountProps {
    active: number
    type: RestrictionAreaType
}

export const RestrictionsCount: FC<RestrictionsCountProps> = ({ active, type }) => (
    <Box row alignItems='baseline'>
        <Text size={52} weight='light' color={Color.WidgetPrimary} lineHeight='standard'>
            {active}
        </Text>
        <Text
            size={18}
            weight='light'
            color={Color.WidgetPrimary}
            lineHeight='standard'
            paddingLeft={8}
        >
            {translateRestrictionAreaResources(type)}
        </Text>
    </Box>
)
