import { cx } from 'aos-components/src/components/base/ClassNames'
import { isDefined } from 'aos-helpers/src/helpers/Function'
import { emptyText } from 'aos-helpers/src/helpers/Text'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { CSSProperties, PropsWithChildren, PureComponent } from 'react'

export class WeatherMetric extends PureComponent<PropsWithChildren<WeatherMetricItemProps>> {
    private formatNumber = (value: number) => {
        if (Number.isNaN(value)) {
            return emptyText()
        }
        let formatted = value.toFixed(1)

        if (formatted.endsWith('.0')) {
            formatted = formatted.slice(0, -2)
        }

        return formatted
    }

    public render() {
        const { children, value, unit, topUnit, label, unitClass, iconStyle } = this.props
        return (
            <Box row>
                <Box className='dashboard-weather__metric__icon' marginRight={4} style={iconStyle}>
                    {children}
                </Box>
                <Box className='dashboard-weather__metric' column>
                    <Box row>
                        <Box className='dashboard-weather__metric__text' marginRight={4}>
                            {isDefined(value) ? this.formatNumber(value) : emptyText()}
                        </Box>
                        {unit && (
                            <Box
                                className={cx(
                                    unitClass,
                                    'dashboard-weather__metric__unit container__item--end',
                                )}
                            >
                                {unit}
                            </Box>
                        )}
                        {!unit && topUnit && (
                            <Box
                                className={cx(
                                    unitClass,
                                    'dashboard-weather__metric__unit container__item--start',
                                )}
                            >
                                {topUnit}
                            </Box>
                        )}
                    </Box>
                    <Box marginTop={8}>
                        <Text textNoWrap color={Color.WidgetSecondary}>
                            {label}
                        </Text>
                    </Box>
                </Box>
            </Box>
        )
    }
}

interface WeatherMetricItemProps {
    label: string
    value?: number
    unit?: string
    topUnit?: string
    unitClass?: string
    iconStyle?: CSSProperties
}
