import { loadContactList } from 'aos-services/src/core/contactList/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { ContactListModal } from './ContactListModal'
import { ContactUnitModal } from './ContactUnitModal'
import { ContactListHeader } from './partial/ContactListHeader'
import { ContactListTable } from './partial/ContactListTable'

export const ContactList = () => {
    return (
        <SyncWrapper onEnter={[loadContactList()]} onLeave={[]}>
            <Box bg={Color.Background} fullSize column>
                <ContactListHeader />
                <Box flex={1} padding={12}>
                    <ContactListTable />
                </Box>
            </Box>
            <ContactListModal />
            <ContactUnitModal />
        </SyncWrapper>
    )
}
