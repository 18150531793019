import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { setEventMapUserLayersVisibilityAction } from 'aos-services/src/core/eventUserLayersVisibility/actions'
import { EventMapUserLayerVisibilityState } from 'aos-services/src/core/eventUserLayersVisibility/state'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { LayersVisibility } from 'aos-services/src/services/map/types/LayersVisibility'
import { removeUuidFromTaskPayload } from 'aos-services/src/services/tasks/helpers/task-form'
import {
    TaskActionPayload,
    TaskFormPayload,
    TaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import { MapFormAwareModal } from 'aos-ui-map/src/components/map/MapFormAwareModal'
import { MapState } from 'aos-ui-map/src/core/state'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadTaskChecklistTemplatesAction } from '../../../core/checklistManager/task/actions'
import { selectTemplatesForCurrentAirport } from '../../../core/checklistManager/task/selectors'
import { State } from '../../../core/state'
import {
    changeFormAction,
    loadMetadataAction,
    saveLocationAction,
    saveTaskAction,
    setFormPristineAction,
    setTaskCreateModalOpenAction,
    setTaskEditModalOpenAction,
    taskEditMapAction,
    taskManagerAttachmentsParentAction,
} from '../../../core/tasks/actions'
import { TaskManagerStateAware } from '../../../core/tasks/state'
import { TaskForm } from './TaskForm'
import { TasksMap } from './TasksMap'

interface TaskEditModalProps extends TaskModalSelectorState, TaskModalDispatchProps {}

/* eslint-disable react/jsx-no-literals */
export const TaskModalComponent: FC<TaskEditModalProps> = ({
    isOpen,
    closeTaskModal,
    saveTask,
    task,
    keyPrefix,
    form,
    map,
    setFormPristine,
    saveLocation,
    setLayersVisibility,
    switchMap,
    attachmentsState,
    attachmentsAction,
    metadata,
    loadMetadata,
    bimLayers,
    eventMapUserLayerVisibility,
    setEventMapUserLayersVisibilityAction,
    resetLocation,
    checklists,
    loadChecklistTemplates,
    ...props
}) => {
    const [createAnother, setCreateAnother] = useState(false)

    useEffect(() => {
        if (isOpen) {
            loadMetadata()
            loadChecklistTemplates()
        }
    }, [isOpen])

    const submitForm = () => {
        if (!form.valid) {
            setFormPristine(false)
        } else {
            saveTask({
                payload: removeUuidFromTaskPayload(task),
                createAnother,
            })
        }
    }

    const changeForm = (payload: Partial<TaskFormPayload>) => {
        props.changeForm({ ...task, ...payload } as TaskFormPayload)
    }

    const changeUserMapLayersVisibility = (v: string[]) => {
        setEventMapUserLayersVisibilityAction({
            ...eventMapUserLayerVisibility,
            list: v,
        })
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...eventMapUserLayerVisibility,
            list: [
                ...eventMapUserLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setEventMapUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    const creatAnotherFooter = () => {
        return (
            <Box row justify='center' columnGap={8}>
                <Checkbox checked={createAnother} onChange={setCreateAnother} />
                <Text size={13} color={Color.TimelineBackground}>
                    {translate('create-task.create-another')}
                </Text>
            </Box>
        )
    }

    const checklistsForCategory = useMemo(
        () => checklists.filter(checklist => checklist.taskCategory === task.category),
        [checklists, task.category],
    )

    return (
        <MapFormAwareModal
            customFooter={!task.id && !task.parentTaskId && creatAnotherFooter()}
            map={() => <TasksMap />}
            content={({ setView }) => (
                <TaskForm
                    checklists={checklistsForCategory}
                    statusReadOnly={!!task.id}
                    setViewMode={setView}
                    changeForm={changeForm}
                    task={task}
                    form={form}
                    attachmentsState={attachmentsState}
                    attachmentsAction={attachmentsAction}
                    metadata={metadata!}
                />
            )}
            modalKind={ModalKind.Big}
            isOpen={isOpen}
            keyPrefix={keyPrefix}
            form={form}
            mapState={map}
            hideAction={() => {
                closeTaskModal()
                setCreateAnother(false)
            }}
            saveLocation={saveLocation}
            setLayersVisibility={setLayersVisibility}
            switchMap={switchMap}
            submitForm={submitForm}
            hasPickedLocation={!!map.pickedTask}
            isLoading={!metadata}
            bimLayers={bimLayers}
            userLayerVisibility={eventMapUserLayerVisibility}
            setUserLayersVisibilityAction={v => changeUserMapLayersVisibility(v)}
            changeFloorLayerVisibility={v => changeFloorLayerVisibility(v)}
            resetSelectedLocation={resetLocation}
        />
    )
}

interface TaskModalSelectorState {
    isOpen: boolean
    task: TaskFormPayload
    keyPrefix: string
    form: FormValidation<TaskPayloadValidation>
    map: MapState
    attachmentsState: AttachmentsState
    metadata?: TaskMetadata
    bimLayers: BimLayersState
    eventMapUserLayerVisibility: EventMapUserLayerVisibilityState
    checklists: AosTaskChecklistTemplate[]
}

interface TaskModalDispatchProps {
    closeTaskModal(): void
    setFormPristine(v: boolean): void
    saveTask(v: TaskActionPayload): void
    changeForm(v: TaskFormPayload): void
    // map
    saveLocation(): void
    setLayersVisibility(m: LayersVisibility): void
    switchMap(m: MapVariant): void
    attachmentsAction(m: AttachmentsAction): void
    loadMetadata(): void
    setEventMapUserLayersVisibilityAction(v: EventMapUserLayerVisibilityState): void
    resetLocation(): void
    loadChecklistTemplates(): void
}

const commonActions = {
    setFormPristine: setFormPristineAction,
    saveTask: saveTaskAction,
    changeForm: changeFormAction,
    setLayersVisibility: taskEditMapAction.setLayersVisibilityAction,
    // map
    switchMap: taskEditMapAction.switchMapAction,
    saveLocation: saveLocationAction,
    attachmentsAction: taskManagerAttachmentsParentAction,
    loadMetadata: loadMetadataAction,
    setEventMapUserLayersVisibilityAction: setEventMapUserLayersVisibilityAction,
    resetLocation: taskEditMapAction.resetPickedLocationAction,
    loadChecklistTemplates: loadTaskChecklistTemplatesAction,
}

const commonStateSelectors = {
    task: (state: TaskManagerStateAware) => state.taskManager.payload,
    form: (state: TaskManagerStateAware) => state.taskManager.form,
    map: (state: TaskManagerStateAware) => state.taskManager.editMap,
    attachmentsState: (state: TaskManagerStateAware) => state.taskManager.attachmentsState,
    metadata: (state: TaskManagerStateAware) => state.taskManager.metadata,
    checklists: selectTemplatesForCurrentAirport,
}

export const TaskEditModal = connect<TaskModalSelectorState, TaskModalDispatchProps>(
    createStructuredSelector({
        isOpen: (state: TaskManagerStateAware) => state.taskManager.isEditModalOpen,
        keyPrefix: () => 'task-modal.edit',
        bimLayers: (state: State) => state.bimLayers,
        eventMapUserLayerVisibility: (state: State) => state.eventMapUserLayerVisibility,
        ...commonStateSelectors,
    }),
    {
        closeTaskModal: () => setTaskEditModalOpenAction(false),
        ...commonActions,
    },
)(TaskModalComponent)

export const TaskCreateModal = connect<TaskModalSelectorState, TaskModalDispatchProps>(
    createStructuredSelector({
        isOpen: (state: TaskManagerStateAware) => state.taskManager.isCreateModalOpen,
        keyPrefix: () => 'task-modal.create',
        bimLayers: (state: State) => state.bimLayers,
        eventMapUserLayerVisibility: (state: State) => state.eventMapUserLayerVisibility,
        ...commonStateSelectors,
    }),
    {
        closeTaskModal: () => setTaskCreateModalOpenAction(false),
        ...commonActions,
    },
)(TaskModalComponent)
