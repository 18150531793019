import { IconModal } from 'aos-components/src/components/modal/IconModal/IconModal'
import { Booleanish } from 'aos-helpers/src/helpers/Boolean'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    aosUserGroupName,
    getGroupMembersCountTranslation,
} from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { AtcHardAlert } from '../../eventModals/partial/AtcHardAlert'

interface PrivateChannelConfirmationModalProps {
    isOpen: boolean
    group: AosUserGroup
    isHardAlert?: boolean
    closeAction(): void
    onSubmit(value: Booleanish): void
}

export const SelectUserGroupConfirmationModal: FC<PrivateChannelConfirmationModalProps> = props => {
    const { isOpen, closeAction, group, onSubmit, isHardAlert } = props
    const groupName = aosUserGroupName(group)
    const groupMembersCount = group.userCount

    const standardInvitation = () => (
        <Box flex={1} column>
            <Box column paddingVertical={30} alignItems='center' alignContent='center'>
                <Text color={Color.Grey} size={13} weight='regular' paddingBottom={4}>
                    {translate('invite-to-private-channel-confirmation.text')}
                </Text>
                <Text color={Color.TimelineBackground} size={14} weight='regular' paddingBottom={4}>
                    {`${groupMembersCount} ${getGroupMembersCountTranslation(
                        groupMembersCount,
                    )} (${groupName})`}
                </Text>
                <Box paddingTop={30} row>
                    <FormButton
                        label={translate('invite-to-private-channel.ok')}
                        onClick={() => onSubmit(false)}
                    />
                </Box>
            </Box>
        </Box>
    )

    return (
        <IconModal
            isOpen={isOpen}
            modalKind={ModalKind.Icon}
            closeAction={closeAction}
            svg={isHardAlert ? SvgIcon.SendAlert : SvgIcon.FeedOutConfirmation}
        >
            {isHardAlert ? (
                <Box padding={48} alignContent='center' column>
                    <Text color={Color.TimelineBackground} size={16}>
                        {translate('send-hard-alert.text')}
                    </Text>
                    <AtcHardAlert onSubmit={value => onSubmit(value)} aosUserGroup={group} />
                </Box>
            ) : (
                standardInvitation()
            )}
        </IconModal>
    )
}
