import { appConfig } from 'appConfig'
import { rgba } from 'polished'

const BaseColors = {
    Grey: '#69728B',
    GreyLight: '#abb4c9',
    Black: '#050a10',
    BlackCoal: '#1c1c1c',
    White: '#ffffff',
    WhiteOpacity45: 'rgba(255,255,255, .45)',
    Grey1: '#f7f7fa',
    Grey2: '#858891',
    Grey3: '#303d58',
    Grey4: '#B1B4BD',
    Grey5: '#677497',
    Grey6: '#707688',
    Grey7: '#34435F',
    Grey8: '#9BA2B0',
    Grey9: '#8599BB',

    TransparentWhite: '#FFFFFF44',
    Transparent: 'rgba(0,0,0,0)',
    TransparentBlack: 'rgba(0,0,0,0.6)',
    TransparentGrey: '#E5E6EA',

    Green: '#32a538',
    DarkGreen: '#0a6e2c',
    LightGreen: '#45eb4d',
    ToastGreen: '#409244',
    PaleGreen: '#a6d46d',
    VeryPaleOrange: '#F6F0E9',
    UltraPaleGreen: '#E9F6EA',
    SoftGreen: '#81c48d',
    Yellow: '#f8ba00',
    DarkYellow: '#664A02',
    PaleOrange: '#f1a267',
    Orange: '#ff6d00',
    Red: '#e42626',
    PaleRed: '#F6E9E9',
    DarkRed: '#280006',
    Magenta: '#da58ab',
    SoftMagenta: '#d958ab',
    Violet: '#6c55a7',
    SoftBlue: '#4c85ff',
    SeaBlue: '#4dc8c9',
    LightBlue: '#E9EEF6',
    PaleBlue: '#DFE9FF',
    TaskBlue: '#1A62FB',
    IntensiveBlue: '#1D62FB',
    TaskYellow: '#ffd145',
    DarkBlue: '#060B14',
    InfoToastBlue: '#005de5',
    ProgressGreen: '#69F996',
    ProgressRed: '#FF073A',
    ProgressGrey: '#D9D9D9',
    ProgressYellow: '#FFB904',
    ProgressBarGradient: 'linear-gradient(180deg, rgba(108,255,156,1) 0%, rgba(50,165,56,1) 100%)',
    UnitTaskBlack: '#16181C',
    UnitTaskYellow: '#332500',
    UnitTaskGreen: '#163320',
    UnitTaskRed: '#140001',
}

const AosColors = {
    'PrimaryLight': '#417eff',
    'Primary': '#005de5',
    'PrimaryDark': '#0f4aca',
    'PrimaryPale': '#7ca6ff',
    'FontColor': '#FFFFFF',
    'FontLightColor': '#35427C',
    'FontInvertColor': '#000000',
    'WidgetFontColor': '#FFFFFF',
    'Background': '#0b1029',
    'BottomTabsBackground': '#0b1029',
    'LightBackground': '#182146',
    'DarkBackground': '#232d43',
    'WidgetBackground': '#232d43',
    'FlightsScreenAirportSelectBackground': '#005de5',
    'PrimaryButtonLabel': '#FFF',
    'TextButtonLabel': '#005de5',
    'PrimaryButtonHoverBackground': '#417eff',
    'PrimaryOutlinedButtonHoverBackground': '#417eff',
    'ProgressBarBackground': 'rgba(24, 33, 70, 0.4)',
    'ProgressBar': '#FFF',
    'ReportingItem': '#182146',
    'MenuButtonColor': '#6780aa', //UiBlack4
    'SideMenuInactive': '#6780aa',
    'WidgetSecondary': '#69728B',
    'WidgetPrimary': '#FFFFFF',
    'TabBarActive': '#fff',
    'TabBarInactive': '#000',
    'ChartBase': '#3b4b6a', //UiBlack3
    'SmartColor': '#FFF',
    'DashboardItemBackground': '#1B2435',
    'DisabledText': '#c6c7cb', //Grey2
    'TimelineBackground': '#14202c', // UiBlack1
    'FlightGrey': '#879dc1',
    'ActiveGrey': '#2D3952',
    'ContactListItem': '#20293A',
    'SecondaryText': '#838FAE',
    'ToastFont': '#232324',
    'Tint': '#417eff',
    'ChecklistBackground': '#18202a',
    'HeaderActiveTabUnderline': BaseColors.White,
    'DarkScrollbar': 'rgba(59, 75, 106, .8)',
    'ContactFormAddNewButtonColor': '#417eff',
    'EventDetailsSelectLocationLinkColor': BaseColors.Grey,
    'OutlineButtonBackground': '#FFF',
    'FlightsChartTitle': BaseColors.Grey,
    'FlightChartStatsWrapperBackground': '',
    'FlightsTableText': '#879dc1',
    'PrimaryComponentLabel': BaseColors.White,
    'TransparentOutlinedButtonHoverBackground': BaseColors.White,
    'DashboardWarningBadge': BaseColors.Yellow,
    'CheckboxOnWhiteBackground': '#005de5',
    'CheckboxDisabledBackground': BaseColors.Grey2,
    'CheckboxDisabledBorder': BaseColors.Grey2,
    'RadioSeverityMaintenance': '#6780aa',
    'RadioPrimary': '#3b4b6a',

    // new color values example
    'Primary.1': '#005de5', //primary
    'Primary.2': '#417eff', //primary light
    'Primary.3': '#0f4aca', //primary dark
    'Background.1': '#0b1029', //background
    'Background.2': '#182146', //light background
    'Background.3': '#232d43', //dark background
    'Background.4': '#6780aa', //Uiblack
    'Background.5': '#14202c', //Uiblack1
    'Background.6': '#3b4b6a', //UiBlack3
    'TextPrimary': '#FFFFFF',
    'TextSecondary': '#69728B',
    'TextTertiary': '#c6c7cb',
    'TextQuaternary': '#005de5',
    'TextContrast': '#0F0F0F',

    'BottomTabActive': '#FFFFFF',
    'BottomTabInactive': '#6780aa',
    'DisabledButton': BaseColors.Grey5,
    'MapFill': 'rgba(208, 220, 156, 0.5)',
    'MapBorder': 'rgba(245, 247, 156, 1)',
    'DarkInput': BaseColors.Black,
    'WeatherChartBackground': '#1b2437',
    'TransparentButtonBorder': BaseColors.White,
    'TransparentButtonText': BaseColors.White,
    'TransparentButtonTextHover': BaseColors.Black,
    'LinkText': '#7ca6ff',
    'ChartAxisLineStroke': '#3b4b6a',
    'ChartAxisLabel': '#69728b',
    'UnderlineTabsActiveColor': '#005de5',
    'PrimaryIconBoxButtonTint': BaseColors.White,
    'PrimaryOnWhite': '#005de5',
}

const AoimsColors = {
    'PrimaryLight': '#FFDD4F',
    'Primary': '#FFDD4F',
    'PrimaryDark': '#b08f22',
    'PrimaryPale': '#2D2D2D',
    'FontColor': '#0F0F0F',
    'FontLightColor': '#EAEAEA',
    'FontInvertColor': '#F0F0F0',
    'Background': '#0F0F0F',
    'BottomTabsBackground': '#2D2D2D',
    'LightBackground': '#FFDD4F',
    'WidgetBackground': '#2D2D2D',
    'DarkBackground': '#2D2D2D',
    'WidgetFontColor': '#B9B9B9',
    'FlightsScreenAirportSelectBackground': '#2D2D2D',
    'PrimaryButtonLabel': '#0F0F0F',
    'TextButtonLabel': '#6A5600',
    'PrimaryOutlinedButtonHoverBackground': '#b08f22',
    'PrimaryButtonHoverBackground': '#b08f22',
    'ProgressBarBackground': '#FFF1B8',
    'ProgressBar': '#1C1C1C',
    'ReportingItem': '#2D2D2D',
    'MenuButtonColor': '#FFFFFF',
    'SideMenuInactive': '#8b8b8b',
    'WidgetSecondary': '#B9B9B9',
    'WidgetPrimary': '#FFF',
    'TabBarActive': rgba('#000', 1),
    'TabBarInactive': rgba('#000', 0.73),
    'ChartBase': '#5a5a5a',
    'SmartColor': '#000',
    'DashboardItemBackground': '#2D2D2D',
    'DisabledText': '#c6c7cb',
    'TimelineBackground': '#0F0F0F',
    'FlightGrey': '#B9B9B9',
    'ActiveGrey': '#5a5a5a',
    'ContactListItem': '#2D2D2D',
    'SecondaryText': '#B9B9B9',
    'ToastFont': '#232324',
    'Tint': '#2D2D2D',
    'ChecklistBackground': '#18202a',
    'HeaderActiveTabUnderline': '#0F0F0F',
    'DarkScrollbar': 'rgba(50, 50, 50, .8)',
    'ContactFormAddNewButtonColor': '#b08f22',
    'EventDetailsSelectLocationLinkColor': '#B9B9B9',
    'OutlineButtonBackground': '#FFDD4F',
    'FlightsChartTitle': BaseColors.White,
    'FlightChartStatsWrapperBackground': '#1C1C1C',
    'FlightsTableText': BaseColors.White,
    'PrimaryComponentLabel': '#0F0F0F',
    'TransparentOutlinedButtonHoverBackground': '#FFFFFF80',
    'DashboardWarningBadge': '#FF903C',
    'CheckboxOnWhiteBackground': '#5A5A5A',
    'CheckboxDisabledBackground': '#5A5A5A40',
    'CheckboxDisabledBorder': '#5A5A5A40',

    'RadioSeverityMaintenance': '#6780aa',
    'RadioPrimary': '#FFDD4F',

    // new color values example
    'Primary.1': '#FFDD4F', //primary
    'Primary.2': '#FFDD4F', //primary light
    'Primary.3': '#b08f22', //primary dark
    'Background.1': '#0F0F0F', //background
    'Background.2': '#FFDD4F', //light background
    'Background.3': '#2D2D2D', //dark background
    'Background.4': '#5a5a5a', //Uiblack
    'Background.5': '#8b8b8b',
    'Background.6': '#14202c',
    'TextPrimary': '#0F0F0F',
    'TextSecondary': '#EAEAEA',
    'TextTertiary': '#2D2D2D',
    'TextQuaternary': '#b08f22',
    'TextContrast': '#FFFFFF',
    'BottomTabActive': '#FFFFFF',
    'BottomTabInactive': '#5a5a5a',
    'DisabledButton': '#5a5a5a',

    'MapFill': 'rgba(222, 232, 244, 0.5)',
    'MapBorder': 'rgba(45, 142, 156, 1)',
    'DarkInput': BaseColors.BlackCoal,
    'WeatherChartBackground': '#1c1c1c',

    'TransparentButtonBorder': BaseColors.Black,
    'TransparentButtonText': BaseColors.Black,
    'TransparentButtonTextHover': BaseColors.White,
    'LinkText': '#b08f22',
    'ChartAxisLineStroke': '#5A5A5A',
    'ChartAxisLabel': '#EAEAEA',
    'UnderlineTabsActiveColor': '#b08f22',
    'PrimaryIconBoxButtonTint': BaseColors.Black,
    'PrimaryOnWhite': '#b08f22',
}

const ColorConfig: Record<string, typeof AosColors> = {
    AOS: AosColors,
    AOIMS: AoimsColors,
}

export const Color = {
    ...BaseColors,
    ...ColorConfig[appConfig.theme],
}

export const statusBarColor = {
    AOS: 'light-content',
    AOIMS: 'dark-content',
}[appConfig.theme]

export type Color =
    | (typeof BaseColors)[keyof typeof BaseColors]
    | (typeof AosColors)[keyof typeof AosColors]
    | (typeof AoimsColors)[keyof typeof AoimsColors]
