import { put, takeEvery } from 'redux-saga/effects'

import { firebaseFeatureFlagService } from '../../dataaccess/firebase/FirebaseFeatureFlagService'
import { LOAD_FEATURE_FLAGS, LoadFeatureFlagsAction, loadFeatureFlagsSuccess } from './actions'

function* loadFeatureFlags() {
    yield takeEvery<LoadFeatureFlagsAction>(LOAD_FEATURE_FLAGS, function* () {
        const flags: firebase.remoteConfig.RemoteConfig.Value =
            yield firebaseFeatureFlagService.getFeatureFlag('feature_flags')
        if (!flags.asString()) {
            return
        }
        yield put(loadFeatureFlagsSuccess(JSON.parse(flags.asString())))
    })
}

export const featureFlagsSagas = [loadFeatureFlags]
