import { Chart } from 'aos-components/src/components/chart/Chart'
import { LinearStackBarSeries } from 'aos-components/src/components/chart/series/LinearStackBarSeries'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { isTimeInTimeRangeFilter } from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import {
    FlightPoint,
    FlightsSeries,
} from 'aos-services/src/services/airportStatus/flights/types/FlightsInfo'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { LinearStackBarSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent, ReactNode } from 'react'

export interface InvertedPercentageFlightChartProps {
    flights: FlightsSeries
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    color: Color
    timeRangeFilter: number

    tooltip(point: FlightPoint): ReactNode
}

export class InvertedPercentageFlightChart extends PureComponent<InvertedPercentageFlightChartProps> {
    public xAccessor = (d: FlightPoint): DateTime => d.time
    public yAccessor = (d: FlightPoint): number => d.value

    public render() {
        const { yDomain, timeRangeFilter, xDomain } = this.props
        return (
            <Chart
                xConfig={{
                    domain: xDomain,
                    tickFormat: formatHour,
                    tickMarked: isTimeInTimeRangeFilter(timeRangeFilter),
                }}
                y1Config={{
                    domain: yDomain,
                    baseValues: [70, 90],
                    unit: Unit.Percent,
                }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { flights, tooltip, color } = this.props
        const { xScale, y1Scale } = props
        const barSeriesConfig: LinearStackBarSeriesConfig<FlightPoint> = {
            colors: [Color.ChartBase, color],
            //eslint-disable-next-line
            tooltip: data => <span style={{ color }}>{data.value}%</span>,
        }
        if (!xScale || !y1Scale) {
            return null
        }

        return (
            <>
                <LinearStackBarSeries
                    data={flights.all}
                    seriesConfig={barSeriesConfig}
                    accessors={{
                        xAccessor: this.xAccessor,
                        yAccessorKeys: ['value', 'invertedValue'],
                    }}
                    scales={{ xScale, yScale: y1Scale }}
                    minYValue={this.props.yDomain.domain[0]}
                />
                <LineSeries
                    data={flights.arrivals}
                    seriesConfig={{
                        color: Color.PaleOrange,
                        withDots: true,
                        tooltip,
                    }}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                    scales={{ xScale, yScale: y1Scale }}
                />
                <LineSeries
                    data={flights.departures}
                    seriesConfig={{
                        color: Color.Violet,
                        withDots: true,
                        tooltip,
                    }}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                    scales={{ xScale, yScale: y1Scale }}
                />
            </>
        )
    }
}
