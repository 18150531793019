import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'

interface RemoveAccountDialogProps extends PropsWithChildren {
    title: string
    description: string | JSX.Element
}

export const RemoveAccountDialog: FC<RemoveAccountDialogProps> = ({
    title,
    description,
    children,
}) => {
    return (
        <Box
            column
            rowGap={24}
            bg={Color.Grey1}
            style={{ boxShadow: boxShadow.big, zIndex: 1 }}
            padding={40}
            rounded
            width={450}
            minHeight={100}
        >
            <Text size={16} weight='medium' color={Color.ToastFont}>
                {title}
            </Text>
            <Box>
                <Text size={14} color={Color.ToastFont}>
                    {description}
                </Text>
            </Box>
            <Box>{children}</Box>
        </Box>
    )
}
