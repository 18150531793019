import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Text, TextProps } from '../base/Text'
import { boxShadow } from '../base/Theme'

export interface TooltipOverlayProps extends TextProps {
    variant?: TooltipOverlayVariant
    withArrow?: boolean
}

export enum TooltipOverlayVariant {
    White,
    Black,
}

export const TooltipOverlay: FCWithChildren<PropsWithChildren<TooltipOverlayProps>> = ({
    variant = TooltipOverlayVariant.Black,
    withArrow,
    children,
    ...boxProps
}) => {
    const bg = variant === TooltipOverlayVariant.Black ? Color.WidgetBackground : Color.White
    const color = variant === TooltipOverlayVariant.Black ? Color.White : Color.TimelineBackground

    return (
        <TooltipOverlayBox
            withArrow={withArrow}
            size={12}
            bg={bg}
            color={color}
            textWrap
            lineHeight='large'
            selectable
            {...boxProps}
        >
            {children}
        </TooltipOverlayBox>
    )
}

export const TooltipOverlayBox = styled(Text)<{ withArrow?: boolean }>`
    border-radius: 3px;
    box-shadow: ${boxShadow.large};
    ${p =>
        p.withArrow &&
        css`
            &::before {
                position: absolute;
                background-color: inherit;
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                margin: auto;
            }
        `}
`
