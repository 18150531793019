import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import { loadLayersDataAction } from 'aos-services/src/core/layersData/actions'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fodEditMapParentAction, fodMapActions } from '../../core/fod/actions'
import { State } from '../../core/state'

export const FodMap = () => {
    const dispatch = useDispatch()
    const mapState = useSelector((state: State) => state.fod.fodMap)
    const layersData = useSelector((state: State) => state.layersData)
    const userLayerVisibility = useSelector((state: State) => state.fod.userLayerVisibility)
    const bimLayersState = useSelector((state: State) => state.bimLayers)
    const siteLocation = useSelector(currentUserSiteLocation)

    return (
        <MapStack
            siteLocation={siteLocation}
            mapState={mapState}
            layersData={layersData}
            bimLayersState={bimLayersState}
            userLayerVisibility={userLayerVisibility}
            mapAction={v => dispatch(fodEditMapParentAction(v))}
            loadLayersDataAction={v => dispatch(loadLayersDataAction(v))}
            loadUserLayerVisibilityAction={() =>
                dispatch(fodMapActions.loadUserLayerVisibilityAction())
            }
            loadBimLayersDataAction={() => dispatch(loadBimLayersDataAction())}
        />
    )
}
