import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { channelTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { messageChannelIcon } from 'aos-ui/src/components/message/MessageChannelIcon'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

interface MessageChannelSelectorProps {
    channels: AosMessageChannel[]
    onChange: (channel: AosMessageChannel) => void
    channelsState: AosMessageChannel[]
}

export const MessageChannelSelector: FC<MessageChannelSelectorProps> = ({
    channels,
    channelsState,
    onChange,
}) => (
    <Box row paddingVertical={8} wrap flex={1}>
        {channels.map((c, index) => (
            <ChannelItem
                key={c}
                channel={c}
                isActive={channelsState.includes(c)}
                onChange={onChange}
                first={index === 0}
            />
        ))}
    </Box>
)

interface ChannelItemProps {
    channel: AosMessageChannel
    isActive: boolean
    first: boolean
    onChange: (channel: AosMessageChannel) => void
}

export const ChannelItem: FC<ChannelItemProps> = ({ channel, isActive, onChange, first }) => {
    const channelName = enumToName(AosMessageChannel)(channel)
    return (
        <ChannelButton onClick={() => onChange(channel)} first={first}>
            <Text
                row
                size={13}
                color={Color.White}
                style={{ opacity: isActive ? 1 : 0.4 }}
                marginRight={16}
            >
                <Icon iconSize={BlockSize.Std} svg={messageChannelIcon(channel)} block />
                {channelTitle(channelName)}
            </Text>
        </ChannelButton>
    )
}

const ChannelButton = styled(Button)<{ first?: boolean }>`
    ${p =>
        p.first &&
        css`
            flex: 1;
        `}
`
