import { enumToArray } from 'aos-helpers/src/helpers/Enum'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { statusToColor } from 'aos-services/src/services/checklists/types/AosUnitTaskConfig'
import { UnitTaskStatus } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import styled from 'styled-components'

interface UnitTaskItemProps {
    title: string
    description: string
    value: UnitTaskStatus
    onChange: (value: UnitTaskStatus) => void
    disabled?: boolean
    footer?: string
    additionalNote?: string
    editable?: boolean
    onEdit?: () => void
    isSameUnit: boolean
    withTooltip?: boolean
}

const translateTaskStatus = translateEnum(UnitTaskStatus, 'unit-task-status')

export const UnitTaskItem: FC<UnitTaskItemProps> = ({
    title,
    description,
    value,
    onChange,
    disabled,
    footer,
    additionalNote,
    editable,
    onEdit,
    isSameUnit,
    withTooltip = false,
}) => {
    const additionalNoteBox = () => {
        return (
            <Box
                alignItems='flex-start'
                flex={1}
                justify='space-between'
                height={50}
                padding={8}
                rounded
                bg={Color.White}
                row
                columnGap={4}
            >
                <Text size={14} color={Color.TimelineBackground}>
                    {additionalNote}
                </Text>
                {editable && <IconButton onClick={onEdit} svg={SvgIcon.EditDark} />}
            </Box>
        )
    }
    const options = useMemo(() => enumToArray(UnitTaskStatus).filter(q => q !== value), [value])
    const TooltipBox = (disabled && withTooltip ? Tooltip : Box) as React.FunctionComponent<
        PropsWithChildren<{ body: ReactNode }>
    >

    return (
        <ShiningBorderedBox flex={1} disabled={!isSameUnit} column rowGap={8} status={value}>
            <Box flex={1} alignContent='center' justify='space-between' row>
                <Text textTransform='uppercase' size={14} color={Color.White}>
                    {title}
                </Text>
                <TooltipBox body={translate('create-unit-task.disabled')}>
                    <Box>
                        <DropdownAutocomplete
                            small
                            variant={statusToDropdownVariant[value]}
                            width={120}
                            outlined
                            disabled={!!disabled}
                            hasClear={false}
                            labelRenderer={v => translateTaskStatus(v)}
                            value={value}
                            onChange={onChange}
                            items={options}
                        />
                    </Box>
                </TooltipBox>
            </Box>
            <Text strikeThrough={value === UnitTaskStatus.SKIPPED} size={14} color={Color.White}>
                {description}
            </Text>
            {!!additionalNote && additionalNoteBox()}
            {footer && value !== UnitTaskStatus.TO_DO && (
                <Box alignSelf='flex-end' flex={1}>
                    <Text color={Color.White} size={12}>
                        {footer}
                    </Text>
                </Box>
            )}
        </ShiningBorderedBox>
    )
}

export const statusToDropdownVariant = {
    [UnitTaskStatus.TO_DO]: DropdownVariant.Red,
    [UnitTaskStatus.IN_PROGRESS]: DropdownVariant.Yellow,
    [UnitTaskStatus.DONE]: DropdownVariant.Green,
    [UnitTaskStatus.SKIPPED]: DropdownVariant.Grey,
}

const ShiningBorderedBox = styled(Box)<{ status: UnitTaskStatus; disabled: boolean }>`
    border: 4px ${props => (props.disabled ? 'dashed' : 'solid')}
        ${props => statusToColor[props.status]};
    border-radius: 4px;
    padding: 12px;
    box-shadow: ${props => (props.disabled ? 'none' : `0 0 10px 0 ${statusToColor[props.status]}`)};
    transition: box-shadow 0.5s ease-in-out;
`
