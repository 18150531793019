import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import { ShiftNotesDto } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesListDto'
import { Box } from 'aos-ui/src/components/base/Box'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    shiftNotesAddAction,
    shiftNotesLoadAction,
    shiftNotesReloadAction,
} from '../../../core/tasks/shiftNotes/actions'
import { shiftNotesListSelector } from '../../../core/tasks/shiftNotes/selectors'
import { ShiftNotesSyncWrapper } from '../../statusDashboard/renderers/ShiftNotes/ShiftNotesSyncWrapper'
import { ShiftNotesTile } from './list/ShiftNotesTile'

interface ShiftNotesListProps extends ShiftNotesListStateProps, ShiftNotesDispatchProps {}

const ShiftNotesListComponent: FC<ShiftNotesListProps> = ({
    shiftNotesReload,
    shiftNotesLoad,
    list,
}) => {
    useEffect(() => {
        shiftNotesLoad()
    }, [])

    return (
        <ShiftNotesSyncWrapper id='details'>
            <Box bg={Color.TimelineBackground} padding={30}>
                <PaginationList page={list} onChange={shiftNotesReload} paddingBottom={20}>
                    {list.content?.map((item, index) => (
                        <Box key={index} paddingBottom={16}>
                            <ShiftNotesTile item={item} />
                        </Box>
                    ))}
                </PaginationList>
            </Box>
        </ShiftNotesSyncWrapper>
    )
}

interface ShiftNotesListStateProps {
    list: Pageable<ShiftNotesDto>
    canEdit: boolean
}

interface ShiftNotesDispatchProps {
    shiftNotesLoad: typeof shiftNotesLoadAction
    shiftNotesUpdate: typeof shiftNotesAddAction
    shiftNotesReload: typeof shiftNotesReloadAction
}

export const ShiftNotesList = connect<ShiftNotesListStateProps, ShiftNotesDispatchProps>(
    createStructuredSelector({
        list: shiftNotesListSelector,
        canEdit: canEditTasksSelector,
    }),
    {
        shiftNotesLoad: shiftNotesLoadAction,
        shiftNotesUpdate: shiftNotesAddAction,
        shiftNotesReload: shiftNotesReloadAction,
    },
)(ShiftNotesListComponent)
