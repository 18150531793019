import { HelpModal, HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import {
    DashboardItemMode,
    dashboardItemModeColor,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import {
    DashboardItemStatus,
    translateReasonCodes,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { isUpToDate } from 'aos-services/src/services/widgetHealths/type/DashboardItemHealths'
import { Box } from 'aos-ui/src/components/base/Box'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { PropsWithChildren, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { StatusDashboardItemCommonProps } from '../renderers/base/StatusDashboardItemCommonProps'
import { StatusDashboardItemNoDataWarning } from '../renderers/base/StatusDashboardItemNoDataWarning'
import { PreviewLabel } from './PreviewLabel'
import { StatusDashboardAlertOverlay } from './StatusDashboardAlertOverlay'
import { StatusDashboardItemFadeIn } from './StatusDashboardItemFadeIn'
import { StatusDashboardItemTitle, StatusDashboardItemTitleProps } from './StatusDashboardItemTitle'
import { TestingLabel } from './TestingLabel'

interface StatusDashboardItemProps extends StatusDashboardItemTitleProps {
    helpConfig?: HelpModalConfig
    isPreview?: boolean
    noDataWarning?: boolean
    link?: string
    overrideItemStatus?: DashboardItemStatus // Override default itemStatus.status
}

interface StatusDashboardItemState {
    isHelpVisible: boolean
    lastStatus?: DashboardItemMode
    alertShown: boolean
}

export class StatusDashboardItem extends PureComponent<
    PropsWithChildren<StatusDashboardItemProps>,
    StatusDashboardItemState
> {
    public state: StatusDashboardItemState = {
        isHelpVisible: false,
        alertShown: false,
    }

    public render() {
        const { title, labels, helpConfig, link } = this.props
        const itemStatus = this.getItemStatus()

        const warningColor = itemStatus.warning
            ? dashboardItemModeColor[itemStatus.status]
            : undefined

        return (
            <StatusDashboardItemBox column fullSize warningColor={warningColor}>
                <StatusDashboardItemTitle
                    title={title}
                    labels={labels}
                    openHelp={helpConfig && this.handleToggleHelpModal}
                    description={this.getTitleDescription()}
                    {...pickDashboardItemProps(this.props)}
                    itemStatus={itemStatus}
                >
                    {this.renderLabels()}
                </StatusDashboardItemTitle>
                <Box flex='auto' column>
                    <StatusDashboardItemFadeIn>{this.renderContent()}</StatusDashboardItemFadeIn>
                </Box>
                {link && <ProcessLink to={link} />}
                {this.renderHelpModal()}
                {this.renderAlertOverlay()}
            </StatusDashboardItemBox>
        )
    }

    private renderContent() {
        const { itemHealth, children, noDataWarning } = this.props

        if (!isUpToDate(itemHealth) || noDataWarning) {
            return this.renderWarning()
        }

        return children
    }

    private renderLabels() {
        const { labels, isPreview } = this.props
        const itemStatus = this.getItemStatus()

        return (
            <>
                {itemStatus.testing && <TestingLabel marginLeft={12} />}
                {isPreview && <PreviewLabel marginLeft={12} />}
                {labels}
            </>
        )
    }

    private getTitleDescription() {
        const itemStatus = this.getItemStatus()
        const reasons = this.getReasons()

        if (
            itemStatus.status === DashboardItemMode.Normal ||
            itemStatus.status === DashboardItemMode.NoData ||
            !reasons.length
        ) {
            return this.props.description
        }

        const reasonsText = <Box fullWidth>{reasons.join(', ')}</Box>

        if (reasons.length > 1) {
            return (
                <Tooltip body={reasons.join('\n')} withArrow>
                    {reasonsText}
                </Tooltip>
            )
        }

        return reasonsText
    }

    private renderWarning() {
        const { itemHealth } = this.props
        return (
            <StatusDashboardItemNoDataWarning lastChanged={itemHealth && itemHealth.lastChanged} />
        )
    }

    private renderAlertOverlay() {
        const { title, description } = this.props
        const itemStatus = this.getItemStatus()
        const reasons = this.getReasons()

        if (!this.state.alertShown && itemStatus.warning) {
            return (
                <StatusDashboardAlertOverlay
                    title={title}
                    reasons={reasons}
                    status={itemStatus.status}
                    onEnd={this.handleAlertOverlayEnd}
                >
                    {description}
                </StatusDashboardAlertOverlay>
            )
        }

        return null
    }

    private getItemStatus() {
        const { overrideItemStatus, itemStatus } = this.props
        return overrideItemStatus || itemStatus
    }

    private getReasons() {
        const itemStatus = this.getItemStatus()
        return translateReasonCodes(itemStatus.reasonCodes)
    }

    private handleAlertOverlayEnd = () => {
        this.setState({ alertShown: true })
    }

    private renderHelpModal() {
        const { helpConfig } = this.props

        if (!helpConfig) {
            return null
        }

        return (
            <HelpModal
                items={helpConfig.items}
                title={helpConfig.title}
                closeAction={this.handleToggleHelpModal}
                isOpen={this.state.isHelpVisible}
            />
        )
    }

    private handleToggleHelpModal = () => {
        this.setState({ isHelpVisible: !this.state.isHelpVisible })
    }

    public static getDerivedStateFromProps(
        props: StatusDashboardItemProps,
        state: StatusDashboardItemState,
    ): Partial<StatusDashboardItemState> | null {
        const { status } = props.itemStatus
        if (status === DashboardItemMode.NoData || status === state.lastStatus) {
            return null
        }

        return {
            lastStatus: props.itemStatus.status,
            alertShown: state.lastStatus === undefined,
        }
    }
}

const StatusDashboardItemBox = styled(Box)<{ warningColor?: Color }>`
    box-shadow: ${boxShadow.std};
    margin-bottom: 24px;
    background-color: ${Color.WidgetBackground};
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    ${p =>
        p.warningColor &&
        css`
            box-shadow: 0 0 0 2px ${p.warningColor};
        `}
`

const ProcessLink = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: ${rgba(Color.MenuButtonColor, 0.15)};
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`

export const pickDashboardItemProps = (
    p: StatusDashboardItemCommonProps,
): StatusDashboardItemCommonProps => ({
    id: p.id,
    itemStatus: p.itemStatus,
    itemState: p.itemState,
    sizes: p.sizes,
    onDismissAlert: p.onDismissAlert,
    onChangeSize: p.onChangeSize,
    helpConfig: p.helpConfig,
    itemHealth: p.itemHealth,
    isApoc: p.isApoc,
    removeItem: p.removeItem,
})
