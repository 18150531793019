import { parentActionWrapper } from 'aos-helpers/src/helpers/ActionCreator'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { NavigationMapElementType } from 'aos-services/src/services/mapui/types/NavigationMapElement'
import { navigateToMapAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { airportMapMapParentAction } from '../../../core/airportMap/actions'
import { ChangedValueCell } from '../../common/cells/ChangedValueCell'

interface GateCellProps extends GateCellDispatchProps {
    row: Flight
}

export const GateCellComponent: FC<GateCellProps> = props => {
    const { gate, isMainAirport } = props.row

    if (!gate) {
        return null
    }

    const navigate = () => {
        if (isMainAirport && gate.current) {
            props.navigateToMap({
                type: NavigationMapElementType.Gate,
                gateId: gate.current,
            })
        }
    }

    return <ChangedValueCell newValue={gate.current} oldValue={gate.previous} onClick={navigate} />
}

interface GateCellDispatchProps {
    navigateToMap: typeof navigateToMapAction
}

export const GateCell = connect<null, GateCellDispatchProps>(null, {
    navigateToMap: parentActionWrapper(airportMapMapParentAction, navigateToMapAction),
})(GateCellComponent)
