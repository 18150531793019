import {
    AosHandlingAgentType,
    mapHandlingAgentToCompany,
} from 'aos-services/src/services/flightInformation/types/AosHandlingAgent'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent } from 'react'

export class HandlingAgentCell extends PureComponent<HandlingAgentCellProps> {
    public render() {
        const handl = this.props.value

        if (!handl) {
            return null
        }

        return (
            <Tooltip body={mapHandlingAgentToCompany(handl)} withArrow>
                <span>{handl}</span>
            </Tooltip>
        )
    }
}

interface HandlingAgentCellProps {
    value?: AosHandlingAgentType
}
