import { createSelector, createStructuredSelector } from 'reselect'

import { CurrentUser } from '../../services/auth/types/CurrentUser'
import { OptionalJwtTokenWrapper } from '../../services/auth/types/JwtToken'
import { Role } from '../../services/auth/types/Role'
import { FirebaseUid } from '../../services/firebase/types/FirebaseUid'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { AosAdUser, isAosAdUser } from '../../services/users/types/AosUser'
import { AosUserLocalisation } from '../../services/users/types/AosUserLocalisation'

export interface CommonAuthState {
    current: OptionalJwtTokenWrapper
    userProfile?: AosAdUser
    currentUser: CurrentUser
}

export interface UserPreference {
    siteLocation: AosAirport
    localisation: AosUserLocalisation
}

export const initialCommonAuthState: CommonAuthState = {
    current: null,
    currentUser: new CurrentUser(null),
}

export interface CommonAuthStateAware {
    auth: CommonAuthState
}

export const currentUserIdSelector = (state: CommonAuthStateAware): number | undefined =>
    state.auth.current?.payload.aosId

export const currentUserFirebaseIdSelector = (state: CommonAuthStateAware): FirebaseUid =>
    state.auth.current?.payload.firebaseId ?? ''

export const currentUserSiteLocation = (state: CommonAuthStateAware) =>
    state.auth.currentUser.siteLocation

export const isAirportNetworkSelector = (state: CommonAuthStateAware) =>
    state.auth.currentUser.isNetwork()

export const isHelSelector = (state: CommonAuthStateAware) => state.auth.currentUser.isMainAirport()

export const currentLanguageSelector = (state: CommonAuthStateAware) =>
    state.auth.currentUser.localisation

export const isLoggedInSelector = (state: CommonAuthStateAware): boolean => !!state.auth.current

export const setTokenInAuth = <T extends CommonAuthState>(
    state: T,
    token: OptionalJwtTokenWrapper,
): T => ({
    ...state,
    current: token,
    currentUser: new CurrentUser(token && token.payload),
})
export const hasCurrentUserAnyUnitRole = (state: CommonAuthStateAware) =>
    state.auth.currentUser.userHasAnyUnitRole()

export const displayNameSelector = (state: CommonAuthStateAware) => {
    if (state.auth.current) {
        return state.auth.current.payload.name
    }
    return
}

export const userThumbnailSelector = (state: CommonAuthStateAware) => {
    if (state.auth.userProfile && isAosAdUser(state.auth.userProfile)) {
        return state.auth.userProfile.thumbnail
    }
    return
}

export const isApocSelector = (state: CommonAuthStateAware) => state.auth.currentUser.isApoc()

export const isCurrentUserCommander = (state: CommonAuthStateAware) =>
    state.auth.currentUser.userHasRole(Role.EventCommander)

export const isApronControlSelector = (state: CommonAuthStateAware) =>
    state.auth.currentUser.userHasRole(Role.ApronControlUser)

export const authStateForToken = <T extends CommonAuthState>(
    tokenWrapper: OptionalJwtTokenWrapper,
    initial: T,
): T => {
    const token = tokenWrapper ? tokenWrapper.payload : null
    return { ...initial, current: tokenWrapper, currentUser: new CurrentUser(token) }
}

export const preferencesSelector = createStructuredSelector<CommonAuthStateAware, UserPreference>({
    siteLocation: currentUserSiteLocation,
    localisation: currentLanguageSelector,
})

export const currentUserSelector = (state: CommonAuthStateAware) => state.auth.currentUser

export const canViewTasksSelector = createSelector(currentUserSelector, currentUser =>
    currentUser.canViewTasks(),
)

export const canEditTasksSelector = createSelector(currentUserSelector, currentUser =>
    currentUser.canEditTasks(),
)
