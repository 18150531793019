import { IExportForm, mapFormToExportTaskPayload } from 'aos-helpers/src/helpers/TaskExport'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exportTasksAction } from '../../../core/tasks/actions'
import { openShiftNotesModalAction } from '../../../core/tasks/shiftNotes/actions'
import { ExportTasksModal } from '../common/export/ExportTasksModal'
import { HeaderListView } from '../common/HeaderListView'
import { FilterType, TasksHeaderFilter } from '../common/TasksHeaderFilter'
import { ShiftNotesList } from './ShiftNotesList'

export const ShiftNotes = () => {
    const canEdit = useSelector(canEditTasksSelector)
    const [exportTaskOpen, setExportTaskOpen] = useState(false)
    const dispatch = useDispatch()
    const exportTasks = (r: IExportForm) => {
        dispatch(exportTasksAction(mapFormToExportTaskPayload(r)))
    }

    const submit = (r: IExportForm) => {
        setExportTaskOpen(false)
        exportTasks(r)
    }

    return (
        <>
            <PanelWithHeader
                header={
                    <HeaderListView
                        title={<TasksHeaderFilter value={FilterType.ShiftNotes} />}
                        createButton={
                            <Box row>
                                {canEdit && (
                                    <FormButton
                                        label={translate('shift-notes.button.create')}
                                        onClick={() => dispatch(openShiftNotesModalAction())}
                                        variant={FormButtonVariant.White}
                                    />
                                )}
                                <More iconVariant={IconVariant.Smart} marginLeft={16}>
                                    <MoreItem onClick={() => setExportTaskOpen(true)}>
                                        {translate('export-tasks.title')}
                                    </MoreItem>
                                </More>
                            </Box>
                        }
                    />
                }
            >
                <ShiftNotesList />
            </PanelWithHeader>

            <ExportTasksModal
                initialTaskVariant={TaskVariant.SHIFT_NOTES}
                isOpen={exportTaskOpen}
                onClose={() => setExportTaskOpen(false)}
                onSubmit={submit}
            />
        </>
    )
}
