import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box, BoxProps } from '../base/Box'
import { SeleniumProps } from '../base/SeleniumProps'
import { Text } from '../base/Text'

interface DarkListItemTextBlockProps extends SeleniumProps, BoxProps {
    title?: JSX.Element | string
    subtitle?: string
    noTextOverflow?: boolean
    variant?: DarkListItemTextBlockVariant
    isOverdue?: boolean
}

export enum DarkListItemTextBlockVariant {
    DarkListItemS,
    DarkListItemM,
    DarkListItemChecklist,
}

export const DarkListItemTextBlock: FCWithChildren<DarkListItemTextBlockProps> = ({
    title,
    subtitle,
    noTextOverflow,
    variant = DarkListItemTextBlockVariant.DarkListItemS,
    seleniumLocation,
    isOverdue,
    ...marginProps
}) => {
    const titleColor = isOverdue ? Color.Yellow : Color.White
    return (
        <Box {...marginProps} column>
            {title && (
                <Text
                    size={14}
                    color={titleColor}
                    lineHeightPx={20}
                    weight='regular'
                    textClomp={2}
                    fullWidth
                    marginBottom={2}
                    data-test-id={`${seleniumLocation}-title`}
                >
                    {title}
                </Text>
            )}
            {subtitle && (
                <Subtitle
                    noTextOverflow={noTextOverflow}
                    size={SUBTITLE_FONT_SIZE[variant]}
                    color={
                        variant === DarkListItemTextBlockVariant.DarkListItemChecklist
                            ? Color.Grey1
                            : Color.TextSecondary
                    }
                    lineHeight={
                        variant === DarkListItemTextBlockVariant.DarkListItemChecklist
                            ? 'xxLarge'
                            : 'standard'
                    }
                    weight={
                        variant === DarkListItemTextBlockVariant.DarkListItemChecklist
                            ? 'medium'
                            : 'regular'
                    }
                    textClomp={3}
                    data-test-id={`${seleniumLocation}-subtitle`}
                >
                    {subtitle}
                </Subtitle>
            )}
        </Box>
    )
}

const SUBTITLE_FONT_SIZE = {
    [DarkListItemTextBlockVariant.DarkListItemS]: 12,
    [DarkListItemTextBlockVariant.DarkListItemM]: 13,
    [DarkListItemTextBlockVariant.DarkListItemChecklist]: 14,
} as const

const Subtitle = styled(Text)<{ noTextOverflow?: boolean }>`
    ${p =>
        p.noTextOverflow &&
        css`
            text-overflow: inherit;
            overflow: visible;
            display: block;
            max-height: none;
        `}
`
