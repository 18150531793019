import { Color } from '../../../styles/Color'
import { getMinMax } from '../helpers/getMinMax'
import { GaugeNeedle } from '../types/GaugeNeedle'
import { GaugeRange } from '../types/GaugeRange'
import { getNeedleCircles } from './getNeedleCircles'
import { getNeedlePath } from './getNeedlePath'

const OUT_OF_RANGE_ANGLE = 10

export interface GaugeNeedleOptions {
    currentValue: number | null
    ranges: GaugeRange[]
    size: number
    angle: number
    activeArcSize?: number
    activeArcLightSize?: number
}

export const createNeedle = ({
    currentValue,
    ranges,
    size,
    angle,
}: GaugeNeedleOptions): GaugeNeedle | null => {
    if (currentValue === null) {
        return null
    }

    const [min, max] = getMinMax(ranges)
    let value = currentValue
    let additionalAngle = 0

    if (value < min) {
        value = min
        additionalAngle -= OUT_OF_RANGE_ANGLE
    }

    if (value > max) {
        value = max
        additionalAngle += OUT_OF_RANGE_ANGLE
    }

    const maximum = Math.abs(min > max ? min - max : max - min)
    const percent = Math.abs(value - min) / maximum

    const fix = angle / (maximum * 3)
    const startAngle = -(angle - fix)
    const fullAngle = (angle - fix) * 2
    const needleAngle = startAngle + fullAngle * percent + 90 + additionalAngle
    const needleRadius = size / 40

    return {
        angle: needleAngle,
        path: getNeedlePath(needleAngle, size / 4, needleRadius),
        fill: Color.DisabledText,
        circles: getNeedleCircles(needleRadius),
    }
}
