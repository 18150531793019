import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box, BoxProps } from '../base/Box'

interface SelectableItemProps extends BoxProps {
    isSelected: boolean
    onClick(): void
    selectedColor?: Color
}

export const SelectableItem: FCWithChildren<PropsWithChildren<SelectableItemProps>> = ({
    isSelected,
    onClick,
    children,
    selectedColor = Color.Background,
    ...boxProps
}) => (
    <SelectableBox
        isSelected={isSelected}
        onClick={onClick}
        cursor='pointer'
        $selectedColor={selectedColor}
        {...boxProps}
    >
        {children}
    </SelectableBox>
)

const SelectableBox = styled(Box)<{ isSelected: boolean; $selectedColor: Color }>`
    border-bottom: 1px solid ${Color.ChartBase};
    ${p =>
        p.isSelected &&
        css`
            background: ${p.$selectedColor};
        `}
`
