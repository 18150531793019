import { seleniumLocationWithDefault } from 'aos-helpers/src/helpers/SeleniumLocators'
import { Box, BoxProps, NumberValues } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Header, HeaderVariant } from '../header/Header'

export interface PanelWithHeaderProps extends SeleniumProps, BoxProps {
    header?: ReactNode
    variant?: PanelWithHeaderVariant
    headerVariant?: HeaderVariant
    disableScroll?: boolean
    splitterRightPane?: boolean

    headerPaddingLeft?: NumberValues
    headerPaddingRight?: NumberValues
}

export const PanelWithHeader: FCWithChildren<PropsWithChildren<PanelWithHeaderProps>> = props => {
    const {
        variant,
        headerVariant = HeaderVariant.Dialog,
        header,
        disableScroll,
        children,
        splitterRightPane,
        bg = Color.Background,
        headerPaddingLeft = 30,
        headerPaddingRight = 20,
        ...boxProps
    } = props

    return (
        <PanelWithHeaderBox
            variant={variant}
            splitterRightPane={splitterRightPane}
            fullHeight
            column
            data-test-id={seleniumLocationWithDefault(props, 'panel-with-header')}
        >
            <Header
                variant={headerVariant}
                paddingLeft={headerPaddingLeft}
                paddingRight={headerPaddingRight}
            >
                {header}
            </Header>
            <PanelWithHeaderContent flex={1} bg={bg} {...boxProps}>
                {disableScroll ? children : <DarkScrollbar>{children}</DarkScrollbar>}
            </PanelWithHeaderContent>
        </PanelWithHeaderBox>
    )
}

const PanelWithHeaderContent = styled(Box)`
    box-shadow: ${boxShadow.large};
`

const PanelWithHeaderBox = styled(Box)<{
    variant?: PanelWithHeaderVariant
    splitterRightPane?: boolean
}>(({ variant, splitterRightPane }) => {
    const styles: CSSObject = {}

    if (variant) {
        styles.width = `${variant}px`
        styles.flexShrink = 0
    } else {
        styles.width = '100%'
    }

    if (splitterRightPane) {
        styles.marginLeft = '-1px'
    }

    return styles
})

export enum PanelWithHeaderVariant {
    Small = 300,
    Decreased = 328,
    Std = 360,
    Medium = 420,
    Large = 560,
}
