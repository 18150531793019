import { isDefined } from 'aos-helpers/src/helpers/Function'
import { seleniumLocationWithDefault } from 'aos-helpers/src/helpers/SeleniumLocators'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

import { Box } from '../base/Box'
import { SeleniumProps } from '../base/SeleniumProps'
import { Text } from '../base/Text'

interface HeaderContainerProps extends SeleniumProps {
    title: ReactNode
    titleCount?: number | string
    leftButton?: ReactNode
    rightButton?: ReactNode
    rightButtonVisible?: boolean
}

/* eslint-disable react/jsx-no-literals */
export const HeaderContainer: FCWithChildren<HeaderContainerProps> = props => {
    const {
        leftButton,
        children,
        title,
        titleCount,
        rightButton,
        rightButtonVisible = true,
    } = props
    return (
        <Box
            fullHeight
            flex={1}
            row
            seleniumLocation={seleniumLocationWithDefault(props, 'simple-header')}
        >
            {leftButton}
            <Box
                flex='auto'
                overflow='hidden'
                row
                fullHeight
                paddingLeft={leftButton !== undefined ? 8 : 0}
            >
                <Text color={Color.SmartColor}>{title}</Text>
                {isDefined(titleCount) && (
                    <Text size={18} weight='light' color={Color.Black}>
                        &nbsp;{`(${titleCount})`}
                    </Text>
                )}
            </Box>
            {children}
            {rightButton && rightButtonVisible && <Box paddingLeft={20}>{rightButton}</Box>}
        </Box>
    )
}
