import {
    defaultAdditionalNotificationPreferences,
    defaultDisabledTimeNotificationPreferences,
} from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

import {
    GET_USER_DETAILS_SUCCESS,
    SET_ACTIVE_TAB,
    SET_FORM_PRISTINE,
    TOGGLE_USER_PROFILE_MODAL,
    UPDATE_FORM_STATE,
    UserProfileModalAction,
} from './actions'
import { initialUserProfileState, UserProfileModalState, validateUserProfile } from './state'

export function userProfileModalReducer(
    state = initialUserProfileState,
    action: UserProfileModalAction,
): UserProfileModalState {
    switch (action.type) {
        case GET_USER_DETAILS_SUCCESS:
            const formState = {
                notificationPreferences: {
                    timePreference:
                        action.payload.notificationPreferences?.timePreference ??
                        defaultDisabledTimeNotificationPreferences,
                    additional:
                        action.payload.notificationPreferences?.additional ??
                        defaultAdditionalNotificationPreferences,
                },
                localisation: action.payload.localisation,
                siteLocation: action.payload.siteLocation,
            }
            return {
                ...state,
                formState,
                form: state.form.modify(validateUserProfile(formState)),
            }

        case UPDATE_FORM_STATE:
            return {
                ...state,
                formState: action.payload,
                form: state.form.modify(validateUserProfile(action.payload)),
            }

        case TOGGLE_USER_PROFILE_MODAL:
            return { ...state, userProfileModal: action.payload }

        case SET_FORM_PRISTINE:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
            }

        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            }

        default:
            return state
    }
}
