import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { processCategoryIcon } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Icon, IconVariant, svgIconForName } from 'aos-ui/src/components/svg/Icon'
import React, { FC } from 'react'

interface EventIconProps {
    category: string
    iconVariant: IconVariant
}

export const EventIcon: FC<EventIconProps> = props => {
    const { iconVariant } = props
    return (
        <Icon
            iconSize={BlockSize.Std}
            svg={svgIconForName(processCategoryIcon(props.category as string))}
            iconVariant={iconVariant!}
        />
    )
}
