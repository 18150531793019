import {
    initialBimLayersState,
    initialWorldBimLayersState,
} from 'aos-services/src/core/bimLayersData/state'
import {
    initialUserLayersVisibilityState,
    initialWorldUserLayersVisibilityState,
    UserLayerVisibilityState,
} from 'aos-services/src/core/userLayersVisibility/state'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import {
    AosAirport,
    MainAirport,
    toSiteLocationCustomization,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FodDto } from 'aos-services/src/services/fod/types'
import {
    europeCentralPosition,
    getAirportPosition,
    mainAirportLocation,
} from 'aos-services/src/services/map/mapPositions'
import {
    AosMapEvent,
    BaseAosMapEvent,
    newBaseAosMapEvent,
} from 'aos-services/src/services/map/types/AosMapEvent'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import { MapRestriction } from 'aos-services/src/services/map/types/MapRestriction'
import {
    BaseMapTask,
    MapTask,
    newBaseAosMapTask,
} from 'aos-services/src/services/map/types/MapTask'
import {
    BaseMapState,
    MapMode,
    positionForMapVariant,
    SingleMapState,
} from 'aos-services/src/services/mapui/types/BaseMapState'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { flow } from 'lodash'

export interface MapState extends BaseMapState {
    events: AosMapEvent[]
    eventsBBox?: BBox
    pickedEvent: BaseAosMapEvent | null

    tasks: MapTask[]
    tasksBBox?: BBox
    pickedTask: BaseMapTask | null
    pickedFod: FodDto | null

    restrictions: MapRestriction[]

    isMobile: boolean
    layersSidebarOpen: boolean
    atcLayerControl: boolean
    userLayerVisibility: UserLayerVisibilityState
}

export const defaultZoomLevel = 16
export const atcZoomLevel = 18
export const initialBaseMapState: BaseMapState = {
    mode: MapMode.Standard,
    variant: MapVariant.MainAirport,
    siteLocationCustomization: MapSiteLocationCustomization.MainAirport,
    terminalMapState: {
        position: mainAirportLocation,
        userLayersVisibility: initialUserLayersVisibilityState,
        bimLayersState: initialBimLayersState,
        layersVisibility: {
            events: true,
            tasks: true,
            stands: true,
        },
    },
    selectedFloor: 0,
    worldMapState: {
        position: europeCentralPosition,
        userLayersVisibility: initialWorldUserLayersVisibilityState,
        bimLayersState: initialWorldBimLayersState,
        layersVisibility: {
            events: true,
            tasks: true,
            stands: true,
        },
    },
    navigatedElement: null,
    selectedElement: null,
}

export const initialMapState: MapState = {
    events: [],
    tasks: [],
    restrictions: [],
    isMobile: false,
    pickedEvent: null,
    pickedTask: null,
    pickedFod: null,
    layersSidebarOpen: false,
    atcLayerControl: false,
    userLayerVisibility: initialUserLayersVisibilityState,
    ...initialBaseMapState,
}

export const withCustomizedLocation = <T extends BaseMapState>(
    initial: T,
    siteLocation?: AosAirport,
): T => {
    const customization = toSiteLocationCustomization(siteLocation ?? MainAirport)
    return {
        ...initial,
        variant:
            customization === MapSiteLocationCustomization.Other
                ? MapVariant.World
                : MapVariant.MainAirport,
        siteLocationCustomization: customization,
        worldMapState: {
            ...initial.worldMapState,
            position: getAirportPosition(siteLocation ?? MainAirport),
        },
    }
}

export const initialMapStateWithAdjustedZoom = (diff: number) => {
    const zoomSetter = (s: SingleMapState) => ({
        ...s,
        position: { ...s.position, zoom: s.position.zoom + diff },
    })
    return flow([updateTerminalMapState(zoomSetter), updateWorldMapState(zoomSetter)])(
        initialMapState,
    )
}

export const shouldSyncRingRail = <T extends BaseMapState>(state: T): boolean => {
    return state.variant === MapVariant.MainAirport
}

export const aosLocationForMapState = <T extends BaseMapState>(state: T): AosLocation => {
    const position = positionForMapVariant(state)
    return {
        lon: position.lon,
        lat: position.lat,
        locationSource: state.variant,
    }
}

export const updateTerminalMapState =
    (setter: f.Func1<SingleMapState, SingleMapState>) => (state: BaseMapState) => ({
        ...state,
        terminalMapState: setter(state.terminalMapState),
    })

export const updateWorldMapState =
    (setter: f.Func1<SingleMapState, SingleMapState>) => (state: BaseMapState) => ({
        ...state,
        worldMapState: setter(state.worldMapState),
    })

export const updateSingleMapPropForVariant = <T extends BaseMapState>(
    state: T,
    variant: MapVariant,
    setter: f.Func1<SingleMapState, SingleMapState>,
): T => {
    switch (variant) {
        case MapVariant.MainAirport:
            return updateTerminalMapState(setter)(state) as T

        case MapVariant.World:
            return updateWorldMapState(setter)(state) as T

        default:
            return state
    }
}

export const updateMapPositionForVariant = <T extends BaseMapState>(
    state: T,
    variant: MapVariant,
    position: MapPosition,
): T => updateSingleMapPropForVariant(state, variant, t => ({ ...t, position }))

export const setPickedLocationFromFod = (state: MapState, fod?: Partial<FodDto>): MapState => {
    if (fod && fod.location) {
        const mapPosition = getMapPositionForLocation(fod.location)
        const variant = fod.location.locationSource || MapVariant.MainAirport
        const pickedFod = {
            ...fod,
            id: 0,
        } as FodDto

        return updateMapPositionForVariant(
            {
                ...state,
                pickedFod,
                variant,
                siteLocationCustomization:
                    fod.location.locationSource === MapVariant.World
                        ? MapSiteLocationCustomization.Other
                        : MapSiteLocationCustomization.MainAirport,
            },
            variant,
            mapPosition,
        )
    } else {
        return state
    }
}
export const setPickedLocationFromEvent = (
    state: MapState,
    event?: Partial<AosMapEvent>,
): MapState => {
    if (event && event.location) {
        const mapPosition = getMapPositionForLocation(event.location)
        const variant = event.location.locationSource || MapVariant.MainAirport
        const pickedEvent = newBaseAosMapEvent(event.location, event)

        return updateMapPositionForVariant(
            {
                ...state,
                pickedEvent,
                variant,
            },
            variant,
            mapPosition,
        )
    } else {
        return state
    }
}

export const setPickedLocationFromTask = (state: MapState, task?: Partial<Task>): MapState => {
    if (task && task.location) {
        const mapPosition = getMapPositionForLocation(task.location)
        const variant = task.location.locationSource || MapVariant.MainAirport
        const pickedTask = newBaseAosMapTask(task.location, task)

        return updateMapPositionForVariant(
            {
                ...state,
                pickedTask,
                variant,
            },
            variant,
            mapPosition,
        )
    } else {
        return state
    }
}

export const getMapPositionForLocation = (location: AosLocation): MapPosition => ({
    lon: location.lon,
    lat: location.lat,
    zoom: defaultZoomLevel,
})

export const eventsForMapState = (state: MapState) => {
    if (state.mode === MapMode.Standard) {
        return state.events
    } else if (state.pickedEvent) {
        return [state.pickedEvent]
    }
    return []
}

export const tasksForMapState = (state: MapState) => {
    if (state.mode === MapMode.Standard) {
        return state.tasks
    } else if (state.pickedTask) {
        return [state.pickedTask]
    }
    return []
}

export const fodsForMapState = (state: MapState) => {
    if (state.pickedFod) {
        return [state.pickedFod]
    }
    return []
}
