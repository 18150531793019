import Enf from './enf.svg'
import Hel from './hel.svg'
import Hem from './hem.svg'
import Hyv from './hyv.svg'
import Ivl from './ivl.svg'
import Joe from './joe.svg'
import Jyv from './jyv.svg'
import Kaj from './kaj.svg'
import Kao from './kao.svg'
import Kem from './kem.svg'
import Kev from './kev.svg'
import Kok from './kok.svg'
import Ktt from './ktt.svg'
import Kuo from './kuo.svg'
import Lpp from './lpp.svg'
import Mhq from './mhq.svg'
import Mik from './mik.svg'
import Oul from './oul.svg'
import Por from './por.svg'
import Rvn from './rvn.svg'
import Sjy from './sjy.svg'
import Svl from './svl.svg'
import Tku from './tku.svg'
import Tmp from './tmp.svg'
import Uti from './uti.svg'
import Vaa from './vaa.svg'
import Vrk from './vrk.svg'

export default {
    Enf,
    Hel,
    Hem,
    Hyv,
    Ivl,
    Joe,
    Jyv,
    Kaj,
    Kao,
    Kem,
    Kev,
    Kok,
    Ktt,
    Kuo,
    Lpp,
    Mhq,
    Mik,
    Oul,
    Por,
    Rvn,
    Sjy,
    Svl,
    Tku,
    Tmp,
    Uti,
    Vaa,
    Vrk,
}
