import { mapProvider } from 'aos-map-engine/src'
import { MapProvider } from 'aos-map-engine/src/Types'
import { WMTSCapabilities } from 'ol/format'
import GeoJSON from 'ol/format/GeoJSON'
import { Layer, Tile, Vector as VectorLayer } from 'ol/layer'
import Map from 'ol/Map'
import * as proj from 'ol/proj'
import { TileWMS, Vector as VectorSource, WMTS } from 'ol/source'
import { optionsFromCapabilities } from 'ol/source/WMTS'
import * as Styles from 'ol/style'
import View from 'ol/View'
import React, {
    forwardRef,
    PropsWithChildren,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'

import { Provider as BimMapContextProvider } from './BimMapContext'
import { MapContext } from './OpenlayersMapContext'

export interface BimMapRef {
    bim: MapProvider
    map: Map
}

export const BimMap = forwardRef<BimMapRef, PropsWithChildren>(({ children }, ref) => {
    const [bim, setBim] = useState<MapProvider | null>(null)
    const mapContext = useContext(MapContext)
    const [isMounted, setIsMounted] = useState(false)
    const openLayers = useMemo(
        () => ({
            /** Reference to ol.View class */
            View,
            /** Reference to ol.layer.Layer class*/
            Layer,
            /** Reference to ol.layer.Tile class */
            Tile,
            /** Reference to ol.source.WMTS class */
            WMTS,
            /** Reference to ol.source.TileWMS class */
            TileWMS,
            /** Reference to ol.format.WMTSCapabilities class */
            WMTSCapabilities,
            /** Reference to ol.source.Vector */
            VectorSource,
            /** Reference to ol.layer.Vector */
            VectorLayer,
            /** Reference to ol.format.GeoJSON */
            GeoJSON,
            /** Reference to ol.style */
            Styles,
            /** Reference to ol.format.WMTS.optionsFromCapabilities function */
            optionsFromCapabilities,
            /** Reference to ol.proj module */
            proj,
        }),
        [],
    )

    const bcOptions = useMemo(
        () => ({
            openLayers,
            openLayersMap: mapContext.map,
        }),
        [],
    )
    useEffect(() => {
        ;(async () => {
            const bimInstance = await mapProvider
            setBim(bimInstance.initialise())
            await bimInstance.mount({ bcContainer: 'bcContainer', bcOptions })
            setIsMounted(true)
        })()
    }, [])

    useImperativeHandle(ref, () => ({ bim: bim!, map: mapContext.map }), [bim, mapContext.map])

    return (
        <div>
            {bim && (
                <BimMapContextProvider
                    value={{
                        bim,
                        map: mapContext.map,
                    }}
                >
                    {isMounted && children}
                </BimMapContextProvider>
            )}
        </div>
    )
})
