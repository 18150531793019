import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { MultilineText } from 'aos-ui/src/components/text/MultilineText'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

interface StatusDashboardItemWarningProps {
    title: string
}

export const StatusDashboardItemWarning: FC<
    PropsWithChildren<StatusDashboardItemWarningProps>
> = props => (
    <Text className='full-size' column centered color={Color.Grey} size={13} paddingBottom={30}>
        <PlaceholderIcon svg={SvgIcon.NoData} iconVariant={IconVariant.White} />
        <Text color={Color.WidgetPrimary} size={18} paddingTop={16} lineHeight='standard'>
            {props.title}
        </Text>
        <MultilineText
            color={Color.WidgetSecondary}
            className='padding-top--small padding-horizontal--small text--lh-large text--center'
        >
            {props.children}
        </MultilineText>
    </Text>
)

const PlaceholderIcon = styled(Icon)`
    max-width: 100px;
    max-height: 100px;
`
