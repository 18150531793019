import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { Box } from '../../base/Box'
import { Text } from '../../base/Text'
import { Icon, IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'

export const PickLocationInfo = () => (
    <Text size={14} color={Color.Grey2} weight='regular' flex={1} row>
        <Icon iconSize={BlockSize.Std} iconVariant={IconVariant.Grey} svg={SvgIcon.Info} />
        <Box marginLeft={8}>{translate('pick-location-info')}</Box>
    </Text>
)
