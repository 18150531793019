import {
    AosSeverity,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import React, { FC } from 'react'

import { EventIcon } from './EventIcon'
import { eventItemIconVariant } from './eventItemFormatter'

interface IconEventItemProps {
    severity: AosSeverity
    category: string
}

export const IconEventItem: FC<IconEventItemProps> = props => {
    const eventRef = { hasPrivateChannel: false, severity: props.severity }
    return (
        <InfoBar color={severityColorsMapping[props.severity]} padding={8} shadowed>
            <EventIcon
                category={props.category}
                iconVariant={eventItemIconVariant(false, eventRef)}
            />
        </InfoBar>
    )
}
