import { AbstractEntity } from '../../base/types/AbstractEntity'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosCommonEventProps } from '../../events/types/AosCommonEventProps'

export interface AosMapEvent extends BaseAosMapEvent, AosCommonEventProps {}

export interface AosMapEventProperties {
    event: AosMapEvent
}

export interface BaseAosMapEvent extends AbstractEntity {
    location: AosLocation
    severity: AosSeverity
    category: string
}

export const newBaseAosMapEvent = (
    location: AosLocation,
    event: { severity?: AosSeverity; category?: string },
) => ({
    location,
    severity: event.severity || AosSeverity.Maintenance,
    category: event.category || '',
    id: 0,
})
