class LanguageProvider {
    private lang: string = 'en-US'
    private fallbackLang: string = 'en-US'

    public setLanguage = (l: string | undefined) => {
        this.lang = l ? l.replace('_', '-') : this.fallbackLang
    }

    public getCurrent = () => this.lang

    public getFallback = () => this.fallbackLang
}

export const languageProvider = new LanguageProvider()
