import { loadLayersDataAction } from 'aos-services/src/core/layersData/actions'
import { MapDataProps, MapDispatchProps } from 'aos-ui-map/src/components/map/MapProps'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import { connect } from 'react-redux'

import { eventPreviewMapParentAction } from '../../../core/eventPreview/actions'
import { State } from '../../../core/state'

export const EventPreviewMap = connect<MapDataProps, MapDispatchProps, {}>(
    (state: State) => ({
        mapState: state.eventPreview.map,
        layersData: state.layersData,
        userLayerVisibility: state.eventMapUserLayerVisibility,
        bimLayersState: state.bimLayers,
        siteLocation: state.auth.currentUser.siteLocation,
    }),
    {
        loadLayersDataAction,
        mapAction: eventPreviewMapParentAction,
    },
)(MapStack)
