import { Box } from 'aos-ui/src/components/base/Box'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { Text } from 'aos-ui/src/components/base/Text'
import { MenuButton } from 'aos-ui/src/components/buttons/MenuButton'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface MenuItemProps extends SvgProps {
    isMenuOpen: boolean
    isActive: boolean
    isDotVisible?: boolean
    link?: string
    onClick?: f.Action | f.Action1<any>
    label: string
    isDisabled?: boolean
}

export const MenuItem: FC<MenuItemProps> = props => {
    const { label, svg, isActive, onClick, isDotVisible = false, link, isDisabled } = props
    const button = () => (
        <Box overflow='hidden' width={props.isMenuOpen ? 0 : 'auto'} row>
            <MenuButton
                svg={svg}
                isActive={isActive}
                onClick={onClick}
                isDotVisible={isDotVisible}
            />
            <Text
                flex={1}
                row
                onClick={onClick}
                size={16}
                color={isActive ? Color.White : Color.SideMenuInactive}
                weight='light'
                cursor='pointer'
                textNoWrap
            >
                {label}
            </Text>
        </Box>
    )
    const hasLink = link && !isDisabled
    return hasLink ? <Link to={link!}>{button()}</Link> : button()
}
