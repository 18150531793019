export enum Role {
    User = 'USER',
    ApocUser = 'UNIT_APOC',
    AirportServiceUser = 'UNIT_AIRPORT_SERVICES',
    EquipmentMaintenanceUser = 'UNIT_EQUIPMENT_MAINTENANCE',
    ApronServicesUser = 'UNIT_APRON_SERVICES',
    ApronControlUser = 'UNIT_APRON_CONTROL',
    RescueServiceUser = 'UNIT_RESCUE_SERVICE',
    AviationElectricityUser = 'UNIT_AVIATION_ELECTRICITY',
    SecurityServicesUser = 'UNIT_SECURITY_SERVICES',
    AirfieldMaintenanceUser = 'UNIT_AIRFIELD_MAINTENANCE',
    FacilityMaintenanceUser = 'UNIT_FACILITY_MAINTENANCE',
    CorporateIMServicesUser = 'UNIT_CORPORATE_IM_SERVICES',
    CommunicationUser = 'UNIT_COMMUNICATION',
    EventCommander = 'EVENT_COMMANDER',
    CommercialBusinessUnit = 'UNIT_COMMERCIAL_BUSINESS',
}

export const UnitRoles = [
    Role.ApocUser,
    Role.AirportServiceUser,
    Role.EquipmentMaintenanceUser,
    Role.ApronServicesUser,
    Role.ApronControlUser,
    Role.RescueServiceUser,
    Role.AviationElectricityUser,
    Role.SecurityServicesUser,
    Role.AirfieldMaintenanceUser,
    Role.FacilityMaintenanceUser,
    Role.CorporateIMServicesUser,
    Role.CommunicationUser,
]
