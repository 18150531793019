import {
    DiscountCoupon,
    DiscountCouponDTO,
    DiscountCouponGroup,
    DiscountStore,
    DiscountStoreDTO,
} from '../../services/discountModule/types/DiscountCoupon'
import { BaseRestService } from '../base/BaseRestService'

export class DiscountModuleRestService extends BaseRestService {
    //coupons
    createDiscountCoupon(data: DiscountCouponDTO) {
        return this.postJsonAndTransform<DiscountCoupon>(
            '/utilities-service/discount-coupon',
            data,
            BaseRestService.jsonTransformer,
        )
    }
    getDiscountCoupons() {
        return this.getAndTransform<DiscountCouponGroup>(
            '/utilities-service/discount-coupon',
            BaseRestService.jsonTransformer,
        )
    }
    getDiscountCouponById(id: number) {
        return this.getAndTransform<DiscountCoupon>(
            `/utilities-service/discount-coupon/${id}`,
            BaseRestService.jsonTransformer,
        )
    }
    editDiscountCouponById({ id, payload }: { id: number; payload: DiscountCouponDTO }) {
        return this.putJsonAndTransform<DiscountCoupon>(
            `/utilities-service/discount-coupon/${id}`,
            payload,
            BaseRestService.jsonTransformer,
        )
    }
    deleteDiscountCouponById(id: number) {
        return this.deleteReq(`/utilities-service/discount-coupon/${id}`)
    }

    //stores
    getDiscountStores() {
        return this.getAndTransform<DiscountStore[]>(
            '/utilities-service/discount-store',
            BaseRestService.jsonTransformer,
        )
    }
    getDiscountStoreById(id: number) {
        return this.getAndTransform<DiscountStore>(
            `/utilities-service/discount-store/${id}`,
            BaseRestService.jsonTransformer,
        )
    }
    createDiscountStore(data: DiscountStoreDTO) {
        return this.postJsonAndTransform<DiscountStore>(
            '/utilities-service/discount-store',
            data,
            BaseRestService.jsonTransformer,
        )
    }
    editDiscountStoreById({ id, ...payload }: DiscountStore) {
        return this.putJsonAndTransform<DiscountStore>(
            `/utilities-service/discount-store/${id}`,
            payload,
            BaseRestService.jsonTransformer,
        )
    }
    deleteDiscountStoreById(id: number) {
        return this.deleteReq(`/utilities-service/discount-store/${id}`)
    }
}

export const discountModuleRestService = new DiscountModuleRestService()
