import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { DatetimeInput, DatetimeInputProps } from './DatetimeInput'

export const JumpToDate: FCWithChildren<DatetimeInputProps> = props => (
    <DatetimeInput {...props}>
        <Box row cursor='pointer'>
            <Icon
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Large}
                svg={SvgIcon.Datapicker}
            />
            <Text paddingLeft={8} size={14} color={Color.SmartColor}>
                {translate('dashboard.jump-to-date')}
            </Text>
        </Box>
    </DatetimeInput>
)
