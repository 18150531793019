import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

interface ChartLabelProps extends BoxProps {
    items: ChartLabelItem[]
}

interface ChartLabelItem {
    left: ReactNode
    right: ReactNode
    inverted?: boolean
}

export const ChartLabel: FCWithChildren<ChartLabelProps> = ({ items, ...boxProps }) => (
    <Box paddingBottom={2} {...boxProps}>
        {items.map((i, index) => (
            <Box as='span' marginLeft={index !== 0 ? 12 : 0} key={index}>
                <Text
                    as='span'
                    size={12}
                    weight='regular'
                    paddingRight={4}
                    color={i.inverted ? Color.WidgetSecondary : Color.WidgetPrimary}
                >
                    {i.left}
                </Text>
                <Text
                    as='span'
                    size={12}
                    weight='regular'
                    color={i.inverted ? Color.WidgetPrimary : Color.WidgetSecondary}
                >
                    {i.right}
                </Text>
            </Box>
        ))}
    </Box>
)
