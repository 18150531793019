import { isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import {
    AosHandlingAgent,
    AosHandlingAgentType,
    mapHandlingAgentToCompany,
} from '../../../flightInformation/types/AosHandlingAgent'
import { FilterOptionAll, filterOptionsWithAll } from './common'

export type HandlingAgentFilter = FilterOptionAll | AosHandlingAgentType

export const handlingAgentFilterValues: HandlingAgentFilter[] =
    filterOptionsWithAll(AosHandlingAgent)

export const translateHandlingAgent = (value: HandlingAgentFilter): string =>
    isValidEnumEntry(AosHandlingAgent)<AosHandlingAgentType>(value)
        ? mapHandlingAgentToCompany(value)
        : translateEnum(FilterOptionAll, 'dashboard.baggage-delivery.handling-agent-tab')(value)
