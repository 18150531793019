import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosAirport,
    locationName,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    AosUserLocalisation,
    languageName,
} from 'aos-services/src/services/users/types/AosUserLocalisation'
import { AosUserProfileSettings } from 'aos-services/src/services/users/types/AosUserSetting'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { EnumValues } from 'enum-values'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    saveUserProfileAction,
    setActiveTabAction,
    setFormPristineAction,
    toggleUserProfileModalAction,
    updateFormStateAction,
} from '../../core/userProfileModal/actions'
import {
    allAirports,
    languageOptions,
} from '../../core/userProfileModal/helpers/userProfileOptions'
import {
    TabId,
    UserProfileFormState,
    UserProfileModalStateAware,
} from '../../core/userProfileModal/state'
import { NotificationSettingsForm } from './NotificationSettings/NotificationSettingsForm'

export const UserProfilePreferencesModal = () => {
    const dispatch = useDispatch()

    const { userProfileModal, form, formState, activeTab } = useSelector(
        (state: UserProfileModalStateAware) => state.userProfileModal,
    )

    const toggleModal = useCallback(
        (isOpen: boolean) => dispatch(toggleUserProfileModalAction(isOpen)),
        [dispatch],
    )

    const saveProfile = useCallback(
        (profile: AosUserProfileSettings) => dispatch(saveUserProfileAction(profile)),
        [dispatch],
    )

    const setFormPristine = useCallback(
        (isPristine: boolean) => dispatch(setFormPristineAction(isPristine)),
        [dispatch],
    )

    const setActiveTab = useCallback((tab: TabId) => dispatch(setActiveTabAction(tab)), [dispatch])

    const updateFormState = useCallback(
        (fields: UserProfileFormState) => dispatch(updateFormStateAction(fields)),
        [dispatch],
    )

    const closeModal = useCallback(() => toggleModal(false), [toggleModal])

    const submitForm = useCallback(() => {
        if (!form.valid) {
            setFormPristine(false)
        } else {
            saveProfile(formState)
        }
    }, [form.valid, formState, saveProfile, setFormPristine])

    const resetPristine = useCallback(() => {
        if (!form.pristine && form.valid) {
            setFormPristine(true)
        }
    }, [form.pristine, form.valid, setFormPristine])

    const updateForm = useCallback(
        (fields: UserProfileFormState) => {
            updateFormState({ ...formState, ...fields })
        },
        [formState, updateFormState],
    )

    const changeSiteLocation = useCallback(
        (siteLocation: AosAirport) => {
            updateForm({ siteLocation })
            resetPristine()
        },
        [updateForm, resetPristine],
    )

    const changeLocalisation = useCallback(
        (localisation: AosUserLocalisation) => {
            updateForm({ localisation })
            resetPristine()
        },
        [updateForm, resetPristine],
    )

    const header = useMemo(() => <span>{translate('user-profile.settings.title')}</span>, [])

    const footer = useMemo(
        () => (
            <Box row fullWidth>
                <ValidationInfo pristine={form.pristine} valid={form.valid} />
                <FormButton label={translate('user-profile.settings.save')} onClick={submitForm} />
            </Box>
        ),
        [form.pristine, form.valid, submitForm],
    )

    const renderLabelSelectors = useMemo(
        () => (
            <Box margin={40}>
                <Box margin={40}>
                    <LabeledSelect
                        isError={form.error.siteLocation}
                        value={formState.siteLocation}
                        onChange={changeSiteLocation}
                        isRequired
                        keyPrefix='user-profile.site-location'
                        items={allAirports}
                        labelRenderer={locationName}
                    />
                </Box>
                <Box margin={40}>
                    <LabeledSelect
                        isError={form.error.localisation}
                        value={formState.localisation}
                        onChange={changeLocalisation}
                        isRequired
                        keyPrefix='user-profile.localisation'
                        items={languageOptions}
                        labelRenderer={languageName}
                    />
                </Box>
            </Box>
        ),
        [form.error, formState, changeSiteLocation, changeLocalisation],
    )

    const renderNotificationSettings = useMemo(() => {
        const { notificationPreferences } = formState

        if (!notificationPreferences) {
            return (
                <Box flex={1}>
                    <Spinner onLight />
                </Box>
            )
        }

        return (
            <Box fullHeight column>
                <NotificationSettingsForm
                    settings={notificationPreferences}
                    onUpdate={preference =>
                        updateForm({
                            notificationPreferences: { ...notificationPreferences, ...preference },
                        })
                    }
                />
            </Box>
        )
    }, [formState, updateForm])

    const renderTabs = useMemo(
        () => (
            <Box fullHeight column>
                <UnderlineTabs
                    items={EnumValues.getValues<TabId>(TabId)}
                    nameFormatter={translateEnum<TabId>(TabId, 'user-profile.tabs')}
                    value={activeTab}
                    onChange={tab => setActiveTab(tab)}
                    paddingLeft={30}
                />
                <Box relative flex={1}>
                    {activeTab === TabId.UserSettings
                        ? renderLabelSelectors
                        : renderNotificationSettings}
                </Box>
            </Box>
        ),
        [activeTab, renderLabelSelectors, renderNotificationSettings, setActiveTab],
    )

    return (
        <Modal
            id='user-profile-modal'
            isOpen={userProfileModal}
            closeAction={closeModal}
            title={header}
            footer={footer}
            modalKind={ModalKind.Big}
            className='user-modal'
        >
            <Box column flex={1}>
                {renderTabs}
            </Box>
        </Modal>
    )
}
