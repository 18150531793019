import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    MarginBoxProps,
    marginStyleGenerator,
    PaddingBoxProps,
    paddingStyleGenerator,
} from 'aos-ui/src/components/base/Box'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { Button } from 'aos-ui/src/components/buttons/Button'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Color } from '../../../../aos-ui-common/src/styles/Color'
import { Icon, IconVariant } from '../svg/Icon'

export interface IconButtonProps extends ButtonProps, SvgProps, IconButtonStyleProps {
    iconSize?: BlockSize
    iconVariant?: IconVariant
    type?: 'submit' | 'reset' | 'button'
    tabIndex?: number
}

export interface IconButtonStyleProps extends MarginBoxProps, PaddingBoxProps {
    disabled?: boolean
    block?: boolean
    cursor?: string
    iconColor?: Color
}

export const IconButton: FCWithChildren<PropsWithChildren<IconButtonProps>> = props => {
    const {
        onClick,
        disabled,
        id,
        type = 'button',
        tabIndex,
        children,
        seleniumLocation,
        svg,
        iconSize = BlockSize.Std,
        iconVariant,
        block = false,
        ...rest
    } = props
    return (
        <IconButtonWrapper
            id={id}
            type={type}
            onClick={onClick}
            disabled={disabled}
            data-test-id={seleniumLocation}
            tabIndex={tabIndex}
            block={block}
            {...rest}
        >
            <Icon
                color={rest.iconColor}
                block
                svg={svg}
                iconSize={iconSize}
                iconVariant={iconVariant}
            />
            {children}
        </IconButtonWrapper>
    )
}

const IconButtonWrapper = styled(Button)<IconButtonStyleProps>(
    ({ disabled, block, cursor = 'pointer', ...props }) => {
        const margins = marginStyleGenerator(props)
        const paddings = paddingStyleGenerator(props)
        const styles: CSSObject = {
            cursor,
        }

        if (disabled) {
            styles.opacity = 0.3
        }
        if (block) {
            styles.display = 'block'
        }

        return { ...styles, ...margins, ...paddings }
    },
)
