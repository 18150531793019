import { cx } from 'aos-components/src/components/base/ClassNames'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

interface StatusDashboardSidebarProps {
    isOpen: boolean
}

export const DashboardSidebar: FC<PropsWithChildren<StatusDashboardSidebarProps>> = props => (
    <StyledAside
        className={cx(
            props.isOpen ? 'dashboard-sidebar--open' : 'dashboard-sidebar--closed',
            'dashboard-sidebar container container--vertical full-height flex-shrink--0 ',
        )}
    >
        <DarkScrollbar>{props.children}</DarkScrollbar>
    </StyledAside>
)

const StyledAside = styled.aside`
    color: ${Color.WidgetBackground};
`
