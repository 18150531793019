import { emptyText, optionalText } from 'aos-helpers/src/helpers/Text'
import { formatFromDateTime, formatToDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { eventSeverityTitle } from 'aos-services/src/services/common/types/AosSeverity'
import {
    eventProcessTitle,
    eventScenarioTitle,
} from 'aos-services/src/services/events/aosEventFormatter'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { isAosEvent } from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { ProcessCategory } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { ByAtText } from 'aos-ui/src/components/text/ByAtText'
import { Link } from 'aos-ui/src/components/text/Link'
import { TextWithLinks } from 'aos-ui/src/components/text/TextWithLinks'
import React, { PureComponent } from 'react'

import { EventVisibility } from './section/EventVisibility'

interface EventDetailsProps extends MarginBoxProps {
    event: AosEvent | AosFeedIn
    variant?: ThemeVariant
    size?: LabeledTextElementSize
    withVisibilityField: boolean
    inviteAction?(): void
    selectLocation?(): void
}

export class EventDetails extends PureComponent<EventDetailsProps> {
    public render() {
        const {
            event,
            variant,
            size,
            inviteAction,
            withVisibilityField,
            selectLocation,
            ...boxProps
        } = this.props
        const atOrBy = event.createdAt || event.createdBy
        const modifiedAtOrBy = event.modifiedAt || event.modifiedBy
        const items = [
            ...(withVisibilityField ? [['event-details.visibility', this.eventVisibility()]] : []),
            ['event-details.status', eventSeverityTitle(event.severity)],
            ['event-details.process', eventProcessTitle(event.process)],
            [
                'event-details.category',
                eventScenarioTitle({ name: event.category } as ProcessCategory),
            ],
            ['event-details.title', optionalText(event.title)],
            ['event-details.description', this.eventDescription(event.description)],
            ['event-details.datetime', this.fromToText()],
            ['event-details.location', this.locationField()],
            [
                'event-details.reporter',
                atOrBy && <ByAtText key='1' at={event.createdAt} by={event.createdBy} />,
            ],
            [
                'event-details.modifier',
                modifiedAtOrBy && <ByAtText key='1' at={event.modifiedAt} by={event.modifiedBy} />,
            ],
        ] as PropsListEntry[]

        return <PropsList items={items} variant={variant} size={size} {...boxProps} />
    }

    private eventVisibility = () => {
        const { event, withVisibilityField } = this.props
        return withVisibilityField && isAosEvent(event) ? (
            <EventVisibility
                eventVisibility={event.visibility}
                visibilityGroup={event.privateGroup}
                inviteAction={this.props.inviteAction}
            />
        ) : null
    }

    private eventDescription = (description?: string) => (
        <TextWithLinks text={optionalText(description)} />
    )

    private fromToText = () =>
        this.props.event.startTime ? (
            <Box>
                <Box>{formatFromDateTime(this.props.event.startTime)}</Box>
                {this.props.event.endTime && (
                    <Box>{formatToDateTime(this.props.event.endTime)}</Box>
                )}
            </Box>
        ) : (
            emptyText()
        )

    private locationField = () => {
        const { hasLocation } = this.props.event
        const locationAction = hasLocation ? this.props.selectLocation : undefined
        return hasLocation ? (
            <Link onClick={locationAction} as='span'>
                {translate('event-details.select-location')}
            </Link>
        ) : (
            emptyText()
        )
    }
}
