import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import {
    CleanableInput,
    CleanableInputProps,
} from 'aos-ui/src/components/form/input/CleanableInput'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { useDebouncedValueUpdate } from 'aos-ui-common/src/components/hooks/useDebouncedValueUpdate'
import React, { FC, useEffect } from 'react'

interface QuickSearchProps extends CleanableInputProps {
    value: string
    onChange(query: string): void
}

export const QuickSearch: FC<QuickSearchProps> = ({ value: outerValue, onChange, ...rest }) => {
    const [value, updateValue, instantUpdate] = useDebouncedValueUpdate(outerValue, onChange)

    useEffect(() => {
        // When the user clears input we want instant update
        if (value === '') {
            instantUpdate(value)
        }
    }, [value])

    return (
        <CleanableInput
            type='search'
            variant={ThemeVariant.Black}
            placeholder={translate('checklist-manager.search.placeholder')}
            value={value}
            leftSvg={SvgIcon.SearchWhite}
            onChangeText={e => updateValue(e || '')}
            {...rest}
        />
    )
}
