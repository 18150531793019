import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FC } from 'react'
import styled from 'styled-components'

interface LogoProps {
    location: string
}

const Logo: FC<LogoProps> = ({ location }) => (
    <LogoSvg width='100%' height='50' xmlns='http://www.w3.org/2000/svg'>
        <defs>
            <clipPath id='clip0_227_4683'>
                <rect width='144.828' height='40' fill='white' transform='translate(154.586)' />
            </clipPath>
            <clipPath id='clip1_227_4683'>
                <rect width='40' height='40.0508' fill='white' transform='translate(4 5.00635)' />
            </clipPath>
        </defs>
        <g clipPath='url(#clip1_227_4683)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M37.3627 44.0996C27.2712 40.8054 20.1207 36.884 15.639 33.0722C12.7768 31.6342 10.6378 29.9641 9.24994 28.187C9.23466 28.1666 9.21938 28.1462 9.20155 28.1258C9.08442 27.9728 8.96983 27.8224 8.86288 27.6669C6.375 24.2019 6.14327 20.0561 6.14327 20.0561C5.99558 18.3758 5.16034 16.6726 4.01953 16.4636L4.08065 16.3437C4.24107 16.2953 5.31567 16.41 5.31567 16.41C5.31567 16.41 6.62964 14.8624 8.4147 15.2321C9.56315 15.4717 10.2507 16.0148 10.6607 16.6038C17.6583 10.1327 31.4142 6.74926 35.486 5.86452C35.6948 5.82118 35.7967 6.10419 35.6133 6.20873C-16.8181 36.0834 39.7946 37.631 42.8809 37.6948C42.8936 37.6948 43.0082 37.6999 43.0082 37.6999L43.0439 37.733L43.0388 37.7305C32.6136 37.9854 24.4599 36.6112 18.5827 34.3649C24.8979 39.3801 35.9851 43.559 37.2278 44.0205C37.238 44.0231 37.3398 44.0639 37.3398 44.0639L37.3627 44.0996ZM21.2386 16.7591C28.6839 15.959 37.2051 17.0372 40.3523 17.5089C40.5534 17.5395 40.5432 17.8302 40.3421 17.8531C22.2419 19.9592 15.3487 23.3732 14.3581 27.0524C14.1977 23.9188 17.037 20.3235 21.2386 16.7591Z'
                fill='white'
            />
        </g>
        <path
            d='M113.491 36.168C111.699 36.168 109.982 35.9333 108.339 35.464C106.697 34.9733 105.374 34.344 104.371 33.576L106.131 29.672C107.091 30.3547 108.222 30.92 109.523 31.368C110.846 31.7947 112.179 32.008 113.523 32.008C114.548 32.008 115.369 31.912 115.987 31.72C116.628 31.5067 117.097 31.2187 117.395 30.856C117.694 30.4933 117.844 30.0773 117.844 29.608C117.844 29.0107 117.609 28.5413 117.139 28.2C116.67 27.8373 116.051 27.5493 115.283 27.336C114.515 27.1013 113.662 26.888 112.723 26.696C111.806 26.4827 110.878 26.2267 109.939 25.928C109.022 25.6293 108.18 25.2453 107.411 24.776C106.643 24.3067 106.014 23.688 105.523 22.92C105.054 22.152 104.819 21.1707 104.819 19.976C104.819 18.696 105.161 17.5333 105.843 16.488C106.547 15.4213 107.593 14.5787 108.979 13.96C110.388 13.32 112.147 13 114.259 13C115.667 13 117.054 13.1707 118.419 13.512C119.785 13.832 120.99 14.3227 122.035 14.984L120.436 18.92C119.39 18.3227 118.345 17.8853 117.299 17.608C116.254 17.3093 115.23 17.16 114.227 17.16C113.225 17.16 112.403 17.2773 111.764 17.512C111.124 17.7467 110.665 18.056 110.387 18.44C110.11 18.8027 109.972 19.2293 109.972 19.72C109.972 20.296 110.206 20.7653 110.675 21.128C111.145 21.4693 111.763 21.7467 112.531 21.96C113.299 22.1733 114.142 22.3867 115.059 22.6C115.998 22.8133 116.926 23.0587 117.844 23.336C118.782 23.6133 119.636 23.9867 120.404 24.456C121.172 24.9253 121.79 25.544 122.259 26.312C122.75 27.08 122.995 28.0507 122.995 29.224C122.995 30.4827 122.644 31.6347 121.939 32.68C121.235 33.7253 120.179 34.568 118.771 35.208C117.385 35.848 115.625 36.168 113.491 36.168Z'
            fill='white'
        />
        <path
            d='M90.0115 36.168C88.2408 36.168 86.5982 35.88 85.0835 35.304C83.5902 34.728 82.2888 33.9173 81.1795 32.872C80.0915 31.8267 79.2382 30.6 78.6195 29.192C78.0222 27.784 77.7235 26.248 77.7235 24.584C77.7235 22.92 78.0222 21.384 78.6195 19.976C79.2382 18.568 80.1022 17.3413 81.2115 16.296C82.3208 15.2507 83.6222 14.44 85.1155 13.864C86.6088 13.288 88.2302 13 89.9795 13C91.7502 13 93.3715 13.288 94.8435 13.864C96.3368 14.44 97.6275 15.2507 98.7155 16.296C99.8248 17.3413 100.689 18.568 101.308 19.976C101.926 21.3627 102.236 22.8987 102.236 24.584C102.236 26.248 101.926 27.7947 101.308 29.224C100.689 30.632 99.8248 31.8587 98.7155 32.904C97.6275 33.928 96.3368 34.728 94.8435 35.304C93.3715 35.88 91.7608 36.168 90.0115 36.168ZM89.9795 31.752C90.9822 31.752 91.8995 31.5813 92.7315 31.24C93.5848 30.8987 94.3315 30.408 94.9715 29.768C95.6115 29.128 96.1022 28.3707 96.4435 27.496C96.8062 26.6213 96.9875 25.6507 96.9875 24.584C96.9875 23.5173 96.8062 22.5467 96.4435 21.672C96.1022 20.7973 95.6115 20.04 94.9715 19.4C94.3528 18.76 93.6168 18.2693 92.7635 17.928C91.9102 17.5867 90.9822 17.416 89.9795 17.416C88.9768 17.416 88.0488 17.5867 87.1955 17.928C86.3635 18.2693 85.6275 18.76 84.9875 19.4C84.3475 20.04 83.8462 20.7973 83.4835 21.672C83.1422 22.5467 82.9715 23.5173 82.9715 24.584C82.9715 25.6293 83.1422 26.6 83.4835 27.496C83.8462 28.3707 84.3368 29.128 84.9555 29.768C85.5955 30.408 86.3422 30.8987 87.1955 31.24C88.0488 31.5813 88.9768 31.752 89.9795 31.752Z'
            fill='white'
        />
        <path
            d='M52 35.784L61.984 13.384H67.104L77.12 35.784H71.68L63.488 16.008H65.536L57.312 35.784H52ZM56.992 30.984L58.368 27.048H69.888L71.296 30.984H56.992Z'
            fill='white'
        />
        <path d='M139 7.00891V43.0089' stroke='#5A5A5A' strokeWidth='2' strokeLinecap='square' />
        <text
            dominantBaseline='alphabetic'
            x='156'
            fontSize='18'
            y='33'
            fill='#fff'
            fontWeight='200'
        >
            {`${location} ${translate('airport.name')}`}
        </text>
    </LogoSvg>
)

const LogoSvg = styled.svg`
    overflow: visible;
`

export default Logo
