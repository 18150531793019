import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateAreaNumberShortLabel } from 'aos-services/src/services/restrictions/types/RestrictionArea'
import { RestrictionsGroupedStats } from 'aos-services/src/services/restrictions/types/RestrictionsGroupedStats'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface RestrictionsSummaryProps {
    stats: RestrictionsGroupedStats[]
}

export const RestrictionsSummary: FC<RestrictionsSummaryProps> = ({ stats }) => {
    if (stats.length) {
        return (
            <Box wrap row alignItems='baseline'>
                <Text size={12} weight='light' color={Color.WidgetPrimary}>
                    {translate('dashboard.restrictions.in')}
                </Text>
                {stats.map((stat, index, stats) => (
                    <Box key={index} alignItems='baseline' row>
                        <Text
                            size={12}
                            weight='light'
                            color={Color.WidgetPrimary}
                            paddingHorizontal={4}
                        >
                            {`${translateAreaNumberShortLabel(stat.areaType, stat.areaNumber)}:`}
                        </Text>
                        <Text size={12} weight='medium' color={Color.WidgetPrimary}>
                            {stat.active}
                        </Text>
                        {index < stats.length - 1 && ', '}
                    </Box>
                ))}
            </Box>
        )
    } else {
        return (
            <Text size={12} weight='light' color={Color.WidgetPrimary}>
                {translate('dashboard.restrictions.empty')}
            </Text>
        )
    }
}
