import { isNil } from 'lodash'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Color } from '../../../../../aos-ui-common/src/styles/Color'
import { Box } from '../../base/Box'
import { Text } from '../../base/Text'
import { IconButton } from '../../buttons/IconButton'
import { SvgIcon } from '../../svg/SvgIcon'
import { Input } from '../input/Input'
import { TimePickerProps } from './utils'

export const TimeInput = forwardRef<HTMLInputElement, TimePickerProps>(
    ({ value, label, onChange, min = 0, max, step = 1 }) => {
        const increase = () => !isNil(value) && checkMinMax(value + step)
        const decrease = () => value && checkMinMax(value - step)

        const checkMinMax = (value: number) => {
            if (max && value > max) {
                onChange(max)
            } else if (value < min) {
                onChange(min)
            } else {
                onChange(value)
            }
        }

        return (
            <Box column rowGap={2}>
                <Text color={Color.TimelineBackground} textTransform='uppercase' size={12}>
                    {label}
                </Text>
                <TimeBox row>
                    <NoBorderInput
                        value={value}
                        onChange={e => checkMinMax(e.target.value ? parseInt(e.target.value) : 0)}
                    />
                    <ButtonBox paddingHorizontal={8} column height='80%' justify='space-around'>
                        <RotatedIconButton
                            disabled={value === max}
                            onClick={increase}
                            svg={SvgIcon.Chevron}
                        />
                        <IconButton
                            disabled={value === min}
                            onClick={decrease}
                            svg={SvgIcon.Chevron}
                        />
                    </ButtonBox>
                </TimeBox>
            </Box>
        )
    },
)

const NoBorderInput = styled(Input)`
    border: none;
    font-size: 52px;
    text-align: center;
    margin: 0 2px;
    color: ${Color.TimelineBackground};
    height: 95%;
    padding: 2px;
`

const RotatedIconButton = styled(IconButton)`
    transform: rotate(180deg);
`

const TimeBox = styled(Box)`
    width: 148px;
    height: 90px;
    border-radius: 4px;
    background: ${Color.White};
    box-shadow: 0 0 5px 0 ${Color.TransparentGrey} inset;
`

const ButtonBox = styled(Box)`
    border-left: 1px solid ${Color.TransparentGrey};
`
