import { EventFormPayload } from 'aos-services/src/services/events/input/EventPayload'

import { EventAutomation } from '../Types'

export class AosProvider implements EventAutomation {
    runAutomation(
        event: EventFormPayload,
        changeForm: (form: Partial<EventFormPayload>) => void,
        isEditing: boolean,
    ) {
        if (event.atcAlert && !event.category && event.process === 'HEL_OTHERS') {
            changeForm({
                category: {
                    name: 'SAFETY_SECURITY',
                    icon: 'SafetySecurity',
                },
            })
        } else if (event.atcAlert && !isEditing && !event.process) {
            changeForm({ category: undefined })
        }
    }

    get hookDependencies(): (keyof EventFormPayload)[] {
        return ['atcAlert', 'process']
    }
}
