import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { BaseChartComponent } from 'aos-components/src/components/chart/base/BaseChartComponent'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { waitingTimeConfig } from 'aos-services/src/core/statusDashboardData/pax/waitingTimeConfig'
import { PaxEntry } from 'aos-services/src/services/pax/types/PaxEntry'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisScale } from 'aos-ui-common/src/components/chart/types/AxisScale'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const minBarHeight = 1.5
const dimensionConfig = {
    barWidth: 15,
    securityLineWidth: 20 + 6,
}

class PaxBarSeriesComponent extends BaseChartComponent<PaxBarSeriesProps> {
    public render() {
        const { width, lineWidth } = this.calculateWidth()
        const { data } = this.props
        const largestLineCount = Math.max(...data.map(d => d.lineCount || 1))
        const largestWaitingTime = Math.max(...data.map(d => d.waitingTime || 1))
        const poleHeight = largestWaitingTime / largestLineCount
        return (
            <g className={cxp(this.props, 'series')}>
                {this.props.data.map(this.renderSeries(width, lineWidth, poleHeight))}
            </g>
        )
    }

    private calculateWidth = () => {
        const centerPoint = this.props.data[Math.round(this.props.data.length / 2)] as PaxEntry
        const scaledPoint = this.props.scales.xScale(centerPoint.time) ?? 0
        const scaledPointBar =
            this.props.scales.xScale(
                centerPoint.time.clone().subtract(dimensionConfig.barWidth, 'minutes'),
            ) ?? 0
        const scaledPointSecurityLine =
            this.props.scales.xScale(
                centerPoint.time.clone().subtract(dimensionConfig.securityLineWidth, 'minutes'),
            ) ?? 0
        const width = scaledPoint - scaledPointBar

        const lineWidth = scaledPoint - scaledPointSecurityLine

        return { width, lineWidth }
    }

    private getColor = (waitingTime: number) => {
        if (waitingTime > waitingTimeConfig.LONG.value) {
            return Color.Red
        } else if (
            waitingTime >= waitingTimeConfig.MEDIUM.value &&
            waitingTime <= waitingTimeConfig.LONG.value
        ) {
            return Color.Yellow
        }
        return Color.Green
    }
    private renderSeries =
        (width: number, lineWidth: number, poleHeight: number) =>
        (data: PaxEntry, index: number) => {
            return (
                <g key={index}>
                    {this.renderSectionedBar(
                        Color.Grey3,
                        data.time,
                        index,
                        data.lineCount,
                        lineWidth,
                        poleHeight,
                    )}
                    {this.renderBar(
                        this.getColor(data.waitingTime),
                        data.time,
                        index,
                        data.waitingTime,
                        data.waitingTime,
                        width,
                    )}
                    {this.renderTooltip(data, index)}
                </g>
            )
        }

    private renderTooltip(data: PaxEntry, index: number) {
        const { xScale } = this.props.scales
        return (
            <Tooltip body={this.renderTooltipBody(data)} offset={[0, 20]} placement='top' withArrow>
                <rect
                    key={index}
                    x={xScale(data.time)}
                    y={0}
                    height='100%'
                    width={40}
                    fill='transparent'
                />
            </Tooltip>
        )
    }

    private renderTooltipBody(data: PaxEntry) {
        return (
            <Box paddingHorizontal={8}>
                <Box marginVertical={8} row>
                    <TooltipIcon color={this.getColor(data.waitingTime)} />
                    {translate('dashboard.pax.tooltip.waiting-time', { value: data.waitingTime })}
                </Box>
                <Box marginVertical={8} style={{ color: Color.White }}>
                    <span className='tooltip-overlay__color margin-right--small' />
                    {translate('dashboard.pax.tooltip.pax', { value: data.pax })}
                </Box>
                <Box marginVertical={8} style={{ color: Color.White }}>
                    <span className='tooltip-overlay__color margin-right--small' />
                    {translate('dashboard.pax.tooltip.open-security-lines', {
                        value: data.lineCount,
                    })}
                </Box>
            </Box>
        )
    }

    private renderBar = (
        color: string,
        date: DateTime,
        index: number,
        top: number,
        height: number,
        width: number,
    ) => {
        const {
            size,
            margins,
            scales: { xScale, yScale },
        } = this.props

        const maxYPos = size.height + margins.top
        const finalTop = Math.min(yScale(top)!, maxYPos - minBarHeight)
        const finalHeight = Math.max(maxYPos - yScale(height)!, minBarHeight)
        const x = xScale(date.add({ minute: 8 }))
        return (
            x && (
                <rect
                    key={`${color}${index}${finalTop}`}
                    x={x}
                    y={finalTop}
                    height={finalHeight}
                    width={width}
                    fill={color}
                />
            )
        )
    }

    private renderSectionedBar = (
        color: string,
        date: DateTime,
        index: number,
        count: number,
        width: number,
        poleHeight: number,
    ) => {
        const { xScale, yScale } = this.props.scales
        const { size, margins } = this.props
        const ticks = this.props.domain.ticks
        const step = (ticks[1] - ticks[0]) / 20
        const height = poleHeight - step

        return Array.from({ length: count }, (_, i) => {
            const maxYPos = size.height + margins.top
            const finalHeight = Math.max(maxYPos - yScale(height)!, minBarHeight)
            const x = xScale(date.clone().add({ minute: 2.5 }))

            return (
                x && (
                    <rect
                        key={`${color}${index}${i}`}
                        x={x}
                        y={this.props.scales.yScale(height + i * (height + step))}
                        height={finalHeight}
                        width={width}
                        fill={color}
                    />
                )
            )
        })
    }
}

interface PaxBarSeriesProps extends ClassNameProps {
    data: Partial<PaxEntry>[]
    scales: {
        xScale: AxisScale<moment.Moment>
        yScale: AxisScale<number>
    }
    domain: Domain<number>
}

const TooltipIcon = styled.span`
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 8px;
    background-color: ${props => props.color};
`

export const PaxBarSeries = withChartContext(PaxBarSeriesComponent)
