import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSelector } from 'aos-services/src/core/auth/state'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import {
    taskCategories,
    TaskCategory,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { Searchable } from 'aos-ui/src/components/search/Searchable'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { showActionModalAction } from '../../../core/actionModal/actions'
import { getExistingTags } from '../../../core/checklistManager/selectors'
import { ChecklistManagerStateAware } from '../../../core/checklistManager/state'
import {
    deleteTaskChecklistTemplateAction,
    exportSingleTaskChecklistTemplateAction,
    openEditTaskChecklistTemplateModalAction,
    selectTaskChecklistTemplateAction,
} from '../../../core/checklistManager/task/actions'
import { State } from '../../../core/state'
import { ChecklistTemplateItem } from '../checklist/partials/ChecklistTemplateItem'
import { TemplatesList } from '../TemplatesList'

export const TaskChecklistTemplateList = () => {
    const dispatch = useDispatch()
    const [tagFilter, setTagFilter] = useState<string | undefined>()
    const [categoryFilter, setCategoryFilter] = useState<TaskCategory[]>(taskCategories)
    const {
        taskChecklistManager: { taskChecklistTemplates },
        exportingChecklistTemplates,
        selectedItemFromTemplate,
    } = useSelector((state: State) => state.checklistManager)
    const currentUser = useSelector(currentUserSelector)
    const existingTags = useSelector((state: ChecklistManagerStateAware) =>
        getExistingTags(state.checklistManager.taskChecklistManager.taskChecklistTemplates),
    )

    useEffect(() => {
        if (tagFilter && !existingTags.includes(tagFilter)) {
            setTagFilter(undefined)
        }
    }, [tagFilter, existingTags])

    const exportSingleChecklistsAction = (id: number) => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-checklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportSingleTaskChecklistTemplateAction(id)),
            }),
        )
    }

    const onDeleteChecklistTemplate = (checklistTemplate: AosSimpleTemplate) => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.delete-checklist-template'),
                svg: SvgIcon.DeleteRound,
                onClickAction: () =>
                    dispatch(deleteTaskChecklistTemplateAction(checklistTemplate.id)),
                okLabel: translate('checklist-manager.delete-checklist-template.okLabel'),
            }),
        )
    }

    const filteredTemplates = useMemo(
        () =>
            taskChecklistTemplates
                .filter(q => categoryFilter.includes(q.taskCategory))
                .sort((a, b) => a.name.localeCompare(b.name)),
        [taskChecklistTemplates, categoryFilter],
    )

    return (
        <Searchable list={filteredTemplates} extractText={item => item.name}>
            {(filteredChecklists, query, setQuery) => (
                <>
                    <Box
                        row
                        className='selectable-item '
                        paddingVertical={20}
                        paddingHorizontal={30}
                        columnGap={20}
                        style={{ borderBottom: `1px solid ${Color.ChartBase}` }}
                    >
                        <Box flex={2}>
                            <CleanableInput
                                type='text'
                                variant={ThemeVariant.Black}
                                placeholder={translate('checklist-manager.search.placeholder')}
                                value={query}
                                leftSvg={SvgIcon.SearchWhite}
                                onChangeText={e => setQuery(e || '')}
                            />
                        </Box>
                        <Box flex={1}>
                            <CheckboxDropdown
                                allContent={translate('checklist-manager.filter-by-category.all')}
                                partialContent={(i: TaskCategory[]) =>
                                    translate('filter.placeholder.selected', {
                                        count: i.length,
                                    })
                                }
                                value={categoryFilter}
                                items={taskCategories}
                                valueRenderer={translateTaskCategory}
                                onChange={(category: TaskCategory[]) => {
                                    setCategoryFilter(category)
                                }}
                                width={DropdownWidth.Std}
                                variant={DropdownVariant.Black}
                            />
                        </Box>
                        <Box flex={1}>
                            <Dropdown
                                placeholder={translate(
                                    'checklist-manager.filter-by-tag.placeholder',
                                )}
                                value={tagFilter}
                                items={existingTags}
                                valueRenderer={tag => tag}
                                onChange={selectedTag => {
                                    setTagFilter(selectedTag)
                                }}
                                clearAction={() => {
                                    setTagFilter(undefined)
                                }}
                                width={DropdownWidth.Std}
                                variant={DropdownVariant.Black}
                            />
                        </Box>
                    </Box>
                    <TemplatesList
                        canEdit={currentUser.canEditTasks() || currentUser.isApoc()}
                        Component={ChecklistTemplateItem(false)}
                        onSelect={value =>
                            dispatch(
                                selectTaskChecklistTemplateAction(
                                    value as AosTaskChecklistTemplate,
                                ),
                            )
                        }
                        checklistTemplates={filteredChecklists.filter(
                            checklist => !tagFilter || checklist.tag === tagFilter,
                        )}
                        selectedChecklistTemplate={
                            selectedItemFromTemplate as AosTaskChecklistTemplate | undefined
                        }
                        onDelete={onDeleteChecklistTemplate}
                        onEdit={value =>
                            dispatch(
                                openEditTaskChecklistTemplateModalAction(
                                    value as AosTaskChecklistTemplate,
                                ),
                            )
                        }
                        onExport={exportSingleChecklistsAction}
                        exportingChecklistTemplates={exportingChecklistTemplates}
                        currentUser={currentUser}
                    />
                </>
            )}
        </Searchable>
    )
}
