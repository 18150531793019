import { EventFormPayload } from 'aos-services/src/services/events/input/EventPayload'
import { useEffect, useMemo } from 'react'

import { AutomationFactory } from './AutomationFactory'

type AutomationHook = {
    event: EventFormPayload
    changeForm: any
    isEditing: boolean
}

export const useAutomation = ({ event, changeForm, isEditing }: AutomationHook) => {
    const automation = useMemo(() => {
        const factory = new AutomationFactory()
        return factory.getAutomation()
    }, [])

    const dependencies = automation.hookDependencies.map(dep => event[dep])

    useEffect(() => {
        automation.runAutomation(event, changeForm, isEditing)
    }, dependencies)
}
