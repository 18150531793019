import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Feed } from 'aos-services/src/services/newsFeed/types/Feed'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Link } from 'aos-ui/src/components/text/Link'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface FeedItemBigWidgetProps {
    feed: Feed
}

export const FeedItemBigWidget: FC<FeedItemBigWidgetProps> = ({ feed }) => (
    <Box flex={1} paddingTop={24} paddingLeft={20} paddingRight={6}>
        <Text color={Color.White} size={14}>
            {feed.title}
        </Text>
        <Box paddingTop={12}>
            <Link as='a' href={feed.link} target='_blank' rel='noreferrer' size={12}>
                {translate('go-to.article')}
            </Link>
        </Box>
        <Box paddingTop={16}>{feed.pubDate && <HourClock time={feed.pubDate} showFullDate />}</Box>
    </Box>
)
