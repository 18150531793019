import { translate, translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import { kebabCase } from 'lodash'

import { ProcessCategory } from '../flightInformation/types/AosAirport'
import { AosEventVisibility } from './types/AosEventVisibility'

export const eventVisibilityTitle = (e: AosEventVisibility) =>
    e ? translate('event-visibility.' + kebabCase(e)) : '-'

export const eventProcessTitle = (e: string) =>
    e ? translate('event-process.' + kebabCase(e)) : '-'

export const eventScenarioTitle = (e: ProcessCategory) =>
    e.name ? translate('event-scenario.' + kebabCase(e.name)) : '-'

export const eventScenarioTitleTemplate = (e: string) =>
    e ? translate(`event-scenario.${kebabCase(e)}.title-template`) : '-'

export const channelTitle = (t: string) => (t ? translateParts('feed-out', t) : '-')
