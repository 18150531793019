import { chain, identity, toPairs } from 'lodash'

export type CheckboxFilter<T> = [T, boolean]
export type CheckboxFilters<T> = Record<keyof T, CheckboxFilter<string>[]>
export type NormalizedFilters<T> = Record<keyof T, string[]>

export type FilterCollection<T> = [keyof T, string][]

export const itemsToCheckboxFilter = <T>(items: T[], all: T[]): CheckboxFilter<T>[] =>
    all.map(name => [name, items.includes(name)])

export const checkboxFiltersToItems = <T>(filters: CheckboxFilter<T>[]): T[] =>
    filters.filter(([_name, checked]) => checked).map(([name]) => name)

export const toggleCheckboxFilter = <T>(
    checkboxes: CheckboxFilter<T>[],
    key: T,
): CheckboxFilter<T>[] => checkboxes.map(([k, selected]) => [k, k === key ? !selected : selected])

export const filtersToFilterCollection = <T extends object>(f: T): FilterCollection<T> =>
    chain(toPairs(f))
        .flatMap(([type, values]: [keyof T, string[]]) =>
            values.map(item => [type, item] as [keyof T, string]),
        )
        .value() as unknown as FilterCollection<T>

const normalize = (s: string | number | undefined) =>
    ((typeof s === 'number' ? s.toString() : s) || '').toLowerCase().replace(/\s+/g, '')

export const includesNormalizes = (a: string | number | undefined, b: string | undefined) =>
    normalize(a).includes(normalize(b))

export const searchStringsContain = (
    sourceStrings: (string | number | undefined)[],
    query?: string,
): boolean => {
    if (query) {
        const enteredText = query.trim().toLowerCase()
        return sourceStrings.filter(identity).some(str => includesNormalizes(str, enteredText))
    }
    return true
}
