import { optionalDateTimeToISOString } from 'aos-helpers/src/helpers/Time'

import { AosEventInputDto } from '../../dataaccess/events/types/AosEventDto'
import {
    AosCommonEventDto,
    AtcFieldsDto,
    AtcFieldsDtoSchema,
} from '../../dataaccess/feeds/types/AosCommonEventDto'
import { ProcessCategory } from '../flightInformation/types/AosAirport'
import { AtcFields } from './input/AtcFields'
import { EventPayload } from './input/EventPayload'

export async function aosFeedInPropsToAosBaseEventDto(
    event: EventPayload,
): Promise<AosCommonEventDto> {
    return {
        startTime: optionalDateTimeToISOString(event.startTime),
        endTime: optionalDateTimeToISOString(event.endTime),
        title: event.title!,
        category: event.category ? (event.category as ProcessCategory)?.name : event.category!,
        process: event.process!,
        severity: event.severity!,
        description: event.description,
        attachments: event.attachments,
        location: event.location,
        atcAlert: event.atcAlert,
        atcFields: await aosAtcFieldsToAtcFieldsDto(event.atcFields),
        aosUserGroupId: event.aosUserGroup?.id,
        exercise: event.exercise,
        hardAlert: event.hardAlert,
    }
}

export async function aosEventToAosBaseEventDto(event: EventPayload): Promise<AosEventInputDto> {
    return {
        ...(await aosFeedInPropsToAosBaseEventDto(event)),
        visibility: event.visibility!,
    }
}

export const aosAtcFieldsToAtcFieldsDto = async (
    atcFields: AtcFields | undefined,
): Promise<AtcFieldsDto> => {
    if (atcFields === undefined) {
        return {} as AtcFieldsDto
    }
    const result = await AtcFieldsDtoSchema.safeParseAsync(atcFields)
    if (result.success) {
        return result.data
    } else {
        throw new Error('Invalid AtcFieldsDto')
    }
}
