import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { ZIndex } from 'aos-ui/src/components/base/Theme'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export interface SplittedPanelProps extends BoxProps {
    separated?: boolean
    separatedHeader?: boolean
}

export const SplittedPanel: FCWithChildren<PropsWithChildren<SplittedPanelProps>> = ({
    separated,
    separatedHeader,
    children,
    ...rest
}) => (
    <SplittedPanelBox
        row
        fullSize
        separated={separated}
        separatedHeader={separatedHeader}
        {...rest}
    >
        {children}
    </SplittedPanelBox>
)

const SplittedPanelBox = styled(Box)<{ separated?: boolean; separatedHeader?: boolean }>`
    ${p =>
        p.separated &&
        css`
            > * + * {
                border-left: 1px solid ${Color.ChartBase};
            }
        `}

    ${p =>
        p.separatedHeader &&
        css`
            > * + * {
            position: relative;
            ::before {
                display: block;
                content:"";
                width: 1px;
                height: 76px;
                background: ${Color.ChartBase};
                top: 0;
                left: 0;
                position: absolute;
                z-index: ${ZIndex.Header + 1};
            }
        `}
`
