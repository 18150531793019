import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ExportDataNotification } from 'aos-services/src/services/notifications/types/ExportDataNotification'
import { Box } from 'aos-ui/src/components/base/Box'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Link } from 'aos-ui/src/components/text/Link'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import React, { FC } from 'react'

import { BaseNotificationItem } from './BaseNotificationItem'

interface ExportDataNotificationItemProps {
    notification: ExportDataNotification
}

export const ExportDataNotificationItem: FC<ExportDataNotificationItemProps> = props => {
    const { url, title, read, timestamp } = props.notification
    const openLink = () => window.open(url)
    return (
        <BaseNotificationItem
            title={title}
            read={read}
            leftColumn={
                <InfoBar padding={8}>
                    <Icon
                        iconSize={BlockSize.Std}
                        svg={SvgIcon.Download}
                        iconVariant={IconVariant.White}
                    />
                </InfoBar>
            }
        >
            <Box paddingBottom={12} paddingTop={8}>
                <Link onClick={openLink} as='span'>
                    {translate(`notifications.download.link`)}
                </Link>
            </Box>
            <HourClock time={timestamp} />
        </BaseNotificationItem>
    )
}
