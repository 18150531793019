import { syncMqttWebsocket } from 'aos-helpers/src/helpers/websocket/MqttSaga'
import { Dictionary } from 'lodash'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { ringRailDataAccessService } from '../../dataaccess/layerData/ringRailDataAccessService'
import { websocketListenerService } from '../../services/common/WebsockerListenerService'
import { layerDataService } from '../../services/layerData/LayerDataService'
import { LayerDataServiceEntry } from '../../services/layerData/types/LayerDataServiceEntry'
import { LayersDataState } from '../../services/layerData/types/LayersDataState'
import { RingRailStation, RingRailTrain } from '../../services/layerData/types/RingRailTrain'
import { ringRailService } from '../../services/ringRail/ringRailService'
import {
    LOAD_LAYERS_DATA,
    LoadLayersDataAction,
    loadLayersDataSuccessAction,
    updateTrainLocationsAction,
    updateTrainsAction,
} from './actions'

function* loadLayersData(layers: LayerDataServiceEntry[]) {
    const data: LayersDataState = yield call(layerDataService.loadLayers, layers)
    yield put(loadLayersDataSuccessAction(data))
}

function* loadLayersDataSaga() {
    yield takeEvery<LoadLayersDataAction>(
        LOAD_LAYERS_DATA,
        function* (action: LoadLayersDataAction) {
            yield loadLayersData(action.payload)
        },
    )
}

export const ringRailTrainsSync = 'ringRailTrains'
export const ringRailLocationsSync = 'ringRailLocations'

export function* assureRingRailSync(
    ringRailSyncGetter: (state: any) => boolean,
    componentName: string,
) {
    const ringRailSync: boolean = yield select(ringRailSyncGetter)
    if (ringRailSync) {
        yield websocketListenerService.onListeners(
            {
                *[ringRailTrainsSync]() {
                    const stations: Dictionary<RingRailStation> = yield call(
                        ringRailService.getTrainStations,
                    )
                    const trains: RingRailTrain[] = yield call(
                        ringRailService.getCurrentTrains,
                        stations,
                    )
                    yield put(
                        loadLayersDataSuccessAction({ ringRailTrainsInfo: { trains, stations } }),
                    )
                    yield syncMqttWebsocket(
                        ringRailDataAccessService.getTrainsRef(),
                        updateTrainsAction,
                        2000,
                    )
                },
                *[ringRailLocationsSync]() {
                    yield syncMqttWebsocket(
                        ringRailDataAccessService.getTrainLocationsRef(),
                        updateTrainLocationsAction,
                        2000,
                    )
                },
            },
            componentName,
        )
    } else {
        yield websocketListenerService.offListeners(
            [ringRailTrainsSync, ringRailLocationsSync],
            componentName,
        )
    }
}

export const layersDataSagas = [loadLayersDataSaga]
