import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { mapEngine } from 'aos-map-engine/src'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { DefaultRadioRenderer } from 'aos-ui/src/components/form/radio/DefaultRadioRenderer'
import React from 'react'

export interface FloorButtonsProps {
    selectedFloor: number
    setFloorNumber(v: number): void
}

export const FloorButtons = ({ selectedFloor, setFloorNumber }: FloorButtonsProps) => {
    const changeFloor = (floorNumber: number) => {
        setFloorNumber(floorNumber)
    }

    return (
        <LabeledFormElement
            label={translate('bim.floor.title')}
            marginHorizontal={24}
            marginTop={24}
            marginBottom={24}
        >
            <BlockRadioGroup
                items={mapEngine.availableFloors}
                value={selectedFloor}
                gridSize={4}
                marginTop={16}
                Renderer={({ isSelected, item, onSelect }) => (
                    <DefaultRadioRenderer
                        isSelected={isSelected}
                        label={translate('bim.floor') + ' ' + item}
                        onSelect={onSelect}
                        justifyContent='center'
                    />
                )}
                onChange={changeFloor}
            />
        </LabeledFormElement>
    )
}
