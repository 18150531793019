import { cx } from 'aos-components/src/components/base/ClassNames'
import {
    DropTargetOverlay,
    DropTargetOverlayProps,
} from 'aos-components/src/components/dnd/DropTargetOverlay'
import { BaseDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { conditionalFunctionCall1Arg, conditionalWrapper } from 'aos-helpers/src/helpers/Function'
import { Page, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { PageRequest } from 'aos-helpers/src/helpers/PageRequest'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AbstractEntity } from 'aos-services/src/services/base/types/AbstractEntity'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import {
    AosEventGroup,
    AosEventGroupOrEventOrFeedIn,
    isAosEventGroup,
} from 'aos-services/src/services/events/types/AosEventGroup'
import { isAosFeedIn } from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { Box } from 'aos-ui/src/components/base/Box'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { identity, noop } from 'lodash'
import React, { PureComponent } from 'react'

import { EventItem } from './EventItem'
import { EventItemGroup } from './EventItemGroup'
import { droppableEventListWrapper, droppableEventWrapper } from './EventListDnd'

export const ActivateEventDropTargetOverlay =
    droppableEventWrapper<DropTargetOverlayProps>()(DropTargetOverlay)

export class EventList extends PureComponent<EventListProps & BaseDropTargetProps> {
    public render() {
        const { isOver } = this.props
        const wrapper = conditionalWrapper(true, this.props.connectDropTarget)
        const loadEvents = (p: Page) => {
            ;(this.props.loadItems || noop)({
                page: p.number,
                size: p.size,
            })
        }

        return wrapper(
            <div
                className={cx('padding-top--x-large event-list full-size', {
                    'event-list--drop-over': isOver,
                })}
            >
                {isOver && this.renderActivatePlaceholder()}
                <PaginationList
                    page={this.props.events}
                    onChange={loadEvents}
                    paddingHorizontal={30}
                    paddingBottom={20}
                >
                    {this.props.events.content.map(this.renderEventOrGroup)}
                </PaginationList>
            </div>,
        )
    }

    private renderActivatePlaceholder = () => (
        <Box className='event-list__activate-box' marginBottom={20} marginHorizontal={30}>
            <ActivateEventDropTargetOverlay
                label={translate('event-manager.activate-event')}
                onDrop={this.props.activateEvent}
            />
        </Box>
    )

    private renderEventOrGroup = (g: AosEventGroupOrEventOrFeedIn, index: number) => {
        if (isAosEventGroup(g)) {
            return this.renderEventGroup(g, index)
        } else {
            return this.renderEvent(g, index)
        }
    }

    private renderEventGroup = (g: AosEventGroup, index: number) => {
        const addEventToGroup = (t: AbstractEntity) =>
            (this.props.addEventToGroup || identity)([g.id, t.id])
        const onItemClick = conditionalFunctionCall1Arg(true, this.props.onItemClick, g)
        const onSubItemClick = (event: AosFeedIn) =>
            conditionalFunctionCall1Arg(true, this.props.onSubItemClick, event)
        return (
            <Box className='event-list__group-container' paddingBottom={20} key={index}>
                <EventItemGroup
                    group={g}
                    droppable={this.props.droppableItems}
                    onDrop={addEventToGroup}
                    onItemClick={onItemClick}
                    onSubItemClick={onSubItemClick}
                />
            </Box>
        )
    }

    private renderEvent = (e: AosEvent | AosFeedIn, index: number) => {
        const addEventToGroup = (t: AbstractEntity) =>
            (this.props.addEventToGroup || identity)([e.id, t.id])
        const eventNotDismissed = !this.isEventDismissed(e)
        const activate = conditionalFunctionCall1Arg(
            this.props.activableItems && eventNotDismissed,
            this.props.activateEvent,
            e,
        )
        const dismiss = conditionalFunctionCall1Arg(
            this.props.dismisableItems && eventNotDismissed,
            this.props.dismissEvent,
            e,
        )
        const onItemClick = conditionalFunctionCall1Arg(true, this.props.onItemClick, e)
        return (
            <Box paddingBottom={20} key={index}>
                <EventItem
                    event={e}
                    draggable={this.props.draggableItems && eventNotDismissed}
                    droppable={this.props.droppableItems}
                    activate={activate}
                    dismiss={dismiss}
                    onDrop={addEventToGroup}
                    onClick={onItemClick}
                />
            </Box>
        )
    }

    private isEventDismissed = (e: AosEvent | AosFeedIn) => {
        if (isAosFeedIn(e)) {
            return e.dismissed
        } else {
            return false
        }
    }
}

export interface EventListProps {
    events: Pageable<AosEventGroupOrEventOrFeedIn>
    draggableItems?: boolean
    droppableItems?: boolean
    activableItems?: boolean
    dismisableItems?: boolean
    activateEvent?(v: AbstractEntity): void
    dismissEvent?(v: AbstractEntity): void
    addEventToGroup?(v: [number, number]): void
    loadItems?(v: PageRequest): void
    onItemClick?(v: AbstractEntity): void
    onSubItemClick?(v: AbstractEntity): void
}

export const EventDndList = droppableEventListWrapper<EventListProps>()(EventList)
