import React, { FC, PropsWithChildren, useMemo } from 'react'

import { SingleMapProps } from '../MapProps'
import { BimMap } from '../openlayers/BimMap'
import { BimMapLayer } from '../openlayers/layers/BimMapLayer'
import { OSMLayer } from '../openlayers/layers/OSMLayer'
import { OpenlayersMap } from '../openlayers/OpenlayersMap'

export const WorldMap: FC<PropsWithChildren<SingleMapProps>> = props => {
    const { onBoundsChanged, children, bimLayersState, userLayerVisibility } = props
    const isLayerVisible = (bimLayerName: string) =>
        userLayerVisibility.list?.includes(bimLayerName)
    const mapLayers = useMemo(
        () =>
            bimLayersState.list
                .filter(q => q.worldMapLayer)
                .map(layer => (
                    <BimMapLayer
                        suffix={layer.customLoader?.suffix ?? ''}
                        mapLayerId={layer.id}
                        layerId={layer.name}
                        key={layer.id}
                        isVisible={isLayerVisible(layer.name)}
                        zIndex={layer.zIndex}
                    />
                )),
        [bimLayersState],
    )

    return (
        <OpenlayersMap initialBoundsSet={onBoundsChanged}>
            <OSMLayer zIndex={1} />
            <BimMap>{mapLayers}</BimMap>
            {children}
        </OpenlayersMap>
    )
}
