import { BimLayersState } from '../../../core/bimLayersData/state'
import { UserLayerVisibilityState } from '../../../core/userLayersVisibility/state'
import { MapSiteLocationCustomization } from '../../common/types/MapSiteLocationCustomization'
import { MapVariant } from '../../common/types/MapVariant'
import { BaseLayersVisibility, LayersVisibility } from '../../map/types/LayersVisibility'
import { MapPosition } from '../../map/types/MapPosition'
import { SelectedMapElement } from '../../map/types/SelectedMapElement'
import { NavigationMapElement } from './NavigationMapElement'

export enum MapMode {
    Standard,
    ViewSingleLocation,
    EditSingleLocation,
}

export interface BaseMapState {
    mode: MapMode
    variant: MapVariant
    siteLocationCustomization: MapSiteLocationCustomization
    terminalMapState: SingleMapState
    worldMapState: SingleMapState
    selectedElement: SelectedMapElement | null
    navigatedElement: NavigationMapElement | null
    selectedFloor: number
}

export interface SingleMapState {
    position: MapPosition
    layersVisibility: BaseLayersVisibility
    userLayersVisibility: UserLayerVisibilityState
    bimLayersState: BimLayersState
}

export const layersVisibilityForMapVariant = (state: BaseMapState): LayersVisibility =>
    singleMapPropForVariantGetter(state, t => t.layersVisibility)

export const layersUserVisibilityForMapVariant = (state: BaseMapState): UserLayerVisibilityState =>
    singleMapPropForVariantGetter(state, t => t.userLayersVisibility)

export const bimLayersForMapVariant = (state: BaseMapState): BimLayersState =>
    singleMapPropForVariantGetter(state, t => t.bimLayersState)

export const singleMapPropForVariantGetter = <T>(
    state: BaseMapState,
    getter: f.Func1<SingleMapState, T>,
): T => {
    switch (state.variant) {
        case MapVariant.MainAirport:
            return getter(state.terminalMapState)

        case MapVariant.World:
            return getter(state.worldMapState)
    }
}

export const positionForMapVariant = <T extends BaseMapState>(state: T): MapPosition =>
    singleMapPropForVariantGetter(state, t => t.position)
