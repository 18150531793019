import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

export interface IconModalHeaderProps extends SvgProps {
    closeAction(): void
    isNonDismissible?: boolean
}

export const IconModalHeader: FC<IconModalHeaderProps> = props => (
    <Header centered variant={HeaderVariant.ModalIcon}>
        <Icon iconVariant={IconVariant.Smart} iconSize={BlockSize.ModalIcon} svg={props.svg} />

        {!props.isNonDismissible && (
            <Positioned top={20} right={20}>
                <IconButton
                    onClick={props.closeAction}
                    iconSize={BlockSize.Small}
                    iconVariant={IconVariant.Smart}
                    svg={SvgIcon.Close}
                    seleniumLocation='modal-container-button-close'
                />
            </Positioned>
        )}
    </Header>
)
