import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { TextSize } from 'aos-ui/src/components/base/Text'
import { CheckableLabel } from 'aos-ui/src/components/text/CheckableLabel'
import { Color } from 'aos-ui-common/src/styles/Color'
import { uniqueId } from 'lodash'
import React, { FCWithChildren, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'

import IndeterminateIcon from '../../../assets/icons/dynamic/IndeterminateIcon'
import { ThemeVariant } from '../../base/ThemeVariant'

interface CheckboxProps extends MarginBoxProps {
    checked: boolean
    position?: 'left' | 'right' | 'center'
    disabled?: boolean
    variant?: ThemeVariant
    fontSize?: TextSize
    checkableLabel?: boolean
    readOnly?: boolean
    onChange?(v: boolean): void
    checkBoxBackgroundColor?: Color
    tickColor?: Color
    indeterminate?: boolean
    CustomUncheckedIcon?: React.ReactElement
    CustomCheckedIcon?: JSX.Element
}

export const Checkbox: FCWithChildren<CheckboxProps> = ({
    checked,
    children,
    onChange,
    disabled,
    position = 'left',
    variant = ThemeVariant.White,
    fontSize = 13,
    checkableLabel,
    readOnly,
    checkBoxBackgroundColor,
    tickColor,
    indeterminate = false,
    CustomCheckedIcon,
    CustomUncheckedIcon,
    ...margins
}) => {
    const checkboxId = useMemo(() => uniqueId('checkbox'), [])
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.indeterminate = indeterminate
        }
    }, [indeterminate])

    const classicCheckbox = () => (
        <>
            <input
                ref={inputRef}
                className={indeterminate ? 'indeterminate' : ''}
                type='checkbox'
                disabled={disabled}
                id={checkboxId}
                checked={checked}
                onChange={() => onChange && onChange(!checked)}
                readOnly={readOnly}
            />
            {indeterminate && (
                <StyledIndeterminateIcon onClick={() => onChange && onChange(!checked)}>
                    <IndeterminateIcon />
                </StyledIndeterminateIcon>
            )}
            <label htmlFor={checkboxId}>
                {!!children &&
                    (checkableLabel ? (
                        <CheckableLabel
                            paddingLeft={position === 'left' ? 8 : position === 'center' ? 4 : 0}
                            paddingRight={position === 'right' ? 8 : position === 'center' ? 4 : 0}
                            variant={variant}
                            fontSize={fontSize}
                            checked={checked}
                        >
                            {children}
                        </CheckableLabel>
                    ) : (
                        children
                    ))}
            </label>
        </>
    )

    const hasCustomization = CustomCheckedIcon || CustomUncheckedIcon

    if (hasCustomization && !CustomUncheckedIcon && !CustomCheckedIcon) {
        throw new Error('CustomUncheckedIcon and CustomCheckedIcon must be provided together')
    }

    const onClick = (e: React.MouseEvent<unknown>) => {
        e.stopPropagation()
        onChange && onChange(!checked)
    }

    const customCheckbox = () => (
        <>
            {CustomCheckedIcon && checked && (
                <StyledIconWrapper onClick={onClick}>{CustomCheckedIcon}</StyledIconWrapper>
            )}
            {CustomUncheckedIcon && !checked && (
                <StyledIconWrapper onClick={onClick}>{CustomUncheckedIcon}</StyledIconWrapper>
            )}
            <Box paddingLeft={16}>
                {!!children &&
                    (checkableLabel ? (
                        <CheckableLabel
                            paddingLeft={position === 'left' ? 8 : 0}
                            paddingRight={position === 'right' ? 8 : 0}
                            variant={variant}
                            fontSize={fontSize}
                            checked={checked}
                        >
                            {children}
                        </CheckableLabel>
                    ) : (
                        children
                    ))}
            </Box>
        </>
    )

    return (
        <CheckboxContainer
            row
            flex={1}
            position={position}
            data-test-id={`${checkableLabel}-checkbox`}
            {...margins}
            checkBoxBackgroundColor={checkBoxBackgroundColor}
            tickColor={tickColor}
        >
            {hasCustomization ? customCheckbox() : classicCheckbox()}
        </CheckboxContainer>
    )
}

const StyledIconWrapper = styled(Box)`
    position: absolute;
    top: 12px;
    left: 10px;
    z-index: 90;
`

const StyledIndeterminateIcon = styled(Box)`
    position: absolute;
    top: 7px;
    z-index: 100;
`

const CheckboxContainer = styled(Box)<{
    position: 'left' | 'right' | 'center'
    checkBoxBackgroundColor?: Color
    tickColor?: Color
}>`
    > input {
        display: none;
    }

    > label {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            transform-origin: 50% 50%;
            content: '';
        }

        &::before {
            width: 16px;
            height: 16px;
            border-radius: 3px;
            border: 2px solid ${Color.Grey2};
            background: ${Color.White};
            transform: translate(0, -50%);
        }
        &::after {
            right: 5px;
            width: 5px;
            height: 10px;
            transform: translate(0, -60%) rotate(45deg);
            border: 2px solid ${({ tickColor }) => tickColor || Color.White};
            border-top: 0;
            border-left: 0;
            opacity: 0;
        }
    }

    > input:checked + label::before {
        border: none;
        background-color: ${({ checkBoxBackgroundColor }) =>
            checkBoxBackgroundColor ? checkBoxBackgroundColor : Color.CheckboxOnWhiteBackground};
    }

    > input:checked + label::after {
        opacity: 1;
    }

    > input:disabled + label::before {
        border-color: ${Color.CheckboxDisabledBorder};
        background-color: ${Color.CheckboxDisabledBackground};
    }

    > input:indeterminate + label::before {
        border: none;
        background-color: ${({ checkBoxBackgroundColor }) =>
            checkBoxBackgroundColor ? checkBoxBackgroundColor : Color.CheckboxOnWhiteBackground};
    }

    ${p =>
        p.position === 'left' &&
        css`
            > label {
                padding-left: 16px;

                &::before,
                &::after {
                    left: 0;
                }
                &::after {
                    left: 5px;
                }
            }
        `}

    ${p =>
        p.position === 'right' &&
        css`
            > label {
                padding-right: 16px;

                &::before,
                &::after {
                    right: 0;
                }
                &::after {
                    right: 5px;
                }
            }
        `}
        ${p =>
        p.position === 'center' &&
        css`
            > label {
                padding-left: 8px;
                padding-right: 8px;

                &::before,
                &::after {
                    left: 50%;
                    translate: -50% 0;
                }
                &::after {
                    left: 50%;
                    translate: -50% 0;
                }
            }
        `}
`
