import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DiscountCoupon } from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { deleteDiscountCoupon, saveDiscountCoupon } from '../../../core/discountModule/actions'
import { State } from '../../../core/state'
import DiscountModuleStoreImage from './DiscountModuleStoreImage'

const DiscountCouponListElement = (props: DiscountCoupon) => {
    const [isHovered, setIsHovered] = useState(false)
    const dispatch = useDispatch()
    const currentUser = useSelector((state: State) => state.auth.currentUser)

    const { discountStore, title, specialOfferValue } = props
    return (
        <Wrapper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            rounded
            bg={Color.ContactListItem}
            row
            columnGap={16}
            alignItems='center'
            padding={16}
        >
            <DiscountModuleStoreImage flex={1} size={32} {...discountStore} />
            <Details column rowGap={8} flex={9}>
                <Text size={14} color={Color.Grey8} textTransform='uppercase'>
                    {discountStore.name}
                </Text>
                <Text size={14}>{title}</Text>
                <Text size={14} color={Color.Grey8} textTransform='uppercase'>
                    {discountStore.location}
                </Text>
            </Details>
            {isHovered && currentUser.canEditDiscounts() ? (
                <Box flex={2} row columnGap={12} justify='center'>
                    <IconButton
                        svg={SvgIcon.EditDark}
                        iconVariant={IconVariant.White}
                        onClick={() => dispatch(saveDiscountCoupon(props))}
                    />
                    <IconButton
                        svg={SvgIcon.Delete}
                        iconVariant={IconVariant.White}
                        onClick={() => dispatch(deleteDiscountCoupon({ id: props.id }))}
                    />
                </Box>
            ) : (
                <Box flex={2}>
                    <Text size={14} textTransform='uppercase' textAlign='center'>
                        {specialOfferValue || translate('discountModule.specialOffer')}
                    </Text>
                </Box>
            )}
        </Wrapper>
    )
}

export default DiscountCouponListElement

const Wrapper = styled(Box)`
    &:hover {
        background-color: ${Color.ActiveGrey};
    }
`

const Details = styled(Box)`
    border-right: 0.5px dashed ${Color.Grey8};
`
