export type RequestInterceptorParam = [string, RequestInit]
export type RequestInterceptor = f.Func1<
    RequestInterceptorParam,
    RequestInterceptorParam | Promise<RequestInterceptorParam>
>
export type ResponseInterceptor = f.Func1<Response, Promise<Response>>

const contentType = 'Content-Type'

export class BaseRestService {
    public static isRefreshing: boolean = false
    private static refreshQueue: Array<Promise<RequestInterceptorParam>> = []
    public static responseInterceptors: ResponseInterceptor[] = []
    public static requestInterceptors: RequestInterceptor[] = []
    public static errorHandlers: f.Func1<Error, Promise<Error>>[] = [
        (e: Error) => Promise.reject(e),
    ]

    public static jsonTransformer<T>(r: Response): T {
        return r.json() as any
    }

    public static rawTransformer(r: Response): Response {
        return r
    }

    public static textTransformer(r: Response): string {
        return r.text() as any
    }

    public static blobTransformer(r: Response): Promise<Blob> {
        return r
            .blob()
            .then(b => new Blob([b], { type: r.headers.get(contentType) || 'text/plain' }))
    }

    public static fileDownloadTransformer(r: Response): Promise<[Blob, string]> {
        return BaseRestService.blobTransformer(r).then(b => {
            const filename = BaseRestService.getFilenameFromHeader(
                r.headers.get('Content-Disposition'),
            )
            if (filename) {
                return [b, filename] as [Blob, string]
            }
            throw new Error('missing filename in download response')
        })
    }

    private static getFilenameFromHeader = (headerValue: string | null) => {
        if (headerValue && headerValue.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(headerValue)
            // eslint-disable-next-line eqeqeq
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '')
            }
        }
        return undefined
    }

    protected post = (url: string, payload?: any, headers?: Headers): Promise<Response> =>
        this.request(url, {
            method: 'POST',
            body: payload,
            headers: headers || new Headers(),
        })

    protected get = (url: string, headers?: Headers): Promise<Response> =>
        this.request(url, {
            method: 'GET',
            headers: headers || new Headers(),
        })

    protected deleteReq = (url: string, headers?: Headers): Promise<Response> =>
        this.request(url, {
            method: 'DELETE',
            headers: headers || new Headers(),
        })

    protected patch = (url: string, payload?: any, headers?: Headers): Promise<Response> =>
        this.request(url, {
            method: 'PATCH',
            body: payload,
            headers: headers || new Headers(),
        })
    protected put = (url: string, payload?: any, headers?: Headers): Promise<Response> =>
        this.request(url, {
            method: 'PUT',
            body: payload,
            headers: headers || new Headers(),
        })

    protected postJson = (url: string, payload: any, headers?: Headers): Promise<Response> =>
        this.post(url, payload, this.addJsonHeaders(headers))
    protected putJson = (url: string, payload: any, headers?: Headers): Promise<Response> =>
        this.put(url, payload, this.addJsonHeaders(headers))

    protected patchJson = (url: string, payload: any, headers?: Headers): Promise<Response> =>
        this.patch(url, payload, this.addJsonHeaders(headers))

    protected request = (url: string, init: RequestInit = {}): Promise<Response> =>
        this.requestInterceptors(url, init)
            .then(([nextUrl, nextInit]: RequestInterceptorParam) => {
                return fetch(nextUrl, nextInit)
            })
            .then(this.responseInterceptors)
            .catch((e: Error) => {
                let current: Promise<Error> = Promise.reject(e)
                BaseRestService.errorHandlers.map(i => {
                    current = current.catch(i)
                })
                return current
            }) as Promise<Response>

    protected postJsonAndTransform<T>(
        url: string,
        payload: any,
        transformer: f.Func1<Response, T>,
    ): Promise<T> {
        return this.postJson(url, payload).then(transformer)
    }

    protected putJsonAndTransform<T>(
        url: string,
        payload: any,
        transformer: f.Func1<Response, T>,
    ): Promise<T> {
        return this.putJson(url, payload).then(transformer)
    }

    protected getAndTransform<T>(
        url: string,
        transformer: f.Func1<Response, T | Promise<T>>,
    ): Promise<T> {
        return this.get(url).then(transformer)
    }

    protected deleteAndTransform<T>(url: string, transformer: f.Func1<Response, T>): Promise<T> {
        return this.deleteReq(url).then(transformer)
    }

    protected patchAndTransform<T>(url: string, transformer: f.Func1<Response, T>): Promise<T> {
        return this.patch(url).then(transformer)
    }

    protected patchJsonAndTransform<T>(
        url: string,
        payload: any,
        transformer: f.Func1<Response, T>,
    ): Promise<T> {
        return this.patchJson(url, payload).then(transformer)
    }

    private responseInterceptors = (r: Response): Promise<Response> => {
        let current = Promise.resolve(r)
        BaseRestService.responseInterceptors.map(i => {
            current = current.then(i)
        })
        return current
    }

    private requestInterceptors = (
        url: string,
        init: RequestInit,
    ): Promise<RequestInterceptorParam> => {
        let current = Promise.resolve([url, init] as RequestInterceptorParam)
        BaseRestService.requestInterceptors.map(i => {
            current = current.then(i)
        })
        if (BaseRestService.isRefreshing) {
            BaseRestService.refreshQueue.push(current)

            throw new Error(`Token refresh is pending, adding request ${url} to queue`)
        }

        return current
    }

    public static retryRequests() {
        BaseRestService.isRefreshing = false
        BaseRestService.refreshQueue.forEach(req => {
            req.then(([url, init]) => fetch(url, init))
        })
        BaseRestService.refreshQueue = []
    }

    public static flushPendingRequests() {
        BaseRestService.isRefreshing = false
        BaseRestService.refreshQueue = []
    }

    private addJsonHeaders = (headers?: Headers): Headers => {
        const newHeaders = headers || new Headers()
        newHeaders.append(contentType, 'application/json')
        return newHeaders
    }

    protected transformedQuery = <T extends object>(query: T) =>
        Object.entries(query).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
                acc[key] = value.join(',')
            } else {
                acc[key] = value
            }
            return acc
        }, {} as Record<string, keyof T | string>)

    public static isApiPath = (apiPath: string, url: string) =>
        url.startsWith(apiPath) || url.startsWith('/')
}
