import { IExportForm } from 'aos-helpers/src/helpers/TaskExport'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import {
    taskCategories,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CheckboxList } from 'aos-ui/src/components/form/checkbox/CheckboxList'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

export const TaskDataSelection = () => {
    const { control } = useFormContext<IExportForm>()
    return (
        <Box rowGap={12} column>
            <Text color={Color.TimelineBackground} size={18}>
                {translate('export-tasks.data.title')}
            </Text>
            <Box alignContent='flex-start' justify='space-between' row>
                <Controller
                    name='taskTypes'
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <Box>
                                <CheckboxList<TaskVariant>
                                    {...field}
                                    itemToLabel={item =>
                                        translateEnum(TaskVariant, 'export-tasks.task-types')(item)
                                    }
                                    items={field.value}
                                    onChange={field.onChange}
                                />
                                {fieldState.error && (
                                    <Text
                                        size={12}
                                        color={Color.Red}
                                        paddingTop={4}
                                        lineHeight='standard'
                                    >
                                        {fieldState.error.message}
                                    </Text>
                                )}
                            </Box>
                        )
                    }}
                />

                <AbsoluteBox>
                    <Controller
                        control={control}
                        name='categories'
                        render={({ field, fieldState: { error } }) => (
                            <Box>
                                <CheckboxDropdown
                                    {...field}
                                    outlined
                                    items={taskCategories}
                                    allContent='All categories'
                                    partialContent={i => `Categories: ${i.length}`}
                                    valueRenderer={translateTaskCategory}
                                    width={DropdownWidth.Big}
                                    small
                                />
                                {error && (
                                    <Text
                                        size={12}
                                        color={Color.Red}
                                        paddingTop={4}
                                        lineHeight='standard'
                                    >
                                        {error.message}
                                    </Text>
                                )}
                            </Box>
                        )}
                    />
                </AbsoluteBox>
            </Box>
        </Box>
    )
}

const AbsoluteBox = styled(Box)`
    position: absolute;
    right: 10%;
`
