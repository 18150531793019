import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { Scrollbar } from './Scrollbar'

export class DarkScrollbar extends Scrollbar {
    protected getThumbStyle(): Partial<React.CSSProperties> {
        return {
            backgroundColor: Color.DarkScrollbar,
            borderRadius: '5px',
        }
    }
}
