import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { AttachmentsState, initialAttachmentsState } from 'aos-services/src/core/attachments/state'
import { EventFormPayload } from 'aos-services/src/services/events/input/EventPayload'
import { AosEventVisibility } from 'aos-services/src/services/events/types/AosEventVisibility'
import { processCategories } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import {
    initialMapState,
    initialMapStateWithAdjustedZoom,
    MapState,
} from 'aos-ui-map/src/core/state'

export enum EventModal {
    None,
    ReportSimpleFeedIn,
    ReportExtendedFeedIn,
    ActivateEvent,
    CreateEvent,
    EditEvent,
}

export interface EventModalsState {
    currentModal: EventModal
    currentEvent: EventFormPayload
    currentAtcLayers: string[]
    parentFeedInId?: number
    simpleForm: FormValidation<EventModalSimpleFormValidationState>
    extendedForm: FormValidation<EventModalExtendedFormValidationState>
    isEditingEvent?: boolean
    attachmentsState: AttachmentsState
    map: MapState
}

export interface EventModalsStateAware {
    eventModals: EventModalsState
}

export const emptyEvent: EventFormPayload = {
    visibility: AosEventVisibility.Public,
    atcFields: {},
    exercise: false,
}

export interface EventModalSimpleFormValidationState {
    description: boolean
}

export interface EventModalExtendedFormValidationState {
    visibility: boolean
    severity: boolean
    process: boolean
    category: boolean
    title: boolean
    aosUserGroup: boolean
    location: boolean
}

const EVENT_TITLE_MAX_LEN = 256

export const validateAosEventSimple = (
    e: EventFormPayload,
): EventModalSimpleFormValidationState => ({
    description: isNotEmpty(e.description),
})

export const validateAosEventExtended = (
    e: EventFormPayload,
): EventModalExtendedFormValidationState => ({
    visibility: isNotEmpty(e.visibility),
    severity: isNotEmpty(e.severity),
    process: isNotEmpty(e.process),
    category: isNotEmpty(e.category),
    title: e.atcAlert ? true : isNotEmpty(e.title) && e.title!.length <= EVENT_TITLE_MAX_LEN,
    aosUserGroup: validateAosEventUserGroup(e),
    location:
        e.process && processCategories[e.process].locationRequired && e.atcAlert
            ? isNotEmpty(e.location)
            : true,
})

const validateAosEventUserGroup = (e: EventFormPayload) => {
    if (e.visibility === AosEventVisibility.Restricted && e.atcAlert) {
        return !!e.aosUserGroup
    } else {
        return true
    }
}

const defaultVisibilityLayers = [
    BimLayerName.NavigationGridAtc,
    BimLayerName.TaxiwayLines,
    BimLayerName.TaxiwayNames,
    BimLayerName.RescueService,
    BimLayerName.ServiceRoads,
    BimLayerName.AirsideFence,
    BimLayerName.DeicingArea,
    BimLayerName.TaxiwayLabels,
    BimLayerName.Buildings,
    BimLayerName.RescueStationsAtc,
] as string[]

const initialEventModalsState: EventModalsState = {
    currentModal: EventModal.None,
    currentEvent: emptyEvent,
    attachmentsState: initialAttachmentsState,
    simpleForm: FormValidation.fromFields(validateAosEventSimple(emptyEvent)),
    extendedForm: FormValidation.fromFields(validateAosEventExtended(emptyEvent)),
    map: { ...initialMapState, mode: MapMode.EditSingleLocation },
    currentAtcLayers: defaultVisibilityLayers,
}

export const initialSimpleEventModalsState: EventModalsState = {
    ...initialEventModalsState,
    map: { ...initialMapStateWithAdjustedZoom(-1.05), mode: MapMode.EditSingleLocation },
}

export const initialExtendedEventModalsState: EventModalsState = {
    ...initialEventModalsState,
    map: { ...initialMapStateWithAdjustedZoom(-0.5), mode: MapMode.EditSingleLocation },
}
