import { MapLayer } from 'aos-map-engine/src/Types'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import { mapLayerDataService } from 'aos-ui-map/src/components/map/bim/MapLayerDataService'
import { Feature, GeoJSON, Point } from 'geojson'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { GatesProperties } from '../../services/layerData/properties/GatesProperties'
import { StandProperties } from '../../services/layerData/properties/StandProperties'
import { mapService } from '../../services/map/mapService'
import { MapRestriction } from '../../services/map/types/MapRestriction'
import { SelectedMapElementType } from '../../services/map/types/SelectedMapElement'
import { RestrictionResourceType } from '../../services/restrictions/types/RestrictionResourceType'
import { currentUserSiteLocation } from '../auth/state'
import {
    LOAD_BIM_LAYERS_DATA,
    LoadBimLayersDataAction,
    loadBimLayersDataSuccessAction,
} from './actions'
import { busGateStateSelector, gateStateSelector, standStateSelector } from './state'

type BimLayerWithGeojson = MapLayer & { geojson: GeoJSON }

function* loadLayersDataSaga() {
    yield takeEvery<LoadBimLayersDataAction>(LOAD_BIM_LAYERS_DATA, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const data: BimLayerWithGeojson[] = yield call(
            mapLayerDataService.loadBimLayers,
            siteLocation,
        )
        const restrictions: MapRestriction[] = yield call(mapService.loadMapRestrictions)

        const layersWithRestrictions = () => {
            const newGatesLayers = gatesLoader(data, restrictions)
            const index = data.findIndex((i: { name: string }) => i.name === BimLayerName.Gates)
            data[index] = newGatesLayers as BimLayerWithGeojson

            const newBusGatesLayers = busGatesLoader(data, restrictions)
            const busGateIndex = data.findIndex(
                (i: { name: string }) => i.name === BimLayerName.BusGates,
            )
            data[busGateIndex] = newBusGatesLayers as BimLayerWithGeojson

            const newStandsLayers = standsLoader(data, restrictions)
            const standIndex = data.findIndex(
                (i: { name: string }) => i.name === BimLayerName.Stands,
            )
            data[standIndex] = newStandsLayers as BimLayerWithGeojson
            return data
        }

        yield put(loadBimLayersDataSuccessAction(layersWithRestrictions()))
    })
}

const gatesLoader = (mapLayers: MapLayer[], restrictions: MapRestriction[]) => {
    const gatesLayer = mapLayers.find(i => i.name === BimLayerName.Gates)
    // @ts-ignore
    const modifiedLayer = gatesLayer?.geojson?.features?.map(feature => {
        const gatesRestrictions = restrictions.filter(
            restrictions => restrictions.resourceType === RestrictionResourceType.Gate,
        )

        let pictogramName = feature.properties?.pictogram

        if (gatesRestrictions.find(i => i.resourceNumber === feature.properties?.label)) {
            pictogramName = 'FA_Pictogram_Series_' + feature.properties?.label + '_Gate_Restriction'
        }
        return {
            ...feature,
            properties: {
                ...feature.properties,
                pictogram: pictogramName,
            },
        }
    })
    return {
        ...gatesLayer,
        geojson: {
            ...gatesLayer?.geojson,
            features: modifiedLayer,
        },
    }
}

const busGatesLoader = (mapLayers: MapLayer[], restrictions: MapRestriction[]) => {
    const busGatesLayer = mapLayers.find(i => i.name === BimLayerName.BusGates)
    const busGatesRestrictions = restrictions.filter(
        restrictions => restrictions.resourceType === RestrictionResourceType.BusGate,
    )
    // @ts-ignore
    const modifiedLayer = busGatesLayer?.geojson?.features?.map(feature => {
        let pictogramName = feature.properties?.pictogram
        if (
            busGatesRestrictions.find(i => {
                const number = i.resourceNumber.slice(0, 2)
                const character = i.resourceNumber[2]
                return (
                    feature.properties?.label.includes(number) &&
                    feature.properties.label.includes(character)
                )
            })
        ) {
            pictogramName =
                'FA_Pictogram_Series_BG' + feature.properties?.label + '_Restriction_WEB_DM'
        }
        return {
            ...feature,
            properties: {
                ...feature.properties,
                pictogram: pictogramName,
            },
        }
    })
    return {
        ...busGatesLayer,
        geojson: {
            ...busGatesLayer?.geojson,
            features: modifiedLayer,
        },
    }
}

const standsLoader = (mapLayers: MapLayer[], restrictions: MapRestriction[]) => {
    const standsLayer = mapLayers.find(i => i.name === BimLayerName.Stands)
    // @ts-ignore
    const modifiedLayer = standsLayer?.geojson?.features?.map(feature => {
        const gatesRestrictions = restrictions.filter(
            restrictions => restrictions.resourceType === RestrictionResourceType.Stand,
        )

        let pictogramName = feature.properties?.pictogram

        if (gatesRestrictions.find(i => i.resourceNumber === feature.properties?.teksti)) {
            pictogramName =
                'FA_Pictogram_Series_' + feature.properties?.teksti + '_Stand_Restriction'
        }
        return {
            ...feature,
            properties: {
                ...feature.properties,
                pictogram: pictogramName,
            },
        }
    })
    return {
        ...standsLayer,
        geojson: {
            ...standsLayer?.geojson,
            features: modifiedLayer,
        },
    }
}

function* findStandInState(standId: string) {
    const result: Feature<Point, unknown> = yield select(standStateSelector(standId))
    return result
}

export function* findStandFeature(standId: string) {
    let stand: Feature<Point, StandProperties> | undefined = yield findStandInState(standId)
    if (!stand) {
        yield loadLayersDataSaga()
        stand = yield findStandInState(standId)
    }
    return stand
}

function* findGateInState(gateId: string) {
    const gate: Feature<Point, GatesProperties> | undefined = yield select(
        gateStateSelector(gateId),
    )
    const busGate: Feature<Point, GatesProperties> | undefined = yield select(
        busGateStateSelector(gateId),
    )
    const gateWithTarget = gate ? [gate, SelectedMapElementType.Gate] : undefined
    const busGateWithTarget = busGate ? [busGate, SelectedMapElementType.BusGate] : undefined
    return gateWithTarget || busGateWithTarget
}

export function* findGateFeature(gateId: string) {
    let gate: [Feature<Point, GatesProperties>, SelectedMapElementType] | undefined =
        yield findGateInState(gateId)
    if (!gate) {
        yield loadLayersDataSaga()
        gate = yield findGateInState(gateId)
    }
    return gate
}

export const bimLayersDataSagas = [loadLayersDataSaga]
