import { cx } from 'aos-components/src/components/base/ClassNames'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosHandlingAgent,
    AosHandlingAgentType,
} from 'aos-services/src/services/flightInformation/types/AosHandlingAgent'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Box } from 'aos-ui/src/components/base/Box'
import { TypedColumn } from 'aos-ui/src/components/table/TypedColumn'
import { LargeSpinner } from 'aos-ui/src/components/ui/LargeSpinner'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'
import { AutoSizer, Column, ColumnProps, Table, TableRowProps } from 'react-virtualized'

import { changeSortingAction, lockNowAction } from '../../core/flightInformation/actions'
import { FlightFilters } from '../../core/flightInformation/flight/flightFiltersState'
import { FlightInfoField } from '../../core/flightInformation/flight/FlightInfoField'
import { FlightInfoTableSelectorState } from '../../core/flightInformation/flight/flightSelectors'
import { FlightInformationColumnValues } from '../../core/flightInformation/state'
import { HandlingAgentCell } from './cells/HandlingAgentCell'
import { PublicRemarkCell } from './cells/PublicRemarkCell'
import { TimeCell } from './cells/TimeCell'
import { FlightTableWrapper } from './FlightTableWrapper'
import { StringFilter, TimeFilter } from './header/Filters'
import { FlightTableHeader, FlightTableHeaderSort } from './header/FlightTableHeader'
import { TableCheckboxDropdown } from './partial/TableCheckboxDropdown'
import { TableDropdown } from './partial/TableDropdown'
import { NOW_ROW_HEIGHT, ROW_HEIGHT, TableRow } from './partial/TableRow'

interface ColumnOptions {
    dataKey: keyof FlightFilters
    width?: number
    maxWidth?: number
    caption?: string
}

const DEFAULT_WIDTH = 60

export interface FlightInfoTableDispatchProps {
    lockNow: typeof lockNowAction
    changeSorting: typeof changeSortingAction
    updateFilters(v: Partial<FlightFilters>): void
}

interface FlightInformationProps
    extends FlightInfoTableSelectorState,
        FlightInfoTableDispatchProps {}

export abstract class AbstractFlightInfoTableClass extends PureComponent<FlightInformationProps> {
    private ref = React.createRef<Table>()

    protected defaultColumnProps: Partial<ColumnProps> = {
        className: 'text--center',
        disableSort: true,
    }

    private translateHandlingAgent = translateEnum<AosHandlingAgentType>(
        AosHandlingAgent,
        'flight-information.handling-agent',
    )

    public render() {
        const { flights, isLoading, nowLocked, filters, columnList, nowIndex } = this.props
        return (
            <Box flex={1} fullHeight padding={16} paddingRight={8}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FlightTableWrapper
                            innerRef={this.ref}
                            width={width}
                            height={height}
                            headerHeight={filters.filtersVisible ? 90 : 30}
                            rowHeight={({ index }) =>
                                index === nowIndex ? NOW_ROW_HEIGHT : ROW_HEIGHT
                            }
                            scrollToIndex={nowLocked ? nowIndex : undefined}
                            scrollToAlignment='center'
                            rowCount={flights.length}
                            rowClassName={({ index }) => this.getTableRowClassName(flights[index])}
                            rowGetter={({ index }) => flights[index]}
                            rowRenderer={this.renderRow}
                            onUserScroll={this.handleUserScroll}
                            columnList={columnList}
                            noRowsRenderer={() => (
                                <Box className='full-size' centered>
                                    {isLoading && <LargeSpinner />}
                                </Box>
                            )}
                        >
                            {this.renderColumns()}
                        </FlightTableWrapper>
                    )}
                </AutoSizer>
            </Box>
        )
    }

    public componentDidUpdate() {
        if (this.ref.current) {
            this.ref.current.recomputeRowHeights()
        }
    }

    protected abstract renderColumns(): JSX.Element[]

    protected abstract sortBy(sortKey: keyof FlightFilters): FlightTableHeaderSort

    protected renderDropdownFilter(key: keyof FlightFilters) {
        return (
            <TableDropdown
                items={this.getDropdownValues(key)}
                value={this.props.filters[key]}
                onChange={value => this.props.updateFilters({ [key]: value })}
            />
        )
    }

    protected renderCheckboxDropdownFilter(key: keyof FlightFilters) {
        return (
            <TableCheckboxDropdown<string>
                items={this.getDropdownValues(key)}
                values={this.props.filters[key]}
                onChange={values => this.props.updateFilters({ [key]: values })}
                itemToLabel={item => item}
            />
        )
    }

    protected renderTimeFilter(key: keyof FlightFilters) {
        return (
            <TimeFilter
                value={this.props.filters[key] as string}
                onChange={this.updateFilterHandler(key)}
            />
        )
    }

    protected renderStringFilter(key: keyof FlightFilters) {
        return (
            <StringFilter
                value={this.props.filters[key] as string}
                onChange={this.updateFilterHandler(key)}
            />
        )
    }

    protected updateFilterHandler = (key: keyof FlightFilters) => (value: string | string[]) => {
        this.props.updateFilters({ [key]: value })
    }

    protected textColumn(options: ColumnOptions, props: Partial<ColumnProps> = {}) {
        const { filtersVisible } = this.props.filters
        return (
            <Column
                {...this.getColumnProps(options, props)}
                headerRenderer={() => (
                    <FlightTableHeader
                        caption={options.caption || options.dataKey}
                        sort={this.sortBy(options.dataKey)}
                        filter={filtersVisible && this.renderStringFilter(options.dataKey)}
                    />
                )}
            />
        )
    }

    protected timeColumn(options: ColumnOptions, props: Partial<ColumnProps> = {}) {
        const { filtersVisible } = this.props.filters
        return (
            <TypedColumn<Flight>
                {...this.getColumnProps(options, props)}
                headerRenderer={() => (
                    <FlightTableHeader
                        caption={options.caption || options.dataKey}
                        sort={this.sortBy(options.dataKey)}
                        filter={filtersVisible && this.renderTimeFilter(options.dataKey)}
                    />
                )}
                cellRenderer={({ cellData }) => <TimeCell value={cellData as DateTime} />}
            />
        )
    }

    protected checkboxDropdownColumn(options: ColumnOptions, props: Partial<ColumnProps> = {}) {
        const { filtersVisible } = this.props.filters
        return (
            <Column
                {...this.getColumnProps(options, props)}
                headerRenderer={() => (
                    <FlightTableHeader
                        caption={options.caption || options.dataKey}
                        sort={this.sortBy(options.dataKey)}
                        filter={
                            filtersVisible && this.renderCheckboxDropdownFilter(options.dataKey)
                        }
                    />
                )}
            />
        )
    }

    protected dropdownColumn(options: ColumnOptions, props: Partial<ColumnProps> = {}) {
        const { filtersVisible } = this.props.filters
        return (
            <Column
                {...this.getColumnProps(options, props)}
                headerRenderer={() => (
                    <FlightTableHeader
                        caption={options.caption || options.dataKey}
                        sort={this.sortBy(options.dataKey)}
                        filter={filtersVisible && this.renderDropdownFilter(options.dataKey)}
                    />
                )}
            />
        )
    }

    protected handlColumn(options?: Partial<ColumnOptions>) {
        const { filtersVisible } = this.props.filters
        return (
            <TypedColumn<Flight, FlightInfoField.handl>
                {...this.defaultColumnProps}
                width={120}
                flexGrow={140}
                {...options}
                key={FlightInfoField.handl}
                dataKey={FlightInfoField.handl}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={this.sortBy(FlightInfoField.handl)}
                        caption={FlightInfoField.handl}
                        filter={
                            filtersVisible && (
                                <TableCheckboxDropdown<AosHandlingAgentType>
                                    items={EnumValues.getValues(AosHandlingAgent)}
                                    onChange={this.updateFilterHandler(FlightInfoField.handl)}
                                    values={this.props.filters.handl}
                                    itemToLabel={this.translateHandlingAgent}
                                />
                            )
                        }
                    />
                )}
                cellRenderer={({ cellData }) => <HandlingAgentCell value={cellData} />}
            />
        )
    }

    protected prmStatusColumn(options?: Partial<ColumnOptions>) {
        const { filtersVisible } = this.props.filters
        return (
            <TypedColumn<Flight>
                {...this.defaultColumnProps}
                width={120}
                flexGrow={140}
                {...options}
                key={FlightInfoField.prm}
                dataKey={FlightInfoField.prm}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={this.sortBy(FlightInfoField.prm)}
                        caption='status'
                        filter={filtersVisible && this.renderDropdownFilter('prm')}
                    />
                )}
                cellRenderer={({ rowData }) => <PublicRemarkCell row={rowData} />}
            />
        )
    }

    private getColumnProps(
        options: ColumnOptions,
        props: Partial<ColumnProps> = {},
    ): ColumnProps & { key: string } {
        return {
            ...this.defaultColumnProps,
            ...props,
            key: options.dataKey,
            width: options.width || DEFAULT_WIDTH,
            flexGrow: options.maxWidth,
            dataKey: options.dataKey,
        }
    }

    private getTableRowClassName(flight: Flight) {
        const prmClass = flight && flight.prm && `flight-table-row--prm-${flight.prm.code}`
        const delayedClass =
            flight && flight.isDelayedBasedOnEstimated && 'flight-table-row--delayed'
        return cx('flight-table-row', prmClass, delayedClass)
    }

    private getDropdownValues(key: keyof FlightFilters): string[] {
        return this.props.columnValues[key as keyof FlightInformationColumnValues]
    }

    private renderRow = (row: TableRowProps) => {
        return (
            <TableRow
                key={row.index}
                row={row}
                now={this.props.now}
                nowIndex={this.props.nowIndex}
            />
        )
    }

    private handleUserScroll = () => {
        this.props.lockNow(false)
    }
}
