import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import {
    AttachmentUpdatePayload,
    AttachmentUpdateSuccessPayload,
} from '../../services/attachments/types/AttachmentUpdatePayload'
import { UploadedAttachment } from '../../services/attachments/types/UploadedAttachment'

export const UPLOAD_ATTACHMENT = 'ATTACHMENTS/UPLOAD_ATTACHMENT'
export type UploadAttachmentAction = PayloadAction<typeof UPLOAD_ATTACHMENT, FileList>
export const uploadAttachmentAction = payloadActionCreator<UploadAttachmentAction, FileList>(
    UPLOAD_ATTACHMENT,
)

export const REPLACE_ATTACHMENT = 'ATTACHMENTS/REPLACE_ATTACHMENT'
export type ReplaceAttachmentAction = PayloadAction<
    typeof REPLACE_ATTACHMENT,
    AttachmentUpdatePayload
>
export const replaceAttachmentAction = payloadActionCreator<
    ReplaceAttachmentAction,
    AttachmentUpdatePayload
>(REPLACE_ATTACHMENT)

export const REPLACE_ATTACHMENT_SUCCESS = 'ATTACHMENTS/REPLACE_ATTACHMENT_SUCCESS'
export type ReplaceAttachmentSuccessAction = PayloadAction<
    typeof REPLACE_ATTACHMENT_SUCCESS,
    AttachmentUpdateSuccessPayload
>
export const replaceAttachmentSuccessAction = payloadActionCreator<
    ReplaceAttachmentSuccessAction,
    AttachmentUpdateSuccessPayload
>(REPLACE_ATTACHMENT_SUCCESS)

export const DELETE_ATTACHMENT = 'ATTACHMENTS/DELETE_ATTACHMENT'
export type DeleteAttachmentAction = PayloadAction<typeof DELETE_ATTACHMENT, UploadedAttachment>
export const deleteAttachmentAction = payloadActionCreator<
    DeleteAttachmentAction,
    UploadedAttachment
>(DELETE_ATTACHMENT)

export const UPLOAD_ATTACHMENT_ADD = 'ATTACHMENTS/UPLOAD_ATTACHMENT_ADD'
export type UploadAttachmentAddAction = PayloadAction<
    typeof UPLOAD_ATTACHMENT_ADD,
    UploadedAttachment
>
export const uploadAttachmentAddAction = payloadActionCreator<
    UploadAttachmentAddAction,
    UploadedAttachment
>(UPLOAD_ATTACHMENT_ADD)

export const UPLOAD_ATTACHMENT_SUCCESS = 'ATTACHMENTS/UPLOAD_ATTACHMENT_SUCCESS'
export type UploadAttachmentSuccessAction = Action<typeof UPLOAD_ATTACHMENT_SUCCESS>
export const uploadAttachmentSuccessAction =
    emptyActionCreator<UploadAttachmentSuccessAction>(UPLOAD_ATTACHMENT_SUCCESS)

export type AttachmentsAction =
    | UploadAttachmentAction
    | UploadAttachmentAddAction
    | UploadAttachmentSuccessAction
    | DeleteAttachmentAction
    | ReplaceAttachmentAction
    | ReplaceAttachmentSuccessAction
