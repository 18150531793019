import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    InspectionGroup,
    translateInspectionGroup,
} from 'aos-services/src/services/tasks/types/task/InspectionGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CheckableBlock } from 'aos-ui/src/components/form/base/CheckableBlock'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { inspectionGroupIcons } from 'aos-ui-task/src/components/task/InspectionGroupIcon'
import React, { FC } from 'react'
import styled from 'styled-components'

import { InspectionSelectedCounter } from './InspectionSelectedCounter'

interface InspectionTypeIconRendererProps {
    isSelected: boolean
    group: InspectionGroup
    isLate: boolean
    onSelect(): void
    selectedInspectionsCounter: number
    inspectionTotalNumber: number
}

export const InspectionTypeIconRenderer: FC<InspectionTypeIconRendererProps> = ({
    isSelected,
    isLate,
    onSelect,
    group,
    inspectionTotalNumber,
    selectedInspectionsCounter,
}) => (
    <CheckableBlock
        height={80}
        column
        isSelected={isSelected}
        color={Color.ChartBase}
        cursor='pointer'
        onClick={onSelect}
        paddingHorizontal={8}
        alignItems='center'
        relative
    >
        {isLate && <Ticker />}
        {selectedInspectionsCounter > 0 && (
            <InspectionSelectedCounter
                selectedInspectionsCounter={selectedInspectionsCounter}
                inspectionTotalNumber={inspectionTotalNumber}
            />
        )}
        <Box column flex={1} justify='flex-end' marginBottom={4}>
            <Icon iconSize={BlockSize.Std} svg={inspectionGroupIcons[group]} />
        </Box>
        <Text textAlign='center' marginBottom={8}>
            {translateInspectionGroup(group)}
        </Text>
        <Text color={Color.Orange} size={8} marginBottom={8} weight='bold'>
            {isLate && translate('tasks.form.inspection-late')}
        </Text>
    </CheckableBlock>
)

const Ticker = styled.div`
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    background-color: ${Color.Orange};
`
