import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { restrictionsSelector } from 'aos-services/src/core/restrictions/selectors'
import { flightFilterService } from 'aos-services/src/services/flightInformation/FlightFilterService'
import { MainAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FlightPrmGroup } from 'aos-services/src/services/flightInformation/types/AosPublicRemark'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { emptyFlightInfoFilters } from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import { SelectedMapElement } from 'aos-services/src/services/map/types/SelectedMapElement'
import { restrictionFilterService } from 'aos-services/src/services/restrictions/RestrictionFilterService'
import { createSelector } from 'reselect'

import { arrivalsSelector, departuresSelector } from '../flightInformation/flight/flightSelectors'
import { AirportMapStateAware } from './state'

export const sdtComparator = (a: Flight, b: Flight) => a.sdt.unix() - b.sdt.unix()

const filterPRCodes = [FlightPrmGroup.Departed, FlightPrmGroup.Landed, FlightPrmGroup.Cancelled]

const onlyRecentFlightsFilter = (currentTime: DateTime) => {
    const prevTime = currentTime.clone().add(-4, 'hour')

    return (flight: Flight) => {
        if (!filterPRCodes.includes(flight.prm.group)) {
            return true
        }
        return !flight.sdt.isBefore(prevTime)
    }
}

export const restrictionMapSelector = createSelector(
    (state: AirportMapStateAware) => state.airportMap.selectedElement,
    restrictionsSelector,
    (selectedElement, restrictions) =>
        selectedElement
            ? restrictionFilterService.findRestrictionForFeature(restrictions, selectedElement)
            : undefined,
)

export const flightMapArrivalsSelector = createSelector(
    arrivalsSelector,
    currentTimeSelector,
    (_: never, ownProps: { selectionTarget: SelectedMapElement }) => ownProps.selectionTarget,
    (arrivals, currentTime, selectionTarget) =>
        flightFilterService
            .getFilteredArrivals(arrivals, emptyFlightInfoFilters, [MainAirport], selectionTarget)
            .sort(sdtComparator)
            .filter(onlyRecentFlightsFilter(currentTime)),
)

export const flightMapDeparturesSelector = createSelector(
    departuresSelector,
    currentTimeSelector,
    (_: never, ownProps: { selectionTarget: SelectedMapElement }) => ownProps.selectionTarget,
    (departures, currentTime, selectionTarget) =>
        flightFilterService
            .getFilteredDepartures(
                departures,
                emptyFlightInfoFilters,
                [MainAirport],
                selectionTarget,
            )
            .sort(sdtComparator)
            .filter(onlyRecentFlightsFilter(currentTime)),
)
