import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { selectionTargetKey } from 'aos-services/src/services/map/types/SelectedMapElement'
import GeoJSON from 'ol/format/GeoJSON'
import { Layer } from 'ol/layer'
import { unByKey } from 'ol/Observable'
import VectorSource from 'ol/source/Vector'
import { FC, useContext, useEffect, useState } from 'react'

import { LayerId } from '../../LayerId'
import { BimMapContext } from '../BimMapContext'
import { layerIdKey } from '../OpenlayersMapContext'

export interface BimGeoLayerProps {
    data?: GeoJSON
    isVisible: boolean
    zIndex: number
    layerId: LayerId | string
    suffix: string
    minZoom: number
    maxZoom: number
    selectionTarget?: string
    scale: number
    floorNumber: number
}

export const BimGeoLayer: FC<BimGeoLayerProps> = ({
    layerId,
    suffix,
    data,
    isVisible,
    maxZoom,
    minZoom,
    zIndex,
    selectionTarget,
    scale,
    floorNumber,
}) => {
    const mapContext = useContext(BimMapContext)
    const [layer, setLayer] = useState<Layer | undefined>(undefined)
    const loadBimLayer = async (data: GeoJSON) => {
        try {
            const olLayer = await mapContext.bim.addMapLayerAsGeoJson(
                layerId,
                suffix,
                data,
                minZoom,
                maxZoom,
                scale,
                zIndex,
            )
            if (!olLayer) {
                logger.log(`Layer with ID "${layerId}" not found.`)
                return
            }
            olLayer.set(layerIdKey, layerId)
            olLayer.setVisible(isVisible && layerId.includes(String(floorNumber)))
            setLayer(olLayer)

            let vectorSource = olLayer.getSource() as VectorSource
            let listenerKey = vectorSource.on('change', () => {
                if (vectorSource.getState() === 'ready') {
                    unByKey(listenerKey!)
                    for (let featurePosition in vectorSource.getFeatures()) {
                        let feature = vectorSource.getFeatures()[featurePosition]
                        if (selectionTarget) {
                            feature.set(selectionTargetKey, selectionTarget)
                        }
                    }
                }
            })
        } catch (error) {
            logger.handleError(error as Error)
        }
    }

    useEffect(() => {
        ;(async () => {
            if (data) {
                await loadBimLayer(data)
            }
        })()

        return () => {
            if (layer) {
                mapContext.bim.removeMapLayer(layerId)
            }
        }
    }, [])

    useEffect(() => {
        if (layer) {
            layer.setVisible(isVisible && layerId.includes(String(floorNumber)))
        }
    }, [isVisible])

    return null
}
