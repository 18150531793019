import { cx } from 'aos-components/src/components/base/ClassNames'
import {
    conditionalFunctionCall1Arg,
    conditionalFunctionCall2Arg,
} from 'aos-helpers/src/helpers/Function'
import { Page } from 'aos-helpers/src/helpers/Pageable'
import { filterablePageAndSortRequestBuilder } from 'aos-helpers/src/helpers/PageRequest'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import {
    UserPageable,
    UserPageRequest,
} from 'aos-services/src/services/users/transport/UsersPageRequest'
import {
    UsersSorting,
    usersSorting,
    usersSortingFromPageable,
} from 'aos-services/src/services/users/transport/UsersSorting'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserGroupType } from 'aos-services/src/services/users/types/AosUserGroupType'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { PageSizeAndSortDropdown } from 'aos-ui/src/components/form/dropdown/PageSizeAndSortDropdown'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { HeaderItems } from 'aos-ui/src/components/header/HeaderItems'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import { UserListItem } from './UserListItem'
import { UserSearch } from './UserSearch'

export class UserListClass extends PureComponent<UserListProps> {
    public render() {
        return (
            <PanelWithHeader header={this.headerContainer()} seleniumLocation='user-list-header'>
                {this.props.selectedGroup ? this.renderContent() : this.renderPlaceholder()}
            </PanelWithHeader>
        )
    }

    private renderContent = () => {
        const loadUsers = (p: Page) => this.loadUsers({ page: p.number, size: p.size })
        const { users } = this.props
        return (
            <>
                <Box paddingHorizontal={30} paddingTop={20} data-test-id='user-search'>
                    <UserSearch
                        searchUserText={users.filter.searchUserText}
                        onSearch={this.filterUsers}
                    />
                </Box>
                <PaginationList
                    page={users.data}
                    onChange={loadUsers}
                    paddingHorizontal={30}
                    paddingBottom={20}
                >
                    <Box className='flex-grid flex-grid--gap-large' paddingVertical={20}>
                        {users.data.content.map(this.renderItem)}
                    </Box>
                </PaginationList>
            </>
        )
    }

    private renderItem = (u: AosUser, index: number) => {
        const {
            onRemoveUser,
            onRemoveUserFromGroup,
            onRestoreUser,
            onRemoveUserPermanently,
            selectedGroup,
            onSelectUser,
            onEditUser,
            selectedUser,
        } = this.props
        const selectUser = () => onSelectUser(u)

        const editUser = conditionalFunctionCall1Arg(
            selectedGroup.type !== AosUserGroupType.REMOVED,
            onEditUser,
            u,
        )
        const removeUser = conditionalFunctionCall1Arg(
            selectedGroup.type !== AosUserGroupType.REMOVED,
            onRemoveUser,
            u,
        )
        const removeUserFromGroupIfCustom = conditionalFunctionCall2Arg(
            selectedGroup.type === AosUserGroupType.CUSTOM,
            onRemoveUserFromGroup,
            selectedGroup,
            u,
        )
        const restoreUser = conditionalFunctionCall1Arg(
            selectedGroup.type === AosUserGroupType.REMOVED,
            onRestoreUser,
            u,
        )
        const removeUserPermanently = conditionalFunctionCall1Arg(
            selectedGroup.type === AosUserGroupType.REMOVED,
            onRemoveUserPermanently,
            u,
        )

        const {
            size: { width },
        } = this.props
        const userItemClass = width
            ? cx({
                  'col-4': width >= 750,
                  'col-6': width >= 550 && width < 750,
                  'full-width': width < 550,
              })
            : 'col-6'
        return (
            <Box className={userItemClass} key={index}>
                <UserListItem
                    user={u}
                    onClick={selectUser}
                    isSelected={u === selectedUser}
                    onEditUser={editUser}
                    onRemoveUser={removeUser}
                    onRemoveUserFromGroup={removeUserFromGroupIfCustom}
                    onRestoreUser={restoreUser}
                    onRemoveUserPermanently={removeUserPermanently}
                />
            </Box>
        )
    }

    private renderPlaceholder = () => (
        <PanelPlaceholder
            label={translate('group-manager.user-list.placeholder')}
            svg={SvgIcon.UserGroupPlaceholder}
        />
    )

    private headerContainer = () => {
        const { selectedGroup, addAdUser, addContact, users } = this.props
        const title = selectedGroup
            ? aosUserGroupName(selectedGroup)
            : translate('group-manager.user-list.title')
        const titleCount = selectedGroup ? selectedGroup.userCount : undefined
        return (
            <HeaderContainer title={title} titleCount={titleCount}>
                <HeaderItems>
                    {selectedGroup && (
                        <PageSizeAndSortDropdown
                            onChangeSize={size => this.loadUsers({ size })}
                            onChangeSort={sort => this.loadUsers({ sort: usersSorting[sort] })}
                            pageSize={users.data.size}
                            sort={usersSortingFromPageable(users.data)}
                            keyPrefix='users-sorting'
                            sortEntries={EnumValues.getValues<UsersSorting>(UsersSorting)}
                        />
                    )}
                    <More iconVariant={IconVariant.Smart}>
                        <MoreItem onClick={addContact}>
                            {translate('group-manager.add-contact')}
                        </MoreItem>
                        <MoreItem onClick={addAdUser}>
                            {translate('group-manager.add-ad-user')}
                        </MoreItem>
                    </More>
                </HeaderItems>
            </HeaderContainer>
        )
    }

    private loadUsers = (p: Partial<UserPageRequest>) => {
        this.props.loadUsers(filterablePageAndSortRequestBuilder(this.props.users, p))
    }

    private filterUsers = (searchText: string) => {
        this.props.filterUsers(searchText)
    }
}

interface UserListProps extends SizeMeProps {
    users: UserPageable
    selectedGroup: AosUserGroup
    selectedUser?: AosUser
    onRemoveUserFromGroup(g: AosUserGroup, u: AosUser): void
    onSelectUser(v: AosUser): void
    onEditUser(v: AosUser): void
    onRemoveUser(v: AosUser): void
    onRestoreUser(v: AosUser): void
    onRemoveUserPermanently(v: AosUser): void
    loadUsers(v: UserPageRequest): void
    filterUsers(v: string): void
    addAdUser(): void
    addContact(): void
}

export const UserList = sizeMe({ refreshRate: 1000 })(UserListClass)
