import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AttachmentsAction,
    deleteAttachmentAction,
} from 'aos-services/src/core/attachments/actions'
import {
    DiscountArea,
    DiscountStoreDTO,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import { values } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import z from 'zod'

import {
    closeCreateDiscountStoreModal,
    discountModuleAttachmentsAction,
} from '../../../core/discountModule/actions'
import {
    createDiscountStoreModalSelector,
    discountModuleAttachmentsState,
} from '../../../core/discountModule/selectors'

const CreateDiscountStoreModal = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(createDiscountStoreModalSelector)
    const attachmentsState = useSelector(discountModuleAttachmentsState)

    const { control, handleSubmit, reset, setValue } = useForm<DiscountStoreDTO>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',

        defaultValues: {
            area: DiscountArea.LANDSIDE,
        },
        resolver: zodResolver(
            z.object({
                name: z
                    .string()
                    .min(1, { message: translate('discountModule.form.fieldRequired') }),
                area: z
                    .string()
                    .min(1, { message: translate('discountModule.form.fieldRequired') }),
                location: z
                    .string()
                    .min(1, { message: translate('discountModule.form.fieldRequired') }),
                logo: z.object(
                    {
                        id: z.number().optional(),
                        link: z.string().min(1),
                        name: z.string().optional(),
                        type: z.string().optional(),
                    },
                    { message: translate('discountModule.form.fieldRequired') },
                ),
            }),
        ),
    })

    const onSubmit = useCallback(
        (payload: DiscountStoreDTO) => {
            reset()
            dispatch(
                closeCreateDiscountStoreModal({
                    payload,
                }),
            )
        },
        [attachmentsState],
    )

    const onClose = useCallback(() => {
        reset()
        dispatch(closeCreateDiscountStoreModal(undefined))
        attachmentsState.attachments.forEach(attachment =>
            dispatch(discountModuleAttachmentsAction(deleteAttachmentAction(attachment))),
        )
    }, [attachmentsState])

    const handleFileUpload = useCallback(
        (f: AttachmentsAction) => {
            attachmentsState.attachments.forEach(attachment =>
                dispatch(discountModuleAttachmentsAction(deleteAttachmentAction(attachment))),
            )
            dispatch(discountModuleAttachmentsAction(f))
        },
        [attachmentsState],
    )

    useEffect(() => {
        setValue('logo', attachmentsState.attachments[0])
    }, [attachmentsState])

    return (
        <Modal
            relative
            isOpen={isOpen}
            title={translate('discountModule.createStoreModal.title')}
            modalKind={ModalKind.Medium}
            closeAction={onClose}
            footer={
                <Box row flex={1} justify='space-between'>
                    <Text color={Color.Grey6} size={13}>
                        {translate('discountModule.form.requiredFields')}
                    </Text>
                    <FormButton
                        onClick={handleSubmit(onSubmit)}
                        label={translate('discountModule.form.createButtonLabel')}
                    />
                </Box>
            }
        >
            <Box padding={30} column rowGap={16} flex={1}>
                <Controller
                    name='name'
                    control={control}
                    render={({ field: { value, onChange }, fieldState, formState }) => (
                        <LabeledInput
                            type='text'
                            value={value}
                            onChangeText={onChange}
                            keyPrefix='discountModule.form.nameInput'
                            isRequired
                            isError={fieldState.invalid}
                            errorMessage={formState.errors.name?.message}
                            maxLength={50}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name='area'
                    render={({ field: { value, onChange } }) => (
                        <Box column rowGap={10}>
                            <Text color={Color.TimelineBackground} size={14}>
                                {translate('discountModule.form.areaLabel')}
                            </Text>
                            <BlockRadioGroup
                                items={values(DiscountArea)}
                                value={value}
                                Renderer={({ item, isSelected, onSelect }) => (
                                    <RadioElement
                                        rounded
                                        onClick={onSelect}
                                        borderWidth={1}
                                        border={isSelected ? Color.ChartBase : Color.Grey6}
                                        justify='flex-start'
                                        alignItems='center'
                                        padding={12}
                                        bg={isSelected ? Color.ChartBase : Color.White}
                                    >
                                        <Text
                                            size={12}
                                            color={isSelected ? Color.White : Color.Black}
                                        >
                                            {translate(`discountModule.discountArea.${item}`)}
                                        </Text>
                                    </RadioElement>
                                )}
                                onChange={onChange}
                            />
                        </Box>
                    )}
                />
                <Controller
                    name='location'
                    control={control}
                    render={({ field: { value, onChange }, fieldState, formState }) => (
                        <LabeledInput
                            type='text'
                            value={value}
                            onChangeText={onChange}
                            keyPrefix='discountModule.form.locationInput'
                            errorMessage={formState.errors.location?.message}
                            isError={fieldState.invalid}
                            isRequired
                            maxLength={50}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name='logo'
                    render={({ fieldState, formState }) => (
                        <Box column rowGap={10}>
                            <Text
                                size={14}
                                color={fieldState.invalid ? Color.Red : Color.TimelineBackground}
                            >
                                {translate('discountModule.form.logoLabel')}
                            </Text>

                            <AttachmentContainer
                                attachmentsState={attachmentsState}
                                editable
                                gridSize={5}
                                attachmentsAction={handleFileUpload}
                            />
                            {fieldState.invalid && (
                                <Text size={12} color={Color.Red}>
                                    {formState.errors.logo?.message}
                                </Text>
                            )}
                        </Box>
                    )}
                />
            </Box>
        </Modal>
    )
}

export default CreateDiscountStoreModal

const RadioElement = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`
