import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'

import { LayerConfiguration } from './Types'

export class MapEngine {
    private readonly config: LayerConfiguration
    constructor() {
        this.config = require('layersConfig.json') as LayerConfiguration
    }

    public getLayersForCurrentAirport = (airport: AosAirport) => {
        return this.config.airports[airport]
    }

    public getLayerById = (airport: AosAirport, layerId: string) => {
        return this.config.airports[airport].find(q => q.id === layerId)
    }

    public get availableFloors() {
        return this.config.availableFloors
    }
}

export const mapEngine = new MapEngine()
