import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from '../base/Box'
import { ZIndex } from '../base/Theme'
import { IconButton } from '../buttons/IconButton'
import { SvgIcon } from '../svg/SvgIcon'

const DEFAULT_SCROLL_BY_AMOUNT = 300

type OnClickScrollListProps<T> = BoxProps & {
    items: T[]
    renderItem: (item: T) => JSX.Element
    scrollBy?: number
    keyExtractor?: (item: T) => string
    arrowsVisible?: boolean
    startArrowComponent?: JSX.Element
    endArrowComponent?: JSX.Element
    firstListElement?: JSX.Element
    lastListElement?: JSX.Element
}

function OnClickScrollList<T>({
    items,
    renderItem,
    keyExtractor,
    scrollBy = DEFAULT_SCROLL_BY_AMOUNT,
    arrowsVisible = true,
    startArrowComponent,
    endArrowComponent,
    firstListElement,
    lastListElement,
    ...boxProps
}: OnClickScrollListProps<T>) {
    const boxRef = useRef<HTMLDivElement | null>(null)
    const listDirection = useMemo(() => (boxProps.row ? 'row' : 'column'), [boxProps])

    const [maxScroll, setMaxScroll] = useState(0)
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        const handleScrollInit = () => {
            if (boxRef.current) {
                const { scrollWidth, scrollHeight, clientWidth, clientHeight } = boxRef.current
                listDirection === 'row'
                    ? setMaxScroll(scrollWidth - clientWidth)
                    : setMaxScroll(scrollHeight - clientHeight)
            }
        }
        handleScrollInit()
        window.addEventListener('resize', handleScrollInit, false)
        return () => window.removeEventListener('resize', handleScrollInit, false)
    }, [items, boxRef.current])

    const onStartArrowPress = useCallback(() => {
        listDirection === 'row'
            ? boxRef.current?.scrollBy({ left: -scrollBy, behavior: 'smooth' })
            : boxRef.current?.scrollBy({ top: -scrollBy, behavior: 'smooth' })
    }, [])

    const onEndArrowPress = useCallback(() => {
        listDirection === 'row'
            ? boxRef.current?.scrollBy({ left: scrollBy, behavior: 'smooth' })
            : boxRef.current?.scrollBy({ top: scrollBy, behavior: 'smooth' })
    }, [])

    const startArrowVisible = useMemo(
        () => arrowsVisible && scrollPosition > 0,
        [scrollPosition, arrowsVisible],
    )
    const endArrowVisible = useMemo(
        () => arrowsVisible && scrollPosition < maxScroll,
        [scrollPosition, maxScroll, arrowsVisible],
    )

    const renderStartArrow = useCallback(
        () =>
            startArrowVisible ? (
                <ArrowContainer
                    $position='start'
                    $listDirection={listDirection}
                    onClick={onStartArrowPress}
                >
                    {startArrowComponent ?? (
                        <BaseArrow style={{ transform: 'rotate(180deg)' }}>
                            <IconButton
                                svg={
                                    listDirection === 'row' ? SvgIcon.ChevronRight : SvgIcon.Chevron
                                }
                            />
                        </BaseArrow>
                    )}
                </ArrowContainer>
            ) : null,
        [startArrowComponent, startArrowVisible],
    )

    const renderEndArrow = useCallback(
        () =>
            endArrowVisible ? (
                <ArrowContainer
                    $position='end'
                    $listDirection={listDirection}
                    onClick={onEndArrowPress}
                >
                    {endArrowComponent ?? (
                        <BaseArrow>
                            <IconButton
                                svg={
                                    listDirection === 'row' ? SvgIcon.ChevronRight : SvgIcon.Chevron
                                }
                            />
                        </BaseArrow>
                    )}
                </ArrowContainer>
            ) : null,
        [endArrowComponent, endArrowVisible],
    )

    return (
        <Box
            relative
            width={listDirection === 'row' ? 'auto' : 'max-content'}
            height={listDirection === 'row' ? 'max-content' : '100%'}
        >
            {renderStartArrow()}
            <ListWrapper
                {...boxProps}
                width='100%'
                height='100%'
                ref={boxRef}
                overflow='auto'
                onScroll={() => {
                    listDirection === 'row'
                        ? setScrollPosition(boxRef.current?.scrollLeft ?? 0)
                        : setScrollPosition(boxRef.current?.scrollTop ?? 0)
                }}
            >
                {firstListElement}
                {items.map((item, i) => (
                    <div key={keyExtractor?.(item) ?? i}>{renderItem(item)}</div>
                ))}
                {lastListElement}
            </ListWrapper>
            {renderEndArrow()}
        </Box>
    )
}

export default OnClickScrollList

const ListWrapper = styled(Box)`
    ::-webkit-scrollbar {
        display: none;
    }
`

const ArrowContainer = styled(Box)<{
    $listDirection: 'column' | 'row'
    $position: 'start' | 'end'
}>`
    display: flex;
    flex-direction: ${props => (props.$listDirection === 'row' ? 'row' : 'column')};
    align-items: center;
    justify-content: ${props => (props.$position === 'start' ? 'flex-start' : 'flex-end')};
    z-index: ${ZIndex.Overlay};
    position: absolute;
    height: ${props => (props.$listDirection === 'row' ? '100%' : '100px')};
    width: ${props => (props.$listDirection === 'row' ? '100px' : '100%')};
    top: ${props =>
        props.$listDirection === 'row' ? 0 : props.$position === 'start' ? 0 : undefined};
    bottom: ${props =>
        props.$listDirection === 'column' && props.$position === 'end' ? 0 : undefined};
    left: ${props =>
        props.$listDirection === 'column' ? 0 : props.$position === 'start' ? 0 : undefined};
    right: ${props =>
        props.$listDirection === 'column' ? 0 : props.$position === 'end' ? 0 : undefined};
    background: ${props =>
        props.$listDirection === 'row'
            ? props.$position === 'start'
                ? `linear-gradient(to left, transparent, ${Color.TimelineBackground})`
                : `linear-gradient(to right, transparent, ${Color.TimelineBackground})`
            : props.$position === 'start'
            ? `linear-gradient(to top, transparent, ${Color.TimelineBackground})`
            : `linear-gradient(to bottom, transparent, ${Color.TimelineBackground})`};
`

const BaseArrow = styled(Box)`
    background-color: ${Color.White};
    width: 32px;
    height: 32px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
`
