import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DiscountStore } from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import OnClickScrollList from 'aos-ui/src/components/list/OnClickScrollList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import DiscountModuleStoreImage from './DiscountModuleStoreImage'

type DiscountModuleStoreFilterBarProps = {
    discountStores: DiscountStore[]
    handleClearSelectedStores(): void
    handleSelectStore(id: number): void
    selectedStores: number[]
}

const DiscountModuleStoreFilterBar: FC<DiscountModuleStoreFilterBarProps> = ({
    discountStores,
    handleClearSelectedStores,
    handleSelectStore,
    selectedStores,
}) => {
    const sortedDiscountStores = useMemo(
        () => discountStores.sort((a, b) => a.name.localeCompare(b.name)),
        [discountStores],
    )

    const renderItem = useCallback(
        (store: DiscountStore) => (
            <FilterStoreElement onClick={() => handleSelectStore(store.id)}>
                <DiscountModuleStoreImage
                    {...store}
                    size={50}
                    selected={selectedStores.includes(store.id)}
                />
            </FilterStoreElement>
        ),
        [handleSelectStore, selectedStores],
    )

    return (
        <OnClickScrollList
            row
            columnGap={16}
            padding={4}
            items={sortedDiscountStores}
            renderItem={renderItem}
            keyExtractor={({ id }) => String(id)}
            firstListElement={
                <FilterButtonAll
                    $size={50}
                    onClick={handleClearSelectedStores}
                    $selected={selectedStores.length === 0}
                >
                    <Text size={14} color={Color.Black} textTransform='uppercase'>
                        {translate('discountModule.filters.allButtonLabel')}
                    </Text>
                </FilterButtonAll>
            }
        />
    )
}

export default DiscountModuleStoreFilterBar

const FilterStoreElement = styled(Box)`
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`

const FilterButtonAll = styled(Box)<{ $size: number; $selected?: boolean }>`
    border: solid ${Color.PrimaryPale} ${props => (props.$selected ? 2 : 0)}px;
    border-radius: 100%;
    background-color: ${Color.White};
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.$size}px;
    aspect-ratio: 1/1;
    box-shadow: ${props => (props.$selected ? `0px 0px 6px 0px ${Color.White}` : '')};
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`
