import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { TimelineAction } from 'aos-services/src/core/timeline/actions'
import { AosTimelineEvent } from 'aos-services/src/services/events/types/AosTimelineEvent'

import { EventTimelineFilter } from './state'

export const LOAD_TIMELINE_EVENTS = 'EVENT_TIMELINE/LOAD_EVENTS'
export type LoadTimelineEventsAction = Action<typeof LOAD_TIMELINE_EVENTS>
export const loadTimelineEventsAction =
    emptyActionCreator<LoadTimelineEventsAction>(LOAD_TIMELINE_EVENTS)

export const LOAD_EVENTS_SUCCESS = 'EVENT_TIMELINE/LOAD_EVENTS_SUCCESS'
export type LoadEventsSuccessAction = PayloadAction<
    typeof LOAD_EVENTS_SUCCESS,
    [AosTimelineEvent[], TimeRange | undefined]
>
export const loadEventsSuccessAction = payloadActionCreator<
    LoadEventsSuccessAction,
    [AosTimelineEvent[], TimeRange | undefined]
>(LOAD_EVENTS_SUCCESS)

export const TOGGLE_GROUP = 'EVENT_TIMELINE/TOGGLE_GROUP'
export type ToggleGroupAction = PayloadAction<typeof TOGGLE_GROUP, string>
export const toggleGroupAction = payloadActionCreator<ToggleGroupAction, string>(TOGGLE_GROUP)

export const SET_FILTER = 'EVENT_TIMELINE/SET_FILTER'
export type SetFilterAction = PayloadAction<typeof SET_FILTER, Partial<EventTimelineFilter>>
export const setFilterAction = payloadActionCreator<SetFilterAction, Partial<EventTimelineFilter>>(
    SET_FILTER,
)

export const OPEN_TIMELINE = 'EVENT_TIMELINE/OPEN_TIMELINE'
export type OpenTimelineAction = Action<typeof OPEN_TIMELINE>
export const openTimelineAction = emptyActionCreator<OpenTimelineAction>(OPEN_TIMELINE)

export const EXPORT_EVENTS = 'EVENT_TIMELINE/EXPORT_EVENTS'
export type ExportEventsAction = PayloadAction<typeof EXPORT_EVENTS, TimeRange>
export const exportEventsAction = payloadActionCreator<ExportEventsAction, TimeRange>(EXPORT_EVENTS)

export const EVENT_TIMELINE_TIMELINE_PARENT = 'EVENT_TIMELINE/EVENT_TIMELINE_TIMELINE_PARENT'
export type EventTimelineTimelineParentAction = PayloadAction<
    typeof EVENT_TIMELINE_TIMELINE_PARENT,
    TimelineAction
>
export const eventTimelineTimelineParentAction = payloadActionCreator<
    EventTimelineTimelineParentAction,
    TimelineAction
>(EVENT_TIMELINE_TIMELINE_PARENT)

export const SYNC_TIMELINE = 'EVENT_TIMELINE/SYNC_TIMELINE'
export type SyncTimelineAction = PayloadAction<typeof SYNC_TIMELINE, boolean>
export const syncTimelineAction = payloadActionCreator<SyncTimelineAction, boolean>(SYNC_TIMELINE)

export type EventTimelineAction =
    | LoadTimelineEventsAction
    | LoadEventsSuccessAction
    | ToggleGroupAction
    | SetFilterAction
    | EventTimelineTimelineParentAction
    | ExportEventsAction
