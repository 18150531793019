import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MaintenanceJobAssignmentPayload } from 'aos-services/src/services/tasks/types/payload/MaintenanceTaskFormPayload'
import { TaskFormPayload } from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { BrushesBrand } from 'aos-services/src/services/tasks/types/task/BrushesBrand'
import { BrushesVehicle } from 'aos-services/src/services/tasks/types/task/BrushesVehicle'
import {
    allMaintenanceTaskTypes,
    MaintenanceJob,
    MaintenanceTaskType,
} from 'aos-services/src/services/tasks/types/task/MaintenanceTask'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DropdownItemProps } from 'aos-ui/src/components/form/dropdown/base/BaseDropdown'
import {
    dropdownHeight,
    dropdownPadding,
} from 'aos-ui/src/components/form/dropdown/base/DropdownContent'
import { DropdownItem } from 'aos-ui/src/components/form/dropdown/base/DropdownItem'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledNumberInput } from 'aos-ui/src/components/form/labeled/LabeledNumberInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { maintenanceTaskTypeIcons } from 'aos-ui-task/src/components/task/MaintenanceTaskTypeIcon'
import { isNull, startCase } from 'lodash'
import React, { FC, useEffect, useState } from 'react'

interface MaintenanceJobAssignmentProps {
    assignment?: MaintenanceJobAssignmentPayload
    maintenanceJobs: MaintenanceJob[]
    metadata: TaskMetadata
    onChange(v: MaintenanceJobAssignmentPayload | undefined): void
}

export const MaintenanceJobAssignmentRenderer: FC<MaintenanceJobAssignmentProps> = ({
    assignment,
    maintenanceJobs,
    metadata,
    onChange,
}) => {
    const [maintenanceTaskType, setMaintenanceTaskType] = useState<MaintenanceTaskType>(
        assignment?.job?.jobType ?? MaintenanceTaskType.General,
    )
    const jobs = maintenanceJobs.filter(j => j.jobType === maintenanceTaskType)
    const brushesJob = maintenanceJobs.find(j => j.jobType === MaintenanceTaskType.Brushes)

    const clearJobState = (jobType: MaintenanceTaskType) => {
        if (jobType !== maintenanceTaskType) {
            setMaintenanceTaskType(jobType)
            onChange({ ...assignment, job: undefined })
        }
    }

    useEffect(() => {
        if (assignment?.job?.jobType) {
            setMaintenanceTaskType(assignment.job.jobType)
        }
    }, [assignment?.job])

    return (
        <Box flex={1} marginRight={16}>
            <Box alignItems='flex-start' row flex={1}>
                <Box>
                    <MaintenanceTaskTypeSelectorRenderer
                        onChange={clearJobState}
                        maintenanceTaskType={maintenanceTaskType}
                    />
                </Box>
                <Box flex={1} marginLeft={20}>
                    {maintenanceTaskType !== MaintenanceTaskType.Brushes && (
                        <DropdownAutocomplete
                            maxHeight={200}
                            value={assignment?.job}
                            items={jobs}
                            variant={DropdownVariant.White}
                            labelRenderer={v => v.label}
                            placeholder={translate(
                                'tasks.form.maintenance.assignments.job.placeholder',
                            )}
                            onChange={job =>
                                onChange({
                                    ...assignment,
                                    job: isNull(job) ? undefined : job,
                                    brushesVehicle: undefined,
                                    brushesBrand: undefined,
                                    engineLastShiftHours: undefined,
                                    brushesLastShiftHours: undefined,
                                    totalHours: undefined,
                                })
                            }
                            outlined
                        />
                    )}
                </Box>
            </Box>
            {maintenanceTaskType === MaintenanceTaskType.Brushes && (
                <Box flex={1} row>
                    <MaintenanceJobBrushesReplacementRenderer
                        assignment={assignment}
                        metadata={metadata}
                        onChange={brushesAssignment => {
                            onChange({ ...assignment, ...brushesAssignment, job: brushesJob })
                        }}
                    />
                </Box>
            )}
        </Box>
    )
}

interface MaintenanceTaskTypeSelectorRendererProps {
    maintenanceTaskType: MaintenanceTaskType
    onChange(v: MaintenanceTaskType): void
}

export const MaintenanceTaskTypeSelectorRenderer: FC<MaintenanceTaskTypeSelectorRendererProps> = ({
    maintenanceTaskType,
    onChange,
}) => {
    const itemRenderer = ({
        onClick,
        variant,
        v: item,
    }: DropdownItemProps<MaintenanceTaskType>) => (
        <DropdownItem
            onClick={onClick}
            variant={variant}
            isSelected={maintenanceTaskType === item}
            height={dropdownHeight(variant)}
            padding={dropdownPadding(variant)}
        >
            <Box flex={1} alignItems='center'>
                <Icon
                    marginRight={12}
                    iconSize={BlockSize.Std}
                    svg={maintenanceTaskTypeIcons[item]}
                />
                <Text as='span' size={12} weight='regular' paddingRight={4} color={Color.Grey}>
                    {item}
                </Text>
            </Box>
        </DropdownItem>
    )

    return (
        <Dropdown
            outlined
            value={maintenanceTaskType}
            items={allMaintenanceTaskTypes}
            width={DropdownWidth.Small}
            minDropWidth={120}
            valueRenderer={value => (
                <Box flex={1} alignItems='center'>
                    <Icon
                        iconVariant={IconVariant.GreyTxt}
                        iconSize={BlockSize.Std}
                        svg={maintenanceTaskTypeIcons[value]}
                    />
                </Box>
            )}
            onChange={onChange}
            itemRenderer={itemRenderer}
        />
    )
}

interface MaintenanceJobBrushesReplacementRendererProps {
    assignment?: MaintenanceJobAssignmentPayload
    metadata: TaskMetadata
    onChange(v: Partial<MaintenanceJobAssignmentPayload> | Partial<TaskFormPayload>): void
}

export const MaintenanceJobBrushesReplacementRenderer: FC<
    MaintenanceJobBrushesReplacementRendererProps
> = ({ assignment, metadata, onChange }) => (
    <Box flex={1} paddingTop={12}>
        <Box row alignItems='flex-start'>
            <Box flex={1}>
                <LabeledSelect
                    keyPrefix='tasks.form.maintenance.assignments.brushes-vehicle'
                    marginBottom={12}
                    isRequired
                    seleniumLocation='brushes-vehicle'
                    labelRenderer={(v: BrushesVehicle) => v.label}
                    value={assignment?.brushesVehicle}
                    items={metadata.brushesVehicles}
                    onChange={(brushesVehicle: BrushesVehicle) => {
                        if (brushesVehicle?.label === 'Other') {
                            onChange({ newBrushVehicle: undefined })
                        }
                        onChange({ brushesVehicle, title: startCase(brushesVehicle?.label) })
                    }}
                />
            </Box>
            <Box flex={1} marginLeft={20}>
                {assignment?.brushesVehicle?.label === 'Other' && (
                    <LabeledInput
                        keyPrefix='tasks.form.maintenance.assignments.new-brushes-vehicle'
                        marginBottom={12}
                        onChangeText={newBrushVehicle =>
                            onChange({ newBrushVehicle: newBrushVehicle })
                        }
                        value={assignment?.newBrushVehicle}
                        type='text'
                    />
                )}
            </Box>
        </Box>
        <Box row alignItems='flex-start'>
            <Box flex={1}>
                <LabeledSelect
                    keyPrefix='tasks.form.maintenance.assignments.brushes-brand'
                    marginBottom={12}
                    seleniumLocation='brushbrand'
                    items={metadata.brushesBrands}
                    labelRenderer={(v: BrushesBrand) => v.name}
                    value={assignment?.brushesBrand}
                    onChange={(brushesBrand: BrushesBrand) => onChange({ brushesBrand })}
                    isRequired
                />
            </Box>
            <Box flex={1} marginLeft={20}>
                <LabeledNumberInput
                    keyPrefix='tasks.form.maintenance.assignments.engine-last-shift-hours'
                    marginBottom={12}
                    seleniumLocation='engine-last-shift-hours'
                    value={assignment?.engineLastShiftHours}
                    onChange={engineLastShiftHours => onChange({ engineLastShiftHours })}
                    isRequired
                />
            </Box>
        </Box>
        <Box row alignItems='flex-start'>
            <Box flex={1}>
                <LabeledNumberInput
                    keyPrefix='tasks.form.maintenance.assignments.brushes-last-shift-hours'
                    marginBottom={12}
                    seleniumLocation='brushes-last-shift-hours'
                    value={assignment?.brushesLastShiftHours}
                    onChange={brushesLastShiftHours => onChange({ brushesLastShiftHours })}
                    isRequired
                />
            </Box>
            <Box flex={1} marginLeft={20}>
                <LabeledNumberInput
                    keyPrefix='tasks.form.maintenance.assignments.total-hours'
                    marginBottom={12}
                    seleniumLocation='total-hours'
                    value={assignment?.totalHours}
                    onChange={totalHours => onChange({ totalHours })}
                    isRequired
                />
            </Box>
        </Box>
    </Box>
)
