import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FeatureFlagStateAware } from 'aos-services/src/core/featureFlags/state'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

export interface MenuAboutProps {
    horizontal: boolean
    toggleWhatsNew(v: boolean): void
}

export const MenuAbout: FC<MenuAboutProps> = props => {
    const { horizontal, toggleWhatsNew } = props

    const whatsNewVisible = useSelector(
        (state: FeatureFlagStateAware) => state.featureFlags.flags.whatsNew,
    )

    if (!whatsNewVisible) {
        return null
    }

    return (
        <Box as='button' paddingBottom={20} row centered onClick={() => toggleWhatsNew(true)}>
            <Icon
                iconSize={BlockSize.Std}
                iconVariant={IconVariant.BlackGrey}
                svg={SvgIcon.Help}
                marginRight={8}
            />
            {horizontal && (
                <Text as='span' size={12} color={Color.SideMenuInactive}>
                    {translate('generic.about-aos')}
                </Text>
            )}
        </Box>
    )
}
