import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    canEditTasksSelector,
    isApocSelector,
    isHelSelector,
} from 'aos-services/src/core/auth/state'
import { FeatureFlagType } from 'aos-services/src/dataaccess/firebase/FirebaseFeatureFlagService'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FeatureFlag } from 'aos-ui/src/components/featureFlag/FeatureFlag'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
    createEventShowModalAction,
    reportSimpleFeedInShowModalAction,
} from '../../core/eventModals/actions'
import { setFodCreateModalOpenAction } from '../../core/fod/actions'
import { closeReportingModalAction } from '../../core/reporting/actions'
import { isReportingModalOpenSelector } from '../../core/reporting/selectors'
import { setTaskCreateModalOpenAction } from '../../core/tasks/actions'
import { openFireFighterFormAction } from '../../core/tasks/firefighters/actions'
import { openShiftNotesModalAction } from '../../core/tasks/shiftNotes/actions'
import { ReportingItem } from './ReportingItem'

const EMERGENCY_NUMBER = '112'
const SECURITY_CONTROL_ROOM_NUMBER = '+358 20 708 3710'

export const ReportingModal = () => {
    const isOpen = useSelector(isReportingModalOpenSelector)
    const dispatch = useDispatch()
    const canEditTasks = useSelector(canEditTasksSelector)
    const isApoc = useSelector(isApocSelector)
    const isHel = useSelector(isHelSelector)

    const onClose = () => {
        dispatch(closeReportingModalAction())
    }

    return (
        <Modal
            title={translate('reporting.modal.title')}
            modalKind={ModalKind.Medium}
            isOpen={isOpen}
            closeAction={onClose}
            height={700}
        >
            <LightScrollbar autoHeightMin='100%' autoHeightMax='100%'>
                <Box padding={24}>
                    <ReportingItem
                        title={translate('reporting.event.title')}
                        description={translate('reporting.event.description')}
                        icon={SvgIcon.Events}
                        onClick={() => {
                            if (isApoc) {
                                dispatch(createEventShowModalAction())
                            } else {
                                dispatch(reportSimpleFeedInShowModalAction())
                            }
                            onClose()
                        }}
                    />
                    <ReportingItem
                        title={translate('reporting.fod.title')}
                        description={translate('reporting.fod.description')}
                        icon={SvgIcon.TaskInspectionFod}
                        onClick={() => {
                            dispatch(setFodCreateModalOpenAction(true))
                            onClose()
                        }}
                    />
                    {canEditTasks && (
                        <>
                            <ReportingItem
                                title={translate('reporting.task.title')}
                                description={translate('reporting.task.description')}
                                icon={SvgIcon.Assignment}
                                onClick={() => {
                                    dispatch(setTaskCreateModalOpenAction(true))
                                    onClose()
                                }}
                            />
                            <ReportingItem
                                title={translate('reporting.shift-note.title')}
                                description={translate('reporting.shift-note.description')}
                                icon={SvgIcon.ShiftNote}
                                onClick={() => {
                                    dispatch(openShiftNotesModalAction())
                                    onClose()
                                }}
                            />
                            <ReportingItem
                                title={translate('reporting.firefighters.title')}
                                description={translate('reporting.firefighters.description')}
                                icon={SvgIcon.Firefighters}
                                onClick={() => {
                                    dispatch(openFireFighterFormAction(undefined))
                                    onClose()
                                }}
                            />
                        </>
                    )}
                    <FeatureFlag flag={FeatureFlagType.ReportingContactsButton}>
                        <Box row>
                            <DividingLine />
                            <Text marginHorizontal={48} size={14} color={Color.TimelineBackground}>
                                {translate('reporting.or')}
                            </Text>
                            <DividingLine />
                        </Box>
                        <ReportingItem
                            title={translate('reporting.contacts.title')}
                            description={translate('reporting.contacts.description')}
                            icon={SvgIcon.Mail}
                            onClick={onClose}
                        />
                    </FeatureFlag>
                    <Box
                        rowGap={12}
                        column
                        bg={Color.Yellow}
                        paddingHorizontal={18}
                        paddingVertical={20}
                    >
                        <Text size={14} color={Color.Black}>
                            {translate('reporting.emergency-note')}
                        </Text>
                        <Box columnGap={16} row>
                            <Text size={14} color={Color.Black}>
                                {translate('reporting.emergency-phone')}
                            </Text>
                            <Text size={20} color={Color.Black}>
                                {EMERGENCY_NUMBER}
                            </Text>
                        </Box>
                        {isHel && (
                            <Box columnGap={16} row>
                                <Text size={14} color={Color.Black}>
                                    {translate('reporting.security-phone')}
                                </Text>
                                <Text size={20} color={Color.Black}>
                                    {SECURITY_CONTROL_ROOM_NUMBER}
                                </Text>
                            </Box>
                        )}
                    </Box>
                </Box>
            </LightScrollbar>
        </Modal>
    )
}

const DividingLine = styled(Box)`
    border-bottom: 1px solid ${Color.Grey};
    width: 100%;
`
