import { attachmentReducer } from 'aos-services/src/core/attachments/reducer'
import {
    CLEAR_SELECTED_DISCOUNT_STORE_FILTER,
    LOAD_DISCOUNT_COUPONS_SUCCESS,
    LOAD_DISCOUNT_STORES_SUCCESS,
    SET_SELECTED_DISCOUNT_STORE_FILTER,
} from 'aos-services/src/core/discountModule/actions'

import {
    CLEAR_DISCOUNT_MODULE_ATTACHMENTS,
    CLOSE_CREATE_DISCOUNT_STORE_MODAL,
    CLOSE_MANAGE_DISCOUNT_STORES_MODAL,
    CLOSE_SAVE_DISCOUNT_COUPON_MODAL,
    DISCOUNT_MODULE_ATTACHMENTS,
    DiscountModuleActions,
    OPEN_CREATE_DISCOUNT_STORE_MODAL,
    OPEN_MANAGE_DISCOUNT_STORES_MODAL,
    OPEN_SAVE_DISCOUNT_COUPON_MODAL,
    SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL,
} from './actions'
import { initialDiscountModuleState } from './state'

export const discountModuleReducer = (
    state = initialDiscountModuleState,
    action: DiscountModuleActions,
) => {
    switch (action.type) {
        case LOAD_DISCOUNT_COUPONS_SUCCESS:
            return { ...state, discountCoupons: action.payload }
        case LOAD_DISCOUNT_STORES_SUCCESS:
            return { ...state, discountStores: action.payload }
        case SET_SELECTED_DISCOUNT_STORE_FILTER:
            return {
                ...state,
                selectedFilterStores: state.selectedFilterStores.includes(action.payload)
                    ? state.selectedFilterStores.filter(id => id !== action.payload)
                    : [...state.selectedFilterStores, action.payload],
            }
        case CLEAR_SELECTED_DISCOUNT_STORE_FILTER:
            return { ...state, selectedFilterStores: [] }
        case OPEN_SAVE_DISCOUNT_COUPON_MODAL:
            return {
                ...state,
                saveDiscountCouponModalOpen: true,
                editedDiscountCoupon: action.payload,
            }
        case CLOSE_SAVE_DISCOUNT_COUPON_MODAL:
            return { ...state, saveDiscountCouponModalOpen: false, editedDiscountCoupon: undefined }
        case OPEN_CREATE_DISCOUNT_STORE_MODAL:
            return { ...state, createDiscountStoreModalOpen: true }
        case CLOSE_CREATE_DISCOUNT_STORE_MODAL:
            return { ...state, createDiscountStoreModalOpen: false }
        case OPEN_MANAGE_DISCOUNT_STORES_MODAL:
            return { ...state, manageDiscountStoresModalOpen: true }
        case CLOSE_MANAGE_DISCOUNT_STORES_MODAL:
            return { ...state, manageDiscountStoresModalOpen: false }
        case DISCOUNT_MODULE_ATTACHMENTS:
            return {
                ...state,
                attachmentsState: attachmentReducer(state.attachmentsState, action.payload),
            }
        case CLEAR_DISCOUNT_MODULE_ATTACHMENTS:
            return {
                ...state,
                attachmentsState: { isUploading: false, attachments: [] },
            }
        case SET_DISCOUNT_MODULE_ATTACHMENTS_MANUAL: {
            return {
                ...state,
                attachmentsState: { isUploading: false, attachments: action.payload },
            }
        }
        default:
            return state
    }
}
