import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { splitAssignees, TaskLog, TaskLogType } from 'aos-services/src/services/tasks/types/TaskLog'
import { translateTaskTitle } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { ChipList } from 'aos-ui/src/components/chip/ChipList'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Pipe } from 'aos-ui/src/components/text/Pipe'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface TaskLogBubbleProps {
    log: TaskLog
}

export const TaskLogBubble: FC<TaskLogBubbleProps> = ({ log }) => {
    const notPermittedInfoMessages = ['created', 'updated']

    const header = (
        <Text
            selectable
            color={Color.TextSecondary}
            size={12}
            lineHeight='standard'
            paddingBottom={16}
        >
            <Text as='span' size={12} color={Color.TextSecondary}>
                {log.createdBy}
            </Text>
            <Pipe />
            {formatCalendarTime(log.createdAt)}
        </Text>
    )

    switch (log.type) {
        case TaskLogType.Assign:
            const [assigned, unassigned] = splitAssignees(log)
            return (
                <Box paddingBottom={30}>
                    {header}
                    {!!unassigned.length && (
                        <Box row>
                            <Text selectable color={Color.White} size={12} paddingRight={8}>
                                {translate('tasks.log.unassigned')}
                            </Text>
                            <ChipList
                                items={unassigned}
                                renderer={a => a}
                                horizontalSpacing={6}
                                verticalSpacing={6}
                                crossed
                            />
                        </Box>
                    )}
                    {!!assigned.length && (
                        <Box row>
                            <Text selectable color={Color.White} size={12} paddingRight={8}>
                                {translate('tasks.log.assigned')}
                            </Text>
                            <ChipList
                                items={assigned}
                                renderer={a => a}
                                horizontalSpacing={6}
                                verticalSpacing={6}
                            />
                        </Box>
                    )}
                </Box>
            )

        case TaskLogType.Status:
            return (
                <Box paddingBottom={30}>
                    {header}
                    <Box row>
                        <Text selectable color={Color.White} size={12}>
                            {translate('tasks.log.changed')}
                        </Text>
                        <BorderedChip marginHorizontal={8}>
                            {translateTaskTitle(log.from)}
                        </BorderedChip>
                        <Icon svg={SvgIcon.Forward} iconSize={BlockSize.XTiny} />
                        <BorderedChip marginHorizontal={8}>
                            {translateTaskTitle(log.to)}
                        </BorderedChip>
                    </Box>
                </Box>
            )

        case TaskLogType.Message:
            return (
                <Box paddingBottom={30}>
                    {header}
                    <MessageBubbleBox
                        padding={20}
                        lineHeight='large'
                        weight='light'
                        size={14}
                        color={Color.TimelineBackground}
                        selectable
                        whiteSpace='pre-line'
                    >
                        {log.message}
                    </MessageBubbleBox>
                </Box>
            )

        case TaskLogType.Info:
            return !notPermittedInfoMessages.includes(log.messageKey) ? (
                <Box paddingBottom={30}>
                    {header}
                    <Box row>
                        <Text selectable color={Color.White} size={12}>
                            {log.messageKey}
                        </Text>
                    </Box>
                </Box>
            ) : null

        default:
            return null
    }
}

const MessageBubbleBox = styled(Text)`
    position: relative;
    background: ${Color.White};
    padding: 16px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -8px;
        left: 0;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent transparent ${Color.White};
    }
`
