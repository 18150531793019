import { appConfig } from 'appConfig'

import AOIMS from './aoims/index'
import AOS from './aos/index'

const iconConfig: Record<string, any> = {
    AOIMS,
    AOS,
}
export const AirportIconsSvg = iconConfig[appConfig.theme]
