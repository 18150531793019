import {
    generateGauge,
    GenerateGaugeReturn,
} from 'aos-ui-common/src/components/gauge/generateGauge'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'
import { GaugeStatus } from 'aos-ui-common/src/components/gauge/types/GaugeStatus'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { BaseGauge } from './BaseGauge'

export class GaugePlaceholder extends PureComponent<GaugePlaceholderProps> {
    public render() {
        return <BaseGauge contentRenderer={this.renderContent} />
    }

    public renderContent = (size: number) => {
        const currentValue = null
        const ranges: GaugeRange[] = [{ from: 0, to: 1, status: GaugeStatus.Normal }]

        const gauge = generateGauge({ size, currentValue, ranges })

        return (
            <>
                {this.renderTexts()}
                {this.renderArcs(gauge)}
            </>
        )
    }

    private renderText = (text: string, index: number) => (
        <text
            key={index}
            y={index * 16}
            fontSize={14}
            fill={Color.WidgetPrimary}
            dominantBaseline='middle'
            textAnchor='middle'
        >
            {text}
        </text>
    )

    private renderTexts() {
        return this.props.noDataText.split('\n').map(this.renderText)
    }

    private renderArcs(gauge: GenerateGaugeReturn): React.ReactNode {
        return gauge.arcs.map((arc, index) => (
            <path key={index} fill={Color.ChartBase} d={arc.path} />
        ))
    }
}

interface GaugePlaceholderProps {
    noDataText: string
}
