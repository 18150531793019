import { DiscountModuleStateAware } from './state'

export const discountCouponsSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.discountCoupons

export const discountStoresSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.discountStores

export const selectedFilterStoresSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.selectedFilterStores

export const saveDiscountModuleOpenSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.saveDiscountCouponModalOpen

export const editedDiscountCouponsSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.editedDiscountCoupon

export const createDiscountStoreModalSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.createDiscountStoreModalOpen

export const manageDiscountStoresModalSelector = (state: DiscountModuleStateAware) =>
    state.discountModule.manageDiscountStoresModalOpen

export const discountModuleAttachmentsState = (state: DiscountModuleStateAware) =>
    state.discountModule.attachmentsState
