import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    createDiscountStore,
    manageDiscountStores,
    saveDiscountCoupon,
} from '../../../core/discountModule/actions'
import { State } from '../../../core/state'

const DiscountModuleHeader = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector((state: State) => state.auth.currentUser)

    return (
        <Header variant={HeaderVariant.Dialog} paddingHorizontal={24} justify='space-between'>
            <Text>{translate('menu.discounts')}</Text>
            {currentUser.canEditDiscounts() ? (
                <More>
                    <MoreItem onClick={() => dispatch(saveDiscountCoupon(undefined))}>
                        {translate('discountModule.menu.addCoupon')}
                    </MoreItem>
                    <MoreItem onClick={() => dispatch(createDiscountStore())}>
                        {translate('discountModule.menu.addStore')}
                    </MoreItem>
                    <MoreItem onClick={() => dispatch(manageDiscountStores())}>
                        {translate('discountModule.menu.manageStores')}
                    </MoreItem>
                </More>
            ) : null}
        </Header>
    )
}

export default DiscountModuleHeader
