import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    WeatherCategory,
    weatherLvpProbabilityThreshold,
    weatherThunderstormThreshold,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { getWeatherIconForWeatherCategory } from '../WeatherSettings'

interface WeatherIconProps {
    weatherCategory?: WeatherCategory
    lvp: number
    thunderstorm: number
}

export const WeatherIcon: FC<WeatherIconProps> = props => {
    const { weatherCategory, lvp, thunderstorm } = props

    const weatherIcon = weatherCategory
        ? getWeatherIconForWeatherCategory(weatherCategory)
        : undefined
    const [icon, color] = weatherIcon || [null, Color.Background]
    return (
        <Box
            className='dashboard-weather__icon'
            shrink={0}
            marginRight={20}
            style={{ backgroundColor: color }}
        >
            {icon && (
                <Tooltip
                    body={translateWeatherCategory(weatherCategory!)}
                    placement='top'
                    withArrow
                >
                    <div>
                        <Icon
                            svg={icon}
                            iconSize={BlockSize.XXLarge}
                            iconVariant={IconVariant.White}
                        />
                    </div>
                </Tooltip>
            )}
            <Box className='dashboard-weather__markers'>
                {lvp > weatherLvpProbabilityThreshold && (
                    <Tooltip
                        body={translate('dashboard.weather.lvp-tooltip')}
                        placement='topLeft'
                        withArrow
                    >
                        <div>
                            <Box marginBottom={8}>
                                <Image svg={SvgIcon.WeatherLvp} />
                            </Box>
                        </div>
                    </Tooltip>
                )}
                {thunderstorm > weatherThunderstormThreshold && (
                    <Tooltip
                        body={translate('dashboard.weather.thunderstorm-tooltip')}
                        placement='topLeft'
                        withArrow
                    >
                        <div>
                            <Image svg={SvgIcon.WeatherLightingProbability} />
                        </div>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

const Image = styled(SvgImage)`
    width: 32px;
    height: 32px;
`

const translateWeatherCategory = translateEnum(WeatherCategory, 'dashboard.weather.category')
