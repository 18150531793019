import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { BoxProps } from '../base/Box'
import { Text, TextSize } from '../base/Text'
import { ThemeVariant } from '../base/ThemeVariant'

interface CheckableLabelProps extends BoxProps {
    variant: ThemeVariant
    fontSize: TextSize
    checked: boolean
}

const colors: Record<ThemeVariant, { normal: Color; checked: Color }> = {
    [ThemeVariant.White]: {
        normal: Color.Grey2,
        checked: Color.Grey,
    },
    [ThemeVariant.Black]: {
        normal: Color.Grey,
        checked: Color.White,
    },
}

export const CheckableLabel: FCWithChildren<CheckableLabelProps> = ({
    variant,
    fontSize,
    children,
    checked,
    ...boxProps
}) => (
    <Text
        color={checked ? colors[variant].checked : colors[variant].normal}
        weight='regular'
        size={fontSize}
        {...boxProps}
    >
        {children}
    </Text>
)
