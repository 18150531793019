import { cx } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosUser, isAosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { mapAosUserTypeToColor } from 'aos-services/src/services/users/types/AosUserType'
import { Text } from 'aos-ui/src/components/base/Text'
import { ColorIndicator } from 'aos-ui/src/components/indicators/ColorIndicator'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import { UserPhoto } from 'aos-ui/src/components/UserPhoto'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface UserListItemProps {
    user: AosUser
    isSelected: boolean
    onClick?(): void
    onEditUser?(): void
    onRemoveUser?(): void
    onRemoveUserFromGroup?(): void
    onRestoreUser?(): void
    onRemoveUserPermanently?(): void
}

export const UserListItem: FC<UserListItemProps> = props => {
    const { user } = props
    return (
        <MoreHover
            className={cx(
                'container container--horizontal',
                'padding-horizontal--large padding-vertical--decreased',
            )}
            bg={props.isSelected ? Color.TimelineBackground : Color.DarkBackground}
            onClick={props.onClick}
            data-test-id='user-list-item'
        >
            <UserPhoto base64Img={isAosAdUser(user) ? user.thumbnail : ''} />
            <Text
                flex={1}
                paddingLeft={16}
                size={14}
                color={Color.White}
                selectable
                ellipsis
                wordBreak
            >
                {user.name}
            </Text>
            <ColorIndicator
                color={mapAosUserTypeToColor[props.user.type]}
                marginRight={12}
                isAbsolutePositioned
            />
            <More>
                <MoreItem onClick={props.onEditUser}>
                    {translate('group-manager.edit-user')}
                </MoreItem>
                <MoreItem onClick={props.onRemoveUserFromGroup}>
                    {translate('group-manager.remove-user-from-group')}
                </MoreItem>
                <MoreItem onClick={props.onRemoveUser}>
                    <span className='text__m-red'>{translate('group-manager.remove-user')}</span>
                </MoreItem>
                <MoreItem onClick={props.onRestoreUser}>
                    {translate('group-manager.restore-user')}
                </MoreItem>
                <MoreItem onClick={props.onRemoveUserPermanently}>
                    <span className='text__m-red'>
                        {translate('group-manager.remove-user-permanently')}
                    </span>
                </MoreItem>
            </More>
        </MoreHover>
    )
}
