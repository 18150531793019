import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { waitingTimeConfig } from 'aos-services/src/core/statusDashboardData/pax/waitingTimeConfig'
import { HorizontalLegend } from 'aos-ui/src/components/chart/HorizontalLegend'
import { LegendItem } from 'aos-ui/src/components/ui/LegendItem'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const PaxLegend: FC = () => (
    <HorizontalLegend
        rightItems={[
            <LegendItem
                key='short-waiting-time'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.Green,
                }}
            >
                {waitingTimeConfig.SHORT.label}
            </LegendItem>,
            <LegendItem
                key='medium-waiting-time'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.Yellow,
                }}
            >
                {waitingTimeConfig.MEDIUM.label}
            </LegendItem>,
            <LegendItem
                key='long-waiting-time'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.Red,
                }}
            >
                {waitingTimeConfig.LONG.label}
            </LegendItem>,
            <LegendItem
                key='security-lines'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.Grey3,
                }}
            >
                {translate('dashboard.pax.legend.security-lines')}
            </LegendItem>,
        ]}
        leftItems={[
            <LegendItem
                key='pax-forecast'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.White,
                    variant: LegendDotVariant.Line,
                }}
            >
                {translate('dashboard.pax.legend.pax-forecast')}
            </LegendItem>,
            <LegendItem
                key='current-time'
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: Color.PrimaryLight,
                    variant: LegendDotVariant.VerticalLine,
                }}
            >
                {translate('dashboard.pax.legend.current-time')}
            </LegendItem>,
        ]}
    />
)
