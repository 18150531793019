import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    DiscountArea,
    DiscountCoupon,
} from 'aos-services/src/services/discountModule/types/DiscountCoupon'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

import DiscountCouponListElement from './DiscountCouponListElement'

type DiscountModuleListColumnProps = {
    discountCoupons?: DiscountCoupon[]
    discountArea: DiscountArea
}
const DiscountModuleListColumn = ({
    discountCoupons = [],
    discountArea,
}: DiscountModuleListColumnProps) => {
    return (
        <Box flex={1} column rowGap={20} padding={20} paddingBottom={0} height='100%'>
            <Text size={14} color={Color.GreyLight}>
                {discountArea}
            </Text>
            <DarkScrollbar>
                <List>
                    {discountCoupons.length ? (
                        discountCoupons.map(discountCoupon => (
                            <li key={discountCoupon.id}>
                                <DiscountCouponListElement {...discountCoupon} />
                            </li>
                        ))
                    ) : (
                        <Box column rowGap={12} grow={1} alignItems='center' justify='center'>
                            <SvgImage svg={SvgIcon.NoData} attributes={{ width: 52, height: 52 }} />
                            <Text size={12} color={Color.Grey2}>
                                {translate('discountModule.list.emptyLabel')}
                            </Text>
                        </Box>
                    )}
                </List>
            </DarkScrollbar>
        </Box>
    )
}

export default DiscountModuleListColumn

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    min-width: 300px;
`
