import React from 'react'

import CreateDiscountStoreModal from './CreateDiscountStoreModal'
import ManageDiscountStoresModal from './ManageDiscountStoresModal'
import SaveDiscountCouponModal from './SaveDiscountCouponModal'
export const DiscountModuleModals = () => (
    <>
        <SaveDiscountCouponModal />
        <CreateDiscountStoreModal />
        <ManageDiscountStoresModal />
    </>
)
