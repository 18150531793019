import { chainValidators, required, useBindForm, useForm } from 'aos-form'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { firetrucksLoadAction } from 'aos-services/src/core/firefighters/actions'
import {
    FirefighterFiretruck,
    FirefighterFormFields,
    FiretruckMember,
} from 'aos-services/src/services/tasks/firefighters/types/FirefighterFormFields'
import { FireTruck } from 'aos-services/src/services/tasks/types/task/FireTruck'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { FormLabel } from 'aos-ui/src/components/form/labeled/FormLabel'
import { LabeledDatetimeInput } from 'aos-ui/src/components/form/labeled/LabeledDatetimeInput'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
    firetrucksFormLoadingSelector,
    firetrucksListSelector,
} from '../../../../core/tasks/firefighters/selectors'
import { LabeledUserNameAutocomplete } from '../../common/form/LabeledUserNameAutocomplete'
import { FirefighterFormFiretruck } from './FirefighterFormFiretruck'
import { notEmpty, validFirefighterFiretrucks, validValidUntilDate } from './validators'

interface FirefighterFormProps extends FirefighterFormStateProps, FirefighterFormDispatchProps {
    initialState: FirefighterFormFields
    validFromDate: DateTime
    onSubmit(data: FirefighterFormFields): void
    onClose(): void
}

const FirefighterFormComponent: FC<FirefighterFormProps> = ({
    firetrucks,
    onClose,
    loadFiretruck,
    initialState,
    validFromDate,
    onSubmit,
    isLoading,
}) => {
    useEffect(() => {
        loadFiretruck()
    }, [])

    const formApi = useForm<FirefighterFormFields>({
        initialState: initialState,
        validators: {
            reporter: required(),
            validUntil: chainValidators(required(), validValidUntilDate(validFromDate)),
            fireFighterSquads: chainValidators(notEmpty(), validFirefighterFiretrucks()),
        },
    })

    const { submitHandler, values, updateFieldValue, getFieldError } = formApi
    const { bindLabeledDatetimeInput, bindDropdown } = useBindForm(formApi)

    const updateFirefighterMembers = (fireFighters: FiretruckMember[], metaTruck: FireTruck) => {
        const sameFiretruck = (firetruck: FirefighterFiretruck) =>
            firetruck.fireTruckId === metaTruck.id

        // Force only one lp3/kat12 selected
        const hasLp3 = fireFighters.some(member => member.lp3)
        const hasLp5 = fireFighters.some(member => member.lp5)
        const hasKat12 = fireFighters.some(member => member.kat12)
        const currentFiretrucks = values.fireFighterSquads.map(firetruck => ({
            ...firetruck,
            fireFighters: firetruck.fireFighters.map(member => ({
                ...member,
                lp3: hasLp3 ? false : member.lp3,
                lp5: hasLp5 ? false : member.lp5,
                kat12: hasKat12 ? false : member.kat12,
            })),
        }))

        // Remove squad when fireFighters is empty
        if (!fireFighters.length) {
            return updateFieldValue(
                'fireFighterSquads',
                currentFiretrucks.filter(firetruck => !sameFiretruck(firetruck)),
            )
        }

        // Update existing squad
        if (values.fireFighterSquads.some(sameFiretruck)) {
            return updateFieldValue(
                'fireFighterSquads',
                currentFiretrucks.map(firetruck =>
                    sameFiretruck(firetruck) ? { ...firetruck, fireFighters } : firetruck,
                ),
            )
        }

        // Add new squad
        return updateFieldValue('fireFighterSquads', [
            ...currentFiretrucks,
            { fireTruckId: metaTruck.id, fireFighters },
        ])
    }

    const findTruckMembers = (metaTruck: FireTruck) => {
        const truck = values.fireFighterSquads.find(
            firetruck => firetruck.fireTruckId === metaTruck.id,
        )
        return truck?.fireFighters || []
    }

    const fireFighterSquadsError = getFieldError('fireFighterSquads')

    return (
        <Modal
            id='firefighter-form-modal'
            title={translate('firefighters.modal.title')}
            modalKind={ModalKind.Standard}
            closeAction={onClose}
            footer={
                <>
                    <FormButton
                        label={translate('firefighters.form.save')}
                        onClick={submitHandler(onSubmit)}
                        isLoading={isLoading}
                    />

                    {fireFighterSquadsError && (
                        <Box flex={1} row>
                            <Icon
                                iconSize={BlockSize.Std}
                                svg={SvgIcon.OtherRed}
                                iconVariant={IconVariant.Red}
                                marginHorizontal={12}
                            />
                            <FormLabel isError>{fireFighterSquadsError}</FormLabel>
                        </Box>
                    )}
                </>
            }
            isOpen
        >
            <Box flex={1} alignItems='stretch' row>
                <Box flex={2.5}>
                    <LightScrollbar>
                        <Box paddingVertical={16}>
                            {firetrucks ? (
                                firetrucks.map((metaTruck, index) => (
                                    <Box key={metaTruck.id}>
                                        <FirefighterFormFiretruck
                                            truck={metaTruck}
                                            members={findTruckMembers(metaTruck)}
                                            onChange={members =>
                                                updateFirefighterMembers(members, metaTruck)
                                            }
                                            showHeader={index === 0}
                                            isError={!!fireFighterSquadsError}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <Spinner onLight />
                            )}
                        </Box>
                    </LightScrollbar>
                </Box>

                <BorderedBox flex={1} padding={30}>
                    <LabeledDatetimeInput
                        keyPrefix='firefighters.form.valid-till'
                        marginBottom={16}
                        roundMinutesTo={15}
                        isRequired
                        {...bindLabeledDatetimeInput('validUntil')}
                    />

                    <LabeledUserNameAutocomplete
                        keyPrefix='firefighters.form.reporter'
                        isRequired
                        {...bindDropdown('reporter')}
                    />
                </BorderedBox>
            </Box>
        </Modal>
    )
}

interface FirefighterFormStateProps {
    firetrucks?: FireTruck[]
    isLoading: boolean
}

interface FirefighterFormDispatchProps {
    loadFiretruck: typeof firetrucksLoadAction
}

export const FirefighterForm = connect<FirefighterFormStateProps, FirefighterFormDispatchProps>(
    createStructuredSelector({
        firetrucks: firetrucksListSelector,
        isLoading: firetrucksFormLoadingSelector,
    }),
    {
        loadFiretruck: firetrucksLoadAction,
    },
)(FirefighterFormComponent)

const BorderedBox = styled(Box)`
    border-left: 1px solid ${Color.DisabledText};
    background: ${Color.Grey1};
`
