import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { TaskExport } from 'aos-helpers/src/helpers/TaskExport'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { TimelineAction } from 'aos-services/src/core/timeline/actions'
import {
    TaskChecklistItemChange,
    TaskRecurrentChecklistItemChange,
    TaskRecurrentFirstChecklistItemChange,
} from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { TaskUpdateMode } from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import {
    TaskActionPayload,
    TaskFormPayload,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import {
    TaskPageable,
    TaskPageableFilters,
} from 'aos-services/src/services/tasks/types/TaskPageable'
import { TaskProcessType } from 'aos-services/src/services/tasks/types/TaskProcessType'
import { TaskId, TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { TaskDuration, TaskStatusUpdate } from 'aos-services/src/services/tasks/types/TaskStatus'
import { AnimalsReportData } from 'aos-services/src/services/tasksReporting/types/AnimalsReportData'
import { ChemicalsReportData } from 'aos-services/src/services/tasksReporting/types/ChemicalsReportData'
import { MapAction, MapActionCreators, mapActionCreators } from 'aos-ui-map/src/core/actions'

import { ReportsFilters } from './reports/state'
import { TimelineFilterState } from './state'

export const LOAD_TASKS = 'TASKS/LOAD_TASKS'
export type LoadTasksAction = PayloadAction<typeof LOAD_TASKS, TaskPageableFilters>
export const loadTasksAction = payloadActionCreator<LoadTasksAction, TaskPageableFilters>(
    LOAD_TASKS,
)

export const LOAD_TASKS_SUCCESS = 'TASKS/LOAD_TASKS_SUCCESS'
export type LoadTasksSuccessAction = PayloadAction<typeof LOAD_TASKS_SUCCESS, TaskPageable>
export const loadTasksSuccessAction = payloadActionCreator<LoadTasksSuccessAction, TaskPageable>(
    LOAD_TASKS_SUCCESS,
)

export const RELOAD_TASKS = 'TASKS/RELOAD_TASKS'
export type ReloadTasksAction = Action<typeof RELOAD_TASKS>
export const reloadTasksAction = emptyActionCreator<ReloadTasksAction>(RELOAD_TASKS)

export const OPEN_TASK = 'TASKS/OPEN_TASK'
export type OpenTaskAction = PayloadAction<typeof OPEN_TASK, [TaskFilter, TaskId]>
export const openTaskAction = payloadActionCreator<OpenTaskAction, [TaskFilter, TaskId]>(OPEN_TASK)

export const EDIT_TASK = 'TASKS/EDIT_TASK'
export type EditTaskAction = PayloadAction<typeof EDIT_TASK, [Task, TaskUpdateMode]>
export const editTaskAction = payloadActionCreator<EditTaskAction, [Task, TaskUpdateMode]>(
    EDIT_TASK,
)

export const OPEN_TASK_FOR_EDIT = 'TASKS/OPEN_TASK_FOR_EDIT'
export type OpenTaskForEditAction = PayloadAction<
    typeof OPEN_TASK_FOR_EDIT,
    [Task, TaskUpdateMode, AosAirport]
>
export const openTaskForEditAction = payloadActionCreator<
    OpenTaskForEditAction,
    [Task, TaskUpdateMode, AosAirport]
>(OPEN_TASK_FOR_EDIT)

export const DELETE_TASK = 'TASKS/DELETE_TASK'
export type DeleteTaskAction = PayloadAction<typeof DELETE_TASK, [TaskId, TaskUpdateMode]>
export const deleteTaskAction = payloadActionCreator<DeleteTaskAction, [TaskId, TaskUpdateMode]>(
    DELETE_TASK,
)

export const CHANGE_STATUS = 'TASKS/CHANGE_STATUS'
export type ChangeStatusAction = PayloadAction<
    typeof CHANGE_STATUS,
    [TaskStatusUpdate, TaskId, TaskUpdateMode]
>
export const changeStatusAction = payloadActionCreator<
    ChangeStatusAction,
    [TaskStatusUpdate, TaskId, TaskUpdateMode]
>(CHANGE_STATUS)

export const SEND_MESSAGE = 'TASKS/SEND_MESSAGE'
export type SendMessageAction = PayloadAction<typeof SEND_MESSAGE, [string, TaskId]>
export const sendMessageAction = payloadActionCreator<SendMessageAction, [string, TaskId]>(
    SEND_MESSAGE,
)

export const SEND_MESSAGE_SUCCESS = 'TASKS/SEND_MESSAGE_SUCCESS'
export type SendMessageSuccessAction = Action<typeof SEND_MESSAGE_SUCCESS>
export const sendMessageSuccessAction =
    emptyActionCreator<SendMessageSuccessAction>(SEND_MESSAGE_SUCCESS)

export const SELECT_TASK = 'TASKS/SELECT_TASK'
export type SelectTaskAction = PayloadAction<typeof SELECT_TASK, TaskId>
export const selectTaskAction = payloadActionCreator<SelectTaskAction, TaskId>(SELECT_TASK)

export const LOAD_TASK = 'TASKS/LOAD_TASK'
export type LoadTaskAction = PayloadAction<typeof LOAD_TASK, TaskId>
export const loadTaskAction = payloadActionCreator<LoadTaskAction, TaskId>(LOAD_TASK)

export const LOAD_TASK_SUCCESS = 'TASKS/LOAD_TASK_SUCCESS'
export type LoadTaskSuccessAction = PayloadAction<typeof LOAD_TASK_SUCCESS, Task>
export const loadTaskSuccessAction = payloadActionCreator<LoadTaskSuccessAction, Task>(
    LOAD_TASK_SUCCESS,
)

export const LOAD_TASK_FAILED = 'TASKS/LOAD_TASK_FAILED'
export type LoadTaskFailedAction = Action<typeof LOAD_TASK_FAILED>
export const loadTaskFailedAction = emptyActionCreator<LoadTaskFailedAction>(LOAD_TASK_FAILED)

export const PREVIEW_TASK_LOCATION = 'TASKS/PREVIEW_TASK_LOCATION'
export type PreviewTaskLocationAction = PayloadAction<typeof PREVIEW_TASK_LOCATION, Task>
export const previewTaskLocationAction = payloadActionCreator<PreviewTaskLocationAction, Task>(
    PREVIEW_TASK_LOCATION,
)

// timeline
export const OPEN_TIMELINE = 'TASKS/OPEN_TIMELINE'
export type OpenTimelineAction = Action<typeof OPEN_TIMELINE>
export const openTimelineAction = emptyActionCreator<OpenTimelineAction>(OPEN_TIMELINE)

export const SET_TIMELINE_TASK_FILTER = 'TASKS/SET_TIMELINE_TASK_FILTER'
export type SetTimelineTaskFilterAction = PayloadAction<
    typeof SET_TIMELINE_TASK_FILTER,
    TimelineFilterState
>
export const setTimelineTaskFilterAction = payloadActionCreator<
    SetTimelineTaskFilterAction,
    TimelineFilterState
>(SET_TIMELINE_TASK_FILTER)

export const LOAD_TIMELINE_TASKS = 'TASKS/LOAD_TIMELINE_TASKS'
export type LoadTimelineTasksAction = Action<typeof LOAD_TIMELINE_TASKS>
export const loadTimelineTasksAction =
    emptyActionCreator<LoadTimelineTasksAction>(LOAD_TIMELINE_TASKS)

export const LOAD_TIMELINE_TASKS_SUCCESS = 'TASKS/LOAD_TIMELINE_TASKS_SUCCESS'
export type LoadTimelineTasksSuccessAction = PayloadAction<
    typeof LOAD_TIMELINE_TASKS_SUCCESS,
    [TaskSnapshot[], TimeRange | undefined]
>
export const loadTimelineTasksSuccessAction = payloadActionCreator<
    LoadTimelineTasksSuccessAction,
    [TaskSnapshot[], TimeRange | undefined]
>(LOAD_TIMELINE_TASKS_SUCCESS)

export const TASK_MANAGER_TIMELINE_PARENT = 'TASKS/TASK_MANAGER_TIMELINE_PARENT'
export type TaskManagerTimelineParentAction = PayloadAction<
    typeof TASK_MANAGER_TIMELINE_PARENT,
    TimelineAction
>
export const taskManagerTimelineParentAction = payloadActionCreator<
    TaskManagerTimelineParentAction,
    TimelineAction
>(TASK_MANAGER_TIMELINE_PARENT)

// modal
export const SET_TASK_CREATE_MODAL_OPEN = 'TASKS/SET_TASK_CREATE_MODAL_OPEN'
export type SetTaskCreateModalOpenAction = PayloadAction<typeof SET_TASK_CREATE_MODAL_OPEN, boolean>
export const setTaskCreateModalOpenAction = payloadActionCreator<
    SetTaskCreateModalOpenAction,
    boolean
>(SET_TASK_CREATE_MODAL_OPEN)

export const SET_TASK_EDIT_MODAL_OPEN = 'TASKS/SET_TASK_EDIT_MODAL_OPEN'
export type SetTaskEditModalOpenAction = PayloadAction<typeof SET_TASK_EDIT_MODAL_OPEN, boolean>
export const setTaskEditModalOpenAction = payloadActionCreator<SetTaskEditModalOpenAction, boolean>(
    SET_TASK_EDIT_MODAL_OPEN,
)

export const SET_PREVIEW_LOCATION_MODAL_OPEN = 'TASKS/SET_PREVIEW_LOCATION_MODAL_OPEN'
export type SetPreviewLocationModalOpenAction = PayloadAction<
    typeof SET_PREVIEW_LOCATION_MODAL_OPEN,
    boolean
>
export const setPreviewLocationModalOpenAction = payloadActionCreator<
    SetPreviewLocationModalOpenAction,
    boolean
>(SET_PREVIEW_LOCATION_MODAL_OPEN)

export const SET_FORM_PRISTINE = 'TASKS/SET_FORM_PRISTINE'
export type SetFormPristineAction = PayloadAction<typeof SET_FORM_PRISTINE, boolean>
export const setFormPristineAction = payloadActionCreator<SetFormPristineAction, boolean>(
    SET_FORM_PRISTINE,
)

export const SAVE_TASK = 'TASKS/SAVE_TASK'
export type SaveTaskAction = PayloadAction<typeof SAVE_TASK, TaskActionPayload>
export const saveTaskAction = payloadActionCreator<SaveTaskAction, TaskActionPayload>(SAVE_TASK)

export const SAVE_TASK_SUCCESS = 'TASKS/SAVE_TASK_SUCCESS'
export type SaveTaskSuccessAction = PayloadAction<typeof SAVE_TASK_SUCCESS, TaskActionPayload>
export const saveTaskSuccessAction = payloadActionCreator<SaveTaskSuccessAction, TaskActionPayload>(
    SAVE_TASK_SUCCESS,
)

export const CHANGE_FORM = 'TASKS/CHANGE_FORM'
export type ChangeFormAction = PayloadAction<typeof CHANGE_FORM, TaskFormPayload>
export const changeFormAction = payloadActionCreator<ChangeFormAction, TaskFormPayload>(CHANGE_FORM)

export const SAVE_LOCATION = 'TASKS/SAVE_LOCATION'
export type SaveLocationAction = Action<typeof SAVE_LOCATION>
export const saveLocationAction = emptyActionCreator<SaveLocationAction>(SAVE_LOCATION)

export const TASK_EDIT_MAP_PARENT = 'TASKS/TASK_EDIT_MAP_PARENT'
export type TaskEditMapParentAction = PayloadAction<typeof TASK_EDIT_MAP_PARENT, MapAction>
export const taskEditMapParentAction = payloadActionCreator<TaskEditMapParentAction, MapAction>(
    TASK_EDIT_MAP_PARENT,
)

export const taskEditMapAction: MapActionCreators = parentActionWrapper(
    taskEditMapParentAction,
    mapActionCreators,
)

export const TASK_MANAGER_ATTACHMENTS_PARENT = 'TASKS/TASK_MANAGER_ATTACHMENTS_PARENT'
export type TaskManagerAttachmentsParentAction = PayloadAction<
    typeof TASK_MANAGER_ATTACHMENTS_PARENT,
    AttachmentsAction
>
export const taskManagerAttachmentsParentAction = payloadActionCreator<
    TaskManagerAttachmentsParentAction,
    AttachmentsAction
>(TASK_MANAGER_ATTACHMENTS_PARENT)

export const LOAD_METADATA = 'TASKS/LOAD_METADATA'
export type LoadMetadataAction = Action<typeof LOAD_METADATA>
export const loadMetadataAction = emptyActionCreator<LoadMetadataAction>(LOAD_METADATA)

export const LOAD_METADATA_SUCCESS = 'TASKS/LOAD_METADATA_SUCCESS'
export type LoadMetadataSuccessAction = PayloadAction<typeof LOAD_METADATA_SUCCESS, TaskMetadata>
export const loadMetadataSuccessAction = payloadActionCreator<
    LoadMetadataSuccessAction,
    TaskMetadata
>(LOAD_METADATA_SUCCESS)

// sync tasks
export const SYNC_TASK_LIST = 'TASKS/SYNC_TASK_LIST'
export type SyncTaskListAction = PayloadAction<typeof SYNC_TASK_LIST, boolean>
export const syncTaskListAction = payloadActionCreator<SyncTaskListAction, boolean>(SYNC_TASK_LIST)

export const SYNC_TASK_TIMELINE = 'TASKS/SYNC_TASK_TIMELINE'
export type SyncTaskTimelineAction = PayloadAction<typeof SYNC_TASK_TIMELINE, boolean>
export const syncTaskTimelineAction = payloadActionCreator<SyncTaskTimelineAction, boolean>(
    SYNC_TASK_TIMELINE,
)

export const SYNC_TASK = 'TASKS/SYNC_TASK'
export type SyncTaskAction = PayloadAction<typeof SYNC_TASK, TaskId | undefined>
export const syncTaskAction = payloadActionCreator<SyncTaskAction, TaskId | undefined>(SYNC_TASK)

export const RESET_TASK_MODAL_STATE = 'TASKS/RESET_TASK_MODAL_STATE'
export type ResetTaskModalStateAction = PayloadAction<
    typeof RESET_TASK_MODAL_STATE,
    { siteLocation: AosAirport; startDate: DateTime; endTime: DateTime }
>
export const resetTaskModalStateAction = payloadActionCreator<
    ResetTaskModalStateAction,
    { siteLocation: AosAirport; startDate: DateTime; endTime: DateTime }
>(RESET_TASK_MODAL_STATE)

// reporting
export const LOAD_ANIMALS_REPORT = 'TASKS/LOAD_ANIMALS_REPORT'
export type LoadAnimalsReportAction = Action<typeof LOAD_ANIMALS_REPORT>
export const loadAnimalsReportAction =
    emptyActionCreator<LoadAnimalsReportAction>(LOAD_ANIMALS_REPORT)

export const LOAD_ANIMALS_REPORT_SUCCESS = 'TASKS/LOAD_ANIMALS_REPORT_SUCCESS'
export type LoadAnimalsReportSuccessAction = PayloadAction<
    typeof LOAD_ANIMALS_REPORT_SUCCESS,
    AnimalsReportData
>
export const loadAnimalsReportSuccessAction = payloadActionCreator<
    LoadAnimalsReportSuccessAction,
    AnimalsReportData
>(LOAD_ANIMALS_REPORT_SUCCESS)

export const LOAD_CHEMICALS_REPORT = 'TASKS/LOAD_CHEMICALS_REPORT'
export type LoadChemicalsReportAction = Action<typeof LOAD_CHEMICALS_REPORT>
export const loadChemicalsReportAction =
    emptyActionCreator<LoadChemicalsReportAction>(LOAD_CHEMICALS_REPORT)

export const LOAD_CHEMICALS_REPORT_SUCCESS = 'TASKS/LOAD_CHEMICALS_REPORT_SUCCESS'
export type LoadChemicalsReportSuccessAction = PayloadAction<
    typeof LOAD_CHEMICALS_REPORT_SUCCESS,
    ChemicalsReportData
>
export const loadChemicalsReportSuccessAction = payloadActionCreator<
    LoadChemicalsReportSuccessAction,
    ChemicalsReportData
>(LOAD_CHEMICALS_REPORT_SUCCESS)

export const CHANGE_FILTER = 'TASKS/CHANGE_FILTER'
export type ChangeFilterAction = PayloadAction<typeof CHANGE_FILTER, ReportsFilters>
export const changeFilterAction = payloadActionCreator<ChangeFilterAction, ReportsFilters>(
    CHANGE_FILTER,
)

export const TOGGLE_GROUP = 'TASKS/TOGGLE_GROUP_TIMELINE'
export type ToggleGroupAction = PayloadAction<typeof TOGGLE_GROUP, TaskProcessType>
export const toggleGroupAction = payloadActionCreator<ToggleGroupAction, TaskProcessType>(
    TOGGLE_GROUP,
)

export const EXPORT_TASKS = 'TASKS/EXPORT_TASKS'
export type ExportTasksAction = PayloadAction<typeof EXPORT_TASKS, Omit<TaskExport, 'siteLocation'>>
export const exportTasksAction = payloadActionCreator<
    ExportTasksAction,
    Omit<TaskExport, 'siteLocation'>
>(EXPORT_TASKS)

export const EXPORT_TASK_LOGS = 'TASKS/EXPORT_TASK_LOGS'
export type ExportTaskLogsAction = PayloadAction<typeof EXPORT_TASK_LOGS, number>
export const exportTaskLogsAction = payloadActionCreator<ExportTaskLogsAction, number>(
    EXPORT_TASK_LOGS,
)

export const OPEN_TASK_DURATION_MODAL = 'TASKS/OPEN_TASK_DURATION_MODAL'
export type OpenTaskDurationModalAction = PayloadAction<
    typeof OPEN_TASK_DURATION_MODAL,
    TaskUpdateMode
>
export const openTaskDurationModalAction = payloadActionCreator<
    OpenTaskDurationModalAction,
    TaskUpdateMode
>(OPEN_TASK_DURATION_MODAL)

export const CLOSE_TASK_DURATION_MODAL = 'TASKS/CLOSE_TASK_DURATION_MODAL'
export type CloseTaskDurationModalAction = PayloadAction<
    typeof CLOSE_TASK_DURATION_MODAL,
    TaskDuration | null
>
export const closeTaskDurationModalAction = payloadActionCreator<
    CloseTaskDurationModalAction,
    TaskDuration | null
>(CLOSE_TASK_DURATION_MODAL)

export const SET_TASK_DURATION = 'TASKS/SET_TASK_DURATION'
export type SetTaskDurationAction = PayloadAction<typeof SET_TASK_DURATION, number>
export const setTaskDurationAction = payloadActionCreator<SetTaskDurationAction, number>(
    SET_TASK_DURATION,
)

export const CLONE_TASK = 'TASKS/CLONE_TASK'
export type CloneTaskAction = PayloadAction<typeof CLONE_TASK, Task>
export const cloneTaskAction = payloadActionCreator<CloneTaskAction, Task>(CLONE_TASK)

export const OPEN_TASK_FOR_CLONE = 'TASKS/OPEN_TASK_FOR_CLONE'
export type OpenTaskForCloneAction = PayloadAction<typeof OPEN_TASK_FOR_CLONE, [Task, AosAirport]>
export const openTaskForCloneAction = payloadActionCreator<
    OpenTaskForCloneAction,
    [Task, AosAirport]
>(OPEN_TASK_FOR_CLONE)

export const CHANGE_RECURRENT_TASK_CHECKLIST_STATE = 'TASKS/CHANGE_RECURRENT_TASK_CHECKLIST_STATE'
export type ChangeRecurrentTaskChecklistStateAction = PayloadAction<
    typeof CHANGE_RECURRENT_TASK_CHECKLIST_STATE,
    TaskRecurrentChecklistItemChange
>
export const changeRecurrentTaskChecklistStateAction = payloadActionCreator<
    ChangeRecurrentTaskChecklistStateAction,
    TaskRecurrentChecklistItemChange
>(CHANGE_RECURRENT_TASK_CHECKLIST_STATE)

export const CHANGE_FIRST_RECURRENT_TASK_CHECKLIST_STATE =
    'TASKS/CHANGE_FIRST_RECURRENT_TASK_CHECKLIST_STATE'
export type ChangeFirstRecurrentTaskChecklistStateAction = PayloadAction<
    typeof CHANGE_FIRST_RECURRENT_TASK_CHECKLIST_STATE,
    TaskRecurrentFirstChecklistItemChange
>
export const changeFirstRecurrentTaskChecklistStateAction = payloadActionCreator<
    ChangeFirstRecurrentTaskChecklistStateAction,
    TaskRecurrentFirstChecklistItemChange
>(CHANGE_FIRST_RECURRENT_TASK_CHECKLIST_STATE)

export const CHANGE_TASK_CHECKLIST_STATE = 'TASKS/CHANGE_TASK_CHECKLIST_STATE'
export type ChangeTaskChecklistStateAction = PayloadAction<
    typeof CHANGE_TASK_CHECKLIST_STATE,
    TaskChecklistItemChange
>
export const changeTaskChecklistStateAction = payloadActionCreator<
    ChangeTaskChecklistStateAction,
    TaskChecklistItemChange
>(CHANGE_TASK_CHECKLIST_STATE)

export type TaskManagerAction =
    | OpenTimelineAction
    | LoadTasksAction
    | LoadTasksSuccessAction
    | LoadTaskFailedAction
    | LoadTaskAction
    | LoadTaskSuccessAction
    | LoadTimelineTasksAction
    | LoadTimelineTasksSuccessAction
    | OpenTaskAction
    | OpenTaskForEditAction
    | EditTaskAction
    | DeleteTaskAction
    | SendMessageAction
    | SendMessageSuccessAction
    | ChangeStatusAction
    | PreviewTaskLocationAction
    | SetTimelineTaskFilterAction
    | SetTaskCreateModalOpenAction
    | SetTaskEditModalOpenAction
    | SetPreviewLocationModalOpenAction
    | SetFormPristineAction
    | ChangeFormAction
    | SaveLocationAction
    | TaskEditMapParentAction
    | TaskManagerTimelineParentAction
    | TaskManagerAttachmentsParentAction
    | LoadMetadataAction
    | LoadMetadataSuccessAction
    | SaveTaskAction
    | ResetTaskModalStateAction
    | LoadAnimalsReportSuccessAction
    | LoadChemicalsReportSuccessAction
    | ChangeFilterAction
    | ToggleGroupAction
    | ExportTasksAction
    | OpenTaskDurationModalAction
    | CloseTaskDurationModalAction
    | SetTaskDurationAction
    | ExportTaskLogsAction
    | SaveTaskSuccessAction
    | OpenTaskForCloneAction
