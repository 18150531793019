import { zodResolver } from '@hookform/resolvers/zod'
import { ActionModal } from 'aos-components/src/components/modal/ActionModal/ActionModal'
import { ActionModalContent } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { IExportForm } from 'aos-helpers/src/helpers/TaskExport'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { taskCategories } from 'aos-services/src/services/tasks/types/TaskCategory'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Step, Stepper, StepperRef } from 'aos-ui/src/components/stepper/Stepper'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { EnumValues } from 'enum-values'
import { isEmpty, isEqual } from 'lodash'
import React, { createRef, FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { State } from '../../../../core/state'
import { allAirports } from '../../../../core/userProfileModal/helpers/userProfileOptions'
import { AirportSelection } from './AirportSelection'
import { TaskDataSelection } from './TaskDataSelection'
import { TimeFrame } from './TimeFrame'
import { exportFormSchema } from './validation'

interface ExportTasksModalProps {
    isOpen: boolean
    onClose(): void
    onSubmit(v: IExportForm): void
    initialTaskVariant?: TaskVariant
}

export const ExportTasksModal: FC<ExportTasksModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    initialTaskVariant,
}) => {
    const currentAirport = useSelector((state: State) => state.auth.currentUser.siteLocation)

    const defaultValues = {
        taskTypes: (
            EnumValues.getValues(TaskVariant).map(t => [
                t as TaskVariant,
                false,
            ]) as CheckboxItem<TaskVariant>[]
        ).map(t => {
            if (initialTaskVariant && t[0] === initialTaskVariant) {
                return [t[0], true]
            }
            return t
        }),
        categories: taskCategories,
        airports: allAirports.map(
            airport => [airport, airport === currentAirport] as CheckboxItem<AosAirport>,
        ),
    } as IExportForm
    const [previousState, setPreviousState] = useState<IExportForm>(defaultValues)
    const [isPendingModalOpen, setIsPendingModalOpen] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const formMethods = useForm<IExportForm>({
        defaultValues,
        resolver: zodResolver(exportFormSchema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    })
    const stepperRef = createRef<StepperRef>()

    const steps: Step[] = [
        { title: translate('export-tasks.data'), content: <TaskDataSelection /> },
        { title: translate('export-tasks.airport'), content: <AirportSelection /> },
        { title: translate('export-tasks.timeframe'), content: <TimeFrame /> },
    ]
    const submit = () => {
        formMethods.handleSubmit(value => {
            onSubmit(value)
            setCurrentStep(0)
            setIsPendingModalOpen(true)
        })()
    }

    const validateNextStep = async () => {
        if (currentStep === 0) {
            await formMethods.trigger(['taskTypes', 'categories'])
        } else if (currentStep === 1) {
            await formMethods.trigger(['airports'])
        }
    }

    const isStepDisabled = (step: number) => {
        if (step === 0) {
            return (
                !isEmpty(formMethods.formState.errors.taskTypes) ||
                !isEmpty(formMethods.formState.errors.categories)
            )
        } else if (step === 1) {
            return !isEmpty(formMethods.formState.errors.airports)
        }
        return false
    }

    const footer = () => {
        if (currentStep === steps.length - 1) {
            return (
                <Box justify='space-around' row>
                    <FormButton
                        label={translate('export-tasks.back')}
                        onClick={() => stepperRef.current?.prevStep()}
                        variant={FormButtonVariant.PrimaryOutlined}
                    />
                    <FormButton
                        label={translate('export-tasks.export')}
                        onClick={submit}
                        variant={FormButtonVariant.Primary}
                    />
                </Box>
            )
        } else {
            return currentStep === 0 ? (
                <Box row justify='center'>
                    <FormButton
                        label={translate('export-tasks.next')}
                        onClick={() => {
                            validateNextStep()
                            stepperRef.current?.nextStep()
                        }}
                        disabled={isStepDisabled(currentStep)}
                        variant={FormButtonVariant.Primary}
                    />
                </Box>
            ) : (
                <Box row justify='space-around'>
                    <FormButton
                        label={translate('export-tasks.back')}
                        onClick={() => stepperRef.current?.prevStep()}
                        variant={FormButtonVariant.PrimaryOutlined}
                    />
                    <FormButton
                        label={translate('export-tasks.next')}
                        onClick={() => {
                            validateNextStep()
                            stepperRef.current?.nextStep()
                        }}
                        disabled={isStepDisabled(currentStep)}
                        variant={FormButtonVariant.Primary}
                    />
                </Box>
            )
        }
    }

    const changeTaskValue = (value: boolean) =>
        formMethods.setValue(
            'taskTypes',
            formMethods
                .watch('taskTypes')
                .map(t =>
                    t[0] === TaskVariant.TASKS ? [t[0], value] : t,
                ) as CheckboxItem<TaskVariant>[],
        )

    useEffect(() => {
        formMethods.reset()
        setCurrentStep(0)
    }, [isOpen])

    useEffect(() => {
        if (isOpen) {
            const categories = formMethods.watch('categories')
            const taskTypes = formMethods.watch('taskTypes')
            const isTasksChecked = taskTypes.some(t => t[0] === TaskVariant.TASKS && t[1])
            const categoriesChanged = !isEqual(categories, previousState.categories)
            const taskVariantChanged =
                isTasksChecked !==
                previousState.taskTypes.find(q => q[0] === TaskVariant.TASKS)?.[1]
            if (taskVariantChanged && isTasksChecked) {
                formMethods.setValue('categories', taskCategories)
            } else if (categories.length > 0 && !isTasksChecked && categoriesChanged) {
                changeTaskValue(true)
            } else if (categories.length === 0 && isTasksChecked && categoriesChanged) {
                changeTaskValue(false)
            }
            setPreviousState(formMethods.watch())
        }
    }, [
        formMethods.watch('categories'),
        formMethods.watch('taskTypes'),
        JSON.stringify(previousState),
        isOpen,
    ])

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Modal
                    width={350}
                    isOpen={isOpen}
                    title={translate('export-tasks.title')}
                    modalKind={ModalKind.Auto}
                    closeAction={onClose}
                >
                    <ActionModalContent Footer={footer} className='padding-horizontal--x-large'>
                        <Stepper ref={stepperRef} steps={steps} onStepChange={setCurrentStep} />
                    </ActionModalContent>
                </Modal>
            </form>
            <ActionModal
                title={translate('export-tasks.pending')}
                description={translate('export-tasks.pending-description')}
                okLabel={translate('export-tasks.ok')}
                closeAction={() => setIsPendingModalOpen(false)}
                isOpen={isPendingModalOpen}
                Footer={() => <> </>}
                svg={SvgIcon.ExportChecklistTemplates}
            />
        </FormProvider>
    )
}
