import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MapLayer } from 'aos-map-engine/src/Types'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

import { LayersVisibilityCategory } from './LayersVisibilityCategory'

interface TerminalLayersVisibilityCategoryProps {
    bimMapLayers: MapLayer[]
    bimMapLayersVisibility: string[]
    setBimLayersVisibility(v: string[]): void
}

export const TerminalLayersVisibilityCategory = (props: TerminalLayersVisibilityCategoryProps) => {
    const { bimMapLayers, bimMapLayersVisibility, setBimLayersVisibility } = props

    const categories = bimMapLayers
        ?.map(i => i.category)
        .filter((value, index, arr) => arr.indexOf(value) === index)

    const terminalView = () =>
        categories.map(category => (
            <LayersVisibilityCategory
                key={category}
                category={category}
                bimMapLayerNames={bimMapLayers
                    .filter(i => i.category === category)
                    .map(i => i.name)}
                bimMapLayersVisibility={bimMapLayersVisibility}
                setBimLayersVisibility={setBimLayersVisibility}
            />
        ))

    return (
        <>
            <SidebarTitle>{translate('map.layer-selector.layers')}</SidebarTitle>
            {terminalView()}
        </>
    )
}

const SidebarTitle = styled(Text)`
    color: ${Color.Black};
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
`
