import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import {
    DiscountCoupon,
    DiscountCouponGroup,
    DiscountStore,
} from '../../services/discountModule/types/DiscountCoupon'

export const LOAD_DISCOUNT_COUPONS = 'DISCOUNT_MODULE/LOAD_DISCOUNT_COUPONS'
export type LoadDiscountCouponsAction = Action<typeof LOAD_DISCOUNT_COUPONS>
export const loadDiscountCoupons =
    emptyActionCreator<LoadDiscountCouponsAction>(LOAD_DISCOUNT_COUPONS)

export const LOAD_DISCOUNT_COUPONS_SUCCESS = 'DISCOUNT_MODULE/LOAD_DISCOUNT_COUPONS_SUCCESS'
export type LoadDiscountCouponsSuccessAction = PayloadAction<
    typeof LOAD_DISCOUNT_COUPONS_SUCCESS,
    DiscountCouponGroup
>
export const loadDiscountCouponsSuccess = payloadActionCreator<
    LoadDiscountCouponsSuccessAction,
    DiscountCouponGroup
>(LOAD_DISCOUNT_COUPONS_SUCCESS)

export const LOAD_DISCOUNT_COUPON_BY_ID = 'DISCOUNT_MODULE/LOAD_DISCOUNT_COUPON_BY_ID'
export type LoadDiscountCouponByIdAction = PayloadAction<
    typeof LOAD_DISCOUNT_COUPON_BY_ID,
    { id: number }
>
export const loadDiscountCouponById = payloadActionCreator<
    LoadDiscountCouponByIdAction,
    { id: number }
>(LOAD_DISCOUNT_COUPON_BY_ID)

export const LOAD_DISCOUNT_COUPON_BY_ID_SUCCESS =
    'DISCOUNT_MODULE/LOAD_DISCOUNT_COUPON_BY_ID_SUCCESS'
export type LoadDiscountCouponByIdSuccessAction = PayloadAction<
    typeof LOAD_DISCOUNT_COUPON_BY_ID_SUCCESS,
    DiscountCoupon
>
export const loadDiscountCouponByIdSuccess = payloadActionCreator<
    LoadDiscountCouponByIdSuccessAction,
    DiscountCoupon
>(LOAD_DISCOUNT_COUPON_BY_ID_SUCCESS)

export const LOAD_DISCOUNT_STORES = 'DISCOUNT_MODULE/LOAD_DISCOUNT_STORES'
export type LoadDiscountStoresAction = Action<typeof LOAD_DISCOUNT_STORES>
export const loadDiscountStores = emptyActionCreator<LoadDiscountStoresAction>(LOAD_DISCOUNT_STORES)

export const LOAD_DISCOUNT_STORES_SUCCESS = 'DISCOUNT_MODULE/LOAD_DISCOUNT_STORES_SUCCESS'
export type LoadDiscountStoresSuccessAction = PayloadAction<
    typeof LOAD_DISCOUNT_STORES_SUCCESS,
    DiscountStore[]
>
export const loadDiscountStoresSuccess = payloadActionCreator<
    LoadDiscountStoresSuccessAction,
    DiscountStore[]
>(LOAD_DISCOUNT_STORES_SUCCESS)

export const LOAD_DISCOUNT_STORE_BY_ID = 'DISCOUNT_MODULE/LOAD_DISCOUNT_STORE_BY_ID'
export type LoadDiscountStoreByIdAction = PayloadAction<
    typeof LOAD_DISCOUNT_STORE_BY_ID,
    { id: number }
>
export const loadDiscountStoreById = payloadActionCreator<
    LoadDiscountStoreByIdAction,
    { id: number }
>(LOAD_DISCOUNT_STORE_BY_ID)

export const LOAD_DISCOUNT_STORE_BY_ID_SUCCESS = 'DISCOUNT_MODULE/LOAD_DISCOUNT_STORE_BY_ID_SUCCESS'
export type LoadDiscountStoreByIdSuccessAction = PayloadAction<
    typeof LOAD_DISCOUNT_STORE_BY_ID_SUCCESS,
    DiscountStore
>
export const loadDiscountStoreByIdSuccess = payloadActionCreator<
    LoadDiscountStoreByIdSuccessAction,
    DiscountStore
>(LOAD_DISCOUNT_STORE_BY_ID_SUCCESS)

export const SET_SELECTED_DISCOUNT_STORE_FILTER =
    'DISCOUNT_MODULE/SET_SELECTED_DISCOUNT_STORE_FILTER'
export type SetSelectedDiscountStoreFilter = PayloadAction<
    typeof SET_SELECTED_DISCOUNT_STORE_FILTER,
    number
>
export const setSelectedDiscountStoreFilter = payloadActionCreator<
    SetSelectedDiscountStoreFilter,
    number
>(SET_SELECTED_DISCOUNT_STORE_FILTER)

export const CLEAR_SELECTED_DISCOUNT_STORE_FILTER =
    'DISCOUNT_MODULE/CLEAR_SELECTED_DISCOUNT_STORE_FILTER'
export type ClearSelectedDiscountStoreFilter = Action<typeof CLEAR_SELECTED_DISCOUNT_STORE_FILTER>
export const clearSelectedDiscountStoreFilter =
    emptyActionCreator<ClearSelectedDiscountStoreFilter>(CLEAR_SELECTED_DISCOUNT_STORE_FILTER)

export type DiscountModuleBaseActions =
    | LoadDiscountCouponsAction
    | LoadDiscountCouponsSuccessAction
    | LoadDiscountCouponByIdAction
    | LoadDiscountCouponByIdSuccessAction
    | LoadDiscountStoresAction
    | LoadDiscountStoresSuccessAction
    | LoadDiscountStoreByIdAction
    | LoadDiscountStoreByIdSuccessAction
    | SetSelectedDiscountStoreFilter
    | ClearSelectedDiscountStoreFilter
