import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'

import { changeFilterAction, loadAnimalsReportAction } from '../../../../core/tasks/actions'
import {
    animalsReportSelector,
    AnimalsSelectorState,
} from '../../../../core/tasks/reports/selectors/animals'
import { ReportsFilters } from '../../../../core/tasks/reports/state'
import { translateReportAirport } from '../../../../core/tasks/reports/types/ReportAirport'
import { getColor } from '../chemicals/chemicalsColor'
import { ReportChart } from '../components/ReportChart'
import { ReportSummaryTile } from '../components/ReportSummaryTile'
import { AnimalsReportContent } from './AnimalsReportContent'

interface AnimalsReportDispatchProps {
    loadReport(): void
    changeFilter(v: ReportsFilters): void
}

export const AnimalsReportComponent: FC<AnimalsSelectorState & AnimalsReportDispatchProps> = ({
    loadReport,
    filters,
    changeFilter,
    animals,
    chartState,
    maxYearRange,
    totalSummary,
    yearsSummary,
}) => {
    useEffect(() => {
        loadReport()
    }, [])

    return (
        <>
            <Box margin={24} alignItems='flex-end' row>
                <ReportSummaryTile
                    title={translate('reports.animals.usage')}
                    titleSlot={
                        <Box marginLeft={16} row>
                            <Icon
                                svg={SvgIcon.Flights}
                                color={Color.MenuButtonColor}
                                marginRight={8}
                            />
                            <Text size={12} color={Color.MenuButtonColor}>
                                {translateReportAirport(filters.airport)}
                            </Text>
                        </Box>
                    }
                    value={totalSummary.value}
                    description={translate('reports.animals.total-in', {
                        year: totalSummary.yearRange.join('-'),
                    })}
                    large
                />
                {yearsSummary.map(({ value, yearRange }, index) => (
                    <ReportSummaryTile
                        key={index}
                        value={value}
                        description={translate('reports.animals.total-in', {
                            year: yearRange.join('-'),
                        })}
                        color={getColor(index)}
                    />
                ))}
            </Box>
            <Box margin={24}>
                <AnimalsReportContent
                    filters={filters}
                    onChange={changeFilter}
                    animals={animals}
                    yearRange={maxYearRange}
                />
                <ReportChart
                    chartData={chartState}
                    getColor={getColor}
                    rotateBottomLabels={chartState.dataKeys.length > 8}
                />
            </Box>
        </>
    )
}

export const AnimalsReport = connect<AnimalsSelectorState, AnimalsReportDispatchProps>(
    animalsReportSelector,
    {
        loadReport: loadAnimalsReportAction,
        changeFilter: changeFilterAction,
    },
)(AnimalsReportComponent)
