import { IExportForm, mapFormToExportTaskPayload } from 'aos-helpers/src/helpers/TaskExport'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import {
    firefighterStopSyncAction,
    firefighterSyncAction,
} from 'aos-services/src/core/firefighters/actions'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exportTasksAction } from '../../../core/tasks/actions'
import { openFireFighterFormAction } from '../../../core/tasks/firefighters/actions'
import { useSyncHook } from '../../../hooks/useSync'
import { ExportTasksModal } from '../common/export/ExportTasksModal'
import { HeaderListView } from '../common/HeaderListView'
import { FilterType, TasksHeaderFilter } from '../common/TasksHeaderFilter'
import { FirefightersList } from './FirefightersList'

export const Firefighters = () => {
    const canEdit = useSelector(canEditTasksSelector)
    const [exportTaskOpen, setExportTaskOpen] = useState(false)
    const dispatch = useDispatch()
    const exportTasks = (r: IExportForm) => {
        dispatch(exportTasksAction(mapFormToExportTaskPayload(r)))
    }

    const submit = (r: IExportForm) => {
        setExportTaskOpen(false)
        exportTasks(r)
    }

    const onAdd = () => {
        dispatch(openFireFighterFormAction(undefined))
    }

    const onEdit = (shift: FirefighterShift) => {
        dispatch(openFireFighterFormAction(shift))
    }
    useSyncHook([firefighterSyncAction('list')], [firefighterStopSyncAction('list')])

    return (
        <>
            <PanelWithHeader
                header={
                    <HeaderListView
                        title={<TasksHeaderFilter value={FilterType.FireFighters} />}
                        createButton={
                            <Box row>
                                {canEdit && (
                                    <FormButton
                                        label={translate('firefighters.button.create')}
                                        onClick={onAdd}
                                        variant={FormButtonVariant.White}
                                    />
                                )}
                                <More iconVariant={IconVariant.Smart} marginLeft={16}>
                                    <MoreItem onClick={() => setExportTaskOpen(true)}>
                                        {translate('export-tasks.title')}
                                    </MoreItem>
                                </More>
                            </Box>
                        }
                    />
                }
            >
                <FirefightersList onEdit={onEdit} />
            </PanelWithHeader>
            <ExportTasksModal
                initialTaskVariant={TaskVariant.FIREFIGHTER_RESCUE_UNIT}
                isOpen={exportTaskOpen}
                onClose={() => setExportTaskOpen(false)}
                onSubmit={submit}
            />
        </>
    )
}
