import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { format, Query } from 'url'

import { TaskStatusUpdateDto } from '../../services/tasks/types/TaskStatus'
import { BaseRestService } from '../base/BaseRestService'
import { MapTaskDto } from './types/MapTaskDto'
import { TaskDto } from './types/TaskDto'
import { TaskSnapshotDto } from './types/TaskSnapshotDto'

class TasksRestServiceClass extends BaseRestService {
    public loadTasks(filter: string, query: Query): Promise<Pageable<TaskSnapshotDto>> {
        return this.getAndTransform<Pageable<TaskSnapshotDto>>(
            format({
                pathname: `/task-manager/tasks/${filter}`,
                query,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public createTask(payload: object): Promise<TaskDto> {
        return this.postJsonAndTransform<TaskDto>(
            format({
                pathname: '/task-manager/tasks',
            }),
            payload,
            BaseRestService.jsonTransformer,
        )
    }

    public updateTask(id: string, payload: object): Promise<TaskDto> {
        return this.patchJsonAndTransform<TaskDto>(
            format({
                pathname: `/task-manager/tasks/${id}`,
            }),
            payload,
            BaseRestService.jsonTransformer,
        )
    }

    public deleteTask(id: string): Promise<{}> {
        return this.deleteReq(
            format({
                pathname: `/task-manager/tasks/${id}`,
            }),
        )
    }

    public loadTask(id: string): Promise<TaskDto> {
        return this.getAndTransform<TaskDto>(
            format({
                pathname: `/task-manager/tasks/${id}`,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public loadTimelineTasks(query: Query): Promise<TaskSnapshotDto[]> {
        return this.getAndTransform<TaskSnapshotDto[]>(
            format({
                pathname: '/task-manager/tasks/timeline',
                query,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public getRecentTasks(query: Query): Promise<TaskSnapshotDto[]> {
        return this.getAndTransform<TaskSnapshotDto[]>(
            format({
                pathname: '/task-manager/tasks/recent',
                query,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public getUpcomingTasks(query: Query): Promise<TaskSnapshotDto[]> {
        return this.getAndTransform<TaskSnapshotDto[]>(
            format({
                pathname: '/task-manager/tasks/upcoming',
                query,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public changeStatus(id: string, status: TaskStatusUpdateDto): Promise<Response> {
        return this.postJson(
            format({
                pathname: `/task-manager/tasks/${id}/status`,
            }),
            status,
        )
    }

    public sendMessage(id: string, message: string): Promise<Response> {
        return this.postJson(
            format({
                pathname: `/task-manager/tasks/${id}/message`,
            }),
            { message },
        )
    }

    public loadMapTasks(bbox: string): Promise<MapTaskDto[]> {
        return this.getAndTransform<MapTaskDto[]>(
            format({
                pathname: '/task-manager/tasks/map',
                query: { bbox },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public exportTasks(query: Query): Promise<{}> {
        return this.getAndTransform(
            format({
                pathname: '/export-service/export',
                query: this.transformedQuery(query),
            }),
            BaseRestService.fileDownloadTransformer,
        )
    }

    public exportTaskLogsForTask(id: number): Promise<[Blob, string]> {
        return this.getAndTransform(
            format({
                pathname: `/task-manager/tasks/${id}/export`,
            }),
            BaseRestService.fileDownloadTransformer,
        )
    }

    public changeTaskChecklistItem(
        taskId: number,
        checklistId: number,
        itemId: number,
        done: boolean,
    ): Promise<TaskDto> {
        return this.patchJsonAndTransform<TaskDto>(
            `/task-manager/tasks/${taskId}/checklists/${checklistId}/items/${itemId}`,
            { done },
            BaseRestService.jsonTransformer,
        )
    }

    public changeRecurrentTaskChecklistItem(
        parentTaskId: number,
        instanceId: number,
        checklistId: number,
        itemId: number,
        done: boolean,
    ): Promise<TaskDto> {
        return this.patchJsonAndTransform<TaskDto>(
            `/task-manager/tasks/${parentTaskId}/${instanceId}/checklists/${checklistId}/items/${itemId}`,
            { done },
            BaseRestService.jsonTransformer,
        )
    }

    public firstChangeRecurrentTaskChecklistItem(
        parentTaskId: number,
        instanceId: number,
        templateId: number,
        seq: number,
        done: boolean,
    ): Promise<TaskDto> {
        return this.patchJsonAndTransform<TaskDto>(
            `/task-manager/tasks/${parentTaskId}/${instanceId}/checklists/template/${templateId}/seq/${seq}`,
            { done },
            BaseRestService.jsonTransformer,
        )
    }
}

export const tasksRestService = new TasksRestServiceClass()
