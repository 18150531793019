import { isDefined } from 'aos-helpers/src/helpers/Function'
import { emptyText } from 'aos-helpers/src/helpers/Text'
import { Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

import { Box, MarginBoxProps } from '../../base/Box'
import { Text, TextSize } from '../../base/Text'
import { TrendIcon } from './TrendIcon'
import { trendIncreasingGreenVariants, trendIncreasingRedVariants } from './TrendIconVariants'

const trendVariantToIcon = {
    [TrendChangeVariant.PreferredUp]: trendIncreasingGreenVariants,
    [TrendChangeVariant.PreferredDown]: trendIncreasingRedVariants,
}

export interface TrendProps extends MarginBoxProps {
    label?: ReactNode
    value?: number | string
    unit?: string
    subvalue?: number | string
    subunit?: string
    subunitInversed?: boolean
    trend?: Trend
    trendVariant?: TrendChangeVariant
    centered?: boolean
    sizeVariant?: TrendMeasureSizeVariant
}

export enum TrendMeasureSizeVariant {
    Small,
    Regular,
}

export const TrendMeasure: FCWithChildren<TrendProps> = props => {
    const {
        value,
        unit,
        subvalue,
        subunit,
        label,
        trend,
        trendVariant = TrendChangeVariant.PreferredUp,
        subunitInversed = false,
        sizeVariant = subvalue !== undefined
            ? TrendMeasureSizeVariant.Small
            : TrendMeasureSizeVariant.Regular,
        ...marginProps
    } = props
    const sizes: Record<TrendMeasureSizeVariant, { value: TextSize; unit: TextSize }> = {
        [TrendMeasureSizeVariant.Regular]: {
            value: 50 as TextSize,
            unit: 20,
        },
        [TrendMeasureSizeVariant.Small]: {
            value: 36 as TextSize,
            unit: 16,
        },
    }
    const subvalueColor = subunitInversed ? Color.WidgetSecondary : Color.WidgetPrimary
    const subunitColor = subunitInversed ? Color.WidgetPrimary : Color.WidgetSecondary

    const formattedValue =
        unit === Unit.Minute && typeof value === 'number' ? Math.round(value) : value

    return (
        <Box {...marginProps}>
            <Box row alignItems='baseline'>
                <Text
                    color={Color.WidgetPrimary}
                    weight='light'
                    size={sizes[sizeVariant].value}
                    lineHeight='small'
                >
                    {isDefined(formattedValue) ? formattedValue.toString() : emptyText()}
                </Text>
                {unit && (
                    <Text
                        color={Color.WidgetPrimary}
                        weight='light'
                        lineHeight='small'
                        marginLeft={4}
                        size={sizes[sizeVariant].unit}
                    >
                        {unit}
                    </Text>
                )}
                {trend && (
                    <TrendIcon
                        marginLeft={4}
                        trend={trend}
                        iconsVariants={trendVariantToIcon[trendVariant]}
                    />
                )}
            </Box>
            {isDefined(subvalue) && (
                <Box row marginTop={8}>
                    <Text size={14} color={subvalueColor} weight='light' lineHeight='small'>
                        {subvalue.toString()}
                    </Text>
                    {subunit && (
                        <Text
                            size={14}
                            color={subunitColor}
                            weight='light'
                            lineHeight='small'
                            paddingLeft={4}
                        >
                            {subunit}
                        </Text>
                    )}
                </Box>
            )}
            {label && (
                <Text
                    color={Color.WidgetSecondary}
                    size={12}
                    lineHeight='large'
                    textNoWrap
                    marginTop={8}
                >
                    {label}
                </Text>
            )}
        </Box>
    )
}
