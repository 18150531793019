import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistUnits } from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import {
    AosUnitTaskItem,
    EventPhase,
    eventPhaseOrder,
    UnitTaskGroup,
    UnitTaskStatus,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { AosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { PhaseProgressBar } from 'aos-ui/src/components/bar/PhaseProgressBar'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { chain } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { CollapsableUnitTaskList } from './CollapsableUnitTaskList'

interface UnitTaskChecklistProps {
    unitTasks: AosUnitTaskItem[]
    filter: AosChecklistUnits[]
    isApoc: boolean
    user: AosAdUser | undefined
    disabled: boolean
    changeTask(taskId: number, item: AosUnitTaskItem): void
}

export const UnitTaskChecklist: FC<UnitTaskChecklistProps> = ({
    unitTasks,
    isApoc,
    user,
    changeTask,
    disabled,
    filter,
}) => {
    const groupedUnitTasks: UnitTaskGroup[] = useMemo(
        () =>
            chain(unitTasks)
                .groupBy(q => q.eventPhase)
                .mapValues((v, k) => ({
                    group: k as EventPhase,
                    items: v,
                }))
                .values()
                .orderBy(q => eventPhaseOrder[q.group])
                .value(),
        [unitTasks],
    )

    const placeholder = useCallback(() => {
        return (
            <Box>
                <PanelPlaceholder
                    label={translate('event-checklist.no-unit-tasks')}
                    svg={SvgIcon.ChecklistPlaceholder}
                />
            </Box>
        )
    }, [])

    const renderUnitTask = useCallback(
        (unitTaskGroup: { group: EventPhase; items: AosUnitTaskItem[] }, index: number) => (
            <UnitTaskChecklistSection
                userEmail={user?.email!}
                originalUnitTaskGroup={groupedUnitTasks[index]}
                changeTask={changeTask}
                groupName={unitTaskGroup.group}
                items={unitTaskGroup.items.filter(q => filter.includes(q.unit))}
                unitRole={user?.unit!}
                isApoc={isApoc}
                disabled={disabled}
            />
        ),
        [changeTask, filter, isApoc, disabled, user?.unit],
    )

    const renderUnitTasks = useCallback(
        (groupedUnitTasks: { group: EventPhase; items: AosUnitTaskItem[] }[]) => {
            return (
                <Box className='event-checklists text--selectable'>
                    {groupedUnitTasks.map((group, index) => renderUnitTask(group, index))}
                </Box>
            )
        },
        [unitTasks, filter],
    )

    return groupedUnitTasks && groupedUnitTasks.length
        ? renderUnitTasks(groupedUnitTasks)
        : placeholder()
}

const UnitTaskChecklistSection: FC<{
    originalUnitTaskGroup: UnitTaskGroup
    groupName: EventPhase
    items: AosUnitTaskItem[]
    isApoc: boolean
    disabled: boolean
    unitRole: string
    userEmail: string
    changeTask(taskId: number, item: AosUnitTaskItem): void
}> = ({
    groupName,
    items,
    originalUnitTaskGroup,
    unitRole,
    userEmail,
    isApoc,
    disabled,
    changeTask,
}) => {
    const [collapsed, setCollapsed] = useState(
        originalUnitTaskGroup.items.some(q => q.status !== UnitTaskStatus.TO_DO),
    )

    useEffect(() => {
        setCollapsed(
            originalUnitTaskGroup.items.every(
                q => q.status === UnitTaskStatus.DONE || q.status === UnitTaskStatus.SKIPPED,
            ),
        )
    }, [originalUnitTaskGroup])

    return (
        <Box
            margin={8}
            borderWidth={2}
            padding={18}
            rounded
            border={Color.FlightGrey}
            bg={Color.DarkBlue}
        >
            <PhaseProgressBar
                maxValue={originalUnitTaskGroup.items.length}
                seriesConfig={{
                    leftColor: Color.ProgressBarGradient,
                    rightColor: Color.FlightGrey,
                }}
                value={
                    originalUnitTaskGroup.items.filter(
                        q =>
                            q.status === UnitTaskStatus.DONE || q.status === UnitTaskStatus.SKIPPED,
                    ).length
                }
                minValue={0}
            />
            <CollapsableUnitTaskList
                userEmail={userEmail}
                isApoc={isApoc}
                disabled={disabled}
                userRole={unitRole}
                name={groupName}
                isCollapsed={collapsed}
                items={items}
                onToggle={() => setCollapsed(!collapsed)}
                onTaskChange={changeTask}
            />
        </Box>
    )
}
