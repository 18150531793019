import { cx } from 'aos-components/src/components/base/ClassNames'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    cdmMetricCurrentSubTypeSelector,
    cdmMetricRangesSelector,
    cdmTileMetricRangesSelector,
    cdmTileValueSelector,
    cdmValueSelector,
    makeCdmMetricSubTypesSelector,
} from 'aos-services/src/core/statusDashboardData/cdm/selectors'
import {
    CdmMetricSubType,
    translateCdmMetricSubType,
} from 'aos-services/src/services/airportStatus/cdm/types/CdmMetricSubType'
import {
    CdmMetricType,
    translateCmdMetricTypeTitle,
} from 'aos-services/src/services/airportStatus/cdm/types/CdmMetricType'
import { CdmRange } from 'aos-services/src/services/airportStatus/cdm/types/CdmRange'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { Gauge } from 'aos-ui/src/components/gauge/Gauge'
import { GaugePlaceholder } from 'aos-ui/src/components/gauge/GaugePlaceholder'
import { GaugeText } from 'aos-ui/src/components/gauge/GaugeText'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { setCdmFiltersStateAction } from '../../../../core/statusDashboard/actions'
import { CdmItemState } from '../../../../services/statusDashboard/types/itemStates/CdmItemState'

const translateDescription = translateEnum<CdmMetricType>(CdmMetricType, 'dashboard.cdm.desc')
const translateTooltip = translateEnum<CdmMetricType>(CdmMetricType, 'dashboard.cdm.tooltip')

interface CdmGaugeProps extends CdmGaugeStateProps, CdmGaugeDispatchProps {
    metricType: CdmMetricType
    itemState: CdmItemState
    horizontal?: boolean
    itemId: number
}

class CdmGaugeClass extends PureComponent<CdmGaugeProps> {
    public render() {
        const { metricType, subTypes, currentSubType, setCdmFiltersState, horizontal } = this.props
        return (
            <Box
                className={cx('container container--justify-center full-height cdm-widget__item', {
                    'container--vertical': !horizontal,
                    'container--horizontal-reversed': horizontal,
                })}
            >
                <Box
                    className={cx('text__m-white', {
                        'text--center flex-shrink--0': !horizontal,
                        'text--left': horizontal,
                    })}
                >
                    {translateCmdMetricTypeTitle(metricType)}
                    <Tooltip placement='top' body={translateTooltip(metricType)} withArrow>
                        <div>
                            <Text paddingTop={4} color={Color.WidgetSecondary} size={12}>
                                {translateDescription(metricType)}
                            </Text>
                        </div>
                    </Tooltip>
                </Box>
                <Box className={cx(!horizontal && 'flex--auto')}>{this.renderGauge()}</Box>
                {setCdmFiltersState && (
                    <Centered shrink={0} paddingTop={8}>
                        <Dropdown
                            variant={DropdownVariant.BlackGrey}
                            value={currentSubType}
                            items={subTypes}
                            valueRenderer={translateCdmMetricSubType}
                            onChange={this.handleChangeTime}
                            width={DropdownWidth.Std}
                            tooltip={translate('dashboard.cdm.select.tooltip')}
                        />
                    </Centered>
                )}
            </Box>
        )
    }

    private renderGauge() {
        const { cdmValue } = this.props
        const ranges = this.getRanges()

        // eslint-disable-next-line eqeqeq
        if (cdmValue == null || !ranges.length) {
            return <GaugePlaceholder noDataText={translate('dashboard.cdm.no-data')} />
        }

        return (
            <Gauge currentValue={cdmValue} ranges={this.getRanges()}>
                {this.renderValue(cdmValue)}
            </Gauge>
        )
    }

    private renderValue = (value: number) => (size: number) =>
        (
            <GaugeText size={size} unit='MIN'>
                {value}
            </GaugeText>
        )

    private getRanges(): GaugeRange[] {
        return this.props.cdmRanges as unknown as GaugeRange[]
    }

    private handleChangeTime = (value: CdmMetricSubType) => {
        const { itemId, metricType } = this.props
        this.props.setCdmFiltersState!([itemId, { [metricType]: value }])
    }
}

const Centered = styled(Box)`
    margin: 0 auto;
`

interface CdmGaugeStateProps {
    cdmValue: number | undefined
    cdmRanges: CdmRange[]
    subTypes: string[]
    currentSubType?: CdmMetricSubType
}

interface CdmGaugeDispatchProps {
    setCdmFiltersState?: typeof setCdmFiltersStateAction
}

const makeSelector = () =>
    createStructuredSelector({
        cdmValue: cdmValueSelector,
        cdmRanges: cdmMetricRangesSelector,
        subTypes: makeCdmMetricSubTypesSelector(),
        currentSubType: cdmMetricCurrentSubTypeSelector,
    })

export const CdmGauge = connect<CdmGaugeStateProps, CdmGaugeDispatchProps>(makeSelector, {
    setCdmFiltersState: setCdmFiltersStateAction,
})(CdmGaugeClass)

export const CdmTileGauge = connect<CdmGaugeStateProps>(
    createStructuredSelector({
        cdmValue: cdmTileValueSelector,
        cdmRanges: cdmTileMetricRangesSelector,
        subTypes: makeCdmMetricSubTypesSelector(),
    }),
)(CdmGaugeClass)
