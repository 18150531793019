import { LayerProvider, MapProvider } from './Types'

export const createProviderFactory = (providerName: LayerProvider): MapProvider => {
    switch (providerName) {
        case LayerProvider.Arcgis:
            const { ArcgisProvider } = require('./concrete/ArcgisProvider')
            return new ArcgisProvider()
        case LayerProvider.Vektor:
            const { VektorProvider } = require('./concrete/VektorProvider')
            return new VektorProvider()
        default:
            throw new Error(`Unknown provider: ${providerName}`)
    }
}
