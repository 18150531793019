import { UploadedAttachment } from '../../attachments/types/UploadedAttachment'

export enum DiscountArea {
    LANDSIDE = 'LANDSIDE',
    SCHENGEN = 'SCHENGEN',
    NON_SCHENGEN = 'NON_SCHENGEN',
}

export enum DiscountType {
    DISCOUNT = 'DISCOUNT',
    SPECIAL_OFFER = 'SPECIAL_OFFER',
}

export type DiscountStore = {
    id: number
    name: string
    area: DiscountArea
    location: string
    logo: UploadedAttachment
}
export type DiscountCoupon = {
    id: number
    discountStore: DiscountStore
    type: DiscountType
    title: string
    discountValue: string | null
    specialOfferValue: string | null
}

export type DiscountCouponGroup = {
    [key in keyof typeof DiscountArea]?: DiscountCoupon[]
}

export type DiscountCouponDTO = {
    title: string
    type: DiscountType
    discountStoreRef: Pick<DiscountStore, 'id'>
    discountValue?: string
    specialOfferValue?: string
}

export type DiscountStoreDTO = Omit<DiscountStore, 'id'>
